import React, { Component } from 'react';
import { apiSendContact } from '../../Api/Contact/Contact';
import {
  HospitalisationStep0Template,
  HospitalisationStep1Template,
  HospitalisationStep2Template,
  HospitalisationStep3Template,
  HospitalisationStep4Template,
  HospitalisationStep5Template
} from '../../Templates';
import { isNumber, validateEmail } from '../../Validation';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { apiGetMembers } from '../../Api/Profile/Profile';


const HospitalisationOptions = [
  { text: 'Médecine chirugicale' },
  { text: 'Observation' },
  { text: 'Psychiatrie' },
  { text: 'Accouchement voie basse' },
  { text: 'Accouchement césarienne' },
  { text: 'Soins externes' }
];
class Hospitalisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      files: [],
      data: {
        admissionDate: null,
        hospitalisationNature: null,
        hospitalisationDuration: '',
        facilityName: '',
        adresse: '',
        cp: '',
        city: '',
        fax: '',
        tel: '',
        mail: '',
        codeDMT: ''
      },
      currentStep: 0,
      benificiaireList: props.profile,
      benificiaireMemberList: [],
      benificiaireMemberContentList: [],
      benificiairePicked: {
        name: '',
        value: null
      },
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.getAllBeneficiairyMembers();

    this.props.history.push({
      pathname: '/hospitalisation',
      search: `?category=informations`
    })
  }

  getAllBeneficiairyMembers = async () => {
    const response = await apiGetMembers();
    if (response.status === 200) {
      this.setState({
        benificiaireMemberList: response.benificiaries.map((elem) => { return { text: `${elem.firstName} ${elem.lastName}` } }),
        benificiaireMemberContentList: response.benificiaries
      });
    } else {
      // alert('Une erreur est survenue. Veuillez ré-essayer.');
    }
  };

  getMessage = () => {
    return `admission date: ${moment(
      new Date(this.state.data.admissionDate)
    ).format('DD/MM/YYYY')} 
    Durée d’hospitalisation: ${this.state.data.hospitalisationDuration}
    Nature d’hospitalisation: ${this.state.data.hospitalisationNature}
    benificiaire nom: ${this.state.benificiairePicked.name}
    Nom de l’établissement:${this.state.data.facilityName}
    Email:${this.state.data.mail}
    Adresse: ${this.state.data.adresse}
    Téléphone:${this.state.data.tel}
    CP:${this.state.data.cp}
    Fax:${this.state.data.fax}
    Ville:${this.state.data.city}
    Code DMT:${this.state.data.codeDMT}
    `;
  };

  sendContact = async () => {
    const body = new FormData();
    body.append("HospitalPostalAddress.Address", this.state.data.adresse)
    body.append("HospitalPostalAddress.PostCode", this.state.data.cp)
    body.append("HospitalPostalAddress.City", this.state.data.city)
    body.append("HospitalPostalAddress.Country", "France")

    body.append("HospitalMailPhoneFax.EmailAddress", this.state.data.mail)
    body.append("HospitalMailPhoneFax.FixPhoneNumber", this.state.data.tel)
    body.append("HospitalMailPhoneFax.Fax", this.state.data.fax)

    body.append("HospitalizedPerson.FirstName", this.state.benificiairePicked.value?.name)
    body.append("HospitalizedPerson.Name", this.state.benificiairePicked.value?.lastName)
    body.append("HospitalizedPerson.SocialSecurityNumber", this.state.benificiairePicked.value?.numeroSecuriteSociale)

    if (this.state.benificiairePicked.value?.related === "Spouse") {
      body.append("HospitalizedPerson.Type", "C")
    } else if (this.state.benificiairePicked.value?.related === "Child") {
      body.append("HospitalizedPerson.Type", "E")
    } else {
      body.append("HospitalizedPerson.Type", "A")
    }


    body.append("AdmissionDate", moment(this.state.data.admissionDate).format("DD/MM/YYYY"))
    body.append("AdmissionDuration", this.state.data.hospitalisationDuration)
    body.append("AdmissionCause", this.state.data.hospitalisationNature)
    body.append("HospitalName", this.state.data.facilityName)
    const reqBody = {
      admissionCause: "ACCVB",
      admissionDate: moment(this.state.data.admissionDate),
      admissionDuration: this.state.data.hospitalisationDuration,
      hospitalName: this.state.data.facilityName,
      hospitalizedPerson: {
        codeTiers: this.state.benificiairePicked?.value.codeAdherent,
        firstName: this.state.benificiairePicked?.value.firstName,
        name: this.state.benificiairePicked?.value.lastName,
        socialSecurityNumber: this.state.benificiairePicked?.value.securityNumber,
        type: this.state.benificiairePicked?.value.typeAdherent === "Spouse" ? "C" : this.state.benificiairePicked?.value.typeAdherent === "Child" ? "E" : "A"
      },
      numeroFax: this.state.data.fax,
      telephone: this.state.data.tel
    }
    const res = await apiSendContact(JSON.stringify(reqBody));
    if (res.status == 200) {
      this.setState({
        currentStep: this.state.currentStep + 1
      });
    }
    else {
      this.setState({
        errorMessage: res.errors[0]
      })
    }
  };

  onChangeHospitalisationDuration = value => {
    const aux = { ...this.state.data };
    if (isNumber(value) || !value) {
      aux.hospitalisationDuration = value.trim();
      this.setState({
        data: aux
      });
    }
  };

  onChange = (value, key) => {
    const aux = { ...this.state.data };
    switch (key) {
      case 'hospitalisationDuration':
      case 'cp':
      case 'fax':
        if (isNumber(value) || !value) {
          aux[key] = value.trim();
        }
        break;
      case 'tel':
        if (
          !value ||
          (value.length == 1 && (value == '+' || isNumber(value.trim()))) ||
          isNumber(value.trim().slice(1))
        ) {
          aux[key] = value.trim();
        }
        break;
      case 'benificiairePicked':
        let foundBenef = this.state.benificiaireMemberContentList.find((elem) => `${elem.firstName} ${elem.lastName}` == value)
        this.setState({ benificiairePicked: { name: value, value: foundBenef } });
        break;
      default:
        aux[key] = value;
        break;
    }
    this.setState({ data: aux });
  };

  onHideCalender = () => {
    this.setState({ showCalendar: false });
  };

  onClickOpenCalender = () => {
    this.setState({ showCalendar: true });
  };

  beneficiairPicked = data => {

    this.setState({
      benificiairePicked: data,
      currentStep: this.state.currentStep + 1
    });
  };

  nextStep = () => {
    if (this.state.currentStep == 1) {
      this.sendContact();
    } else {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };


  prevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
  };

  showNextStepBtn = () => {
    return (
      ((this.state.data.mail && validateEmail(this.state.data.mail)) ||
        this.state.data.fax)
    );
  };

  selectFile = files => {
    this.setState({
      files: files
    });
  };

  render() {
    switch (this.state.currentStep) {
      case 0:
        return <HospitalisationStep0Template {...this.state} {...this} />;
      case 1:
        return (
          <HospitalisationStep1Template
            {...this.state}
            {...this}
            HospitalisationOptions={HospitalisationOptions}
          />
        );

      case 2:
        return <HospitalisationStep2Template {...this.state} {...this} />;
      case 3:
        return <HospitalisationStep3Template {...this.state} {...this} />;
      case 4:
        return <HospitalisationStep5Template {...this.state} {...this} />;
      default:
        return null;
    }
  }
}
const mapStateToProps = state => ({
  user: state.auth.user,
  profile: state.profile.profile
});
const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Hospitalisation);