import React from 'react';
import { useHistory } from 'react-router-dom';

import { Text, Icon, Img, Button } from '../../Atoms';
import SuccessImg from '../../assets/img/Happy-Bunch-Chatting.png';
function TemplateHospitalisationStep5 (props) {
  const history = useHistory();
  return (
    <div className='template-scroll cartetp-container'>
      <div className='cartetp-header-container '>
        <Text text='Hospitalisation' type='h4' />
      </div>
      <div className='container-hospitalisation success-step-hospitalisation'>
        <Text text='Votre prise en charge a bien été envoyée.' type='h7' />
        <div className='success-icon-container'>
          <Icon icon='Valid' size={40} color='green' />
        </div>
        <div className='container-img-success-hospitalisation'>
          <Img
            src={SuccessImg}
            width={window.innerWidth <= 768 ? '246px' : '472px'}
            height={window.innerWidth <= 768 ? '136px' : '261px'}
            className='object-fit'
          />
        </div>
      </div>
      <div className='hospitalisation-button-home-container'>
        <Button
          text='Revenir au tableau de bord'
          className='submit-btn-modal-calendar'
          boxShadow
          onClick={() => history.push('/')}
        />
      </div>
    </div>
  );
}

export default TemplateHospitalisationStep5;
