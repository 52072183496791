import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { render } from '@testing-library/react';
import { connect } from 'react-redux';

class OrganismEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editHtmlValue: props.editHtmlValue
    };
  }

  static defaultProps = {
    editHtmlValue: EditorState.createEmpty(),
    onChangeEditor: null,
    toolbarClassName: 'toolbarClassName',
    wrapperClassName: 'wrapperClassName',
    editorClassName: 'editorClassName'
  };

  onChange = async (EditorState) => {
    this.setState({ editHtmlValue: editorState })
    this.props.onChangeEditor(this.state.editHtmlValue);
  }

  render() {
    return (
      <div className='OrganismEditor_Container'>
        <Editor
          editorState={this.props.editHtmlValue}
          toolbarClassName={this.props.toolbarClassName}
          wrapperClassName={this.props.wrapperClassName}
          editorClassName={this.props.editorClassName}
          onEditorStateChange={this.onChange}
          defaultEditorState={this.props.editHtmlValue}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, null)(OrganismEditor);
