import { apiGet, apiPatch, apiPost } from '../standardApi';

export async function apiGetAllCategories() {
  const response = await apiGet(`/Category/GetCategories`, 'application/json');
  return response;
}

export async function apiGetSeuilFacture(silently = false) {
  return apiGet('/Document/GetSeuilFacture', 'application/json', silently);
}

export async function apiGetCategoriesById(id) {
  const response = await apiGet(
    `/Documents/GetCategory/${id}`,
    'application/json'
  );
  return response;
}

export async function apiGetSentDocuments() {
  const response = await apiGet(`/Documents/SentDocuments`, 'application/json');
  return response;
}
export async function apiPostSendDocument(data, processedData) {
  const response = await apiPost(
    `/Document/uploadDocuments?CategoryId=${
      processedData.categoryId
    }&SubCategoryId=${processedData.subCategoryId}&Comment=${encodeURIComponent(
      processedData.comment
    )}&IsSeuilSuperior=${processedData?.isSeuilSuperior}`,
    data,
    '',
    'form-data',
    true
  );
  return response;
}

export async function apiPostNewSendDocument(data, processedData) {
  const response = await apiPost(
    `/Document/Upload?CategoryId=${processedData.categoryId}&SubCategoryId=${processedData.subCategoryId}&IsSeuilSuperior=${processedData?.isSeuilSuperior}`,
    data,
    '',
    'form-data',
    true
  );
  return response;
}
export async function apiGetCgrmDocuments() {
  const response = await apiGet(
    `/Documents/GetAllSentDocuments?StartDate=01/01/2012&EndDate=01/31/${
      new Date().getFullYear() + 1
    }`,
    'application/json'
  );
  return response;
}

export async function apiGetCGRMAttenteDocumentByNumFic(numFic) {
  const response = await apiGet(
    `/Documents/GetCGRMAttenteDocumentByNumFic/${numFic}`,
    'application/json'
  );
  return response;
}

export async function apiGetSpvieDocument(AttachmentId) {
  const response = await apiGet(
    `/Documents/GetSpvieDocument/${AttachmentId}`,
    'application/json'
  );
  return response;
}

export async function apiGetCGRMIndexedDocumentByNumFic(numFic) {
  const response = await apiGet(
    `/Documents/GetCGRMIndexedDocumentByNumFic/${numFic}`,
    'application/json'
  );
  return response;
}

export async function apiGetSpVieDocument(id) {
  const response = await apiGet(
    `/Documents/GetSpVieDocument/${id}`,
    'application/json'
  );
  return response;
}

export async function apiGetDocumentsById(id) {
  const response = await apiGet(
    `/Documents/GetDocuments?categoryId=${id}`,
    'application/json'
  );
  return response;
}

export async function apiGetGaranties() {
  const response = await apiGet('/Document/getGaranties', 'application/json');
  return response;
}
export async function apiGetAffiliateContracts() {
  return apiGet('/Document/GetAffiliateContracts', 'application/json');
}

export async function apiGetRadiationCertificate() {
  return apiGet('/Document/GetRadiationCertificate', 'application/json');
}

export async function apiGetDocumentLibrary() {
  return apiGet('/DocumentLibrary/GetDocuments', 'application/json');
}
export async function apiDownloadSentDemandDocument(numDoc) {
  return apiGet(
    `/Contact/DownloadSentDemandDocument?numFic=${numDoc}`,
    'application/json'
  );
}
export async function apiGetDocumentEntreprise() {
  return apiGet(`/Document/GetDocumentsCompany`, 'application/json');
}

export async function apiDownloadCompanyDocuments(numFic) {
  return apiPost(
    '/Document/DownloadDocumentCompany',
    JSON.stringify({
      fileNumber: numFic
    }),
    'application/json'
  );
}
