import React from 'react';
import styles from './LoginStyles.module.scss';

const LoginModalTitle = ({ title }) => {
  return (
    <div className={styles.loginTitleContentContainer}>
      <span className={styles.loginTitle}>{title}</span>
    </div>
  );
};

export default LoginModalTitle;
