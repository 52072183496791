const initialState = {
  open: false,
  page: ""
};

function SlideNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_SLIDE_NOTIFICATION':
      return {
        ...state,
        open: action.value.open,
        page: action.value.page,
      };
    default:
      return state;
  }
}

export default SlideNotificationReducer;
