import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import {
  SideBarOrganism,
  HeaderOrganism,
  FooterOrganism
} from '../../Organisms';
import { useDispatch, connect, useSelector } from 'react-redux';
import ClickAwayListener from 'react-click-away-listener';
import Notification from '../../Pages/Notification/Notification';
import { Modal } from '../../Molecules';
import KeycloakService from '../../Services/KeycloakService';

const PrivateRoute = ({ component: Component, open, auth, ...rest }) => {
  const [show, setShow] = React.useState(true);
  const [windowWidth, setWindowWidth] = React.useState({
    innerWidth: window.innerWidth
  });
  const dispatch = useDispatch();
  const sideBar = useSelector(state => state.sideBar);
  const container = useSelector(state => state.container);
  const slideNotification = useSelector(state => state.slideNotification);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth({ innerWidth: window.innerWidth });
      // if (window.innerWidth > 768) {
      //   if (!sideBar.open) {
      //     dispatch({ type: 'SET_CURRENT_SIDE_BAR', value: { open: true } })
      //   }
      // }
    });
  }, []);

  function containerStyle () {
    const names = ['page-container'];
    if (sideBar.open == false) {
      names.push('mobile-container');
    }
    return names.join(' ');
  }
  function contentStyle () {
    const names = ['page-content'];
    if (sideBar.open == false) {
      names.push('mobile-content');
    }
    if (sideBar.open == true) {
      names.push('mobile-content-open');
    }
    if (container.padding == false) {
      names.push('mobile-content-nopadding');
    }

    return names.join(' ');
  }
  return (
    <Route
      {...rest}
      render={props =>
        auth.accessToken ? (
          <div className='page'>
            {(sideBar.open || windowWidth.innerWidth >= 786) && (
              <SideBarOrganism {...rest} />
            )}
            <div className={containerStyle()}>
              <div className={contentStyle()}>
                <HeaderOrganism />
                <Component {...props} />
              </div>
              <FooterOrganism />
              {slideNotification.open && (
                <ClickAwayListener
                  onClickAway={() =>
                    dispatch({
                      type: 'SET_CURRENT_SLIDE_NOTIFICATION',
                      value: { open: false, page: '' }
                    })
                  }
                >
                  <div className='container-slide-notification'>
                    {slideNotification.page === 'notification' ? (
                      <Notification
                        onHide={() =>
                          dispatch({
                            type: 'SET_CURRENT_SLIDE_NOTIFICATION',
                            value: { open: false, page: '' }
                          })
                        }
                      />
                    ) : null}
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        ) : (
         null
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  auth: propTypes.object.isRequired
};
const mapStateToProps = state => ({
  open: state.sideBar.open,
  auth: state.auth
});
export default connect(mapStateToProps, null)(PrivateRoute);
