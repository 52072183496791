import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Login from '../Pages/Login/Login';
import Home from './../Pages/home/home';
import Dispense from './../Pages/dispense/dispense';
import FirstLogin from '../Pages/FirstLogin/FirstLogin';
import ResetPasswordMail from '../Pages/ResetPasswordMail/ResetPasswordMail';
import ResetPassword from '../Pages/ResetPassword/ResetPassword';
import Deconnection from '../Pages/Deconnection/Deconnection';
import FirstLoginCreateAccount from '../Pages/FirstLoginCreateAccount/FirstLoginCreateAccount';
import ResetPasswordScreen from '../Pages/LoginScreens/ResetPassword/ResetPassword';
import RegisterUserScreen from '../Pages/LoginScreens/registerUser/registerUser';
import MfaVerification from '../Pages/MfaVerification/MfaVerification';
import Profile from '../Pages/Profile/Profile';
import Setting from '../Pages/Setting/Setting';
import CarteTp from '../Pages/CarteTp/CarteTp';
import Remboursement from './../Pages/Remboursement/Remboursement';
import DocumentGaranties from '../Pages/DocumentGaranties/DocumentGaranties';
import ListDocument from '../Pages/ListDocument/ListDocument';
import SendDocument from '../Pages/SendDocument/SendDocument';
import Contact from '../Pages/Contact/Contact';
import HealthService from '../Pages/HealthService/HealthService';
import SelectPractitionerType from '../Pages/Geolocation/SelectPractitionerType/SelectPractitionerType';
import SetGeolocation from '../Pages/Geolocation/SetGeolocation/SetGeolocation';
import GetGeolocatedPractitioners from '../Pages/Geolocation/GetGeolocatedPractitioners/GetGeolocatedPractitioners';
import Hospitalisation from '../Pages/Hospitalisation/Hospitalisation';
import DocumentContracts from '../Pages/DocumentContracts/DocumentContracts';
import DocumentBibliotheque from '../Pages/DocumentBibliotheque/DocumentBibliotheque';
import FAQ from '../Pages/FAQ/FAQ';
import FaqQuestions from '../Pages/FAQ/FaqQuestions';
import DocumentEntreprise from '../Pages/DocumentEntreprise/DocumentEntreprise';
import RecoverPhoneNumber from '../Pages/RecoverPhoneNumber/RecoverPhoneNumber';
import RegisterSuccess from '../Pages/LoginScreens/registerUser/registerSuccess';
import { TrackPageView } from '../Services/Matomo';

const MainRoute = () => {
  return (
    <Router>
      <TrackPageView />
      <Switch>
        {/* <Route path='/' component={() => <div>En cours de développement</div>} /> */}
        <PrivateRoute exact path="/" component={Home} />
        <Route exact path="/dispense" component={Dispense} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/validate-sms" component={MfaVerification} />
        <Route exact path="/register-success" component={RegisterSuccess} />
        <Route exact path="/FirstLogin" component={FirstLogin} />
        <Route
          exact
          path="/ResetPassword/:token"
          component={ResetPasswordScreen}
        />
        <Route exact path="/register" component={RegisterUserScreen} />
        <Route exact path="/FirstLogin" component={FirstLogin} />
        <Route
          exact
          path="/FirstLogin/:token"
          component={FirstLoginCreateAccount}
        />
        <Route exact path="/ResetPasswordMail" component={ResetPasswordMail} />
        <Route exact path="/recover-phone" component={RecoverPhoneNumber} />
        <Route exact path="/Deconnection" component={Deconnection} />
        <Route exact path="/ResetPassword/*" component={ResetPassword} />
        <PrivateRoute exact path="/Profile" component={Profile} />
        <PrivateRoute exact path="/setting" component={Setting} />
        <PrivateRoute exact path="/Cartetp" component={CarteTp} />
        <PrivateRoute exact path="/Remboursement" component={Remboursement} />
        <PrivateRoute exact path="/consommation" component={Remboursement} />
        <PrivateRoute exact path="/garanties" component={DocumentGaranties} />
        <PrivateRoute exact path="/documents" component={ListDocument} />
        <PrivateRoute exact path="/contracts" component={DocumentContracts} />
        <PrivateRoute exact path="/documents/send" component={SendDocument} />
        <PrivateRoute exact path="/contact" component={Contact} />
        <PrivateRoute exact path="/healthservice" component={HealthService} />
        <PrivateRoute
          exact
          path="/bibliotheque"
          component={DocumentBibliotheque}
        />
        <PrivateRoute
          exact
          path="/DocumentEntreprise"
          component={DocumentEntreprise}
        />
        <PrivateRoute
          exact
          path="/geolocation/select"
          component={SelectPractitionerType}
        />
        <PrivateRoute
          exact
          path="/geolocation/set-location"
          component={SetGeolocation}
        />

        <PrivateRoute
          exact
          path="/geolocation/results"
          component={GetGeolocatedPractitioners}
        />
        <PrivateRoute
          exact
          path="/Hospitalisation"
          component={Hospitalisation}
        />
        <PrivateRoute exact path="/foire-aux-questions" component={FAQ} />
        <PrivateRoute
          exact
          path="/foire-aux-questions/:id"
          component={FaqQuestions}
        />
      </Switch>
    </Router>
  );
};

export default MainRoute;
