export const PATH_LINKS = [
  {
    icon: 'Home',
    links: ['/'],
    defaultLink: '/',
    iconSize: 24,
    iconClassName: ''
  },
  {
    icon: 'Profile',
    links: ['/profile'],
    defaultLink: '/profile',
    iconSize: 24,
    iconClassName: ''
  },
  {
    icon: 'Vector10',
    links: ['/contact'],
    defaultLink: '/contact',
    iconSize: 24,
    iconClassName: ''
  }
  // {
  //   icon: 'Notification',
  //   links: ['/notification'],
  //   defaultLink: '',
  //   iconSize: 24,
  //   iconClassName: '',
  //   params: 'notification'
  // }
];
