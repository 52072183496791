import moment from 'moment';
import { API_URL } from '../../Config/Config';
import { apiGet, apiPatch, apiPost } from '../standardApi';

export async function apiGetMembers(token) {
  const response = await apiGet(
    `/Profile/GetProfile`,
    'application/json',
    token
  );
  return response;
}
export async function apiSendCodeByEmail() {
  const response = await apiPost(
    `/Profile/SendCode`,
    JSON.stringify({}),
    'text/plain'
  );
  return response;
}

export async function apiUpdatePassword(password) {
  const response = await apiPost(
    `/Profile/UpdatePassword`,
    JSON.stringify({
      password,
      currentPassword: 'Spviecgrm2024!@'
    }),
    'text/plain'
  );
  return response;
}

export async function apiConfirmUpdatePassword(otpCode) {
  const response = await apiPost(
    `/Profile/ConfirmUpdatePassword`,
    JSON.stringify({
      otpCode
    }),
    'text/plain'
  );
  return response;
}

export async function apiUpdateMainInsuredInfo({ name, birthdate, numSecu }) {
  const response = await apiPost(
    `/Profile/ModifyMainInsuredInfos`,
    JSON.stringify({
      name: name,
      birthDate: new Date(moment(birthdate, 'DD/MM/YYYY').toISOString()),
      socialSecurityNumber: numSecu
    }),
    'application/json'
  );
  return response;
}
export async function apiConfirmUpdateUserInfo(code) {
  const response = await apiPost(
    `/Profile/ConfirmBeneficiaryInfosUpdate`,
    JSON.stringify({
      otpCode: code
    }),
    'application/json'
  );
  return response;
}

export async function apiConfirmBeneficiaryResiliation(otpCode) {
  return await apiPost(
    '/Profile/ConfirmBeneficiaryResiliation',
    JSON.stringify({
      otpCode
    }),
    'application/json'
  );
}

export async function apiSendBeneficiaryResiliationRequest(codeTiersBenef) {
  return await apiPost(
    '/Profile/SendBeneficiaryResiliationRequest',
    JSON.stringify({
      beneficiaryCodeTiers: codeTiersBenef
    }),
    'application/json'
  );
}

export async function apiUpdatePhone(memberId, code, phone) {
  const response = await apiPatch(
    `/Members/UpdatePhone/${memberId}/${code}`,
    JSON.stringify({
      phone
    }),
    'application/json'
  );
  return response;
}
export async function apiUpdateAddress(memberId, code, body) {
  const response = await apiPatch(
    `/Members/UpdateAddress/${memberId}/${code}`,
    body,
    'form-data'
  );
  return response;
}
export async function apiUpdateEmail(memberId, code, email) {
  const response = await apiPatch(
    `/Users/UpdateEmail/${memberId}/${code}`,
    JSON.stringify({
      email
    }),
    'application/json'
  );
  return response;
}
export async function SendProofOfAddress(memberId, code, attachement) {
  const response = await apiPatch(
    `/Members/SendProofOfAddress/${memberId}/${code}`,
    attachement,
    'application/json',
    'form-data'
  );
  return response;
}

export async function apiGetPostalAddressOnly(adress) {
  const response = await apiGet(
    `/PostalAddress/GetPostalAddressOnly?address=${adress}`,
    'application/json'
  );
  return response;
}

export async function apiGetMembersLogin(token) {
  try {
    const response = await fetch(API_URL + `/Profile/getProfile`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const JsonData = await response.json();
    return { ...JsonData, status: response.status };
  } catch (err) {
    return { status: 500 };
  }
}

export async function apiCreateBeneficiary(contractId, body) {
  const response = await apiPost(
    `/Members/CreateBeneficiary/${contractId}`,
    body,
    '',
    'form-data'
  );
  return response;
}

export async function apiGetStreetTypeList(token) {
  const response = await apiGet(`/Profile/GetListTypeVoie`, 'application/json');
  return response;
}

export async function apiModifyAddress(
  codeAdherent,
  email,
  rue1,
  rue2,
  rue3,
  rue4,
  country,
  city,
  currentPostalCode,
  newPostalCode
) {
  const response = await apiPost(
    `/Profile/UpdateAddress`,
    JSON.stringify({
      codeTiers: codeAdherent,
      email,
      libelle1: rue1,
      libelle2: rue2,
      libelle3: rue3,
      libelle4: rue4,
      country,
      city,
      actualPostalCode: currentPostalCode,
      newPostalCode
    }),
    'application/json'
  );
  return response;
}

export async function apiConfirmUpdateAddress(
  codeAdherent,
  email,
  rue1,
  rue2,
  rue3,
  rue4,
  country,
  city,
  currentPostalCode,
  newPostalCode,
  otpCode
) {
  const response = await apiPost(
    `/Profile/ConfirmUpdateAddress`,
    JSON.stringify({
      codeTiers: codeAdherent,
      email,
      libelle1: rue1,
      libelle2: rue2,
      libelle3: rue3,
      libelle4: rue4,
      country,
      city,
      actualPostalCode: currentPostalCode,
      newPostalCode,
      otpCode
    }),
    'application/json'
  );
  return response;
}
