import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DemandeTemplate } from '..';
import { Text, Button, Input, Icon } from '../../Atoms';
import { ModalSuccessContact } from '../../Modals';
import { Upload } from '../../Molecules';
import { OrganismSelectContact } from '../../Organisms';
import { COLOR_BUTTON } from '../../Config/Config';

function TemplateContact(props) {
  const contact = () => {
    return (
      <Row className="no-margin-mob">
        <Col
          xs={12}
          md={{ order: 'last', sapn: 6 }}
          className="no-padding-mob container-description-contact contact-page-container"
        >
          <div className='contact-page-content'>
            <Text
              text="Information Utiles"
              className="text-contact text-bold-contact contact-page-content-title"
            />
            <div className="container-text-description-contact">
              <Text text="Horaires d'ouverture:" className="text-contact text-bold-contact" />
              <Text
                text="Du lundi au vendredi de 8h30 à 17h30"
                className="text-contact"
              />
            </div>
            <div className="container-text-description-contact">
              <Text text="Numéro de téléphone :" className="text-contact text-bold-contact" />
              <Text
                text="03 28 21 02 10"
                className="text-contact"
              />
            </div>
            <div className="container-text-description-contact">
              <Text text="Adresse :" className="text-contact text-bold-contact" />
              <Text
                text="377 Rue de Luxembourg, 59640 Dunkerque"
                className="text-contact"
              />
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          md={{ order: 'first', sapn: 6 }}
          className="no-padding-mob"
        >
          <div className="container-text-description-contact-marged">
            <Text
              text="Une question ? Consultez notre foire aux questions !"
              className="label-contact text-bold-contact web-display-none"
            />
            <Text
              text="Une question ? Consultez notre foire aux questions !"
              className="label-contact web-bold font-title"
            />
            <Text
              text="Vous y retrouverez toutes les réponses aux questions qui nous sont le plus souvent posées."
              className="label-contact font-title"
            />
            <Button
              text="Consulter la FAQ"
              className="btn-submit-contact"
              onClick={() => props.onClickFAQ()}
              color={COLOR_BUTTON.ORANGE}
            />
          </div>
          <div className="container-text-description-contact-marged">
            <Text
              text="Une autre question ?"
              className="label-contact text-bold-contact web-display-none"
            />
            <Text
              text="Une autre question ?"
              className="label-contact web-bold font-title"
            />
            <Text
              text="Nos conseillers sont disponibles en livechat de 8h30 à 17h30 sur notre chatbot."
              className="label-contact font-title"
            />
            <Button
              text="Accéder au chatbot"
              className="btn-submit-contact"
              onClick={() => props.accessToChatbot()}
              color={COLOR_BUTTON.ORANGE}
            />
          </div>
          {/* <Input
            placeholder='Inscrivez votre adresse mail'
            className='input-contact'
            onChange={e => props.onChangeContact(e.target.value, 'mail')}
          /> */}
          {/* <OrganismSelectContact
            options={[
              { text: 'Ma caisse d’Assurance Maladie' },
              { text: 'Mes remboursements' },
              { text: 'Mes informations personnelles' },
              { text: 'Mes garanties' },
              { text: 'Mes bénéficiaires' },
              { text: 'Télétransmission liaison Noémie' },
              { text: 'Cotisation' },
              { text: 'Résiliation' },
              { text: 'Portabilité' },
              { text: 'Retraite' },
              { text: 'Mes documents' },
              { text: 'Réclamations' }
            ]}
            onChange={(e) => props.onChangeContact(e, 'object')}
            value={props.state.contact.object}
          />
          <Input
            placeholder="Ecrivez votre message ici"
            className="input-contact contact-text-area"
            onChange={(e) => props.onChangeContact(e.target.value, 'message')}
          />
          <Upload
            style={{ height: '1px' }}
            handleFileChange={(e) => props.onChangeContact(e, 'file')}
            size="small"
            containerClassName="container-contact-input-file"
          />
          {/* <Text text='PDF, JPEG -  5mo maximum autorisé' className='condition-upload-doc-hospitalisation' /> 
          <div className="container-end">
            <Button
              text="Envoyer"
              className="btn-submit-contact"
              onClick={() => props.onSubmit()}
            />
          </div> */}
        </Col>
      </Row>
    );
  };
  const demande = () => {
    return (
      <DemandeTemplate
        demandeData={props.demandeData}
        demandeStep={props.demandeStep}
        viewClicked={props.viewClicked}
        selectedDemande={props.selectedDemande}
        responseClicked={props.responseClicked}
        viewDemandeClicked={props.viewDemandeClicked}
        retourClicked={props.retourClicked}
        page={props.page}
        pageNumber={props.pageNumber}
        onChangePagination={props.onChangePagination}
        demandeDataMobile={props.demandeDataMobile}
        value={props.searchWord}
        onKeyPress={props.onKeyPress}
        OnLoopIconClick={props.OnLoopIconClick}
        onChange={props.onChange}
        sortClicked={props.sortClicked}
        response={props.response}
        responseChanged={props.responseChanged}
        sendResponse={props.sendResponse}
      />
    );
  };
  const contactStyle = () => {
    if (props.state.status === 'Nous contacter') {
      return 'title-contact remboursement-title-active';
    }
    return 'title-contact';
  };
  const demandeStyle = () => {
    if (props.state.status === 'Mes demandes') {
      return 'title-contact remboursement-title-active';
    }
    return 'title-contact';
  };
  return (
    <div
      className="template-notification temlplate-contact template-scroll"
      onScroll={props.onScroll}
    >
      <div className="title-contact-container">
        <Text
          text={<span className={contactStyle()}>Nous contacter</span>}
          onClick={() => props.changeStatus('Nous contacter')}
        />
        <Text
          text={<span className={demandeStyle()}>Mes demandes</span>}
          onClick={() => props.changeStatus('Mes demandes')}
        />
      </div>
      {props.state.status === 'Nous contacter' ? contact() : demande()}
      <ModalSuccessContact show={props.state.show} onHide={props.onHide} />
    </div>
  );
}
export default TemplateContact;
