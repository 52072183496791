import React, { useEffect, useState } from 'react';
import styles from './../login.module.css';
import LoginBackground from '../../../Components/Login/LoginBackground';
import LoginModal from '../../../Components/Login/LoginModal';
import LoginModalTitle from '../../../Components/Login/LoginModalTitle';
import LoginButton from '../../../Components/Login/LoginButton';
import KeycloakService from '../../../Services/KeycloakService';

const RegisterSuccess = (props) => {
  const goLoginPage = async (e) => {
    e.preventDefault();
    KeycloakService.doLogin();
  };

  return (
    <LoginBackground>
      <div className={styles.container}>
        <LoginModal>
          <LoginModalTitle title={'Création de votre compte'} />
          <div className={styles.contentContainer}>
            <span>
              Vous allez reçevoir dans quelques instants un mail contenant votre{' '}
            </span>
            <span style={{ textDecorationLine: 'underline' }}>
              identifiant de connexion ainsi que votre mot de passe provisoire.
            </span>
            <br /> <br />
            <span>
              Attention, il se peut que cet email soit arrivé dans vos courriers
              indésirables.
            </span>
            <br /> <br />
            <span> Pour toute question, veuillez contacter </span>
            <a
              href="https://cgrm.fr"
              target="_blank"
              style={{ color: '#8BCAB5' }}
            >
              le service informatique.
            </a>
          </div>
          <div className={styles.submitButtonContainer}>
            <LoginButton onClick={goLoginPage} type="success">
              Connexion
            </LoginButton>
          </div>
        </LoginModal>
      </div>
    </LoginBackground>
  );
};

export default RegisterSuccess;
