import React from 'react';
import { Text, Button, Card, Icon } from '../../Atoms';
import { Col, Row } from 'react-bootstrap';
import { SelectOrganism } from '../../Organisms';
import { Modal } from '../../Molecules';
import { COLOR_BUTTON } from '../../Config/Config';
import { ConfirmPasswordModal } from '../../Modals';
import PageTitle from '../../Components/Dashboard/PageTitle';
function TemplateProfileDelete(props) {
  return (
    <>
      <Modal
        show={props.showDeleteModal}
        showIconClose={false}
        centered={true}
        contentClassName={'content-modal-confirm-password'}
      >
        <div className="full-container">
          <Icon
            icon="BigX"
            className="icon-close-modal-confim-password"
            size="20px"
            onClick={() => props.onClose()}
          />
          <Text
            text={`Supprimer un bénéficiaire`}
            className="title-modal-confirm-password"
          />
          <Text
            text={
              props.validationCodeSuccess
                ? `Votre demande de suppression de ${props.showDeleteName} a bien été
            envoyée à notre service adhésion.`
                : `Votre demande de suppression de bénéficiaire sera envoyée et traitée par notre service adhésion.`
            }
            className="text-description-confirm-password"
          />
          <Text
            text={
              props.validationCodeSuccess
                ? 'Nous vous recontacterons dans les plus brefs délais.'
                : `Souhaitez-vous valider la demande de suppression de ${props.showDeleteName}`
            }
            className="text-description-confirm-password"
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="primary"
              text={props.validationCodeSuccess ? 'Fermer' : 'Annuler'}
              className="btn-cancel-modal-delete-profile"
              onClick={() => props.onClose()}
              color={COLOR_BUTTON.BLACK}
              isAlreadyOutline={true}
            />
            {!props.validationCodeSuccess && (
              <Button
                type="primary"
                text="Valider"
                className="btn-submit-modal-edit-profile"
                onClick={() => props.onConfirmDeletion()}
                color={COLOR_BUTTON.GREEN}
              />
            )}
          </div>
        </div>
      </Modal>

      {props.showConfirmationModal && (
        <ConfirmPasswordModal
          show={props.showConfirmationModal}
          onHide={props.onHide}
          errorMessages={props.validationCodeErrors}
          modalStep={props.verificationSteps}
          success={props.validationCodeSuccess}
          title="Validation des modifications"
          firstText={`Nous venons d’envoyer un code par  email à l’adresse  ${props.data.mail}`}
          secondText="Veuillez le renseigner ci-dessous pour confirmer les modifications :"
          onConfirmCode={() => props.onConfirmCodeDelete()}
          onCancel={() => props.onHide()}
          successText="Vos modifications ont été enregistrées."
          classNameBtncancel="btn-cancel-modal-edit-profile"
          classNameBtnSubmit="btn-submit-modal-edit-profile"
          classNameInput="input-modal-edit-profile"
          onChange={(e) => props.onChangeCode(e.target.value)}
          code={props.code}
        />
      )}
      <div className="template-scroll profile-container">
        {/* <div className='cartetp-header-container'>
          <Text text='Profil' type='h4'  className='title-page'/>
        </div> */}
        <PageTitle titleText="Profil" canGoBack={false} />
        <div className="profile-title">
          <Text
            text={
              window.innerWidth <= 768
                ? 'Séléctionnez un bénéficiaire que vous souhaitez supprimer'
                : 'Séléctionnez un bénéficiaire que vous souhaitez supprimer de votre contrat :'
            }
            type="h6"
            className="text-default-size text-default-font"
          />
        </div>
        <div className="profile-card-container">
          <Row>
            {props.benificiaireList.map((el, index) => (
              <Col
                className="new-container-card-profile scale-up-center"
                md={4}
                xs={
                  (index + 1) % 3 === 2 &&
                  props.benificiaireList.length === index + 1
                    ? 12
                    : 6
                }
              >
                <Card
                  text={el.name}
                  size="medium"
                  disabled={index == 0 ? true : false}
                  onClick={() =>
                    props.handelSelectedProfileDelete(el.value, index)
                  }
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default TemplateProfileDelete;
