import React from 'react';
import { AffiliationHeaderMolecule } from '../../Molecules';
import { FooterOrganism, DispenseOrganism } from '../../Organisms';

function TemplateDispense(props) {
    return (
        <div className="affiliation-container template-scroll">
            <AffiliationHeaderMolecule />
                    <DispenseOrganism />
            <div className="affiliation-footer">
                <FooterOrganism />
            </div>
        </div>
    );
}

export default TemplateDispense;
