import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Text, DatePicker, Icon, Button, Card } from '../../Atoms'
import { Input, Modal } from '../../Molecules'
import { Row, Col } from 'react-bootstrap'
import ClickAwayListener from 'react-click-away-listener';
function OrganismSelectContact(props) {
    const [show, setShow] = useState(false)
    const [value, setValue] = useState(null)
    function onChange(el) {
        setValue(el.value ? el.value : el.text)
        setShow(false)
        if (props.onChange) {
            props.onChange(el.value ? el.value : el.text)
        }
    }
    useEffect(() => {
        if (value != props.value) {
            setValue(props.value)
        }
    }, [props.value])
    function getOptionClassName(el) {
        const className = [window.innerWidth > 768 ? 'option-select-contact' : "option-contact-mobile"]
        if (el.disabled) {
            className.push('disabled-option-select-contact')
        }
        if (el.value) {
            if (value == el.value) {
                className.push('option-select-contact-selected')
            }
        } else {
            if (value == el.text) {
                className.push('option-select-contact-selected')
            }
        }
        return className.join(' ')
    }
    function containerClassName() {
        const className = ['input-select-contact-container']
        if (show) {
            className.push('input-select-contact-container-open')
        }
        if (props.containerClassName) {
            className.push(props.containerClassName)
        }
        return className.join(' ')
    }
    function iconClassName() {
        const className = ['icon-select-contact']
        if (show) {
            className.push('icon-select-contact-open')
        }
        if (props.iconClassName) {
            className.push(props.iconClassName)
        }
        return className.join(' ')
    }
    function getOptionMobileClassName() {
        const className = ["container-select-contact-mobile slide-top"]
        if (props.containerMobileOption) {
            className.push(props.containerMobileOption)
        }
        return className.join(' ')
    }
    return (
        <ClickAwayListener onClickAway={() => setShow(false)} >
            <div style={{ position: "relative" }} >
                <div
                    className={containerClassName()}
                    onClick={() => setShow(!show)}
                >
                    {value != ""
                        ? <Text
                            text={value}
                            className={props.valueClassName}
                            loader={false}
                        />
                        : <Text
                            text={props.placeholder}
                            className={props.placeholderClassName}
                            loader={false}
                        />
                    }
                    <Icon
                        icon='Sort'
                        className={iconClassName()}
                        size={props.iconSize}
                    />
                </div>
                {show && window.innerWidth > 768 &&
                    <div className='select-option-container-contact' >
                        {props.options.map((el) => {
                            return (
                                <div className={getOptionClassName(el)} onClick={() => !el.disabled && onChange(el)} >
                                    {el.text}
                                </div>
                            )
                        })}
                    </div>
                }
                {window.innerWidth <= 768 && show &&
                    <div className={getOptionMobileClassName()}>
                        {props.header && <Text text={props.header} className={props.headerClassName} />}
                        <Icon icon="SortTop" size="15px" className="icon-return-select-contact" onClick={() => setShow(false)} />
                        <Text text={props.titleMobile} className={props.titleMobileClassName} />
                        {props.subTitleMobile && <Text text={props.subTitleMobile} className={props.subTitleMobileClassName} />}
                        {props.options.map((el) => {
                            return (
                                <div className={getOptionClassName(el)} onClick={() => !el.disabled && onChange(el)} >
                                    {el.text}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </ClickAwayListener >
    );

}
OrganismSelectContact.defaultProps = {
    placeholder: 'Selectionnez l’objet de la demande',
    value: "",
    onChange: null,
    options: [],
    titleMobile: "Choissisez l’objet de votre demande",
    titleMobileClassName: "title-option-contact",
    placeholderClassName: "input-placeholder-select-contact",
    valueClassName: "input-text-select-contact",
    containerClassName: null,
    iconClassName: null,
    containerMobileOption: null,
    subTitleMobileClassName: "",
    header: null,
    headerClassName: "",
    iconSize: null
}

export default OrganismSelectContact;
