import React, { useState } from 'react';
import styles from './LoginStyles.module.scss';
import EyeSvg from '../../assets/img/eyes.svg';

const LoginInput = ({
  label,
  previewPasswordEnabled,
  onPreviewPassword,
  className,
  type,
  ...rest
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePreviewPassword = ()=>{
    setPasswordVisible(!passwordVisible)
  }

  const classNameInput = className || styles.loginInput

  return (
    <div className={styles.loginInputContainer}>
      <div className={classNameInput}>
        <input
          {...rest}
          type={type === 'password' && passwordVisible ? 'text' : type}
        />
        {previewPasswordEnabled && (
          <div
            className={styles.loginInputEyeContainer}
            onClick={onPreviewPassword}
          >
            <img
              src={EyeSvg}
              className={`${styles.loginInputEyeIcon} ${
                passwordVisible ? styles['loginInputEyeIcon-visible'] : ''
              }`}
              onClick={togglePreviewPassword}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginInput;
