import React, { useState } from 'react';
import { Button, Icon, Text } from '../../../Atoms';
import GeolocationHeader from '../components/GeolocationHeader';
import styles from '../Geolocation.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import Autosuggest from 'react-autosuggest';
import FrenchDepartmentsAutosuggest from './components/FrenchDepartmentsAutosuggest';
import CitiesAutosuggest from './components/CitiesAutosuggest';
import { useDispatch } from 'react-redux';
import { addPipelineLoader, rmPipelineLoader } from '../../../Redux/Actions/authActions/authActions';
import { COLOR_BUTTON } from '../../../Config/Config';

const SetGeolocation = (props) => {
    const history = useHistory()
    const { search } = useLocation()
    const urlParams = new URLSearchParams(search)
    const goBack = () => history.goBack()
    const practitionerType = urlParams.get('type')

    const dispatch = useDispatch()
    
    const geolocationAroundMeAvailable = !!navigator.geolocation
    const [geolocationLoading, setGeolocationLoader] = useState(false)

    const [departmentInputValue, setDepartmentInputValue] = useState('')
    const [departmentSelectedNumber, setDepartmentSelectedNumber] = useState(null)
    const [citiesInputValue, setCitiesInputValue] = useState('')
    const [citySelectedName, setCitySelectedName] = useState('')

    const onSelectDepartment = (suggestion) => {
        setDepartmentInputValue(suggestion.formated)
        setDepartmentSelectedNumber(suggestion.departmentNumber)
    }
    const onChangeDepartmentInputValue = (value) => {
        if (departmentSelectedNumber || citiesInputValue || citySelectedName) {
            setDepartmentSelectedNumber(null)
            setCitiesInputValue('')
            setCitySelectedName('')
        }
        setDepartmentInputValue(value)
    }
    const onChangeCityInputValue = (value) => {
        if (citySelectedName) {
            setCitySelectedName('')
        }
        setCitiesInputValue(value)
    }

    const onSelectCity = (suggestion) => {
        setCitiesInputValue(suggestion.formated)
        setCitySelectedName(suggestion.cityName)
    }

    const goToResultsPage = (search) => {
        history.push({
            pathname: '/geolocation/results',
            search: search + `&type=${practitionerType}`,
        })
    }

    const geolocateAroundMe = () => {
        const pipelineLoaderName = 'geolocationAPI-getCurrentPosition'
        dispatch(addPipelineLoader(pipelineLoaderName))
        setGeolocationLoader(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    dispatch(rmPipelineLoader(pipelineLoaderName))
                    setGeolocationLoader(false)
                    goToResultsPage(`?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}`)
                },
                (error) => {
                    dispatch(rmPipelineLoader(pipelineLoaderName))
                    setGeolocationLoader(false)
                    if (error.code === 1) {
                        alert("La géolocalisation n'est pas autorisé sur ce navigateur. Veuillez l'activer pour accéder à la géolocalisation des praticiens.")
                    }
                }
            )
        } else {
            alert("La géolocalisation n'est pas disponible sur ce navigateur.")
        }
    }

    const geolocateManually = () => {
        goToResultsPage(`?dep=${departmentSelectedNumber}&city=${citySelectedName}`)
    }

    return (
        <div className={styles["geolocation-template"]}>
            <GeolocationHeader onReturn={goBack} />
            <div className={styles['set-location-content']}>
                {geolocationAroundMeAvailable && (
                    <>
                        <div className={styles['set-location-geolocate-around-container']}>
                            <Button
                                className={styles['set-location-geolocate-around-btn']}
                                onClick={geolocateAroundMe}
                                color={COLOR_BUTTON.SALMON}
                                text={geolocationLoading ? 'Géolocalisation en cours' : 'Géolocaliser autour de moi'}
                            />
                        </div>
                        <div className={styles['set-location-separator-container']}>
                            <h5 className={styles['set-location-separator']}>OU</h5>
                        </div>
                    </>
                )}
                <div className={styles['set-location-manual-location-container']}>
                    <FrenchDepartmentsAutosuggest
                        value={departmentInputValue}
                        onChangeValue={onChangeDepartmentInputValue}
                        onSelectSuggestion={onSelectDepartment}
                        className={styles['autosuggest-container']}
                    />
                    {departmentSelectedNumber ? (
                        <CitiesAutosuggest
                            value={citiesInputValue}
                            onChangeValue={onChangeCityInputValue}
                            onSelectSuggestion={onSelectCity}
                            departmentNumber={departmentSelectedNumber}
                            className={styles['autosuggest-container']}
                        />
                    ) : null}
                    {citySelectedName ? (
                        <button
                            className={styles['set-location-manual-done-btn']}
                            onClick={geolocateManually}
                        >
                            Suivant
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    )
};

export default SetGeolocation;