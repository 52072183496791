import React, { useEffect, useState } from 'react';
import styles from './../login.module.css';
import LoginBackground from '../../../Components/Login/LoginBackground';
import LoginModal from '../../../Components/Login/LoginModal';
import LoginModalTitle from '../../../Components/Login/LoginModalTitle';
import LoginInput from '../../../Components/Login/LoginInput';
import LoginButton from '../../../Components/Login/LoginButton';
import SubscribeButton from '../../../Components/Login/SubscribeButton';
import isPasswordValid from '../../../Utils/isPasswordValid';
import {
  apiGenerateCaptcha,
  apiResetPassword,
  apiValidateCaptcha,
  createAccount
} from '../../../Api/Login/Login';
import { ResetPasswordStepsEnum } from '../../../Config/enum';
import { useParams, useHistory } from 'react-router-dom';
import KeycloakService from '../../../Services/KeycloakService';
import moment from 'moment';
const isDigit = (val) => {
  return /^\d+$/.test(val);
};

const isEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const RegisterUserScreen = (props) => {
  const history = useHistory();
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [birthdayDate, setBirthdayDate] = useState('');
  const [emailAdress, setEmailAdress] = useState('');
  const [confirmEmailAdress, setConfirmEmailAdress] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [captchaErrors, setCaptchaErrors] = useState([]);

  const [captchaDataGenerated, setCaptchaDataGenerated] = useState({
    id: '',
    image: ''
  });
  const [isLoading, setLoader] = useState(false);

  const [step, setStep] = useState(ResetPasswordStepsEnum.SETUP_NEW_PASSWORD);

  const [error, setError] = useState([]);
  const { token } = useParams();

  const generateCaptcha = async () => {
    const resp = await apiGenerateCaptcha();
    if (resp?.status === 200) {
      setCaptchaDataGenerated({
        id: resp?.id,
        image: `data:image/png;base64,${resp.data}`
      });
    }
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setError([]);
    setCaptchaErrors([]);

    if (
      !socialSecurityNumber ||
      !birthdayDate ||
      !emailAdress ||
      !confirmEmailAdress
    ) {
      return setError(['Tous les champs sont obligatoires.']);
    }

    if (socialSecurityNumber.length !== 15) {
      return setError(['Numéro de Sécurité Sociale non valide.']);
    }

    if (!moment(birthdayDate, 'DD/MM/YYYY', true).isValid()) {
      return setError(['Date de naissance non valide.']);
    }

    if (!isEmail(emailAdress)) {
      return setError(['Adresse email non valide.']);
    }

    if (emailAdress !== confirmEmailAdress) {
      return setError(['Les adresses mails ne sont' + ' pas identiques.']);
    }

    setLoader(true);
    const captchaRes = await apiValidateCaptcha({
      id: captchaDataGenerated?.id,
      Value: captchaText
    });

    if (captchaRes.status == 200) {
      const data = {
        securitySocialNumber: socialSecurityNumber,
        birthDate:
          moment(birthdayDate, 'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00.000') +
          'Z',
        email: emailAdress,
        captchaToken: captchaRes.data
      };
      const res = await createAccount(data);

      if (res.status === 200) {
        setLoader(false);
        history.push('/register-success');
      } else {
        setLoader(false);
        return setError(res.errors?.map?.((i) => i.errorMessage) || []);
      }
    } else {
      setLoader(false);
      generateCaptcha();
      setCaptchaText('');
      return setCaptchaErrors(captchaRes.errors?.map?.((i) => i.errorMessage));
    }
  };

  const goLoginPage = async (e) => {
    e.preventDefault();
    KeycloakService.doLogin();
  };

  const handleBirthdateChange = (event) => {
    let inputDate = event.target.value;

    if (inputDate.length > birthdayDate.length) {
      const edition = inputDate.replace(birthdayDate, '');
      if (!isDigit(edition)) return;
    }

    if (inputDate.length === 2 || inputDate.length === 5) {
      if (inputDate.length > birthdayDate.length) {
        setBirthdayDate(`${inputDate}/`);
      } else {
        setBirthdayDate(inputDate.slice(0, -1));
      }
    } else {
      setBirthdayDate(inputDate);
    }
  };

  return (
    <LoginBackground>
      <div className={styles.container}>
        <LoginModal>
          <LoginModalTitle
            title={'Veuillez compléter les informations ci-dessous :'}
          />

          <div className={styles.contentContainer}>
            {error.length > 0 ? (
              <div className={styles.errorMessageContainer}>
                {error?.map((err) => (
                  <strong className={styles.errorMessage}>{err}</strong>
                ))}
              </div>
            ) : null}
            <form id="register-user-form" onSubmit={onSubmit}>
              <LoginInput
                placeholder="N° Sécurité sociale"
                disabled={isLoading}
                id="social-security-number-input"
                type="text"
                value={socialSecurityNumber}
                onChange={(e) => {
                  setSocialSecurityNumber(e.target.value);
                }}
              />

              <LoginInput
                placeholder="Date de naissance (JJ/MM/AAAA)"
                disabled={isLoading}
                id="birth-date-input"
                type="text"
                value={birthdayDate}
                onChange={handleBirthdateChange}
              />
              <LoginInput
                placeholder="Adresse mail"
                disabled={isLoading}
                id="email-adress-input"
                type="text"
                value={emailAdress}
                onChange={(e) => {
                  setEmailAdress(e.target.value);
                }}
              />
              <LoginInput
                placeholder="Confirmez votre adresse mail"
                disabled={isLoading}
                id="confirm-email-adress-input"
                type="text"
                value={confirmEmailAdress}
                onChange={(e) => {
                  setConfirmEmailAdress(e.target.value);
                }}
              />
              <div className={styles.captchaContainer}>
                <div className={styles.imageContainer}>
                  <img
                    className={styles.centeredImage}
                    src={captchaDataGenerated?.image}
                  />
                </div>
                <a
                  href="#"
                  className={styles.changeLink}
                  onClick={generateCaptcha}
                >
                  Changer l'image
                </a>
              </div>
              <LoginInput
                placeholder="Recopiez les caractères de l'image"
                disabled={isLoading}
                id="captcha-text"
                type="text"
                value={captchaText}
                onChange={(e) => {
                  setCaptchaText(e.target.value);
                }}
              />
              {captchaErrors.length > 0 ? (
                <div className={styles.errorMessageContainer}>
                  {captchaErrors?.map((err) => (
                    <strong className={styles.errorMessage}>{err}</strong>
                  ))}
                </div>
              ) : null}

              <div className={styles.submitButtonContainer}>
                <LoginButton
                  onClick={onSubmit}
                  type="submit"
                  form="reset-password-form"
                >
                  Valider
                </LoginButton>
                <hr className={styles.loginSeparator}></hr>
                <span>Déjà inscrit ? </span>
                <SubscribeButton onClick={goLoginPage}>
                  Se connecter
                </SubscribeButton>
              </div>
            </form>
          </div>
        </LoginModal>
      </div>
    </LoginBackground>
  );
};

export default RegisterUserScreen;
