import React, { useState } from 'react';
import { Text, DatePicker, Icon, Button } from '../../Atoms';
import { Input, Modal } from '../../Molecules';
import Calendar from 'react-calendar';
import moment from 'moment';
import './Calendar.css';
function OrganismCalendar (props) {
  const [currentSelect, setCurrentSelect] = useState('');

  const hideCalendar = () => {
    props.onHide();
    setCurrentSelect('');
  };

  const submitSelectedDate = () => {
    props.onChange(currentSelect);
    props.onHide();
    setCurrentSelect('');
  };
  const formatShortWeekday = (local, value) => {
    return new Date(value)
      .toLocaleDateString('FR-fr', { weekday: 'long' })
      .toLowerCase()
      .substring(0, 2);
  };
  return (
    <>
      <div>
        <div
          className='input-calendar-hospitalisation'
          onClick={() => props.onClick()}
        >
          {props.value ? (
            <Text
              text={moment(props.value).format('DD/MM/YYYY')}
              className='placeholder-calender'
            />
          ) : (
            <Text text={props.placeholder} className='placeholder-calender' />
          )}
          <Icon icon='Collapse' size={15} className='icon-calendar-input' />
        </div>
        {window.innerWidth > 768 && props.show && (
          <div className='input-calendar-modal'>
            <div
              className='close-icon-calendar-modal'
              onClick={() => hideCalendar()}
            >
              <Icon icon='BigX' size={16} />
            </div>
            <Calendar
              value={
                currentSelect != '' ? new Date(currentSelect) : props.value
              }
              onChange={value => setCurrentSelect(value)}
              formatShortWeekday={formatShortWeekday}
              className='hospitalisation-calander'
            />
            {currentSelect != '' && (
              <div className='submit-btn-modal-calendar-container'>
                <Button
                  text='Confirmer'
                  className='submit-btn-modal-calendar'
                  onClick={() => submitSelectedDate()}
                  boxShadow
                />
              </div>
            )}
          </div>
        )}
      </div>
      {window.innerWidth <= 768 && props.show && (
        <div className='modal-calendar '>
          <div className='modal-calendar-container slide-top'>
            <div
              className='close-icon-calendar-modal-container'
              onClick={() => hideCalendar()}
            >
              <Icon icon='BigX' size={16} />
            </div>
            <Text
              text='Date d’admission'
              type='h4'
              className='calender-title-modal'
            />
            <Calendar
              value={
                currentSelect != '' ? new Date(currentSelect) : props.value
              }
              onChange={value => setCurrentSelect(value)}
              formatShortWeekday={formatShortWeekday}
            />
            {currentSelect != '' && (
              <div className='submit-btn-modal-calendar-container'>
                <Button
                  text='Confirmer'
                  className='submit-btn-modal-calendar'
                  onClick={() => submitSelectedDate()}
                  boxShadow
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default OrganismCalendar;
