import React from 'react';
import styles from './LoginStyles.module.scss';

const SubscribeButton = ({ children, ...rest }) => {
  return (
    <button className={`${styles.generalLoginButton} ${styles.subscribeButton}`} {...rest}>
      {children}
    </button>
  );
};

export default SubscribeButton;
