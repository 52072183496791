const isPasswordValid = (password) => {
  if (password.length <= 8) {
    return false;
  }

  //Teste si le mot de passe contient une minuscule
  if (!/[a-z]/.test(password)) {
    return false;
  }

  //Teste si le mot de passe contient une MAJUSCULE
  if (!/[A-Z]/.test(password)) {
    return false;
  }

  //Teste si le mot de passe contient un chiffre
  if (!/[0-9]/.test(password)) {
    return false;
  }

  //Teste si le mot de passe contient un caractère spécial
  if (!/[^a-zA-Z0-9]/.test(password)) {
    return false;
  }

  return true;
};

export default isPasswordValid;
