import React from 'react';
import { apiConfirmUpdatePassword, apiSendCodeByEmail, apiUpdateEmail, apiUpdatePassword } from '../../Api/Profile/Profile';
import { SettingTemplate } from '../../Templates';
import Store from '../../Redux/Store';
import { connect } from 'react-redux';
import { apiResetPassword } from '../../Api/User/User';
class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      modalStep: 1,
      check: { notification: false, bio: false },
      password: { password: '', newPassword: '', confirmPassword: '' },
      email: {
        email: Store.getState().auth.user.email,
        newEmail: '',
        confirmEmail: ''
      },
      show: false,
      showMail: false,
      updatePassword: false,
      updateMail: false,
      passwordBtnText: 'Valider',
      mailBtnText: 'Valider',
      mailErrorMsg: '',
      passwordErrorMsg: '',
      code: ''
    };
  }

  onCheck = (key) => {
    this.setState({
      check: { ...this.state.check, [key]: !this.state.check[key] }
    });
  };

  onChangeStepPassword = () => {
    this.setState({ step: 2 });
  };

  onReturn = () => {
    this.setState({ step: 1 });
  };

  onChangePassword = (value, key) => {
    const aux = { ...this.state.password };
    aux[key] = value;
    this.setState({
      password: aux
    });
  };

  onChangeEmail = (value, key) => {
    const aux = { ...this.state.email };
    aux[key] = value;
    this.setState({
      email: aux
    });
  };

  onSubmit = () => {
    this.setState({
      updatePassword: !this.state.updatePassword,
      updateMail: false
     });

    this.props.history.push({
      pathname: '/setting',
      search: `?category=mot-de-passe`
    })
  };

  onSubmitMail = () => {
    this.setState({
      updateMail: !this.state.updateMail,
      updatePassword: false
    });

    this.props.history.push({
      pathname: '/setting',
      search: `?category=email`
    })
  };

  onHide = (key) => {
    this.setState({
      show: false,
      modalStep: 1,
      showMail: false,
      code: '',
      password:
        key == 'password' && this.state.modalStep == 2
          ? {
              ...this.state.password,
              newPassword: '',
              confirmPassword: ''
            }
          : this.state.password,
      email:
        key == 'mail' && this.state.modalStep == 2
          ? {
              ...this.state.email,
              newEmail: '',
              confirmEmail: ''
            }
          : this.state.email,
      updatePassword:
        this.state.modalStep == 2 && key == 'password'
          ? false
          : this.state.updatePassword,
      updateMail:
        this.state.modalStep == 2 && key == 'mail'
          ? false
          : this.state.updateMail
    });
  };

  onConfirmCode = async (key) => {
    let res = null;
    if (key === 'password') {
      res = await apiConfirmUpdatePassword(
        this.state.code,
      );
    } else {
      res = await apiUpdateEmail(
        this.props.user?.memberId,
        this.state.code,
        this.state.email.newEmail
      );
    }

    if (res.status === 400) {
      this.setState({
        passwordErrorMsg: res.errors[0]?.errorMessage
      });
    }
    if (res && res?.status === 200) {
      this.setState({
        modalStep: 2,
        code: ''
      });
    }
  };

  validateMail = () => {
    if (
      this.state.email.newEmail !== '' ||
      this.state.email.confirmEmail !== ''
    ) {
      if (this.state.email.newEmail !== this.state.email.confirmEmail) {
        this.setState({
          mailErrorMsg: 'Attention les emails ne sont pas identiques.'
        });
      } else {
        this.sendCodeByEmail('mail');
      }
    }
  };

  hundleVerifPassword = (password) => {
    let verif = true;
    const validPassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/;
    if (password.length < 12) {
      verif = false;
    }
    if (!validPassword.test(password)) {
      verif = false;
    }
    return verif;
  };

  validatePassword = () => {
    const verifPassword = this.hundleVerifPassword(
      this.state.password.newPassword
    );
    if (
      this.state.password.newPassword !== '' ||
      this.state.password.confirmPassword !== ''
    ) {
      if (
        this.state.password.newPassword !== this.state.password.confirmPassword
      ) {
        this.setState({
          passwordErrorMsg:
            'Attention les mots de passe ne sont pas identiques.'
        });
      } else if (!verifPassword) {
        this.setState({
          passwordErrorMsg:
            'Ce mot de passe n’est pas valide. Essayer à nouveau en respectant les conditions décrites en bas de page.'
        });
      } else {
        this.updatePassword(this.state.password.newPassword);
      }
    }
  };

  updatePassword = async (password) => {
    const res = await apiUpdatePassword(password);
    if (res.status === 200) {
      this.setState({
        show: true,
        showMail: false,
        passwordErrorMsg: ''
      });
    }
  };

  sendCodeByEmail = async (key) => {
    const res = await apiSendCodeByEmail(this.props.user?.memberId);
    if (res.status === 200) {
      if (key == 'password') {
        this.setState({
          show: true,
          showMail: false,
          passwordErrorMsg: ''
        });
      } else {
        this.setState({
          show: false,
          showMail: true,
          mailErrorMsg: ''
        });
      }
    }
  };

  changeCode = (e) => {
    this.setState({
      code: e.target.value
    });
  };

  render() {
    return (
      <SettingTemplate
        state={this.state}
        onCheck={this.onCheck}
        onChangeStepPassword={this.onChangeStepPassword}
        onReturn={this.onReturn}
        onChangePassword={(e) =>
          this.onChangePassword(e.target.value, 'password')
        }
        onChangeNewPassword={(e) =>
          this.onChangePassword(e.target.value, 'newPassword')
        }
        onChangeConfirmPassword={(e) =>
          this.onChangePassword(e.target.value, 'confirmPassword')
        }
        onChangeEmail={(e) => this.onChangeEmail(e.target.value, 'email')}
        onChangeNewEmail={(e) => this.onChangeEmail(e.target.value, 'newEmail')}
        onChangeConfirmEmail={(e) =>
          this.onChangeEmail(e.target.value, 'confirmEmail')
        }
        onSubmit={this.onSubmit}
        onSubmitMail={this.onSubmitMail}
        onHide={this.onHide}
        onConfirmCode={this.onConfirmCode}
        validateMail={this.validateMail}
        validatePassword={this.validatePassword}
        code={this.state.code}
        changeCode={this.changeCode}
        passwordErrorMsg={this.state.passwordErrorMsg}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Setting);
