const getMemberTypeCode = memberType => {
  if (memberType === 'Client') {
    return 1;
  }
  if (memberType === 'Affiliate') {
    return 2;
  }
  return 0;
};

export default getMemberTypeCode;
