import React from 'react';
import { Text, ProgressBar, Icon, Checkbox, Img } from '../../Atoms';
import { Input, AffiliationRecapMolecule, Upload } from '../../Molecules';
import dispenseImage from '../../assets/img/dispense.jpg';

function OrganismDispense(props) {
    return (
        <div className="organism-affilation-stage1-container">
            <Text text="Vous avez choisi de bénéficier d’un cas de dispense" className="dispense-title" />
            <Img src={dispenseImage} className="dispense-img" />
            <div className="dispense-content-container">
                <div className="dispense-content-info">
                    <span>Je soussigné Alba LESIRE</span>, bénéficie du cas de dispense suivant : Salarié(e) bénéficiaire, y compris en tant qu’ayant droit, d’une couverture collective frais de santé relevant de l’un des dispositifs de prévoyance complémentaire suivants fixés par l’arrêté du 26 Mars 2012 :
                </div>
                <Text text="- Dispositif de prévoyance complémentaire collectif obligatoire, le caractère obligatoire s’entendant également pour l’ayant droit (remplissant les conditions mentionnées à l’article L242 - 1 du code de la sécurité sociale);" />
                <Text text="- Régime local d’assurance maladie du Haut-Rhin, du Bas-Rhin et de la Moselle (en application des articles D325-6 et D325-7 du code de la sécurité sociale);" />
                <Text text="- Régime complémentaire d’assurance maladie des industries électriques et gazières (en application du décret n°46-1541 du 22 juin 1946); " />
                <Text text="- Régime de prévoyance de la Fonction Publique d’Etat issu du décret n°2007-1373 du 19 septembre 2007;" />
                <Text text="- Régime de prévoyance de la Fonction Publique territoriale issu du décret n°2011-1474 du 8 novembre 2011;" />
                <Text text="- Contrat d’assurance groupe « Madelin » issu de la loi n°94-126 du 11 février 1994; " />
                <Text text="- Régime spécial de sécurité sociale des gens de mer (ENIM); " />
                <Text text="- Caisse de prévoyance et  de retraite des personnels de la SNCF (CPRPSNCF)." />
                <Text text="Justificatif fourni : Attestation de l'organisme habilité à fournir annuellement." className="dispense-justificatif" />
                
                <Text text="Je déclare avoir été informé de la mise en place d’un régime obligatoire de complémentaire santé dans l’entreprise et avoir reçu la notice d’information relative au dit régime." className="dispense-content-obligation" />
                <div>
                    Je dispose, ainsi que mes ayants droit, <span>d&apos;un droit d&apos;accès, de rectification, d’opposition, d’interrogation, d’un droit à l’effacement (droit à l’oubli), d’un droit à la limitation du traitement et à la portabilité et de suppression des données me concernant.</span>
                </div>

                <Text text="Ces droits peuvent être exercés auprès de SPVIE Assurances - 26, rue Pagès 92150 Suresnes – contact@spvie.com. " />
                
            </div>
        </div>
    );
}

export default OrganismDispense;
