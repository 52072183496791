import React from 'react';
import { Text, Button, Icon, Card } from '../../Atoms';
import { Row, Col } from 'react-bootstrap';
function TemplateHospitalisationStep2 (props) {
  return (
    <div className='template-scroll cartetp-container'>
      <div className='cartetp-header-container hospitalisation-title-container'>
        <Text text='Hospitalisation' type='h4' />
      </div>
      <Icon
        icon='Sort'
        size={15}
        onClick={() => props.prevStep()}
        className='go-back-icon-hospitalisation'
      />
      <Text
        text='Choissisez un bénéficiaire'
        type='h7'
        className='hospitalisation-step-sub-title'
      />
      <div className='container-hospitalisation'>
        <Text
          text='Quel bénéficiaire est concerné par cette prise en charge ?'
          type='h7'
          className='hospitalisation-step-title'
        />
      </div>
      <div className='profile-card-container'>
        <Row>
          {props.benificiaireList.map((el, index) => (
            <Col
              className='container-card-profile scale-up-center'
              md={4}
              xs={
                (index + 1) % 3 === 2 &&
                props.benificiaireList.length === index + 1
                  ? 12
                  : 6
              }
            >
              <Card
                text={el.name}
                size='medium'
                onClick={() => props.beneficiairPicked(el)}
                className='card-hospitalisation'
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default TemplateHospitalisationStep2;
