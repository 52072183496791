import React from "react"
import { HealthServiceTemplate } from "../../Templates";
import Store from "../../Redux/Store";
class HealthService extends React.Component {
    constructor(props) {
        super(props);
        const reseauPARTENAIRE = Store.getState().auth.user.autorization.reseauPARTENAIRE
        // const reseauPARTENAIRE = "Kalivia"
        this.state = {
            content: reseauPARTENAIRE
        };
    }

    componentDidMount = async () => {
    }

    render() {
        return (
            <HealthServiceTemplate
                state={this.state}
            />
        );
    }
}

export default HealthService;
