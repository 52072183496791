import React, { Component } from 'react';
import { apiLogin } from '../../Api/Login/Login';
import { LoginTemplate } from '../../Templates';
import { connect } from 'react-redux';
import { apiGetMembersLogin } from '../../Api/Profile/Profile';
import { serilizeGetMembers } from '../../Api/Profile/serilizeProfile';
import LoginBackground from '../../Components/Login/LoginBackground';
import { apiGetWithCustomToken } from '../../Api/standardApi';
import LoginModal from '../../Components/Login/LoginModal';
import LoginModalTitle from '../../Components/Login/LoginModalTitle';
import styles from './../LoginScreens/login.module.css';
import { Input } from '../../Molecules';
import LoginInput from '../../Components/Login/LoginInput';
import LoginInputLabel from '../../Components/Login/LoginInputLabel';
import LoginButton from '../../Components/Login/LoginButton';
import SubscribeButton from '../../Components/Login/SubscribeButton';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        emailOrMemberNumber: '',
        password: ''
      },
      isValid: true,
      errorMessage: '',
      saveData: false,
      showPassword: true
    };
  }

  onChangeData = (value, key) => {
    const aux = { ...this.state.data };
    aux[key] = value;
    this.setState({ data: aux, isValid: true });
  };

  getProfileData = async (memberId, memberType, token) => {
    const res = await apiGetMembersLogin(memberId, token);
    if (res.status == 200) {
      const action = {
        type: 'SET_PROFILE',
        value: {
          profile: serilizeGetMembers(res, memberType)
        }
      };
      this.props.dispatch(action);
    }
  };

  goRegisterPage = async (e) => {
    e.preventDefault();
    this.props.history.push('/register');
  };

  onChangeSaveData = () => {
    this.setState({ saveData: !this.state.saveData });
  };

  onClickIconShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    this.login();
  };

  render() {
    return (
      <LoginBackground>
        <div className={styles.container}>
          <LoginModal>
            <LoginModalTitle title={'Connexion à votre espace Assuré :'} />
            <div className={styles.contentContainer}>
              <form id="reset-password-form" onSubmit={this.onSubmit}>
                {!this.state.isValid ? (
                  <div className={styles.errorMessageContainer}>
                    <strong className={styles.errorMessage}>
                      {this.state.errorMessage}
                    </strong>
                  </div>
                ) : null}
                <LoginInputLabel htmlFor="email-or-identifier">
                  Email ou identifiant
                </LoginInputLabel>
                <LoginInput
                  id="email-or-identifier"
                  value={this.state.data.emailOrMemberNumber}
                  onChange={(e) => {
                    this.onChangeData(e.target.value, 'emailOrMemberNumber');
                  }}
                />
                <LoginInputLabel htmlFor="new-mdp-input">
                  Mot de passe
                </LoginInputLabel>
                <LoginInput
                  id="new-mdp-input"
                  previewPasswordEnabled={true}
                  type="password"
                  onChange={(e) => {
                    this.onChangeData(e.target.value, 'password');
                  }}
                />
                <div className={styles.submitButtonContainer}>
                  <LoginButton type="submit" form="reset-password-form">
                    Valider
                  </LoginButton>
                  <hr className={styles.loginSeparator}></hr>
                  <span>Pas encore de compte ? </span>
                  <SubscribeButton onClick={this.goRegisterPage}>
                    S'inscrire
                  </SubscribeButton>
                </div>
              </form>
            </div>
          </LoginModal>
        </div>
      </LoginBackground>
      // <LoginTemplate
      //   onChangeData={this.onChangeData}
      //   onChangeSaveData={this.onChangeSaveData}
      //   login={this.login}
      //   onClickIconShowPassword={this.onClickIconShowPassword}
      //   {...this.state}
      // />
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
const mapStateToProps = (state) => ({
  auth: state.auth
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
