import { useDispatch } from 'react-redux';
import KeycloakService from '../Services/KeycloakService';
import { apiGetMembers } from '../Api/Profile/Profile';
import { useState, useEffect } from 'react';
import MainLoader from '../Components/MainLoader';
import { useLocation } from 'react-router-dom';
import { AUTH_ROUTES } from './Auth';
const RenderPrivate = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!KeycloakService.isLoggedIn()) {
      const routeNames = AUTH_ROUTES.map((r) => r.route);
      const routesWithRedirect = [...routeNames, '/redirect/to'];
      if (!routesWithRedirect.includes(pathname?.toLowerCase())) {
        KeycloakService.doLogin();
      } else {
        setLoading(false);
      }
    } else {
      fetchProfil(KeycloakService.getToken());
    }
  }, [pathname]);

  const fetchProfil = async (token) => {
    if (token) {
      const res = await apiGetMembers(token);
      const connecteduser = {
        user: {
          id: '135399',
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.mailPhoneFax.emailAddress,
          token,
          hasIntro: false,
          codeTiers: res.codeAdherent,
          goodCodeTiers: res.codeTiers,
          autorization: {
            codeRetour: 'true',
            autorisationCGRM: 'false',
            autorisationGRASSAVOYE: 'false',
            autorisationKalivia: 'false',
            autorisationLPA: 'false',
            carteTP: 'VIAMEDIS',
            reseauPARTENAIRE: res.reseauPartenaire || ''
          },
          assistance: {
            codePolice: '1043509-5',
            assistance: {
              active: 'true',
              numCompagnie: null,
              nomCompagnie: null,
              numContrat: null,
              numProtocole: '7203069',
              numAppel: '01.72.59.51.60',
              infoComp: null,
              dateInfo: '2022-11-30+01:00'
            }
          },
          firstLogin: false,
          brokerCodeTiers: 3725404
        }
        // beneficiaries: formatBeneficiaries(res),
      };
      const action = {
        type: 'SET_CURRENT_USER',
        value: {
          accessToken: token,
          user: connecteduser.user
        }
      };
      dispatch(action);
      dispatch({
        type: 'SET_PROFILE',
        value: {
          beneficiaries: connecteduser.beneficiaries
        }
      });
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 9999,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: '#ffffff80'
        }}
      >
        <MainLoader />
      </div>
    );

  return KeycloakService.isLoggedIn() ? children : null;
};

export default RenderPrivate;
