import { apiGet } from '../standardApi';

export const getPractitionerSpecialities = () => {
	return apiGet('/Category/GetSpecialities', 'application/json');
};

export const getFrenchDepartments = () => {
	return apiGet(
		'/Geolocalisation/GetDepartments',
		'application/json',
	);
};

export const getFrenchCitiesByDepartment = (departmentNumber) => {
	return apiGet(
		`/Geolocalisation/GetCities?departmentNumber=${departmentNumber}`,
		'application/json',
	);
};

export const apiGetPractioners = ({
	department,
	city,
	speciality,
	latitude,
	longitude,
	rayon,
}) => {
	const params = [
		...(department ? [`Department=${department}`] : []),
		...(city ? [`Commune=${city}`] : []),
		...(speciality ? [`Specialite=${speciality}`] : []),
		...(latitude ? [`Latitude=${latitude}`] : []),
		...(longitude ? [`Longitude=${longitude}`] : []),
		...(rayon ? [`Rayon=${rayon}`] : []),
	];

	return apiGet(`/Geolocalisation/GetPractioners?${params.join('&')}`);
};
