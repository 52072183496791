import React, { Component } from 'react';
import {
  CHECK_MEMBER_EXIST,
  CHECK_MEMBER_NOT_VALID_MAIL
} from '../../Api/Login/errorMessage';
import { apiCheckMember } from '../../Api/Login/Login';
import {
  TemplateFirstLoginStep1,
  TemplateFirstLoginStep2,
  TemplateFirstLoginStep3,
  TemplateResetPasswordMailStep1,
  TemplateResetPasswordMailStep2
} from '../../Templates';
class FirstLogin extends Component {
  constructor (props) {
    super(props);
    this.state = {
      step: 1,
      mail: '',
      isValid: true,
      msgError: '',
      firstConnexion: true
    };
  }

  onChange = value => {
    this.setState({
      mail: value
    });
  };

  onReturn = () => {
    this.props.history.push('/login')
  }

  nextStep = async () => {
    try {
      const res = await apiCheckMember(this.state.mail);
      if (res.status === 200) {
        this.setState({ step: this.state.step + 1 });
      } else {
        if (res.status === 400 && res.errors.length) {
          this.setState({
            isValid: false,
            msgError: res.errors.includes(CHECK_MEMBER_EXIST) ? (
              <>
                <span className='bold-error-msg'>
                  Ce numéro d’adhérent ou cet email est déjà associé à un
                  compte.
                </span>
                <span className='error-msg'>
                  Si vous ne vous souvenez plus de votre mot de passe, cliquez
                  sur “mot de passe oublié” sur la page de connexion.
                </span>
              </>
            ) : (
              <>
                <span className='bold-error-msg'>
                  Numéro adhérent ou adresse mail invalide.
                </span>
                <span className='error-msg'>
                  {' '}
                  Merci de vous réferer aux informations liées à votre contrat.
                </span>
              </>
            )
          });
        } else {
          this.setState({
            isValid: true,
            msgError: ''
          });
        }
      }
    } catch (err) {
      this.setState({
        isValid: true,
        msgError: ''
      });
    }
  };

  render () {
    switch (this.state.step) {
      case 1:
        return <TemplateFirstLoginStep1 {...this.state} {...this} />;

      case 2:
        return <TemplateResetPasswordMailStep2 {...this.state} {...this} />;

      default:
        return null;
    }
  }
}
export default FirstLogin;
