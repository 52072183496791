import React from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from '../../Atoms';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import capitalize from '../../Utils/capitalize';
import FaqQuestionCardTemplate from '../../Templates/TemplateFAQ/FaqQuestionCardTemplate';
import PageTitle from '../../Components/Dashboard/PageTitle';

const FaqQuestions = () => {
    const location = useLocation();
    const history = useHistory();
    const categorieFaq = location.state.item 
    return (
        <div className='template-container template-send-document template-scroll'>
            <PageTitle titleText="Foire aux questions" onTitleClick={() => history.goBack()}/>

            <Text
                text={capitalize(categorieFaq.title)}
                className='faq-question-title'
            />
            <div className='faq-question-container'>
                {
                    categorieFaq && categorieFaq.questions.map((question,index)=>{
                        return <FaqQuestionCardTemplate key={question.id} question={question} category={location.state.category}/>
                    })
                }
            </div>
        </div>
    );
};

export default FaqQuestions;