import { Modal, ModalFooter } from 'react-bootstrap';
import React from 'react';
import { Icon, Button } from '../../Atoms';
import PropTypes from 'prop-types';
MoleculeModal.defaultProps = {
  size: 'meduim',
  showIconClose: true,
  show: false,
  onHide: null,
  onHideWithReset: null,
  resetDataWithClickOutSide: false,
  showBtn: false,
  contentClassName: '',
  dialogClassName: '',
  centered: true,
  transparent: true,
  containerIconClassName: '',
};
MoleculeModal.PropTypes = {
  size: PropTypes.oneOf(['small', 'meduim', 'larg']),
  showIconClose: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.any,
  onHideWithReset: PropTypes.any,
  resetDataWithClickOutSide: PropTypes.bool,
  contentClassName: PropTypes.string,
  dialogClassName: PropTypes.string,
  centered: PropTypes.bool,
  transparent: PropTypes.bool,
  containerIconClassName: PropTypes.string,
};
export default function MoleculeModal (props) {
  function viewIconClose () {
    if (props.showIconClose === true) {
      const containerIconClassName = ['container-flex-end', 'container-icon-fixed-position']
      if (props.containerIconClassName) containerIconClassName.push(props.containerIconClassName)
      return (
        <div className={containerIconClassName.join(' ')}>
          <div>
            <Icon
              icon='CloseIcon'
              size='10px'
              className='icon-close-modal'
              onClick={props.onHideWithReset}
            />
          </div>
        </div>
      );
    }
    return null;
  }
  let contentClassName = 'px-modal-content';
  const contentClass = 'container-modal-content ';

  if (props.size === 'small') {
    contentClassName += ' small-modal-content ';
  }
  if (props.size === 'meduim') {
    contentClassName += ' meduim-modal-content ';
  }
  if (props.size === 'larg') {
    contentClassName += ' larg-modal-content ';
  }
  return (
    <Modal
      show={props.show}
      centered= {props.centered}
      contentClassName={contentClassName + props.contentClassName}
      {...(props.custom ? {dialogClassName: "custom-modal"} : {dialogClassName: 'modal-dialog ' + props.dialogClassName})}
      {...(props.custom && {bsClass: props.dialogClassName})}
      onHide={
        props.resetDataWithClickOutSide === true
          ? props.onHideWithReset
          : props.onHide
      }
    >
        {viewIconClose()}
        {props.children}
    </Modal>
  );
}
