const initialState = {
    show: false
};

function ShowRatingModalReducer(state = initialState, action) {
    switch (action.type) {
        case 'RATING_MODAL_SHOW':
            return {
                ...state,
                show: action.value
            };
        default:
            return state;
    }
}

export default ShowRatingModalReducer;
