import { API_URL } from '../Config/Config';
import {
  addPipelineLoader,
  rmPipelineLoader,
  serverError,
  serverMaintenance,
  setToken,
  setUser
} from '../Redux/Actions/authActions/authActions';
import Store from '../Redux/Store';
import KeycloakService from '../Services/KeycloakService';
import { apiRefreshToken } from './Login/Login';

const createHeader = (token, accept, contentType = 'application/json') => {
  if (contentType === 'form-data') {
    return {
      Authorization: 'Bearer ' + token
    };
  } else {
    return {
      'Content-Type': contentType,
      accept: accept,
      Authorization: 'Bearer ' + token
    };
  }
};

export async function processResponse(response, callback) {
  if (response.status === 500) {
    Store.dispatch(serverError());
  }
  if (response.status === 503) {
    Store.dispatch(serverMaintenance());
  }
  // if (response.status === 401) {
  //   await KeycloakService.updateToken(async () => {
  //     const newResponse = await fetch(response.url, {
  //       method: response.method,
  //       headers: response.headers,
  //       body: response.body
  //     });
  //     if (newResponse.status === 401) {
  //       KeycloakService.doLogin();
  //     } else {
  //       return await processResponse(newResponse, callback);
  //     }
  //   });
  // } else {
  let responseJson;
  try {
    responseJson = await response.json();
  } catch (e) {
    return { status: response.status };
  }

  if (responseJson?.errors && !Array.isArray(responseJson?.errors)) {
    let newErrors = [];
    Object.keys(responseJson.errors).map((key) => {
      const errorsArray = responseJson.errors[key];
      newErrors = [...newErrors, ...errorsArray];
    });
    responseJson.errors = newErrors;
  }

  if (responseJson.length === undefined) {
    return { ...responseJson, status: response.status };
  } else {
    return { data: responseJson, status: response.status };
  }
}

export const makeErrorMsg = (response) => {
  let errorMsg = 'Identifiant ou mot de passe invalide.';
  if (
    response.status === 400 &&
    response.errors[0] ===
      'Identifiants bloqués. Patientez 5 minutes et réessayez'
  ) {
    errorMsg =
      'Votre compte a été bloqué pendant 5 minutes. Trop de tentatives de connexion ratées. ';
  }

  return errorMsg;
};

async function makeGetRequest(api, accept, token) {
  const bearerToken = KeycloakService.isLoggedIn()
    ? KeycloakService.getToken()
    : '';
  try {
    const response = await fetch(API_URL + api, {
      method: 'Get',
      headers: createHeader(bearerToken, accept)
    });

    if (response) {
      Store.dispatch(rmPipelineLoader(api));
    }

    const resp = await processResponse(response, () => apiGet(api, accept));
    return resp;
  } catch (error) {
    Store.dispatch(rmPipelineLoader(api));
  }
}

export async function apiGet(api, accept, token) {
  Store.dispatch(addPipelineLoader(api));
  if (KeycloakService.isTokenExpireSoon()) {
    const refreshed = await KeycloakService.updateToken();
    if (refreshed) {
      return makeGetRequest(api, accept, token);
    }
  } else {
    return makeGetRequest(api, accept, token);
  }
}

async function makePostRequest(
  api,
  body,
  accept,
  contentType = 'application/json'
) {
  Store.dispatch(addPipelineLoader(api));
  try {
    const token = KeycloakService.getToken();

    const response = await fetch(API_URL + api, {
      method: 'POST',
      headers: createHeader(token, accept, contentType),
      body: body
    });
    if (response) {
      Store.dispatch(rmPipelineLoader(api));
    }

    return processResponse(response, () => apiPost(api, body, contentType));
  } catch (err) {
    Store.dispatch(rmPipelineLoader(api));
    return { status: 500 };
  }
}

export async function apiPost(
  api,
  body,
  accept,
  contentType = 'application/json'
) {
  if (KeycloakService.isTokenExpireSoon()) {
    const refreshed = await KeycloakService.updateToken();
    if (refreshed) {
      return makePostRequest(api, body, accept, contentType);
    }
  } else {
    return makePostRequest(api, body, accept, contentType);
  }
}

async function makeDeleteRequest(api, body, accept) {
  Store.dispatch(addPipelineLoader(api));
  const token = KeycloakService.getToken();
  const response = await fetch(API_URL + api, {
    method: 'Delete',
    headers: createHeader(token, accept, 'application/json'),
    body: body
  });

  if (response) {
    Store.dispatch(rmPipelineLoader(api));
  }

  return processResponse(response, () => apiDelete(api, body));
}
export async function apiDelete(api, body, accept) {
  if (KeycloakService.isTokenExpireSoon()) {
    const refreshed = await KeycloakService.updateToken();
    if (refreshed) {
      return makeDeleteRequest(api, body, accept);
    }
  } else {
    return makeDeleteRequest(api, body, accept);
  }
}

async function makePutRequest(
  api,
  body,
  accept,
  contentType = 'application/json'
) {
  Store.dispatch(addPipelineLoader(api));
  const token = KeycloakService.getToken();
  const response = await fetch(API_URL + api, {
    method: 'Put',
    headers: createHeader(token, accept, contentType),
    body: body
  });

  if (response) {
    Store.dispatch(rmPipelineLoader(api));
  }

  return processResponse(response, () => apiPost(api, body, contentType));
}

export async function apiPut(
  api,
  body,
  accept,
  contentType = 'application/json'
) {
  if (KeycloakService.isTokenExpireSoon()) {
    const refreshed = await KeycloakService.updateToken();
    if (refreshed) {
      return makePutRequest(api, body, accept, contentType);
    }
  } else {
    return makePutRequest(api, body, accept, contentType);
  }
}

export async function apiPostUpload(
  api,
  body,
  accept,
  contentType = 'form-data'
) {
  Store.dispatch(addPipelineLoader(api));
  const token = KeycloakService.getToken();
  const response = await fetch(API_URL + api, {
    method: 'POST',
    headers: createHeader(token, accept, contentType),
    body: body
  });

  if (response) {
    Store.dispatch(rmPipelineLoader(api));
  }

  return processResponse(response, () => apiPost(api, body, contentType));
}

async function makePatchRequest(
  api,
  body,
  accept,
  contentType = 'application/json'
) {
  Store.dispatch(addPipelineLoader(api));
  const token = KeycloakService.getToken();
  const response = await fetch(API_URL + api, {
    method: 'PATCH',
    headers: createHeader(token, accept, contentType),
    body: body
  });

  if (response) {
    Store.dispatch(rmPipelineLoader(api));
  }

  return processResponse(response, () =>
    apiPost(api, body, accept, contentType)
  );
}

export async function apiPatch(
  api,
  body,
  accept,
  contentType = 'application/json'
) {
  if (KeycloakService.isTokenExpireSoon()) {
    const refreshed = await KeycloakService.updateToken();
    if (refreshed) {
      return makePatchRequest(api, body, accept, contentType);
    }
  } else {
    return makePatchRequest(api, body, accept, contentType);
  }
}
