import React, { useState, useEffect } from 'react';
import { TdTableWithIcon } from '..';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Checkbox, Status } from '../../Atoms';

MoleculeTable.defaultProps = {
  TableClassName: 'table-content',
  listColumns: null,
  ContainerClassName: 'table-container',
  data: null,
  format: 'DD/MM/YYYY',
  selectElement: null,
  LigneClassName: 'table-ligne',
  LigneSelectedClassName: 'table-ligne-selected',
  ligneSelected: null,
  headerclassName: 'header-table',
  columnSort: null,
  HeaderColumnSortClassName: 'table-sort-header',
  ColumnSortClassName: 'column-sort',
  table: null,
  listTable: ['lot', 'contact'],
  column: null,
  dataOfNullColumn: null,
  onCheck: null
};
MoleculeTable.PropTypes = {
  listColumns: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.elementType
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.elementType
    })
  ),
  column: PropTypes.arrayOf(PropTypes.string),
  dataOfNullColumn: PropTypes.arrayOf(PropTypes.string),
  listTable: PropTypes.arrayOf(PropTypes.string),
  onCheck: PropTypes.any
};
export default function MoleculeTable(props) {
  const [listElementChecked, setlistElementChecked] = useState([])
  function onCheck(id) {
    let list = [...listElementChecked]
    if (id) {
      const index = listElementChecked.indexOf(id)
      if (index === -1) {
        list.push(id)
      } else {
        list.splice(index, 1)
      }
    } else {
      if (listElementChecked.length === props.data.length) {
        list = []
      } else {
        list = props.data.map(el => { return el._id })
      }
    }
    setlistElementChecked(list)
    props.onCheck && props.onCheck(list)
  }
  function getChecked(id) {
    const index = listElementChecked.indexOf(id)
    if (index === -1) { return false } else { return true }
  }
  useEffect(() => {

  }, [props.data])
  function showData() {
    const list = props.data.map(element => {
      const listColumns = defineRow(element, props.listColumns);
      return (
        <tr
          className={
            props.ligneSelected !== null && props.ligneSelected == element._id
              ? props.LigneSelectedClassName
              : props.LigneClassName
          }
        >
          {listColumns}
        </tr>
      );
    });
    return list;
  }
  function defineRow(element, listColumns) {
    const list = listColumns.map(el => {
      if (el.index === 'checked') {
        return <td className="td-checkox"><Checkbox onClick={() => onCheck(element._id)} checked={getChecked(element._id)} /></td>;
      } else {
        if (el.index === "actions") {
          return element[el.index];
        } else {
          if (el.index === "status") {
            return <td><Status type={element[el.index].status} text={element[el.index].text} icon={element[el.index].icon} /></td>
          } else {
            return (
              <td
                onClick={() =>
                  props.selectElement !== null && props.selectElement(element._id)
                }
                className={
                  props.columnSort === el.index ? props.ColumnSortClassName : null
                }
              >
                {view(el, element) ? view(el, element) : viewelementTable(el)}
              </td>
            );
          }
        }
      }
    });
    return list;
  }
  function viewelementTable(col) {
    if (
      props.table !== null &&
      props.listTable !== null &&
      props.column !== null &&
      props.dataOfNullColumn !== null
    ) {
      const indexTable = props.listTable.indexOf(props.table);
      if (indexTable !== -1) {
        const indexColumn = props.column.indexOf(col.index);
        if (indexColumn !== -1) {
          if (props.dataOfNullColumn[indexColumn]) {
            return props.dataOfNullColumn[indexColumn];
          }
        }
      }
    }
    return null;
  }
  function view(index, data) {
    if (index.index.split('.').length === 1) {
      if (data[index.index.split('.')[0]]) {
        if (index.type === 'date') {
          return moment(data[index.index]).format(props.format);
        } else {
          return data[index.index];
        }
      }
    } else if (index.index.split('.').length === 2) {
      if (
        data[index.index.split('.')[0]] &&
        data[index.index.split('.')[0]][index.index.split('.')[1]]
      ) {
        if (index.type === 'date') {
          return moment(
            data[index.index.split('.')[0]][index.index.split('.')[1]]
          ).format(props.format);
        } else {
          return data[index.index.split('.')[0]][index.index.split('.')[1]];
        }
      }
    } else if (index.index.split('.').length === 3) {
      if (
        data[index.index.split('.')[0]] &&
        data[index.index.split('.')[0]][index.index.split('.')[1]] &&
        data[index.index.split('.')[0]][index.index.split('.')[1]][
        index.index.split('.')[2]
        ]
      ) {
        if (index.type === 'date') {
          return moment(
            data[index.index.split('.')[0]][index.index.split('.')[1]][
            index.index.split('.')[2]
            ]
          ).format(props.format);
        } else {
          return data[index.index.split('.')[0]][index.index.split('.')[1]][
            index.index.split('.')[3]
          ];
        }
      }
    }
  }
  return (
    <div className={props.ContainerClassName}>
      <table className={props.TableClassName}>
        <tbody>
          <tr className={props.headerclassName}>
            {props.listColumns.map(element =>
              element.index === "checked" ? <td className="td-checbox"><Checkbox
                onClick={() => onCheck()}
                checked={props.data.length === listElementChecked.length ? true : false} />
              </td> : < TdTableWithIcon
                text={element.column}
                className={
                  props.columnSort === element.index
                    ? props.HeaderColumnSortClassName
                    : element.className
                }
                onClick={element.onClick}
                classNameIcon={element.classNameIcon}
                icon={element.icon}
                size={element.size}
              />
            )
            }
          </tr>
          {showData()}
        </tbody>
      </table>
    </div>
  );
}
