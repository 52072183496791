import React, { Component } from 'react';
import { apiValidateSmsCode} from '../../Api/Login/Login';
import { connect } from 'react-redux';
import { apiGetMembersLogin } from '../../Api/Profile/Profile';
import { serilizeGetMembers } from '../../Api/Profile/serilizeProfile';
import LoginBackground from '../../Components/Login/LoginBackground';
import LoginModal from '../../Components/Login/LoginModal';
import LoginModalTitle from '../../Components/Login/LoginModalTitle';
import styles from './../LoginScreens/login.module.css';
import LoginInput from '../../Components/Login/LoginInput';
import LoginButton from '../../Components/Login/LoginButton';
import { Text } from '../../Atoms';
import { makeErrorMsg } from '../../Api/standardApi';
class MfaVerification extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      data: {
        smsCode: ''
      },
      isValid: true,
      errorMessage: '',
      saveData: false,
    };
  }
  componentDidMount() {
    const id = this.props.location.state?.id;
    if (!id) {
        this.props.history.push('/login');
    }
  }
  onChangeData = (value, key) => {
    const aux = { ...this.state.data };
    aux[key] = value;
    this.setState({ data: aux, isValid: true });
  };
  getProfileData = async (memberId, memberType, token) => {
    const res = await apiGetMembersLogin(memberId, token);
    if (res.status == 200) {
      const action = {
        type: 'SET_PROFILE',
        value: {
          profile: serilizeGetMembers(res, memberType)
        }
      };
      this.props.dispatch(action);
    }
  };
  validateSmsCode = async () => {
    if (!this.state.data.smsCode) {
        return this.setState({
            isValid: false,
            errorMessage: "Le champ est obligatoire"
        });
    }
    try {
      const id = this.props.location.state?.id;  
      const res = await apiValidateSmsCode(
        id,
        this.state.data.smsCode
      );
      const { token, ...user } = res;
      if (res.status === 200) {
        await this.getProfileData(user.memberId, user.memberType, token);
        const action = {
          type: 'SET_CURRENT_USER',
          value: {
            accessToken: token,
            refreshToken: null,
            user
          }
        };
        this.props.dispatch(action);
        this.props.history.push('/');
      } else {
        this.setState({
          isValid: false,
          errorMessage: res.errors[0] ? res.errors[0].errorMessage :  makeErrorMsg(res)
        });
      }
    } catch (e) {
      this.setState({
        isValid: false,
        errorMessage: "Une erreur s'est produite. Veuillez ré-essayer."
      });
    }
  };
  onChangeSaveData = () => {
    this.setState({ saveData: !this.state.saveData });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    this.validateSmsCode();
  };

  onNoReceiveCode = ()=>{
    this.props.history.push('/recover-phone');
  }
  render() {
    return (
      <LoginBackground>
        <div className={styles.container}>
          <LoginModal>
            <LoginModalTitle title={'Connexion à votre espace Assuré :'} />
            <div className={styles.contentContainer}>
              <Text text="Nous venons de vous envoyer un code de verification par SMS." type="h8"/>
              <br/>  
              <Text text="Ce code est necessaire pour vous connecter à notre site web." type="h8"/>
              <br/>  
              <Text text="Veuillez vérifier votre téléphone portable et saisir le code reçu dans le formulaire ci dessous." type="h8"/>  
              <br/>
              <form id="reset-password-form" onSubmit={this.onSubmit}>
                {!this.state.isValid ? (
                  <div className={styles.errorMessageContainer}>
                    <strong className={styles.errorMessage}>
                      {this.state.errorMessage}
                    </strong>
                  </div>
                ) : null}
                <LoginInput
                  placeholder="Code de vérification"  
                  id="code-verification"
                  value={this.state.data.smsCode}
                  onChange={(e) => {
                    this.onChangeData(e.target.value, 'smsCode');
                  }}
                />
                <div className={styles.submitButtonContainer}>
                  <LoginButton type="submit" form="reset-password-form">
                    Valider
                  </LoginButton>
                  <hr className={styles.loginSeparator}></hr>
                  <span className='no-code-received' onClick={this.onNoReceiveCode}>Je n'ai pas reçu de code de vérification </span>
                </div>
              </form>
            </div>
          </LoginModal>
        </div>
      </LoginBackground>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
const mapStateToProps = (state) => ({
  auth: state.auth
});
export default connect(mapStateToProps, mapDispatchToProps)(MfaVerification);