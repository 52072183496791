import React, { useState } from 'react';
import { Chart } from '../../Organisms/';
import { Icon, Button, DatePicker } from '../../Atoms';
import { Input } from '../../Molecules';
import { Col, Row } from 'react-bootstrap';
import { DEMANDE_COLOR, DEMANDE_FROM, red } from '../../Config/Config';
import PropTypes from 'prop-types';
import { apiDownloadSentDemandDocument } from './../../Api/Documents/Documents';
function OrganismDemandeCardDetail(props) {
  const renderDocument = (document) => {
    if (document.length) {
      return (
        <>
          {document.map((element) => {
            return (
              <div className="demande-card-detail-document-container">
                <Icon icon="FolderFacture" size={12} />
                <div style={{ marginLeft: '8.5px' }}>{element}</div>
              </div>
            );
          })}
        </>
      );
    }
    return null;
  };
  const getOrder = (index) => {
    if (index % 2) {
      return 1;
    } else {
      return 2;
    }
  };

  const docContent = (data) => {
    return (
      <div>
        <div>
          <span>{props.date}</span>
        </div>
        <div>
          <span
            onClick={() => downloadFile(data)}
            className="document-contact-downalod"
          >
            document-1
          </span>
        </div>
      </div>
    );
  };
  const downloadFile = async (myDoc) => {
    const res = await apiDownloadSentDemandDocument(myDoc);
    const { blob, url } = await createBlob(res);
    if (url) {
      const download = document.createElement('a');
      download.setAttribute('href', url);
      download.setAttribute('target', '_blank');
      download.click();
    }
  };
  const createBlob = async (data) => {
    try {
      const blob = await (
        await fetch(`data:${data.contentType};base64,${data.fileContent}`)
      ).blob();
      const url = URL.createObjectURL(blob);
      return { blob, url };
    } catch (err) {}
  };
  const content = (data, index) => {
    const order = getOrder(index);
    const containerClassName = ['demande-card-detail-content-container'];
    if (data.from == 'SPVIE') {
      containerClassName.push('flex-reverse');
    }
    let fromColor = DEMANDE_FROM.find((el) => el.from == data.from).color;
    fromColor = fromColor == undefined ? 'white' : fromColor;
    return (
      <div className={containerClassName.join(' ')}>
        <div style={{ flex: 1 }}>
          <div style={{ marginBottom: '10px' }}>{data.date}</div>
          <div className="">{data.description}</div>
          <div className="demande-card-detail-document">
            {renderDocument(data.document)}
          </div>
        </div>
        <div
          className={
            data.from == 'SPVIE'
              ? `demande-card-detail-image-margin-1`
              : `demande-card-detail-image-margin-2`
          }
        >
          <div
            className="demande-card-detail-image"
            style={{ backgroundColor: fromColor }}
          >
            {data.from}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="demande-card-detail-container">
      {/* {props.data.map((element, index) => {
        return content(element, index);
      })} */}
      {docContent(props.docData)}
    </div>
  );
}
OrganismDemandeCardDetail.PropTypes = {
  data: PropTypes.array
};
OrganismDemandeCardDetail.defaultProps = {
  data: [
    {
      date: '09/11/2020',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus sit tristique odio elit ut. Enim rutrum elit erat vel dui euismod porttitor feugiat malesuada.',
      from: 'vous',
      document: ['nom-document1.pdf', 'nom-document2.pdf']
    },
    {
      date: '10/11/2020',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus sit tristique odio elit ut. Enim rutrum elit erat vel dui euismod porttitor feugiat malesuada.',
      from: 'SPVIE',
      document: []
    }
  ]
};
export default OrganismDemandeCardDetail;
