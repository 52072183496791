import React, { Component } from 'react';
import { PASSWORD_PATTERN } from '../../Api/Login/errorMessage';
import {
  apiCheckLinkValidity,
  apiCreateAccount,
  apiResetPassword
} from '../../Api/Login/Login';
import {
  TemplateFirstLoginStep2,
  TemplateFirstLoginStep3,
  TemplateResetPassword
} from '../../Templates';
class FirstLoginCreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      password: '',
      confirmPassword: '',
      memberId: '',
      isValid: true
    };
  }

  componentDidMount() {
    this.checkTokenValidity();
  }

  checkTokenValidity = async () => {
    const response = await apiCheckLinkValidity(
      this.props.match?.params?.token
    );
    if (response.status === 200) {
      this.setState({
        memberId: response.memberId
      });
    } else {
      alert('Impossible de traiter ce lien. Veuillez ré-essayer.');
      this.props.history.replace('/FirstLogin');
    }
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onSubmit = async () => {
    if (
      this.state.password !== this.state.confirmPassword ||
      !this.state.password.match(PASSWORD_PATTERN)
    ) {
      this.setState({
        isValid: false
      });
      return;
    }
    try {
      const res = await apiCreateAccount(
        this.state.memberId,
        this.state.password
      );
      if (res.status === 200) {
        this.setState({
          isValid: true,
          step: 2
        });
      } else {
        this.setState({
          isValid: false
        });
      }
    } catch (err) {
      this.setState({
        isValid: false
      });
    }
  };

  render() {
    switch (this.state.step) {
      case 1:
        return <TemplateFirstLoginStep2 {...this.state} {...this} />;
      case 2:
        return <TemplateFirstLoginStep3 {...this.state} {...this} />;
      default:
        return null;
    }
  }
}
export default FirstLoginCreateAccount;
