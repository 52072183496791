import React, { useState } from 'react';
import styles from './ResetPassword.module.css';
import LoginBackground from '../../../Components/Login/LoginBackground';
import LoginModal from '../../../Components/Login/LoginModal';
import LoginModalTitle from '../../../Components/Login/LoginModalTitle';
import { Input } from '../../../Atoms';
import { FormLabel } from 'react-bootstrap';
import LoginInput from '../../../Components/Login/LoginInput';
import LoginInputLabel from '../../../Components/Login/LoginInputLabel';
import LoginButton from '../../../Components/Login/LoginButton';
import isPasswordValid from '../../../Utils/isPasswordValid';
import { apiResetPassword } from '../../../Api/Login/Login';
import { ResetPasswordStepsEnum } from '../../../Config/enum';
import { useParams } from 'react-router-dom';

const ResetPasswordScreen = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isLoading, setLoader] = useState(false);

  const [step, setStep] = useState(ResetPasswordStepsEnum.SETUP_NEW_PASSWORD);

  const [error, setError] = useState([]);
  const { token } = useParams();

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    setError([]);

    if (!isPasswordValid(password)) {
      setLoader(false);
      return setError([
        'Attention, votre mot de passe ne respecte pas les conditions énoncées ci-dessous.'
      ]);
    }

    if (password !== confirmPassword) {
      setLoader(false);
      return setError(['Attention, les mots de passe ne sont pas identiques.']);
    }

    const res = await apiResetPassword(token, password);

    if (res.status === 200) {
      setLoader(false);
      return setStep(ResetPasswordStepsEnum.SUCCESS);
    } else {
      setLoader(false);
      return setError(res.errors?.map?.((i) => i.errorMessage) || []);
    }
  };

  return (
    <LoginBackground>
      <div className={styles.container}>
        <LoginModal>
          <LoginModalTitle
            title={
              step === ResetPasswordStepsEnum.SETUP_NEW_PASSWORD
                ? 'Veuillez réinitialiser votre mot de passe'
                : ''
            }
          />

          {step === ResetPasswordStepsEnum.SETUP_NEW_PASSWORD ? (
            <>
              <div className={styles.contentContainer}>
                {error.length > 0 ? (
                  <div className={styles.errorMessageContainer}>
                    {error?.map((err) => (
                      <strong className={styles.errorMessage}>{err}</strong>
                    ))}
                  </div>
                ) : null}
                <form id="reset-password-form" onSubmit={onSubmit}>
                  <LoginInputLabel htmlFor="new-mdp-input">
                    Nouveau mot de passe
                  </LoginInputLabel>
                  <LoginInput
                    disabled={isLoading}
                    id="new-mdp-input"
                    previewPasswordEnabled={true}
                    onPreviewPassword={() => setShowPassword((curr) => !curr)}
                    passwordVisible={showPassword}
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <LoginInputLabel htmlFor="confirm-new-mdp-input">
                    Confirmer votre nouveau mot de passe
                  </LoginInputLabel>
                  <LoginInput
                    disabled={isLoading}
                    id="confirm-new-mdp-input"
                    previewPasswordEnabled={true}
                    onPreviewPassword={() =>
                      setShowConfirmPassword((curr) => !curr)
                    }
                    passwordVisible={showConfirmPassword}
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </form>
                <div className={styles.passwordExplanationText}>
                  <span>Votre mot de passe doit contenir :</span>
                  <ul>
                    <li>minimum 8 caractères</li>
                    <li>minimum 1 majuscule</li>
                    <li>minimum 1 minuscule</li>
                    <li>minimum 1 chiffre</li>
                    <li>minimum 1 caractère spécial</li>
                  </ul>
                </div>

                <div className={styles.submitButtonContainer}>
                  <LoginButton
                    disabled={isLoading}
                    type="submit"
                    form="reset-password-form"
                  >
                    Valider
                  </LoginButton>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.textSuccess}>
              Votre mot de passe a bien été modifié
            </div>
          )}
        </LoginModal>
      </div>
    </LoginBackground>
  );
};

export default ResetPasswordScreen;
