import React, { useState, useEffect } from 'react';
import { Button, Icon } from '../../Atoms';
import { COLOR_BUTTON } from '../../Config/Config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FaqQuestionCardTemplate = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.category && props.question.question.includes(props.category)) {
            setIsOpen(true);
        }
    }, [props.category]);

    const interpreteHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const texteBrut = doc.body.textContent || "";
        return texteBrut.replace(/<[^>]*>/g, '');
    };

    const openQuestionDetails = () => {
        setIsOpen(!isOpen);
    };

    const openAction = (action,question) => {
      const content = action.content.toLowerCase();
      
      if (content == "transmettre votre rib") {
        history.push({
          pathname: `/documents/send`,
          state: {
            name: "BANQUE",
            page:"SEPA - mes cotisations et remboursements"
          }
        });
      }
      else if (content == "transmettre votre attestation") {
        history.push({
          pathname: `/documents/send`,
          state: {
            name: "PORTABILITE",
            page:"Attestation mensuelle"
          }
        });
      }
      else if (content == "transmettre des justificatifs") {
        history.push({
          pathname: `/documents/send`,
          state: {
            name: "PORTABILITE",
            page:"Mise en place"
          }
        });
      }
      else if (content == "transmettre vos attestations") {
        history.push({
          pathname: `/documents/send`,
          state: {
            name: "Attestation Sécurité sociale",
            page:"Attestation Sécurité sociale"
          }
        });
      }
      else if (content.includes("transmettre un devis")) {
        history.push({
          pathname: `/documents/send`,
          state: {
            name: "DEVIS"
          }
        });
      }
      else if (content.includes("garanties")) {
        history.push("/garanties");
      } else if (content.includes("bibliothèque")) {
        history.push("/bibliotheque");
      } else if (content.includes("carte de tiers payant")) {
        history.push("/Cartetp");
      } else if (content.includes("ajouter un enfant")) {
        history.push({
          pathname:"/profile",
          state:{
            step:"ADD_BENEFICIAIRE_STEP1"
          }
        });
      } else if (content.includes("mon adresse postale") || content.includes("mes informations personnelles")) {
          history.push({
            pathname:"/profile",
            state:{
              name:"principal"
            }
          });
      } else if (content.includes("télécharger le formulaire") || content.includes("plus dinformations") || content.includes("espace assuré ameli")) {
        window.open(action.redirectionMobile, "_blank");
      }
    };
    

    return (
        <div className='faq-question-card-container'>
            <div className='faq-question-card-icon-and-question-container'>
                <Icon
                    size={"15"}
                    icon="SortTop"
                    className={isOpen ? "faq-icon-open" : "faq-icon-next-step"}
                    style={{ cursor: 'pointer' }}
                    onClick={openQuestionDetails}
                />
                <p className={!isOpen ? "faq-question-card-question" : "faq-question-card-question-open"}>
                    {props.question.question}
                </p>
            </div>
            {isOpen && 
                <p className='faq-question-card-response'>
                    {interpreteHtml(props.question.response)}
                </p>
            }
            <div className='faq-question-card-actions'>
                {isOpen && props.question.actions && props.question.actions.map((action, index) => (
                    <Button
                        key={index}
                        text={action.content}
                        className='btn-faq-action'
                        boxShadow
                        onClick={() => openAction(action,props.question)}
                        color={COLOR_BUTTON.ORANGE}
                    />
                ))}
            </div>
        </div>
    );
};

export default FaqQuestionCardTemplate;