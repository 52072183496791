import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AtomInput = (props) => {
  let className = '';
  className = 'default-input ' + props.className;
  if (props.isInvalid === true) {
    className += ' invalid-input';
  }
  if (props.isValid === true) {
    className += ' valid-input';
  }
  if (props.heightSize === 'meduim') {
    className += ' height-m-input ';
  }
  if (props.heightSize === 'large') {
    className += ' height-l-input ';
  }
  if (props.heightSize === 'x-large') {
    className += ' height-xl-input ';
  }
  if (props.heightSize === 'xx-large') {
    className += ' height-xxl-input ';
  }
  if (props.heightSize === 'small') {
    className += ' height-s-input ';
  }
  if (props.heightSize === 'x-small') {
    className += ' height-xs-input ';
  }
  if (props.borderRadius === true) {
    className += ' input-border-radius';
  }
  return (
    <Form.Control
      as={props.as || 'input'}
      disabled={props.disabled}
      className={className}
      onChange={props.onChange}
      readOnly={props.readOnly}
      value={props.value}
      bsPrefix={props.bsPrefix}
      id={props.id}
      type={props.password === true ? 'password' : props.type}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onKeyPress={props.onKeyPress}
      ref={props.ref}
    />
  );
};
AtomInput.PropTypes = {
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.any,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  bsPrefix: PropTypes.string,
  id: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  feedback: PropTypes.any,
  feedbackTooltip: PropTypes.any,
  password: PropTypes.bool,
  heightSize: PropTypes.oneOf([
    'x-small',
    'small',
    'meduim',
    'large',
    'x-large',
    'xx-large'
  ]),
  borderRadius: PropTypes.bool,
  as: PropTypes.string,
  onKeyPress: PropTypes.any
};
AtomInput.defaultProps = {
  as: 'input',
  disabled: false,
  isInvalid: false,
  isValid: false,
  className: '',
  onChange: null,
  readOnly: false,
  value: null,
  bsPrefix: null,
  id: null,
  placeholder: 'Texte',
  defaultValue: null,
  feedback: null,
  feedbackTooltip: null,
  password: false,
  heightSize: 'meduim',
  borderRadius: false,
  as: 'input',
  onKeyPress: null
};
export default AtomInput;
