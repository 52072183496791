import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Icon, Text } from '../';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import { fr } from 'date-fns/locale';
registerLocale('fr', fr);

AtomDatePicker.defaultProps = {
  className: '',
  placeholder: 'Date',
  minDate: null,
  locale: 'fr',
  dateFormat: 'dd/MM/yyyy',
  filterDate: null,
  onChange: null,
  isInvalid: false,
  isValid: false,
  selectsRange: false,
  size: 'meduim',
  selected: null,
  startDate: null,
  endDate: null,
  renderIcon: true,
  containerClassName: '',
  labelClassName: 'default-label',
  inputLabel: null,
  iconClassName: 'icon-date-picker'
};
AtomDatePicker.PropTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.any,
  locale: PropTypes.string,
  dateFormat: PropTypes.string,
  filterDate: PropTypes.any,
  onChange: PropTypes.any,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  selectsRange: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'meduim', 'larg']),
  selected: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  renderIcon: PropTypes.bool,
  containerClassName: PropTypes.string
};
function AtomDatePicker(props) {
  let className = '';
  let containerClassName = 'container-date-picker ';
  className = props.noBorder
    ? 'default-input-without-border'
    : 'default-input ';
  if (props.isInvalid === true) {
    className += ' invalid-input';
  }
  if (props.isValid === true) {
    className += ' valid-input';
  }
  if (props.size === 'meduim') {
    className += ' defaul-meduim-input ';
    containerClassName += ' defaul-meduim-input ';
  }
  if (props.size === 'larg') {
    className += ' defaul-larg-input ';
    containerClassName += ' defaul-larg-input ';
  }
  if (props.size === 'small') {
    className += ' defaul-small-input ';
    containerClassName += ' defaul-small-input ';
  }
  if (props.containerClassName) {
    containerClassName += props.containerClassName;
  }
  className += props.className;
  function renderIcon() {
    return (
      <Icon icon="Calender" size={'16px'} className={props.iconClassName} />
    );
  }
  function viewLabel() {
    return props.inputLabel !== null ? (
      <Text className={props.labelClassName} text={props.inputLabel} />
    ) : null;
  }
  return (
    <div className={containerClassName}>
      {viewLabel()}
      <DatePicker
        locale={props.locale}
        selected={props.selected && props.selected}
        startDate={props.startDate}
        endDate={props.endDate}
        onChange={(e) => {
          props.onChange && props.onChange(e);
        }}
        // onChange={(e) => {
        //   console.log(e);
        //   console.log(new Date(e));
        // }}
        className={className}
        minDate={props.minDate}
        maxDate={props.maxDate}
        showMonthDropdown
        showYearDropdown
        placeholderText={props.placeholder}
        filterDate={props.filterDate}
        dateFormat={props.dateFormat}
        selectsRange={props.selectsRange}
        renderCustomHeader={CustomHeader}
      />
      {props.renderIcon && renderIcon()}
    </div>
  );
}

export default AtomDatePicker;

const months = [
  'Janvier',
  'Fevrier',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Decembre'
];

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  ...props
}) => (
  <div className="custom-header">
    <button className="icon-button" onClick={decreaseMonth}>
      &lt;
    </button>
    <div className="select-container">
      <select
        value={date.getFullYear()}
        onChange={({ target }) => changeYear(target.value)}
      >
        {Array.from({ length: 200 }, (_, i) => date.getFullYear() - 60 + i).map(
          (year) => (
            <option key={year} value={year}>
              {year}
            </option>
          )
        )}
      </select>
    </div>
    <div className="select-container">
      <select
        value={date.getMonth()}
        onChange={({ target }) => changeMonth(target.value)}
      >
        {months.map((month, index) => (
          <option key={index} value={index}>
            {month}
          </option>
        ))}
      </select>
    </div>
    <button className="icon-button" onClick={increaseMonth}>
      {'>'}
    </button>
  </div>
);
