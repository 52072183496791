import React from 'react';
import { Button, Text } from '../../Atoms';
import { Input } from '../../Molecules';
import { PhotoLoginOrganisme } from '../../Organisms';
function TemplateFirstLoginStep1(props) {
  return (
    <div className='login-template'>
      <PhotoLoginOrganisme />
      <div className='login-template-organism'>
        <div className='login-container'>
          <div>
            <Text text='PREMIERE CONNEXION' type='h4' />
            <Text
              text='Merci de vous munir de votre adresse email ou numéro d’adhérent lié à votre contrat, que vous pouvez retrouver sur votre lettre de bienvenue.'
              className='text-description-first-login'
            />
            <div className='first-login-small-container'>
              <Input
                placeholder='Email ou numéro d’adhérent'
                className={props.state.msgError !== '' ? 'input-first-login-error' : 'input-first-login'}
                onChange={e => props.onChange(e.target.value)}
                value={props.mail}
              />
              {props.state.msgError !== '' &&
                <div className='error-msg-container'>
                  {props.state.msgError}
                </div>
              }
              <div className='btn-next-first-login-container'>
                <Button
                  text='Suivant'
                  className='btn-next-first-login'
                  boxShadow
                  onClick={() => props.nextStep()}
                />
                
              </div>
              <Button
                  text='Retour'
                  className='btn-back-first-login'
                  boxShadow
                  onClick={() => props.onReturn()}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateFirstLoginStep1;
