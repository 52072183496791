import React from 'react';
import { Icon } from '../../../Atoms';
import styles from '../TemplateHome.module.scss';

/**
 *
 * @param {Object} props
 * @param {string} props.text
 * @param {string} props.image
 * @param {function} props.onClick
 * @param {boolean} props.disableArrow
 * @returns {JSX.Element}
 */
const ShortcutButton = ({ text, image, onClick, disableArrow, optClass }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'row' }}
      className={optClass ? styles[optClass] : ''}
      
    >
      <div className={styles['shortcut-btn']} onClick={onClick}>
        <img src={image} className={styles['shortcut-btn-img']} />
        <p className={styles['shortcut-btn-text']}>{text}</p>
      </div>
       {!disableArrow && (
        <div className={styles['shortcut-btn-arrow-container']}>
          <Icon
            icon="SortTop"
            size={20}
            className={styles['shortcut-btn-arrow']}
          />
        </div>
      )}
    </div>
  );
};

export default ShortcutButton;
