import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Icon, Text, Img } from '../../Atoms';
import { Input, Modal } from '../../Molecules';
import {
  SettingOrganism,
  ChangeSettingDetailsOraganism
} from '../../Organisms';
import HappyBunchChatting from '../../assets/img/Happy-Bunch-Chatting.png';
import { ConfirmPasswordModal } from '../../Modals';
import { COLOR_BUTTON } from '../../Config/Config';
import PageTitle from '../../Components/Dashboard/PageTitle';
function TemplateSetting (props) {
  return (
    <div className='template-container template-send-document template-scroll'>
      <PageTitle titleText="Réglages"/>
      
      <div className='content-template-setting'>
        {props.state.step === 1 && (
          <div className='container-change-password'>
            <Text text='Mon compte' className='title-change-password' />
            <Row>
              <Col xs={12} md={{ span: 5, order: 1 }}>
              <SettingOrganism
                title='Mot de passe'
                description='Modifier votre mot de passe'
                icon={props.state.updatePassword ? 'Sort' : 'SortTop'}
                iconClassName={props.state.updatePassword ?  'remboursement-desktop-organism-description-icon' :'icon-next-step'}
                size={'4%'}
                password={true}
                onClick={() => props.onSubmit()}
              />
              {props.state.updatePassword && (
                <div>
                {/* <Input
                  inputLabel='Mot de passe actuel'
                  classNameContainer='container-first-input-change-password'
                  labelClassName='label-input-change-password'
                  className='input-change-password setting-input__set-container'
                  placeholder=''
                  password={true}
                  value={props.state.password.password}
                  onChange={props.onChangePassword}
                /> */}
                
                  <ChangeSettingDetailsOraganism
                    firstInputLabel='Nouveau mot de passe'
                    firstInputClassName='setting-password-title'
                    firstInputdiscription='Nous vous rappelons que votre mot de passe doit contenir au moins douze caractères, une lettre majuscule, une minuscule et un chiffre.'
                    firstInputdiscriptionClassName='setting-password-title-description'
                    firstInputClassNameContainer='container-first-input-change-password'
                    firstInputlabelClassName='label-input-change-password'
                    firstInputValue={props.state.password.newPassword}
                    firstInputOnChange={props.onChangeNewPassword}
                    SecondInputLabel='Confirmez votre nouveau mot de passe'
                    SecondInputValue={props.state.password.confirmPassword}
                    SecondInputOnChange={props.onChangeConfirmPassword}
                    errorMsg={props.state.passwordErrorMsg}
                  />
                   <Col xs={12} md={{ span: 7, order: 4 }} className='pl-0'>
                  <Button
                    text={props.state.passwordBtnText}
                    className={'btn-submit-password-valider'}
                    onClick={() => props.validatePassword()}
                    color={COLOR_BUTTON.BLUE}
                  />
                </Col>
                </div>
                )}
               
              </Col>
              {/* <Col xs={12} md={{ span: 5, order: 1, offset: 1 }}>
              <SettingOrganism
                title='Adresse mail de connexion'
                description='Modifiez votre adresse mail'
                icon={props.state.updateMail ? 'Sort' : 'SortTop'}
                iconClassName={props.state.updateMail ?  'remboursement-desktop-organism-description-icon' :'icon-next-step'}
                password={true}
                size={'4%'}
                onClick={() => props.onSubmitMail()}
              />
              { props.state.updateMail && (
               <div>
               <Input
                  inputLabel='Adresse mail connexion'
                  classNameContainer='container-first-input-change-password'
                  labelClassName='label-input-change-password'
                  className='input-change-password setting-input__set-container'
                  placeholder=''
                  value={props.state.email.email}
                  onChange={props.onChangeEmail}
                />
                
                  <ChangeSettingDetailsOraganism
                    firstInputLabel='Nouvelle adresse mail de connexion'
                    firstInputClassName='setting-password-title mb-8'
                    firstInputClassNameContainer='container-first-input-change-password'
                    firstInputlabelClassName='label-input-change-password'
                    firstInputValue={props.state.email.newEmail}
                    firstInputOnChange={props.onChangeNewEmail}
                    SecondInputLabel='Confirmez votre nouvelle adresse mail de connexion'
                    SecondInputValue={props.state.email.confirmEmail}
                    SecondInputOnChange={props.onChangeConfirmEmail}
                    errorMsg={props.state.mailErrorMsg}
                  />
                  <Col xs={12} md={{ span: 7, order: 4 }} className='pl-0'>
                  <Button
                    text={props.state.mailBtnText}
                    className={'btn-submit-password-valider'}
                    onClick={() => props.validateMail()}
                    color={COLOR_BUTTON.BLUE}
                  />
                </Col>
                  </div>
                )}
                
              </Col> */}
            </Row>
          </div>
        )}
      </div>
      {props.state.show && (
        <ConfirmPasswordModal
          img={HappyBunchChatting}
          show={props.state.show}
          onHide={() => props.onHide('password')}
          modalStep={props.state.modalStep}
          title='Validation du changement de mot de passe'
          firstText={
            'Nous venons d’envoyer un code par email à ' +
            props.state.email.email
          }
          secondText='Veuillez le renseigner ci-dessous pour confirmer le changement de mot de passe : '
          onConfirmCode={() => props.onConfirmCode('password')}
          onCancel={() => props.onHide('password')}
          successText='Votre mot de passe a été modifié.'
          code={props.code}
          onChange={props.changeCode}
          errorMessages={[props.passwordErrorMsg]}
        />
      )}
      {props.state.showMail && (
        <ConfirmPasswordModal
          img={HappyBunchChatting}
          show={props.state.showMail}
          onHide={() => props.onHide('mail')}
          modalStep={props.state.modalStep}
          title='Validation du changement de votre email'
          firstText={
            'Nous venons d’envoyer un code par email à ' +
            props.state.email.email
          }
          secondText='Veuillez le renseigner ci-dessous pour confirmer le changement de mot de passe : '
          onConfirmCode={() => props.onConfirmCode('mail')}
          onCancel={() => props.onHide('mail')}
          successText='Votre mot de passe a été modifié.'
          code={props.code}
          onChange={props.changeCode}
        />
      )}
    </div>
  );
}
export default TemplateSetting;
