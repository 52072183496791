import React, { useEffect, useState } from 'react';
import { Icon, Text } from '../../Atoms';
import CardCategorie from './CardCategorie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PageTitle from '../../Components/Dashboard/PageTitle';

const TemplateFAQ = (props) => {
    const history = useHistory();
    // const [data, setData] = useState(props.data)

    // useEffect(() => {
    //     setData(props.data);
    // }, [props.data]);

    return (
        <div className='template-container template-send-document template-scroll'>
            {/* <div className='container-title-send-doc'>
                <Icon
                icon='SortTop'
                size='20px'
                className='icon-return'
                onClick={() => history.goBack()}
                />    
                <Text
                    text='Foire aux questions'
                    className='title-send-document'
                />
            </div> */}
            <PageTitle titleText="Foire aux questions" onTitleClick={()=>history.goBack()}/> 
            <div className='faq-categorie-container'>
                <div className='faq-categorie-card-container'>
                    {
                        props.data && props.data.map((category)=>{
                            return <CardCategorie key={category.id} item={category} />
                        })
                    }
                </div>
            </div>
            {/* {

            } */}
        </div>
    );
};

export default TemplateFAQ;