import { apiGet, apiPost } from '../standardApi';

export async function apiSendContact (body) {
  const response = await apiPost(
    '/Contact/SendMedicalCareDemand',
    body,
    '',
    'application/json'
  );
  return response;
}
export async function apiGetClientDemands (
  skip,
  top,
  search,
  orderBy,
  direction
) {
  let query = '';
  if (search) {
    query += `&searchQuery=${search}`;
  }
  if (orderBy) {
    query += `&OrderBy=${orderBy}`;
  }
  if (direction) {
    query += `&Direction=${direction}`;
  }
  const response = await apiGet(
    `/Contact/GetSentDemands?Skip=${skip}&Top=${top}` + query
  );
  return response;
}
export async function apiSendResponseDemand (demandeId, body) {
  const response = await apiPost(
    `/Contacts/SendResponseDemand/${demandeId}`,
    JSON.stringify({ ...body }),
    '*/*',
    'application/json'
  );
  return response;
}

export async function apiSendFeedback (qref,clientResponse,comment,brokerCodeTiers,) {
  const response = await apiPost(
    `/Contact/SaveNotice`,
    JSON.stringify({qref,clientResponse,comment,brokerCodeTiers}),
    'application/json',
    'application/json',
  );
  return response;
}