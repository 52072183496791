import React from 'react';
import styles from './LoginStyles.module.scss';

const LoginInputLabel = ({ children, ...rest }) => {
  return (
    <label className={styles.loginInputLabel} {...rest}>
      {children}
    </label>
  );
};

export default LoginInputLabel;
