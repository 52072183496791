import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { apiGetPractioners } from '../../../Api/Geolocation/Geolocation.api';
import GeolocationHeader from '../components/GeolocationHeader';
import styles from '../Geolocation.module.scss';
import capitalize from '../../../Utils/capitalize';

const RADIUS_RESEARCH_KM = 20;

const GetGeolocatedPractitioners = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const goBack = () => history.goBack();

  const [practitioners, setPractitioners] = useState([]);
  const [error, setError] = useState('');

  const getPractitioners = async () => {
    const latitude = urlParams.get('latitude');
    const longitude = urlParams.get('longitude');
    const department = urlParams.get('dep');
    const city = urlParams.get('city');

    const body = {
      speciality: urlParams.get('type'),
      rayon: RADIUS_RESEARCH_KM
    };

    if (latitude && longitude) {
      body.latitude = latitude;
      body.longitude = longitude;
    } else {
      body.department = department;
      body.city = city;
    }

    const res = await apiGetPractioners(body);

    if (res.status === 200) {
      if (res.data?.length > 0) {
        setPractitioners(
          res.data.map((item) => {
            const distance = item.distance ? `${item.distance}km` : null;

            const formatString = (str) => {
              if (!str) return '';
              //Use of deprecated function here because values in databse
              //have been converted with escape function
              return unescape(
                str
                  .split('+')
                  .filter((i) => !!i)
                  .map(capitalize)
                  .join(' ')
              );
            };

            const formatAddress = (street, cp, city) => {
              return `${formatString(street)}\n${cp.trim()} ${formatString(
                city
              ).toUpperCase()}`;
            };

            return {
              distance: distance,
              type: formatString(item.speciality),
              name: formatString(item.name),
              address: formatAddress(item.address, item.postalCode, item.city)
            };
          })
        );
      } else {
        setError('Aucun praticien trouvé avec ces critères.');
      }
    } else {
      setError('Impossible de récupérer les praticiens.');
    }
  };

  const onClickPractitioners = (address) => {
    const googleAddress = 'https://www.google.com/maps/search/?api=1&query=';
    window.open(
      `${googleAddress}${address?.replace(/\s/g, '+')}`,
      '_blank',
      'noreferrer'
    );
  };

  useEffect(() => {
    getPractitioners();
  }, []);

  return (
    <div className={styles['geolocation-template']}>
      <GeolocationHeader onReturn={goBack} />

      <div className={styles['get-geolocated-container']}>
        <div className={styles['get-geolocated-title']}>
          Résultats de votre recherche :{' '}
        </div>
        <div className={styles['get-geolocated-content']}>
          {practitioners.length === 0 && error ? (
            <div className={styles['get-geolocated-error-container']}>
              {error}
            </div>
          ) : null}
          {practitioners.map((item, idx) => (
            <button
              key={idx}
              className={styles['get-geolocated-item']}
              onClick={() => onClickPractitioners(item.address)}
            >
              <div className={styles['get-geolocated-item-infos']}>
                {item.type}
                {item.distance ? ` | ${item.distance}` : ''}
              </div>
              <div className={styles['get-geolocated-item-name']}>
                {item.name}
              </div>
              <address className={styles['get-geolocated-item-address']}>
                {item.address}
              </address>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetGeolocatedPractitioners;
