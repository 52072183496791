import React, { useState } from 'react';
import { Text, DatePicker, Icon, Button, Img } from '../../Atoms';
import SuccessImg from '../../assets/img/HappyBunchChatting.png';
import { Input, Modal } from '../../Molecules';
import { CalendarOrganism, SelectOrganism } from '../../Organisms';
function TemplateHospitalisationStep1 (props) {
  return (
    <div className='template-scroll cartetp-container'>
      <div className='hospitalisation-header-container mb-45'>
        <Text text='Hospitalisation' type='h4' />
      </div>
      <div className='container-hospitalisation'>
        <Text
          text='Pour que nous puissions prendre en charge votre hospitalisation, merci de bien vouloir nous fournir ces informations.'
          type='h7'
          className='hospitalisation-step-title'
        />
        <div style={{ marginTop: '32px' }}>
          <CalendarOrganism
            show={props.showCalendar}
            onHide={() => props.onHideCalender()}
            onChange={value => props.onChange(new Date(value), 'admissionDate')}
            onClick={() => props.onClickOpenCalender()}
            value={props.data.admissionDate}
            placeholder={'Date d’admission'}
          />
        </div>
        {props.data.admissionDate && (
          <div style={{ marginTop: '11px' }}>
            <Input
              className='input-hospitalisation'
              placeholder='Durée d’hospitalisation (en jours)'
              value={props.data.hospitalisationDuration}
              onChange={e =>
                props.onChange(e.target.value.trim(), 'hospitalisationDuration')
              }
            />
          </div>
        )}
        {props.data.hospitalisationDuration && (
          <div style={{ marginTop: '11px' }}>
            <div>
              <SelectOrganism
                classNameOptionContainer='hospitalisation-select-option'
                iconName='Collapse'
                iconSize={15}
                placeholder='Nature de l’hospitalisation'
                option={props.HospitalisationOptions}
                value={props.data.hospitalisationNature}
                onChange={value =>
                  props.onChange(value.text, 'hospitalisationNature')
                }
              />
            </div>
          </div>
        )}
        {props.data.hospitalisationDuration &&
          props.data.hospitalisationNature &&
          props.data.admissionDate && (
            <Button
              text='Etape suivante'
              className='submit-btn-modal-calendar'
              boxShadow
              onClick={() => props.nextStep()}
            />
          )}
      </div>
    </div>
  );
}

export default TemplateHospitalisationStep1;
