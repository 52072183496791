export const FILE_TYPE_NOT_VALID =
  "! Seuls les fichiers '.pdf, .jpeg, .jpg, .png' sont acceptés. ";
export const FILE_SIZE_NOT_VALID = '! Fichier trop lourd, taille maximum 6 Mo ';
export const validateSize = size => size / (1024 * 1024) <= 6;
export const validateType = mimeType => {
  const type = mimeType.split('/')[1];
  const match = type.match(/(jpg|jpeg|png|pdf)$/);
  if (match) return true;
  return false;
};
