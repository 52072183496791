import * as moment from 'moment';
import { DEMANDE_FROM } from '../../Config/Config';
const findText = (text) => {
  const finded = DEMANDE_FROM.find((el) => el.key == text);
  return finded === undefined ? '' : finded;
};
export const serilizeGetDemande = (data) => {
  return data.map((el) => {
    return {
      id: el.id,
      date: el.dateReception
        ? moment(new Date(el.dateReception)).format('DD/MM/YYYY')
        : '',
      created: el.dateReception,
      demande: el.objet,
      status: el.dateTraitement ? "WaitingLine" : "NotDone",
      documentNbr: el.attachmentList?.length,
      treatmentTime: el.cgrmQueue,
      numFic: el.numFic,
      detail: el?.callBackList
        ? el?.callBackList.map((callBackEl) => {
            return {
              date: callBackEl.dateReception
                ? moment(new Date(callBackEl.dateReception)).format(
                    'DD/MM/YYYY'
                  )
                : '',
              description: callBackEl.message,
              from: findText(callBackEl.from).from,
              document: callBackEl?.attachmentList?.map((attaEl) => attaEl.name)
            };
          })
        : []
    };
  });
};
