export const setUser = (userObj) => {
  return {
    type: 'SET_CURRENT_USER',
    value: userObj
  };
};

export const serverError = () => {
  return {
    type: 'SERVER_ERROR'
  };
};

export const serverMaintenance = () => {
  return {
    type: 'SERVER_MAINTENANCE'
  };
};
export const setToken = (userObj) => {
  return {
    type: 'SET_TOKEN',
    value: userObj
  };
};

export const addPipelineLoader = (api) => {
  return {
    type: 'ADD_PIPELINE_LOADER',
    value: api
  };
};
export const rmPipelineLoader = (api) => {
  return {
    type: 'RM_PIPELINE_LOADER',
    value: api
  };
};
