import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Icon } from '../index';
AtomCheckbox.PropTypes = {
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  bsPrefix: PropTypes.string,
  id: PropTypes.any,
  onClick: PropTypes.any,
  checked: PropTypes.bool,
  feedback: PropTypes.any,
  feedbackTooltip: PropTypes.any,
  type: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.number,
  classNameContainer: PropTypes.string
};
AtomCheckbox.defaultProps = {
  disabled: false,
  isInvalid: false,
  isValid: false,
  className: '',
  bsPrefix: null,
  id: null,
  onClick: null,
  checked: false,
  feedback: null,
  feedbackTooltip: null,
  type: 'checkbox',
  label: '',
  size: 14,
  classNameContainer: ''
};
export default function AtomCheckbox (props) {
  let className = '';
  className = 'default-check ' + props.className;
  function renderCheckbox () {
    let className = '';
    className = 'default-check ' + props.className;
    if (props.disabled === true) {
      className += ' check-disabled';
    } else {
      className += ' check-cursor';
    }
    if (props.isInvalid === true) {
      className += ' invalid-check';
    }
    if (props.isValid === true) {
      className += ' valid-check';
    }
    if (props.checked === true) {
      className += ' check-checked';
    }
    if (props.bsPrefix) {
      className = props.bsPrefix + ' ' + className;
    }
    return (
      <div className={'container-check ' + props.classNameContainer}>
        <div
          id={props.id}
          className={className}
          onClick={props.onClick}
          style={{ height: props.size, width: props.size }}
        ></div>
        <div style={{ flex: '1' }}>{props.label}</div>
      </div>
    );
  }
  if (props.type === 'classique') {
    return (
      <div className='container-check'>
        <div className='classique-checkbox' onClick={props.onClick}>
          {props.checked && <Icon icon='Valid' size={'12px'} color='black' />}
        </div>
      </div>
    );
  } else {
    return (
      <div className='container-check' onClick={props.onClick}>
        {props.type === 'checkbox' ? (
          renderCheckbox()
        ) : (
          <Form.Check
            isInvalid={props.isInvalid}
            isValid={props.isValid}
            className={className}
            as='input'
            disabled={props.disabled}
            checked={props.checked}
            bsPrefix={props.bsPrefix}
            id={props.id}
            feedback={props.feedback}
            feedbackTooltip={props.feedbackTooltip}
            label={props.label}
            type={props.type}
            custom
          />
        )}
      </div>
    );
  }
}
