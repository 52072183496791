import React, { useState } from 'react';
import { Button, Img, Text } from '../../Atoms';
import Itelis from '../../assets/img/itelis.png';
import SanteClair from '../../assets/img/santeClair.png';
import { Col, Row } from 'react-bootstrap';
import { LinkHealthOrganism } from '../../Organisms';
import TeleConsult from '../../assets/img/TELECONSULT.png';
import Souris from '../../assets/img/souris.png';
import Earth from '../../assets/img/globe.png';
import Lunette from '../../assets/img/LUNETTES.png';
import Medecin from '../../assets/img/medecin.png';
import Loop from '../../assets/img/doc loupe.png';
import Devis from '../../assets/img/decrypt-devis.png';
import Dispense from '../../assets/img/dispense-avance-tarif.png';
import Garantie from '../../assets/img/garanties.png';
import Tarif from '../../assets/img/tarifs-preferentiels.png';
import Teledepistage from '../../assets/img/teledepistage.png';
import Medicament from '../../assets/img/medicaments.png';
import ReactPlayer from 'react-player';
import { COLOR_BUTTON } from '../../Config/Config';
import { useEffect } from 'react';
import { HEALTH_SERVICE } from '../../Config/enum';
import { apiGetHealthService } from '../../Api/HealthService/HealthService';
import PageTitle from '../../Components/Dashboard/PageTitle';
function TemplateHealthService(props) {
  const [service, setService] = useState({
    serviceTitle: '',
    url: '',
    youtubeUrl: '',
    labelButton: ''
  });

  const [offeredServices, setOfferedServices] = useState([]);

  const getHealthService = async () => {
    const reseauPARTENAIRE = props.state.content;
    if (reseauPARTENAIRE == HEALTH_SERVICE.SANTECLAIR) {
      const response = await apiGetHealthService();
      setService({
        youtubeUrl: 'https://www.youtube.com/embed/8R12WWGIacY',
        url: `https://services.integration.santeclair.fr/owop/authentification.do?idPart=CGRM&amp;idJeton=${response.data}`,
        labelButton: 'Accéder à My Santé Clair',
        serviceTitle: "Qu'est ce que Santé Clair?"
      });

      setOfferedServices([
        {
          text: 'Télécommunication médicale',
          src: TeleConsult,
          imgDirection: 'vertical'
        },
        {
          text: 'Géolocalisation et présentation des praticiens partenaires',
          src: Earth,
          imgDirection: 'circle'
        },
        {
          text: 'Analyse tarifaire de vos devis',
          src: Loop,
          imgDirection: 'vertical'
        },
        {
          text: 'Second avis médical par un spécialiste',
          src: Medecin,
          imgDirection: 'circle'
        }
      ]);
    } else if (reseauPARTENAIRE == HEALTH_SERVICE.ITELIS) {
      setService({
        youtubeUrl: 'https://www.youtube.com/watch?v=soO2xDuULZI',
        url: 'http://partenaires.itelis.fr/partners?access_token=w089ho5t',
        labelButton: 'Accéder à Itelis',
        serviceTitle: 'Qu’est ce qu’Itelis ?'
      });

      setOfferedServices([
        {
          text: 'Des tarifs préférentiels',
          src: Tarif,
          imgDirection: 'vertical'
        },
        {
          text: 'Des garanties et services exclusifs',
          src: Garantie,
          imgDirection: 'vertical'
        },
        {
          text: 'Dispense d’avance de frais',
          src: Dispense,
          imgDirection: 'vertical'
        }
      ]);
    } else if (reseauPARTENAIRE == HEALTH_SERVICE.CARTEBLANCHE) {
      setService({
        youtubeUrl: 'https://www.youtube.com/embed/Q660TA9pxGc',
        url: 'https://www.carteblanchepartenaires.fr/CBPSSOUnique/connexionService?ext=5454frr54t&ste_cod=CM&signature=A%2BSET5cbirlcy%2Bpwwy/8pKfjp2TvAkdiIcsU9oICgNw%3D&redirect=assure/annuaire/professionnels-sante',
        labelButton: 'Accéder à Carte Blanche',
        serviceTitle: 'Qu’est ce que Carte Blanche ?'
      });

      setOfferedServices([
        { text: 'Mon devis décrypté', src: Devis, imgDirection: 'vertical' },
        {
          text: 'Géolocalisation et présentation des praticiens partenaires',
          src: Earth,
          imgDirection: 'circle'
        },
        {
          text: 'Guide des médicaments sans ordonnance',
          src: Medicament,
          imgDirection: 'vertical'
        },
        {
          text: 'Télédespistage bucco-dentaire',
          src: Teledepistage,
          imgDirection: 'verticale'
        }
      ]);
    } else if (
      reseauPARTENAIRE == HEALTH_SERVICE.KALIVIA ||
      reseauPARTENAIRE == HEALTH_SERVICE.KALIXIA
    ) {
      setService({
        youtubeUrl: '',
        url: 'https://www.viamedis.net/ViamedisNet/cartographie/numeroClient/00000000990140/',
        labelButton: 'Accéder à Kalivia',
        serviceTitle: 'À quoi sert le réseau Kalixia ?'
      });

      setOfferedServices([
        {
          text: 'Réseau de + de 18 000 professionnels de santé',
          src: TeleConsult,
          imgDirection: 'vertical'
        },
        {
          text: 'Des réductions et tarifs négociés',
          src: Earth,
          imgDirection: 'circle'
        },
        {
          text: 'Aucune avance de frais sur vos dépenses',
          src: Loop,
          imgDirection: 'vertical'
        }
      ]);
    } else {
      setService({
        youtubeUrl: '',
        url: '',
        labelButton: '',
        serviceTitle: ''
      });
    }
  };

  const onChangeContent = () => {
    window.open(service.url, '_blank');
  };

  useEffect(() => {
    getHealthService();
  }, []);

  function responsiveWidth() {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      return width - 200;
    } else return width / 4;
  }
  function responsiveHeight() {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    var height = window.innerHeight > 0 ? window.innerHeight : screen.height;
    if (width < 768) {
      return height / 6;
    } else return height / 4;
  }
  return (
    <div className="template-health template-scroll">
      <PageTitle titleText="Mes Services santé" canGoBack={false} />
      <div className="container-health-service">
        <div className="content-health-service">
          {props.state.content === '' ? (
            <div className="no-service-message text-default-size text-default-font">
              Votre contrat n'est pas lié à un réseau de soins partenaire.
              Cliquer sur le bouton 'Géolocalisation' du menu pour Rechercher un
              praticien près de vous.
            </div>
          ) : (
            <>
              <Row>
                <Col xs={12} md={6} lg={7}>
                  <Text
                    text={service.serviceTitle}
                    className="title-type-health"
                  />
                  {props.state.content === HEALTH_SERVICE.SANTECLAIR ? (
                    <div className="health-italic-text">
                      {
                        "Pour vous aider à faire des choix éclairés pour votre santé et ceile de votre famille. SPVIE vous propose les services d'un vrai repère santé : "
                      }
                      <span className="health-bold-text">{'Santéclair.'}</span>
                      <div>
                        {
                          'Optique, dentaire, audioprothèse, diététique, ostéopathie, médecine généraliste... Avec santéclaire, vous'
                        }
                        <span className="health-bold-text">
                          {' controlez vous dépenses de santé,'}
                        </span>
                        {
                          ' vous accédez à des soins et des équipement de qualité et vous trouvez les '
                        }
                        <span className="health-bold-text">
                          {
                            ' praticiens et les établissement lesplus adaptés à vos besoins de santé'
                          }
                        </span>
                      </div>
                    </div>
                  ) : props.state.content === HEALTH_SERVICE.ITELIS ? (
                    <div className="health-italic-text">
                      {
                        'Itelis est un réseau de soin qui vous propose des services dans le domaine de la santé. Notre mission ? '
                      }
                      <span className="health-bold-text">
                        {
                          'Favoriser l’accès à la santé dans les meilleures conditions.'
                        }
                      </span>
                      {
                        'Nous vous proposons un réseau de 9000 professionnels partout en France pour vous '
                      }
                      {
                        'offrir plus de qualité et plus d’économie dans des domaines très demandés : Optique, Dentaire, Audio, Chirurgie Réfractive, Ostéopathie/Chiropraxie, Psychologie et Diététique. '
                      }
                    </div>
                  ) : props.state.content === HEALTH_SERVICE.CARTEBLANCHE ? (
                    <div className="health-italic-text">
                      {
                        'Carte Blanche Partenaires est un réseau de soin spécialisé dans la '
                      }
                      <span className="health-bold-text">
                        santé visuelle, auditive et bucco-dentaire,
                        implantologie et préventive.
                      </span>
                      <div>
                        Leur mission est de faciliter l’accès à des soins de
                        qualité à un juste prix, contrôler les engagements des
                        professionnels de santé dans la délivrance de la
                        prestation et généraliser les flux dématérialisés.
                      </div>
                      <div>
                        {"L'objectif étant de "}
                        <span className="health-bold-text">
                          diminuer votre reste à charge
                        </span>
                        <span>
                          {' '}
                          (montant à votre charge après remboursement de la
                          complémentaire santé et de l'assurance maladie) tout
                          en veillant à la qualité des prestations et
                          équipements (verres, lentilles, prothèses dentaires et
                          auditives).
                        </span>
                      </div>
                    </div>
                  ) : props.state.content === HEALTH_SERVICE.KALIVIA ||
                    props.state.content === HEALTH_SERVICE.KALIXIA ? (
                    <div className="health-italic-text">
                      <span>Kalixia est un réseau de soins conventionné </span>
                      <span className="health-bold-text">
                        optique, audiologie et ostéopathie{' '}
                      </span>
                      <span>
                        afin de faire bénéficier aux Français de soins de
                        qualité sur tout le territoire. Au travers de ce réseau
                        de nombreuses mutuelles, ils peuvent faire bénéficier à
                        leurs adhérents présents sur tout le territoire national
                        :
                      </span>
                      <li>de soins moins onéreux et de qualité,</li>
                      <li>de meilleurs remboursements,</li>
                      <li>
                        de tarifs spécialement négociés auprès de professionnels
                        sélectionnés pour leur expertise et leurs compétences.
                      </li>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col xs={12} md={6} lg={5} className="container-img-health">
                  <div className="video-responsive">
                    <ReactPlayer
                      url={service.youtubeUrl}
                      playing={false}
                      width={responsiveWidth()}
                      height={responsiveHeight()}
                    />
                  </div>
                </Col>
              </Row>
              <Text
                text={'Quels sont les princpaux services proposés ?'}
                className="title-type-health"
              />

              <div className="content-link-health-service">
                <Row>
                  {offeredServices?.map((offeredService, index) => {
                    return (
                      <Col
                        xs={6}
                        md={offeredServices.length == 4 ? 3 : 4}
                        key={index}
                      >
                        <LinkHealthOrganism
                          text={offeredService.text}
                          src={offeredService.src}
                          imgDirection={offeredService.imgDirection}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          )}
        </div>
        {props.state.content !== '' && (
          <Button
            text={service.labelButton}
            className="btn-change-health"
            onClick={onChangeContent}
            color={COLOR_BUTTON.GREEN}
          />
        )}
      </div>
    </div>
  );
}
export default TemplateHealthService;
