import React, { useState } from 'react';
import { Chart } from '../../Organisms/';
import { Text, Button, DatePicker } from '../../Atoms';
import { Input } from '../../Molecules';

function OrganismConsomation (props) {
  return (
    <div className='consomation-organism-container'>
      <div className='consomation-filter-container'>
        <Input
          className='consomation-select'
          optionsClassName='consomation-select-options-text'
          selectOptionContainerClassName='consomation-select-options-container'
          as='select'
          placeholder='année'
          options={props.selectData.annual}
          onChange={value => props.selectFilterChange(value, 'annual')}
          value={props.annual}
          viewIconSelect={true}
        />
        <Input
          className='consomation-select'
          optionsClassName='consomation-select-options-text'
          selectOptionContainerClassName='consomation-select-options-container'
          as='select'
          placeholder='visualisation'
          options={props.selectData.vision}
          onChange={value => props.selectFilterChange(value, 'vision')}
          value={props.vision}
          viewIconSelect={true}
        />
      </div>
      <div className='consomation-chart-container'>
        <Chart
          seriesVisionGlobal={props.seriesVisionGlobal}
          seriesBeneficiary={props.seriesBeneficiary}
          seriesPoste={props.seriesPoste}
          vision={props.vision}
          annual={props.annual}
        />
      </div>
    </div>
  );
}

export default OrganismConsomation;
