export const ResetPasswordStepsEnum = {
  SETUP_NEW_PASSWORD: 0,
  SUCCESS: 1,
  EXPIRED: 2
};

export const CONSUMPTIONTYPE = {
  GLOBAL: "GLOBAL",
  BENEF: "BENEF",
  POSTE: "POSTE"
} 

export const HEALTH_SERVICE = {
  SANTECLAIR:"Santéclair",
  ITELIS:"Itelis",
  CARTEBLANCHE:"CBP",
  KALIVIA:"Kalivia",
  KALIXIA:"Kalixia",
};

export const FEEDBACK_STEP = {
  FEEDBACK_GESTION: 1,
  FEEDBACK_GARANTIES: 2,
};