import React from 'react';
import { Text } from '../../Atoms';

function MoleculeText (props) {
  return (
    <div className='molecule-text-container'>
      <Text
        text='Validité du &nbsp;'
        type='h5'
        className='cartetp-header-container '
      />
      <Text
        text='05/04/2020 &nbsp; '
        type='h4'
        className='cartetp-header-container cartetp-header-font'
      />
      <Text text='au &nbsp;' type='h5' className='cartetp-header-container' />
      <Text text='31/03/2020' type='h4' className='cartetp-header-container cartetp-header-font' />
    </div>
  );
}

export default MoleculeText;
