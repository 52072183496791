import React, { Component } from 'react';
import { PASSWORD_PATTERN } from '../../Api/Login/errorMessage';
import { apiCheckLinkValidity, apiResetPassword } from '../../Api/Login/Login';
import {
  TemplateFirstLoginStep3,
  TemplateResetPassword
} from '../../Templates';
class ResetPassword extends Component {
  constructor (props) {
    super(props);
    this.state = {
      step: 0,
      password: '',
      confirmPassword: '',
      userId: '',
      isValid: true
    };
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onSubmit = async () => {
    if (
      this.state.password !== this.state.confirmPassword ||
      !this.state.password.match(PASSWORD_PATTERN)
    ) {
      this.setState({
        isValid: false
      });
      return;
    }
    try {
      const res = await apiResetPassword(
        this.state.userId,
        this.state.password
      );
      if (res.status === 200) {
        this.setState({
          isValid: true,
          step: 2
        });
      }
    } catch (err) {
      this.setState({
        isValid: false
      });
    }
  };

  getToken = async () => {
    const token = this.props.match.params['0']
      .replace('+', '%2B')
      .replace('/', '%2F');

    const res = await apiCheckLinkValidity(token);
    if (res.status == 200) {
      this.setState({
        userId: res.memberId,
        step: 1
      });
    }
  };

  componentDidMount () {
    this.getToken();
  }

  render () {
    switch (this.state.step) {
      case 1:
        return <TemplateResetPassword {...this.state} {...this} />;
      case 2:
        return <TemplateFirstLoginStep3 {...this.state} {...this} />;
      default:
        return null;
    }
  }
}
export default ResetPassword;
