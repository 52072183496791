import React, { useState, useEffect } from 'react';
import { Icon } from '../../Atoms';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function OrganismFooter(props) {
  const location = useLocation();
  const sideBar = useSelector((state) => state.sideBar);
  const tuto = useSelector((state) => state.tuto);
  const [selectedPath, setSelectedPath] = useState('');
  function getFooterClassName() {
    const className = ['container-footer'];
    if (sideBar.open == true) {
      className.push('container-footer-mobile-open');
    }
    if (tuto.step == 8) {
      className.push('footer-tuto-full-opacity');
    }
    return className.join(' ');
  }
  useEffect(() => {
    const path = location.pathname;
    const aux = path.split('/');
    if (aux.length === 0) {
      setSelectedPath('/');
    } else {
      setSelectedPath(`/${aux[1].toLowerCase()}`);
    }
  }, [location.pathname]);
  return (
    <div className={getFooterClassName()}>
      <div className="container-footer-desktop">
        <div>
          CGRM {new Date().getFullYear()} - &nbsp;
          <span>
            <a
              href="https://www.cgrm.com/mentions-legales"
              target="_blank"
              className="footer-links"
            >
              Mentions légales
            </a>
            &nbsp;
          </span>
          - &nbsp;
          <span>
            <a
              href="https://www.cgrm.com/donnees-personnelles"
              target="_blank"
              className="footer-links"
            >
              Politique de gestion des données
            </a>
            &nbsp;
          </span>
          - &nbsp;
          <span>
            <a
              href="https://www.cgrm.com/reclamation"
              target="_blank"
              className="footer-links"
            >
              Réclamation
            </a>
            &nbsp;
          </span>
        </div>
      </div>
      <div className={'mobile-container-footer-icon'}>
        <Link
          className={
            selectedPath === '/'
              ? 'container-footer-selected-icon'
              : 'container-footer-icon'
          }
          to="/"
        >
          <Icon size={18} icon={'Home'} />
        </Link>
        <Link
          className={
            selectedPath === '/profile'
              ? 'container-footer-selected-icon'
              : 'container-footer-icon'
          }
          to="/Profile"
        >
          <Icon size={18} icon={'Profile'} />
        </Link>
        <Link
          className={
            selectedPath === '/contact'
              ? 'container-footer-selected-icon'
              : 'container-footer-icon'
          }
          to="/Contact"
        >
          <Icon size={18} icon={'Vector10'} />
        </Link>
      </div>
    </div>
  );
}
