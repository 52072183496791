import React from 'react';
import { Button, Text } from '../../Atoms';
import { PhotoLoginOrganisme } from '../../Organisms';
import { useHistory } from 'react-router-dom';
function TemplateFirstLoginStep3 (props) {
  const history = useHistory();
  const faireRedirection = url => {
    history.push(url);
  };
  return (
    <div className='login-template'>
      <PhotoLoginOrganisme />
      <div className='login-template-organism'>
        <div className='login-container'>
          <div>
            <Text text='PREMIERE CONNEXION' type='h4' />
            <Text
              text='Votre mot de passe a bien été modifié.'
              className='text-description-first-login'
            />
            <Text
              text='Vous pouvez désormais vous connecter. '
              className='text-description-first-login pt-0
                            '
            />
            <div className='first-login-small-container'>
              <div className='btn-connexion-first-login-container'>
                <Button
                  text='Connexion'
                  className='btn-next-first-login'
                  boxShadow
                  onClick={() => faireRedirection('/login')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateFirstLoginStep3;
