import React, { useCallback } from 'react';
import { Button, DatePicker, Text, Checkbox } from '../../Atoms';
import { Input, Upload } from '../../Molecules';
import { SelectOrganism } from '../../Organisms';
import { ModalAddBeneficiary } from '../../Modals';
import { COLOR_BUTTON } from '../../Config/Config';
import PageTitle from '../../Components/Dashboard/PageTitle';
import { color } from '@storybook/addon-knobs';
import ModalConfirmAddBeneficiary from '../../Modals/ModalConfirmAddBeneficiary/ModalConfirmAddBeneficiary';
function TemplateAddBeneficiaireStep2(props) {
  return (
    <div className="template-scroll profile-container profile-scroll-margin-bottom">
      <PageTitle titleText="Ajouter un bénéficiaire" canGoBack={false} />

      <div className="container-add-beneficiaire-step2-profile">
        <div>
          {props.beneficiaireDataFomulaire.map((el, index) => {
            return (
              <div key={index} className="benef-form-container">
                <div>
                  {/* <Text
                    text={el.errorMessage}
                    className={'profile-add-beneif-error-message'}
                  /> */}
                  <div
                    className={
                      el.errorMessage === ''
                        ? 'big-container-add-beneficiaire-profile'
                        : 'big-container-add-beneficiaire-profile mt-14'
                    }
                  >
                    <div>
                      <Text
                        text="Civilité"
                        className="profile-civilite-label"
                      />
                      <div className="btn-radio-add-container-beneficiaire-profile">
                        <span
                          style={{ display: 'flex' }}
                          onClick={() =>
                            props.onChangeBeneficiaireData(
                              'Mme',
                              'civilite',
                              index,
                              0
                            )
                          }
                        >
                          <div
                            className={
                              el.civilite === 'Mme'
                                ? 'btn-radio-profile selected-btn-radio-profile'
                                : 'btn-radio-profile'
                            }
                            style={{ borderColor: !el.validation[0] && 'red' }}
                          ></div>
                          <Text
                            text="Mme"
                            className="profile-btn-radio-label"
                          />
                        </span>
                        <span
                          className="second-btn-radio-add-beneficiaire-profile-container"
                          onClick={() =>
                            props.onChangeBeneficiaireData(
                              'M',
                              'civilite',
                              index,
                              0
                            )
                          }
                        >
                          <div
                            className={
                              el.civilite === 'M'
                                ? 'btn-radio-profile selected-btn-radio-profile'
                                : 'btn-radio-profile'
                            }
                            style={{ borderColor: !el.validation[0] && 'red' }}
                          ></div>
                          <Text text="M" className="profile-btn-radio-label" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="first-line-add-beneficiaire-profile-form">
                      <div>
                        <Input
                          inputLabel="Nom"
                          placeholder=""
                          className={`nom-input-add-beneficiaire-profile ${
                            !el.validation[2] && 'input-profile-error'
                          }`}
                          value={el.nom}
                          onChange={(e) =>
                            props.onChangeBeneficiaireData(
                              e.target.value,
                              'nom',
                              index,
                              2
                            )
                          }
                        />
                      </div>
                      <div>
                        <Input
                          inputLabel="Prénom"
                          placeholder=""
                          className={`prenom-input-add-beneficiaire-profile ${
                            !el.validation[3] && 'input-profile-error'
                          }`}
                          value={el.prenom}
                          onChange={(e) =>
                            props.onChangeBeneficiaireData(
                              e.target.value,
                              'prenom',
                              index,
                              3
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="second-line-add-beneficiaire-profile-form">
                      <div className="select-container-add-beneficiaire-profile">
                        <SelectOrganism
                          placeholder=" "
                          option={[
                            { text: 'Enfant', value: '' },
                            { text: 'Conjoint', value: '' },
                            { text: 'Ascendant', value: '' }
                          ]}
                          value={el.type}
                          inputLabel="Type de bénéficiaire"
                          classNameInput={`select-add-beneficiaire-profile nom-input-add-beneficiaire-profile ${
                            !el.validation[1] && 'input-profile-error'
                          }`}
                          classNameIcon="icon-select-add-beneficiaire-profile"
                          iconSize={14}
                          classNameSelectMenu="input-select-modal-add-beneficiaire-profile"
                          classNameOptionContainer="select-option-container-beneficiaire-profile"
                          classNameSelectedOption="selected-option-container-beneficiaire-profile"
                          showModalView={false}
                          onChange={(value) =>
                            props.onChangeBeneficiaireData(
                              value.text,
                              'type',
                              index,
                              1
                            )
                          }
                        />
                      </div>
                      <div>
                        <div className="mobile-extra-line-add-beneficiaire-profile-form">
                          <div className="mobile-extra-select-line-add-beneficiaire-profile">
                            <SelectOrganism
                              placeholder=" "
                              option={[
                                { text: 'Enfant', value: '' },
                                { text: 'Conjoint', value: '' },
                                { text: 'Ascendant', value: '' }
                              ]}
                              value={el.type}
                              inputLabel="Type de bénéficiaire"
                              classNameInput={`select-add-beneficiaire-profile ${
                                !el.validation[1] && 'input-profile-error'
                              }`}
                              classNameIcon="icon-select-add-beneficiaire-profile"
                              iconSize={14}
                              classNameSelectMenu="input-select-modal-add-beneficiaire-profile"
                              classNameOptionContainer="select-option-container-beneficiaire-profile"
                              classNameSelectedOption="selected-option-container-beneficiaire-profile"
                              showModalView={false}
                              onChange={(value) =>
                                props.onChangeBeneficiaireData(
                                  value.text,
                                  'type',
                                  index,
                                  1
                                )
                              }
                            />
                          </div>
                          <DatePicker
                            inputLabel="Date de naissance"
                            renderIcon={false}
                            placeholder="JJ/MM/AAAA"
                            className={`date-picker-birth-date-beneficiaire-profile ${
                              !el.validation[4] && 'input-profile-error'
                            }`}
                            selected={el.birthDate}
                            onChange={(e) =>
                              props.onChangeBeneficiaireData(
                                e,
                                'birthDate',
                                index,
                                4
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="third-line-add-beneficiaire-profile-form">
                      <div className="margin-top-mobile-add-beneficiaire-profile">
                        <Input
                          inputLabel="Numéro de sécurité sociale"
                          placeholder=""
                          className={`num-input-security-beneficiaire-profile nom-input-add-beneficiaire-profile ${
                            !el.validation[5] && 'input-profile-error'
                          }`}
                          value={el.numSecurity}
                          onChange={(e) =>
                            props.onChangeBeneficiaireData(
                              e.target.value,
                              'numSecurity',
                              index,
                              5
                            )
                          }
                        />
                      </div>
                      {/* <div>
                        <Input
                          inputLabel='Organisme de rattachement'
                          placeholder=''
                          className={`organism-attachment-add-beneficiaire-profile  ${!el
                            .validation[6] && 'input-profile-error'}`}
                          value={el.organism}
                          onChange={e =>
                            props.onChangeBeneficiaireData(
                              e.target.value,
                              'organism',
                              index,
                              6
                            )
                          }
                        />
                      </div> */}
                      <div className="date-picker-effet-date-container-beneficiaire-profile">
                        <DatePicker
                          inputLabel="Date d’effet"
                          renderIcon={false}
                          placeholder="JJ/MM/AAAA"
                          className={`date-picker-effet-date-beneficiaire-profile  ${
                            !el.validation[7] && 'input-profile-error'
                          }`}
                          selected={el.dateEffet}
                          onChange={(e) =>
                            props.onChangeBeneficiaireData(
                              e,
                              'dateEffet',
                              index,
                              7
                            )
                          }
                        />
                      </div>
                    </div>
                    {props.beneficiaireDataFomulaire[index].type ==
                      'Conjoint' && (
                      <div>
                        <div style={{ marginTop: 20 }}>
                          Votre conjoint(e) bénéficie-t-il/elle d'une autre
                          mutuelle :
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Checkbox
                            type="checkbox"
                            label="Oui"
                            highlightLabel={true}
                            subLabel="par conséquent, la liaison Noémie ne sera pas mise en place pour mon/ma conjoint(e) et il ne disposera pas de carte de Tiers payant."
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('OUI', index, 'otherMutuelle')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'otherMutuelle'
                              ] === 'OUI'
                            }
                          />
                          <Checkbox
                            type="checkbox"
                            label="Non"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('NON', index, 'otherMutuelle')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'otherMutuelle'
                              ] === 'NON'
                            }
                          />
                        </div>
                        <div style={{ marginTop: 20 }}>
                          Souhaitez-vous la mise en place de la liaison Noémie?
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Checkbox
                            type="checkbox"
                            label="Oui"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('OUI', index, 'joinNoemie')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'joinNoemie'
                              ] === 'OUI'
                            }
                          />
                          <Checkbox
                            type="checkbox"
                            label="Non"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('NON', index, 'joinNoemie')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'joinNoemie'
                              ] === 'NON'
                            }
                          />
                        </div>
                      </div>
                    )}

                    {props.beneficiaireDataFomulaire[index].type ==
                      'Enfant' && (
                      <div>
                        <div style={{ marginTop: 20 }}>
                          Sur quel numéro de Sécurité Social l’enfant doit être
                          rattaché :
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <Checkbox
                            type="checkbox"
                            label="La mère"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked(
                                'La mère',
                                index,
                                'attachedTo'
                              )
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index] &&
                              props.state.beneficiaireDataFomulaire[index] &&
                              props.state.beneficiaireDataFomulaire[index]
                                ?.attachedTo === 'La mère'
                            }
                          />
                          <Checkbox
                            type="checkbox"
                            label="Le père"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox ml-35"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked(
                                'Le père',
                                index,
                                'attachedTo'
                              )
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'attachedTo'
                              ] === 'Le père'
                            }
                          />
                        </div>
                        <div style={{ marginTop: 20 }}>
                          L'enfant bénéficie d'une autre mutuelle ?
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <Checkbox
                            type="checkbox"
                            label="Oui"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('OUI', index, 'otherMutuelle')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'otherMutuelle'
                              ] === 'OUI'
                            }
                          />
                          <Checkbox
                            type="checkbox"
                            label="Non"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox ml-70"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('NON', index, 'otherMutuelle')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index][
                                'otherMutuelle'
                              ] === 'NON'
                            }
                          />
                        </div>
                        <div style={{ marginTop: 20 }}>
                          Souhaitez-vous la mise en place de la liaison Noémie?
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <Checkbox
                            type="checkbox"
                            label="Oui"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('OUI', index, 'joinNoemie')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index]
                                .joinNoemie === 'OUI' &&
                              props.state.beneficiaireDataFomulaire[index]
                                .otherMutuelle !== 'OUI'
                            }
                            disabled={
                              props.state.beneficiaireDataFomulaire[index]
                                .otherMutuelle === 'OUI'
                            }
                          />
                          <Checkbox
                            type="checkbox"
                            label="Non"
                            highlightLabel={true}
                            className="organism-affiliation-square-stage1-checkbox ml-70"
                            classNameContainer="organism-affiliation-checkbox-container"
                            size={16}
                            onClick={() =>
                              props.optionClicked('NON', index, 'joinNoemie')
                            }
                            checked={
                              props.state.beneficiaireDataFomulaire[index]
                                .joinNoemie === 'NON' ||
                              props.state.beneficiaireDataFomulaire[index]
                                .otherMutuelle === 'OUI'
                            }
                          />
                        </div>
                      </div>
                    )}
                    <Text
                      text={el.errorMessage}
                      className={'profile-add-beneif-error-message'}
                    />
                    <div className="btn-add-other-beneficiaire-profile"></div>
                    {props.beneficiaireDataFomulaire.length === index + 1 && (
                      <Button
                        text="Ajouter un bénéficiaire supplémentaire"
                        className="btn-add-other-beneficiaire-profile"
                        onClick={() => props.addOtherBenef()}
                        color={COLOR_BUTTON.GREEN}
                      />
                    )}
                  </div>
                </div>
                <div className="upload-document-container-add-beneficiaire-profile">
                  <Upload
                    handleFileChange={(e) => {
                      props.selectFile(e, index);
                    }}
                    id={index}
                    classNameText="upload-text-add-beneficiaire-profile"
                    isUploadValid={props.isUploadValid}
                  />

                  {!props.isUploadValid && !el.files?.length && (
                    <p style={{ color: 'red', fontSize: 12 }}>
                      Veuillez inclure un document
                    </p>
                  )}
                  <div className="upload-document-text-container-add-beneficiaire-profile">
                    <Text
                      text="Pièces justificatives :"
                      className="upload-file-add-beneficiaire-profile-text"
                    />
                    <Text
                      text=" . Attestation vitale de votre nouveau bénéficiaire"
                      className="upload-file-add-beneficiaire-profile-text"
                    />
                    <Text
                      text=" .  RIB de remboursement"
                      className="upload-file-add-beneficiaire-profile-text"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className='upload-document-container-add-beneficiaire-profile' >
          <Upload
            handleFileChange={props.selectFile}
            // contentClassName='upload-file-add-beneficiaire-profile-input'
            classNameText='upload-text-add-beneficiaire-profile'
            isUploadValid={props.isUploadValid}
          />
          <div className='upload-document-text-container-add-beneficiaire-profile'>
            <Text
              text='Pièces justificatives :'
              className='upload-file-add-beneficiaire-profile-text'
            />
            <Text
              text=' . Attestation vitale de votre nouveau bénéficiaire'
              className='upload-file-add-beneficiaire-profile-text'
            />
            <Text
              text=' .  RIB de remboursement'
              className='upload-file-add-beneficiaire-profile-text'
            />
          </div>
        </div> */}
      </div>
      <p className="error-message">{props.ServerErrorMessage}</p>
      <div className="container-btn-actions-add-beneficiaire-profile">
        <Button
          text="Annuler"
          className="btn-cancel-add-beneficiaire-profile"
          onClick={() => props.onCancelClick()}
          color={COLOR_BUTTON.BLACK}
          isAlreadyOutline={true}
        />
        <Button
          text="Confirmer"
          className="btn-submit-add-beneficiaire-profile"
          onClick={() => props.onValidStep()}
          color={COLOR_BUTTON.GREEN}
        />
      </div>
      <ModalAddBeneficiary
        onClose={props.CloseModal}
        show={props.state.showSuccessModal}
      />

      <ModalConfirmAddBeneficiary
        onClose={props.CloseModal}
        show={props.state.showConfirmationModal}
        submit={props.SendBenefData}
        beneficiary={props.beneficiaireDataFomulaire?.map(
          (benef) => `${benef?.prenom} ${benef?.nom}`
        )}
      />
    </div>
  );
}

export default TemplateAddBeneficiaireStep2;
