import React from "react"
import { Icon, Text } from "../../Atoms"
import { ElementNotification } from "../../Organisms"
function TempalateNotification(props) {
    return (
        <div className="template-notification template-scroll template-notification-web slide-left">
            <Text text="Notifications" className="title-notification" />
            <Icon icon="BigX" className="icon-close-notification" onClick={props.onHide} />
            <ElementNotification date='10/10/2021' description="test test test test test" weight={true} icon={"Plus"} color="red" />
            <ElementNotification date='10/10/2021' description="test test test test test" icon={"Plus"} color="yellow" />
        </div>
    )
}
export default TempalateNotification
