import { API_URL, API_VERSION } from '../../Config/Config';
import { addPipelineLoader } from '../../Redux/Actions/authActions/authActions';
import Store from '../../Redux/Store';
import { apiGet, apiPost, processResponse } from '../standardApi';

export async function apiLogin(emailOrMemberNumber, password) {
  const endPoint = '/Auth/Login2';
  const body = JSON.stringify({
    login: emailOrMemberNumber,
    password: password
  });
  return await apiPost(endPoint, body);
}
export async function apiRefreshToken(refreshToken) {
  const response = await fetch(
    API_URL + (API_VERSION ? `/${API_VERSION}` : '') + '/auth/refresh',
    {
      method: 'Get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: 'Bearer ' + refreshToken
      }
    }
  );
  return response.json();
}
export async function apiCheckMember(emailOrMemberNumber) {
  const response = await apiPost(
    '/Auth/CheckMember',
    JSON.stringify({
      emailOrMemberNumber: emailOrMemberNumber
    }),
    'text/plain'
  );
  return response;
}
export async function apiCheckEmail(emailOrMemberNumber) {
  const response = await apiPost(
    '/Auth/CheckEmail',
    JSON.stringify({
      emailOrMemberNumber: emailOrMemberNumber
    }),
    'text/plain'
  );
  return response;
}
export async function apiResetPassword(tokenId, password) {
  return apiPost(
    '/v1/Auth/ResetPasswordWithToken',
    JSON.stringify({
      tokenId,
      password
    }),
    'application/json',
    'application/json'
  );
}

export async function createAccount({ captchaToken, ...data }) {
  const response = await fetch(
    API_URL + (API_VERSION ? `/${API_VERSION}` : '') + '/Auth/CreateAccount',
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
        'X-CAPTCHA-TOKEN': captchaToken
      },
      body: JSON.stringify(data)
    }
  );
  return processResponse(response);
}

export const apiValidateCaptcha = async (body) => {
  return apiPost('/Captcha/ValidateCaptcha', JSON.stringify(body));
};

export const apiGenerateCaptcha = async () => {
  return apiGet('/Captcha/GenerateCaptcha');
};

export async function apiCreateAccount(memberId, password) {
  const response = await apiPost(
    `/Auth/CreateAccount?memberId=${memberId}`,
    JSON.stringify({
      password: password
    }),
    'text/plain'
  );
  return response;
}

export async function apiCheckLinkValidity(token) {
  const response = await apiGet(`/Auth/CheckLinkValidity?token=${token}`);
  return response;
}

export async function apiValidateSmsCode(userId, smsCode) {
  const response = await fetch(
    API_URL + (API_VERSION ? `/${API_VERSION}` : '') + '/Auth/ValidateSMS',
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify({
        userId: userId,
        smsCode: smsCode
      })
    }
  );
  return processResponse(response);
}

export async function apiCollectPhone(captchaToken, data) {
  const response = await fetch(
    API_URL + (API_VERSION ? `/${API_VERSION}` : '') + 'Auth/CollectPhone',
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
        'X-CAPTCHA-TOKEN': captchaToken
      },
      body: JSON.stringify(data)
    }
  );
  return processResponse(response);
}
