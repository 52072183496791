import React, { useEffect, useState } from 'react';
import { apiGetFAQ } from '../../Api/FAQ/FAQ';
import { TemplateFAQ } from '../../Templates';

const FAQ = () => {
    const [data, setData] = useState(null);
    const getFAQ = async () =>{
        const response = await apiGetFAQ();
        if (response.status === 200) {
            setData(response.data)
        }
    } 
    useEffect(()=>{
        getFAQ();
    },[]);

    return (
        <TemplateFAQ
            data={data} 
        />
    );
};

export default FAQ;