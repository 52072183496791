import React from 'react';

AtomProgressBar.defaultProps = {
  containerClassName: 'default-progressBar',
  progressBarClassName: 'container-default-progressBar',
  percent: '0%',
  vertical: false,
  dots: false,
  numberOfDots: 4,
  numberOfActiveDots: 1,
  containerDot: 'container-dots-progress-bar',
  containerVerticalDot: 'container-dots-vertical-progress-bar',
  type: 'primary',
  height: '8px',
  backgroundColor: 'red',
  backgroundColorFulFilled: 'green'
};

function AtomProgressBar (props) {
  let containerClassName = props.containerClassName;
  let progressBarClassName = props.progressBarClassName;
  if (props.vertical === true) {
    containerClassName += ' default-vertical-progressBar';
    progressBarClassName += ' container-vertical-progressBar';
  }
  function renderDots () {
    const list = [];
    if (props.dots === true) {
      for (let index = 0; index < props.numberOfDots; index++) {
        let className = '';
        if (props.type === 'secondary') {
          className += ' secondary-dots';
        }
        if (props.numberOfActiveDots - 1 >= index) {
          if (props.type === 'secondary') {
            className += ' active-secondary-dots';
          } else {
            className = ' active-dots';
          }
        }
        list.push(
          <div className={'default-dot-progress-bar' + className}></div>
        );
      }
    }
    return list;
  }
  if (props.type === 'secondary') {
    containerClassName += ' default-secondary-progressBar';
    progressBarClassName += ' container-secondary-progressBar';
  }
  return (
    <div
      className={containerClassName}
      style={{ height: props.height, backgroundColor: props.backgroundColor }}
    >
      <div
        className={progressBarClassName}
        style={{
          width: props.vertical === false ? props.percent : props.height,
          height: props.vertical === true ? props.percent : props.height,
          backgroundColor: props.backgroundColorFulFilled
        }}
      ></div>
      <div
        className={
          props.vertical === false
            ? props.containerDot
            : props.containerVerticalDot
        }
      >
        {' '}
        {renderDots()}
      </div>
    </div>
  );
}

export default AtomProgressBar;
