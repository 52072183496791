import React from 'react';
import {
  RemboursementOrganism,
  RemboursementStatusOrganism,
  ConsomationOrganism
} from '../../Organisms/';
import { useSelector } from 'react-redux';
import { Text, Button, DatePicker } from '../../Atoms';

function TemplateRemboursement (props) {
  const sideBar = useSelector(state => state.sideBar);
  const remboursementClassName = () => {
    if (props.status == 'remboursement') {
      return 'remboursement-title remboursement-first-title remboursement-title-active';
    }
    return 'remboursement-title remboursement-first-title';
  };
  const consomationClassName = () => {
    if (props.status == 'consomation') {
      return 'remboursement-title remboursement-title-active';
    }
    return 'remboursement-title';
  };
  return (
    <div
      className={
        sideBar.open
          ? 'template-scroll remboursement-width-sibe-bar-open'
          : 'template-scroll remboursement-width'
      }
      onScroll={props.scroll}
    >
      <div className='remboursement-title-container'>
        <Text
          text={
            <span className={remboursementClassName()}>Mes remboursements</span>
          }
          onClick={() => props.statusChanged('remboursement')}
        />
        <Text
          text={<span className={consomationClassName()}>Ma consommation</span>}
          onClick={() => props.statusChanged('consomation')}
        />
      </div>
      {props.status == 'remboursement' ? (
        <RemboursementStatusOrganism
          carteTpCheck={props.carteTpCheck}
          carteTpChecked={props.carteTpChecked}
          remboursement={props.remboursement}
          datePickerChanged={props.datePickerChanged}
          downloadDocument={props.downloadDocument}
          endDate={props.endDate}
          beginDate={props.beginDate}
          viewClicked={props.viewClicked}
          MessageRemboursement={props.MessageRemboursement}
        />
      ) : (
        <ConsomationOrganism
          selectData={props.selectData}
          selectFilterChange={props.selectFilterChange}
          annual={props.annual}
          vision={props.vision}
          seriesVisionGlobal={props.seriesVisionGlobal}
          seriesBeneficiary={props.seriesBeneficiary}
          seriesPoste={props.seriesPoste}
        />
      )}
    </div>
  );
}

export default TemplateRemboursement;
