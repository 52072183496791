import React from 'react';
import { Button, Text } from '../../Atoms';
import { Input } from '../../Molecules';
function TemplateAddBeneficiaireStep3 (props) {
  return (
    <div className='template-scroll profile-container'>
      <div className='cartetp-header-container'>
        <Text text='Ajouter un bénéficiaire' type='h4' className='title-page'/>
      </div>
      {/* <div className='profile-title' >
                <Text
                    text='Afin d’ajouter un bénéficiaire, une signature éléctronique est obligatoire. Vous allez recevoir un code à retranscrire, veuillez choisir le moyen d’envoi : '
                    type='h7'
                />
            </div>
            <div className='add-beneficiaire-profile-verification-method-container' >
                <div className='add-beneficiaire-profile-verification-mini-container' >
                    <div className={props.outilDeValidation === 'email' ? 'selected-verification-method-add-beneficiaire-profile' : 'unselected-verification-method-add-beneficiaire-profile'} onClick={() => props.onSelectOutilDeValidation('email')}>
                        Email
                    </div>
                    <div className={props.outilDeValidation === 'sms' ? 'selected-verification-method-add-beneficiaire-profile' : 'unselected-verification-method-add-beneficiaire-profile'} onClick={() => props.onSelectOutilDeValidation('sms')} >
                        SMS
                    </div>
                </div>
            </div> */}
      {props.outilDeValidation && (
        <>
          <div className='profile-title'>
            <Text
              text={`Nous venons d’envoyer un code par ${props.outilDeValidation} à ${props.benificiaireList[0].value.mail}`}
              type='h7'
            />
            <Text
              text='Veuillez le renseigner ci-dessous pour confirmer les modifications :'
              type='h7'
            />
          </div>
          <div className='verification-code-input-add-beneficiaire-profile-container'>
            <div>
              <Input
                placeholder='tapez le code ici '
                className='verification-code-input-add-beneficiaire-profile'
                onChange={e => props.onChangeVerificationCode(e.target.value)}
                value={props.verificationCode}
              />
            </div>
          </div>
          {props.verificationCode.length >= 6 && (
            <div className='btn-confirm-verification-add-beneficiaire-profile'>
              <Button
                text='Confirmer le code'
                className='btn-add-other-beneficiaire-profile'
                onClick={() => props.navigationSteps('ADD_BENEFICIAIRE_STEP4')}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TemplateAddBeneficiaireStep3;
