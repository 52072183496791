import React, { useState } from 'react';
import { Icon, Text } from '../../Atoms';

const convertDate = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  return `${day}/${month}/${year}`;
};
function OrganismRemboursement(props) {
  const [sort, setsort] = useState(true);
  const { numero, reglement, ligne } = props.data;
  return (
    <>
      <div
        className="remboursement-organism-container remboursement-organism-mobile-display"
        onClick={() => setsort(!sort)}
      >
        <div className="remboursement-organism-header-container">
          <div className="remboursement-organism-telechargement-icon-container">
            <Icon
              icon="Telechargement"
              size="20px"
              onClick={() => props.downloadDocument(numero)}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="remboursement-organism-description-container">
            <div>
              <Text
                text={ligne[0]?.ayantDroit}
                className="remboursement-desktop-text-bold remboursement-organism-benfit-name"
              />
              <Text
                text={`Remboursement effectué le ${reglement.dateReglement}`}
                className="remboursement-desktop-text"
              />
            </div>
            <Icon
              icon={sort ? 'Sort' : 'SortTop'}
              size="13px"
              className="remboursement-organism-description-icon"
            />
          </div>
          <div className="remboursement-organism-price-container">
            <Text
              text={`${reglement.totalRembCGRM}€`}
              className="remboursement-desktop-text-bold"
            />
          </div>
        </div>
        {!sort /* CE MAP SERT POUR LA VERSION MOBILE */ &&
          <>
            {
              ligne.map((element, index) => (
                <div
                  className="remboursement-organism-info-container"
                  key={String(index)}
                >
                  <div className="remboursement-organism-info-content">
                    <div
                      style={{
                        width: "40%",
                        wordBreak: "break-word"
                      }} 
                      className="remboursement-molecule-container"
                    >
                      <Text
                        text="Frais"
                        className="remboursement-desktop-text-bold"
                      />
                      <Text
                        text={`${element.libelle}`}
                        className="remboursement-desktop-text"
                      />
                    </div>
                    <div 
                      className="remboursement-molecule-container"
                      style={{
                        width: "10%"
                      }} 
                    >
                      <Text
                        text="Base SS"
                        className="remboursement-desktop-text-bold"
                      />
                      <Text
                        text={`${element.baseSS}`}
                        className="remboursement-desktop-text"
                      />
                    </div>
                    <div 
                      className="remboursement-molecule-container"
                      style={{
                        width: "10%"
                      }}
                    >
                      <Text
                        text="Taux SS"
                        className="remboursement-desktop-text-bold"
                      />
                      <Text
                        text={`${element.tauxSS}%`}
                        className="remboursement-desktop-text"
                      />
                    </div>
                    <div 
                      className="remboursement-molecule-container"
                      style={{
                        width: "25%"
                      }}
                    >
                      <Text
                        text="Remboursement SS"
                        className="remboursement-desktop-text-bold"
                      />
                      <Text
                        text={`${element.rembSS}€`}
                        className="remboursement-desktop-text"
                      />
                    </div>
                  </div>
                </div>
              ))
            }
            <Text 
              className="remboursement-desktop-text mt-12"
              text="Veuillez noter que le délai peut prendre un peu de temps avant d'apparaître sur le compte bancaire"
            />
          </>
          }
      </div>
      <div
        className={
          !sort
            ? 'remboursement-desktop-organism-container remboursement-organism-desktop-display'
            : 'remboursement-desktop-organism-container-center remboursement-organism-desktop-display'
        }
      >
        
        <div className="remboursement-desktop-organism-content-container">
          <div className="remboursement-desktop-organism-description-container">
            <div style={{ width: '40%' }}>
              <Text
                text={ligne[0]?.ayantDroit}
                className="remboursement-desktop-text-bold"
              />
            </div>
            <div style={{ width: '50%', wordBreak: 'break-word',  }}>
              <Text
                text={`Remboursement effectué le ${reglement.dateReglement} à ${reglement.beneficiaire}`}
                className="remboursement-desktop-text-2"
              />
            </div>
            <div style={{ width: 'max-content' ,marginRight: 0}}>
              <Text
                text={`${reglement.totalRembCGRM}€`}
                className="remboursement-desktop-text-bold"
              />
            </div>
          </div>
          {!sort  &&
            <>
              {ligne.map((element, index) => (
              <div
                className="remboursement-desktop-organism-info-container"
                key={String(index)}
              >
                <div
                  style={{
                    width: '40%',
                    wordBreak: 'break-word',
                  }}
                >
                  <span className="remboursement-desktop-text-bold">
                    {element.libelle}
                  </span>
                  <div className="remboursement-desktop-text mt-6">
                      {`Date du soin: ${convertDate(element.dateActe)}`}
                  </div>
                </div>
                <div
                  className="remboursement-desktop-organism-info-content"
                  style={{ width: '40%' }}
                >
                  <div className="remboursement-desktop-molecule-container">
                    <Text text="Frais" className="remboursement-desktop-text" />
                    <Text
                      text={`${element.fr}€`}
                      className="remboursement-desktop-text-bold"
                    />
                  </div>
                  <div className="remboursement-desktop-molecule-container">
                    <Text
                      text="Base SS"
                      className="remboursement-desktop-text"
                    />
                    <Text
                      text={`${element.baseSS}€`}
                      className="remboursement-desktop-text-bold"
                    />
                  </div>
                  <div className="remboursement-desktop-molecule-container">
                    <Text
                      text="Taux SS"
                      className="remboursement-desktop-text"
                    />
                    <Text
                      text={`${element.tauxSS}%`}
                      className="remboursement-desktop-text-bold"
                    />
                  </div>
                  <div className="remboursement-desktop-molecule-container">
                    <Text
                      text="Remboursement SS"
                      className="remboursement-desktop-text"
                    />
                    <Text
                      text={`${element.rembSS}€`}
                      className="remboursement-desktop-text-bold"
                    />
                  </div>
                </div>
              </div>
            ))}
            <Text 
              className="remboursement-desktop-text mt-12"
              text="Veuillez noter que le délai peut prendre un peu de temps avant d'apparaître sur le compte bancaire"
            />
            </>
            }
        </div>
        <div className="remboursement-desktop-organism-sort-icon-container">
          <Icon
            icon={sort ? 'Sort' : 'SortTop'}
            size="20px"
            style={{ cursor: 'pointer' }}
            className="remboursement-desktop-organism-description-icon"
            onClick={() => setsort(!sort)}
          />
        </div>
      </div>
    </>
  );
}

export default OrganismRemboursement;
