// require('dotenv').config();
const API_URL = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_API_VERSION;
// const API_URL = ;
const pink = '#FF8AD8';
const white = '#FFFFFF';
const red = '#E72222';
const blue = '#1313C6';
const green = '#00CE8D';
const REMBOURSEMENT_LIMIT = 6;
const DEMANDE_LIMIT = 5;
const COLOR_TABLE = [
  '#8BCAB5',
  '#F28F2F',
  '#E44856',
  '#FF8AD8',
  '#FF6722',
  '#1313C6',
  '#00CE8D',
  '#F7DA71',
  '#E72222',
  '#000000',
  '#000000'
];
const SERIES = ['seriesVisionGlobal', 'seriesPoste', 'seriesBeneficiary'];
const FAQ_URL = 'https://assistance.spvie.com/hc/fr';
const DEMANDE_COLOR = [
  {
    text: 'Non traité',
    color: '#E72222',
    status: 'NotDone'
  },
  {
    text: 'En cours',
    color: '#FF6722',
    status: 'WaitingLine'
  },
  {
    text: 'Traité',
    color: '#00CE8D',
    status: 'Done'
  }
];
const DEMANDE_FROM = [
  {
    from: 'VOUS',
    key: 'Client',
    color: '#00CE8D'
  },
  {
    from: 'SPVIE',
    key: 'SPVIE',
    color: '#1313C6'
  }
];
const DEMANDE_HEADER = [
  {
    text: 'Date',
    sort: 'Created'
  },
  {
    text: 'Statut',
    sort: 'Status'
  },
  {
    text: 'Demande',
    sort: 'CgrmQueue'
  },
  {
    text: 'Documents',
    sort: 'Count'
  }
];

const COLOR_BUTTON = {
  SECOND_RED:"#E44856",
  ORANGE:"#F28F2F",
  SALMON:"#F4A6A0",
  BLACK:"#000000",
  GREEN:"#8BCAB5",
  BLUE:"#0D5381"
}
export {
  API_URL,
  API_VERSION,
  pink,
  white,
  red,
  blue,
  green,
  REMBOURSEMENT_LIMIT,
  DEMANDE_LIMIT,
  COLOR_TABLE,
  SERIES,
  FAQ_URL,
  DEMANDE_COLOR,
  DEMANDE_FROM,
  DEMANDE_HEADER,
  COLOR_BUTTON,
};
