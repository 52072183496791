const initialState = {
  accessToken: null,
  refreshToken: null,
  user: null
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        accessToken: action.value.accessToken,
        refreshToken: action.value.refreshToken,
        user: action.value.user
      };
    case 'LOGOUT':
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        user: null
      };

    default:
      return state;
  }
}

export default AuthReducer;
