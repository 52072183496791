import React from 'react';
import { Text, Button, Icon } from '../../Atoms';
import { Upload } from '../../Molecules';
function TemplateHospitalisationStep4 (props) {
  return (
    <div className='template-scroll cartetp-container'>
      <div className='cartetp-header-container hospitalisation-title-container'>
        <Text text='Hospitalisation' type='h4' />
      </div>
      <Icon
        icon='Sort'
        size={15}
        onClick={() => props.prevStep()}
        className='go-back-icon-hospitalisation'
      />
      <Text
        text='Informations sur l’établissement'
        type='h7'
        className='hospitalisation-step-sub-title'
      />
      <div className='container-hospitalisation'>
        <Text
          text='Informations sur l’établissement'
          type='h7'
          className='hospitalisation-step-title'
        />
        <Upload
          handleFileChange={props.selectFile}
          contentClassName='upload-file-add-beneficiaire-profile-input'
          classNameText='upload-text-add-beneficiaire-profile'
          child={
            <div
              className='btn-upload-container-profile'
              style={{ textAlign: 'left' }}
            >
              <div className='upload-doc-hospitalisation'>
                <Text
                  text='Joindre un fichier (falcutatif)'
                  className='placeholder-upload-doc-profile'
                />
                <Icon icon='Plus' size={14} />
              </div>
              <Text
                text='6mo maximum autorisé'
                className='condition-upload-doc-hospitalisation'
              />
            </div>
          }
        />
        <Button
          text='Confirmer'
          className='submit-btn-modal-calendar'
          boxShadow
          onClick={() => props.nextStep()}
        />
      </div>
    </div>
  );
}

export default TemplateHospitalisationStep4;
