import React from 'react';
import { Button, Text } from '../../Atoms';
import { Input } from '../../Molecules';
import { PhotoLoginOrganisme } from '../../Organisms';
function TemplateResetPasswordMailStep1 (props) {
  return (
    <div className='login-template'>
      <PhotoLoginOrganisme />
      <div className='login-template-organism'>
        <div className='login-container'>
          <div>
            <Text
              text='Réinitalisation de votre mot de passe'
              type='h4'
              className='reset-password-title'
            />
            <Text
              text='Merci de bien vouloir renseigner l’adresse mail liée à votre compte.'
              className='text-description-first-login text-description__width'
            />
            <div className='first-login-small-container first-login-small-container__width'>
              <Input
                placeholder='Inscrivez votre email ici'
                className='input-first-login'
                onChange={e => props.onChange(e.target.value)}
                value={props.mail}
              />
              {!props.isValid && (
                <Text
                  type='h8'
                  className='reset-password-error-message mt-24'
                  text={
                    <>
                      <span className='close-weight'>
                        Numéro adhérent ou adresse mail invalide.
                      </span>{' '}
                      Entrez une adresse mail ou un numéro d’adhérent valide.
                    </>
                  }
                />
              )}
              <div className='btn-next-first-login-container'>
                <Button
                  text='Confirmer'
                  className='btn-next-first-login'
                  boxShadow
                  onClick={() => props.nextStep()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateResetPasswordMailStep1;
