import React from 'react';
import { Button, Icon, Pdf, Text } from '../../Atoms';
import { DetailDocument } from '../../Molecules';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import PageTitle from '../../Components/Dashboard/PageTitle';
export default function TemplateDocumentBibliotheque(props) {
  const container = useSelector((state) => state.container);
  function cardClassName(value, list) {
    const classNames = ['type-garanties-doc'];
    if (list.indexOf(value) != -1) {
      classNames.push('type-garanties-doc-selected');
    }
    return classNames.join(' ');
  }
  function templateClassName() {
    const className = [
      ' template-container',
      'template-send-document',
      'template-scroll'
    ];
    if (container.padding === false) {
      className.push('mobile-content-nopadding');
    }
    return className.join(' ');
  }
  return (
    <div className={templateClassName()}>
      {/* <div
        className={
          !container.padding
            ? 'content-header-document-garanties container-title-send-doc'
            : 'container-title-send-doc'
        }
      >
        <Icon
          icon="SortTop"
          size="20px"
          className={
            !container.padding
              ? 'icon-return icon-return-padding'
              : 'icon-return'
          }
          onClick={() => props.onReturn()}
        />
        <Text text={props.name} className="title-send-document title-page" />
      </div> */}
      <PageTitle titleText={props.name} onTitleClick={props.onReturn}/>
      {props.state.step === 1 && (
        <div className="content-template-type-document-container">
          <div style={{ 'margin-bottom': '40px' }}></div>
          {props?.state?.empty ? <Text text="Vous n’avez pas de document disponible" className="title-send-document" /> : props.state.docSections.map((el, index) => (
            <div key={String(index)}>
              <div className="content-template-type-document">
                {el.categoryName}
              </div>
              <hr className="content-template-separator-document"></hr>
              <Row>
                {el.data.map((docEl, docIndex) => (
                  <Col xs={12} md={6} className="" key={String(docIndex)}>
                    <DetailDocument
                      text={docEl.filename}
                      onViewFile={() => props.onOpen(docEl)}
                      onDownload={() => props.onDowLoad(docEl)}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      )}
      {props.state.step === 2 && (
        <div className="content-template-document-sended">
          <div className="container-doc-sended-step2"></div>
          <Pdf url={props.state.file.url} />
          <div
            className="container-btn-downlond-doc"
            style={{ paddingBottom: '50px' }}
          >
            <Button
              text="Télécharger"
              onClick={() => props.onDowLoad(props.state.documentSelected)}
              className="btn-downlond-doc"
            />
          </div>
        </div>
      )}
    </div>
  );
}
