const initialState = {
  data: {}
};

function DocumentsReducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_DOCUMENT_CATEGORY':
      return {
        ...state,
        data: action.value.data
      };
    default:
      return state;
  }
}
export default DocumentsReducer;
