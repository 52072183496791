import React, { Component } from 'react';
import {
  apiPostClientCarteTpDownload,
  apiGetClientCarteTpDownload
} from '../../Api/CarteTp/CarteTp';
import { CarteTpTemplate } from './../../Templates/index';
import { connect } from 'react-redux';
import { apiGetMembers } from '../../Api/Profile/Profile';

class CarteTp extends Component {
  constructor(props) {
    super(props);
    const canonical = document.querySelector('link[rel=canonical]');
    this.url = canonical ? canonical.href : document.location.href;
    this.state = {
      check: false,
      validate: false,
      file: {
        attachement: '',
        blob: null,
        url: ''
      },
      civility: 'Mrs',
      firstName: 'xxxx',
      lastName: 'XXXX',
      socialSecurityNumber: '2 XX XX XXX XXX',
      adherentNumber: 'xxxxxx'
    };
  }

  checkBoxClicked = () => {
    this.setState({
      check: !this.state.check,
      validate: false
    });
  };

  getFile = async () => {
    const res = await apiPostClientCarteTpDownload();
    // this.props.user?.memberId,
    // this.props.user?.memberNumber,
    // this.props.user?.memberType
    if (res.status == 200) {
      const { blob, url } = await this.createBlob(
        res.carteTP.attachmentData.type,
        res.carteTP.attachmentData.data
      );
      this.setState({
        file: {
          attachement: res.carteTP.attachmentData,
          blob: blob,
          url: `${url}`
        }
      });
    }
  };

  createBlob = async (type, data) => {
    try {
      const blob = await (await fetch(`data:${type};base64,${data}`)).blob();
      const url = URL.createObjectURL(blob);
      return { blob, url };
    } catch (err) {}
  };

  handleSharing = async () => {
    if (navigator.share && this.state.file.blob) {
      var file = new File(
        [this.state.file.blob],
        this.state.file.attachement.name,
        { type: this.state.file.attachement.type }
      );
      await navigator.share({ files: [file] });
    }
  };

  componentDidMount() {
    // this.getFile();
    this.getMember();
  }

  getMember = async () => {
    const response = await apiGetMembers();
    if (response.status === 200) {
      this.setState({
        firstName: response.firstName,
        lastName: response.lastName,
        socialSecurityNumber: response.securityNumber,
        adherentNumber: response.codeTiers?.toString(),
        civility: response.gender
      });
    } else {
      // alert('Une erreur est survenue. Veuillez ré-essayer.');
    }
  };

  download = async (codeAdherent) => {
    // if (this.state.file.url) {
    //   const download = document.createElement('a');
    //   download.setAttribute('href', this.state.file.url);
    //   this.state.file.attachement.type == 'application/pdf' &&
    //     download.setAttribute('target', '_blank');
    //   download.click();
    // }
    const resp = await apiGetClientCarteTpDownload(this.state.adherentNumber);
    const { blob, url } = await this.createBlob(
      resp.contentType,
      resp.fileContent
    );
    // window.location.href = 'data:application/octet-stream;base64,' + resp.fileContent;
    var a = document.createElement('a'); //Create <a>
    a.href = url;
    a.target = '_blank';
    a.click(); //Downloaded file
  };

  createBlob = async (type, data) => {
    try {
      const blob = await (await fetch(`data:${type};base64,${data}`)).blob();
      const url = URL.createObjectURL(blob);
      return { blob, url };
    } catch (err) {}
  };

  validateClicked = () => {
    this.setState({
      validate: true
    });
  };

  render() {
    return (
      <CarteTpTemplate
        check={this.state.check}
        checkBoxClicked={this.checkBoxClicked}
        validateClicked={this.validateClicked}
        validate={this.state.validate}
        download={this.download}
        handleSharing={this.handleSharing}
        civility={this.state.civility}
        firstName={this.state.firstName}
        lastName={this.state.lastName}
        socialSecurityNumber={this.state.socialSecurityNumber}
        adherentNumber={this.state.adherentNumber}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CarteTp);
