import React from 'react';
import { Img } from '../../Atoms';
import spvieLogo from '../../assets/img/spvieLogo.svg';

function MoleculeAffiliationHeader(props) {
    return (
        <div className="affiliation-header-container">
            <Img src={spvieLogo} className="affiliation-header-img" />
        </div>
    );
}

export default MoleculeAffiliationHeader;
