import React from 'react';
import { Icon, Text } from '../../../Atoms';
import styles from '../Geolocation.module.scss'

const GeolocationHeader = ({ onReturn }) => {
    return (
        <div className={styles['geolocation-title']}>
            {onReturn &&(
                <div
                    // className={styles['header-back-button-container']}
                    // style={!onReturn ? { display: "none" } : null}
                    onClick={onReturn}
                >
                    <Icon
                        icon="SortTop"
                        size="16px"
                        className={`icon-return ${styles['header-back-button']}`}
                    />
                </div>
            )}
            <div className={styles['header-title-container']}>
                <Text
                    className='title-page'
                    text="Géolocalisation"
                />
            </div>
        </div>
    )
}

export default GeolocationHeader;