import React from 'react';
import { Icon, Text } from '../../Atoms';
import { Link } from 'react-router-dom';
import { MENU, MENUPREV } from './Menu';
import { connect } from 'react-redux';
import cgrmLogo from '../../assets/img/logo_CGRM_2020.svg';

import Store from '../../Redux/Store';
import KeycloakService from '../../Services/KeycloakService';
import { ModalConfirmRating } from '../../Modals';
// import Keycloak from 'keycloak-js';

class OrganismSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPath: '',
      windowWidth: window.innerWidth,
      contractFamily: Store.getState().auth.user.contractTypes,
      sideBar: Store.getState().sideBar,
      show: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState(() => {
        return {
          windowWidth: window.innerWidth
        };
      });
    });
    const path = window.location.pathname;
    const aux = path.split('/');
    if (aux.length === 0) {
      this.setState({ selectedPath: '/' });
    } else {
      this.setState({ selectedPath: `/${aux[1].toLowerCase()}` });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.path != prevProps.path) {
      const path = window.location.pathname;
      const aux = path.split('/');
      if (aux.length === 0) {
        this.setState({ selectedPath: '/' });
      } else {
        this.setState({ selectedPath: `/${aux[1].toLowerCase()}` });
      }
    }
  }

  itemClicked = (element) => {
    this.setState({ selectedPath: element.path });

    Store.dispatch({
      type: 'SET_CURRENT_SIDE_BAR',
      value: { open: !this.state.sideBar.open }
    });
  };

  renderItem = (el, index) => {
    return (
      <Link
        key={index}
        className="container-item-side-bar"
        onClick={() => this.itemClicked(el)}
        to={el.path}
      >
        <div
          className={
            this.state.selectedPath.toLocaleLowerCase() ==
            el.path.toLocaleLowerCase()
              ? 'container-item-path-clicked-side-bar'
              : 'container-item-path-side-bar side-bar-path'
          }
        >
          {/* <Icon
            size={18}
            icon={el.icon}
            className={
              this.state.selectedPath.toLocaleLowerCase() ==
              el.path.toLocaleLowerCase()
                ? 'item-icon-clicked-side-bar'
                : 'item-icon-side-bar'
            }
          /> */}
          <Text text={el.name} className="item-path-side-bar" />
        </div>
      </Link>
    );
  };

  sideBarClassName = () => {
    const className = ['container-side-bar'];
    if (window.innerWidth > 762 && this.props.tuto.step == 9) {
      className.push('side-bar-tuto');
    }
    return className.join(' ');
  };

  ratingClicked = () => {
    const action = {
      type: 'RATING_MODAL_SHOW',
      value: true
    };
    this.props.dispatch(action);

    this.setState({ show: true });
  };

  ratingHide = () => {
    const action = {
      type: 'RATING_MODAL_SHOW',
      value: false
    };
    this.props.dispatch(action);

    this.setState({ show: false });
  };

  logoutClicked = async () => {
    localStorage.removeItem('getRemboursementData');
    localStorage.removeItem('getRemboursementData_timestamp');
    localStorage.removeItem('getConsumption');
    localStorage.removeItem('getConsumption_timestamp');
    localStorage.removeItem('getSlideshow');
    localStorage.removeItem('getSlideshow_timestamp');

    const action = {
      type: 'SET_CURRENT_USER',
      value: {
        accessToken: null,
        refreshToken: null
      }
    };

    this.props.dispatch(action);
    KeycloakService.doLogout();
  };

  render() {
    return (
      <div className={this.sideBarClassName()}>
        <div className="header-side-bar">
          <Link to="/">
            <img className="header-logo-side-bar" src={cgrmLogo} />
          </Link>
          <div className="header-info-side-bar">
            <div className="mobile-header-info-side-bar">
              <Text
                text={`${this.props.user ? this.props.user?.firstName : ''} ${
                  this.props.user ? this.props.user?.lastName : ''
                }`}
                className="header-info-name-side-bar"
              />
              <Text
                text="n° adhérent"
                className="header-info-adherent-side-bar"
              />
              <Text
                text={this.props.user ? this.props.user?.codeTiers : ''}
                className="header-info-adherent-side-bar"
              />
            </div>
          </div>
        </div>

        <div
          className={
            !this.state.contractFamily?.includes('SANTE')
              ? 'container-elemnt-side-bar-prev-temp'
              : 'container-elemnt-side-bar'
          }
        >
          {MENU.map((el, index) => this.renderItem(el, index))}
        </div>
        <Text
          text="Laissez-nous votre avis !"
          className="feed-back-mobile container-item-side-bar"
          onClick={this.ratingClicked}
        />

        <ModalConfirmRating
          contentClassName="rating-content"
          containerIconClassName="rating-icon-container"
          show={this.state.show}
          onHide={this.ratingHide}
          onHideWithReset={this.ratingHide}
          text="Votre avis nous intéresse"
          size={window.innerWidth < 768 ? 'small' : 'meduim'}
          codeTiers={this.props.user?.codeTiers}
        />
        <div className="container-item-side-bar container-deconnexion-side-bar">
          {this.state.windowWidth > 768 ? (
            <div className="container-deconnection-path-side-bar">
              <a
                className="container-item-side-bar logout-item"
                onClick={this.logoutClicked}
              >
                <Icon size={18} icon="Deconnexion" color="black" />
                <Text
                  text="Déconnexion"
                  className="item-path-side-bar deconnexion-text-side-bar deconnexion-text-side-bar"
                />
              </a>
              {/* <span
                className='container-item-side-bar logout-item'
                onClick={this.logoutClicked}
              >
                <Icon size={18} icon='Deconnexion' color='black' />
                <Text
                  text='Déconnexion'
                  className='item-path-side-bar deconnexion-text-side-bar'
                />
              </span> */}
            </div>
          ) : (
            <div className="container-item-path-side-bar container-information-side-bar">
              <Text text="Mentions légales" className="item-legale-side-bar" />
              <Text
                text="|"
                className="item-legale-side-bar item-legale-limiter-sider-bar"
              />
              <Link onClick={this.logoutClicked}>
                <Text text="Déconnexion" className="item-legale-side-bar" />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  open: state.sideBar.open,
  tuto: state.tuto,
  rating: state.rating
});
export default connect(mapStateToProps, mapDispatchToProps)(OrganismSideBar);
export const fnMapDispatchToProps = mapDispatchToProps;
