import React from 'react';
import { RemboursementOrganism } from '../../Organisms/';
import { Text, Button, DatePicker, Switch } from '../../Atoms';
import * as moment from 'moment';
import 'moment/locale/fr';
import { COLOR_BUTTON } from '../../Config/Config';
function OrganismRemboursementStatus(props) {
  moment.locale('fr');

  return (
    <>
      <div className="remboursement-header-container">
        <div className="remboursement-header-date-picker-container">
          <div className="remboursement-date-picker-container remboursement-date-picker-container-mobile">
            <DatePicker
              className="remboursement-date-picker"
              placeholder="Date de début"
              iconClassName="icon-date-picker-remboursement"
              selected={props.beginDate}
              onChange={(date) => props.datePickerChanged('beginDate', date)}
            />
          </div>
          <div className="remboursement-date-picker-container">
            <DatePicker
              className="remboursement-date-picker"
              placeholder="Date de fin"
              iconClassName="icon-date-picker-remboursement"
              selected={props.endDate}
              onChange={(date) => props.datePickerChanged('endDate', date)}
            />
          </div>
        </div>
        <div className="remboursement-header-switch-container">
          <Switch
            width={39}
            height={16}
            className="remboursement-header-switch"
            onColor={'#E44856'}
            offHandleColor={'#E44856'}
            check={props.carteTpCheck}
            setCheck={props.carteTpChecked}
          />
          <Text
            text="Tiers-payant"
            className="remboursement-header-switch-label"
          />
        </div>
        <div className="remboursement-header-button-container">
          <Button
            text="Voir tout"
            className="remboursement-header-button"
            onClick={props.viewClicked}
            onColor={COLOR_BUTTON.SECOND_RED}
          />
        </div>
      </div>
      <div className="remboursement-content-container">
        {props.remboursement.map((element, index) => {
          let isDateView = false;
          let previousElement = null;
          if (index == 0) {
            isDateView = true;
          } else {
            previousElement = props.remboursement[index - 1];
            if (
              previousElement &&
              moment(element.reglement.dateReglement, 'DD/MM/YYYY').format(
                'MM/YYYY'
              ) !=
                moment(
                  previousElement.reglement.dateReglement,
                  'DD/MM/YYYY'
                ).format('MM/YYYY')
            ) {
              isDateView = true;
            } else {
              isDateView = false;
            }
          }
          return (
            <div key={index}>
              {isDateView && (
                <div className="remboursement-content-date-container">
                  <Text
                    text={
                      moment(element.reglement.dateReglement, 'DD/MM/YYYY')
                        .format('MMMM YYYY')
                        .charAt(0)
                        .toUpperCase() +
                      moment(element.reglement.dateReglement, 'DD/MM/YYYY')
                        .format('MMMM YYYY')
                        .substring(1)
                    }
                    className="remboursement-content-date"
                  />
                </div>
              )}
              <div className="remboursement-container">
                <RemboursementOrganism
                  data={{ ...element }}
                  downloadDocument={props.downloadDocument}
                />
              </div>
            </div>
          );
        })}
        <div className="remboursement-content-button-container">
          {props.remboursement.length === 0 &&
          props.beginDate !== null &&
          props.endDate !== null ? (
            <p
              style={{ fontWeight: 'bold', textAlign: 'center', width: '70%' }}
            >
              {props.MessageRemboursement}
            </p>
          ) : props.remboursement.length === 0 ? (
            <div></div>
          ) : (
            <Button
              text="Télécharger le décompte"
              className="remboursement-content-button"
              onClick={() => props.downloadDocument()}
              color={COLOR_BUTTON.SECOND_RED}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default OrganismRemboursementStatus;
