import React from 'react';
import { Modal } from '../../Molecules';
import { Text } from '../../Atoms';
import PropTypes from 'prop-types';
ModalValidation.defaultProps = {
    description: [],
};
ModalValidation.PropTypes = {
    description: PropTypes.array
};
function ModalValidation(props) {
    return (
        <Modal
            {...props}
        >
            <div className={"container-msg-Validation"}>
                {props.description.map(el => {
                    return <Text className={'descriprtion-style-div'} text={el} />;
                })}
            </div>
        </Modal>

    );
}
export default ModalValidation;
