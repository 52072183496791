import React, { Component } from 'react';
import { Text } from '../../Atoms';
import LoginBackground from '../../Components/Login/LoginBackground';
import LoginModal from '../../Components/Login/LoginModal';
import LoginModalTitle from '../../Components/Login/LoginModalTitle';
import styles from './../LoginScreens/login.module.css';
import LoginButton from '../../Components/Login/LoginButton';
import { connect } from 'react-redux';
class Deconnection extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount () {
    const action = {
      type: 'SET_CURRENT_USER',
      value: {
        accessToken: null,
        refreshToken: null,
      }
    }
    
    this.props.dispatch(action);
  }

  render() {
    return (
      <LoginBackground>
        <div className={styles.container}>
          <LoginModal>
            <LoginModalTitle
              title={
                'Connexion à votre espace Assuré :'
              }
            />
            <div>
              <Text className='disconnect-text-container' text='Vous avez été déconnecté.' type='h8' />
              <div className=''>
                <div className='disconnect-text-container btn-confirme-first-login-container deconnection-btn__edit-margin'>
                  <LoginButton
                    text='Connexion'
                    onClick={() => this.props.history.push('/login')}
                    form="reset-password-form"
                  >
                    Connexion
                  </LoginButton>
                </div>
              </div>
            </div>
          </LoginModal>
        </div>
      </LoginBackground>
    );
  }
}
export default connect()(Deconnection);
