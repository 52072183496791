import Keycloak from 'keycloak-js';
// export const redirectUri = 'http://localhost:3006';
export const redirectUri = process.env.REACT_APP_REDIRECT_URI;
export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_BASE_URL;

export const keycloakConfig = {
  url: keycloakUrl,
  realm: 'cgrm',
  clientId: 'CGRM_MOBILE'
};

const _kc = new Keycloak(keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      pkceMethod: 'S256',
      redirectUri,
      checkLoginIframe: false
      //   checkLoginIframe: true,
      //   checkLoginIframeInterval: 1
    })
    .then(onAuthenticatedCallback)
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const isTokenExpireSoon = () => {
  if (isLoggedIn()) {
    const tokenParsed = _kc.tokenParsed;
    const currentTime = Math.floor(Date.now() / 1000);
    if (!tokenParsed) {
      return false;
    }
    const tokenExpiryTime = tokenParsed.exp;
    const timeLeft = tokenExpiryTime - currentTime;

    return timeLeft <= 100;
  } else {
    return false;
  }
};
const updateToken = async () => {
  try {
    return await _kc.updateToken(100);
  } catch (error) {
    doLogin();
  }
};

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  isTokenExpireSoon
};

export default KeycloakService;
