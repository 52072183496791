import React from 'react';
import { ContactTemplate } from '../../Templates';
import { connect } from 'react-redux';
import {
  apiGetClientDemands,
  apiSendContact,
  apiSendResponseDemand
} from '../../Api/Contact/Contact';
import { serilizeGetDemande } from '../../Api/Contact/serilizeContact';
import { DEMANDE_LIMIT } from '../../Config/Config';
import * as moment from 'moment';
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        mail: '',
        message: '',
        file: '',
        object:
          this.props.location.state == null
            ? ''
            : this.props.location.state.object
      },
      show: false,
      status:
        this.props.location.state == null
          ? 'Nous contacter'
          : this.props.location.state.status,
      demandeData: [],
      demandeDataMobile: [],
      demandeStep: 1,
      selectedDemande: null,
      page: 1,
      pageNumber: 0,
      windowWidth: window.innerWidth,
      searchWord: '',
      orderBy: '',
      direction: '',
      skip: 0,
      response: ''
    };
  }

  getPageNumber = (total) => {
    const rest = (total / DEMANDE_LIMIT) % 1 === 0 ? 0 : 1;
    return Math.trunc(total / DEMANDE_LIMIT) + rest;
  };

  sendResponse = async () => {
    const body = {
      created: this.state.selectedDemande.created,
      attachmentIdList: [],
      from: 2,
      to: 0,
      message: this.state.response,
      userEmail: '',
      cgrmFileNum: '',
      cgrmTreatmentDate: this.state.selectedDemande.created
    };
    const res = await apiSendResponseDemand(
      this.state.selectedDemande.id,
      body
    );
    if (res.status === 200) {
      var newSelectedDemande = this.state.selectedDemande;
      newSelectedDemande.detail.push({
        date: moment(this.state.selectedDemande.created).format('DD/MM/YYYY'),
        description: this.state.response,
        document: [],
        from: 'VOUS'
      });
      this.setState({
        response: '',
        selectedDemande: newSelectedDemande
      });
    }
  };

  getClientDemand = async (skip, limit, searchWord, orderBy, direction) => {
    const res = await apiGetClientDemands(
      skip,
      limit,
      searchWord,
      orderBy,
      direction
    );
    if (res.status == 200) {
      // modif
      this.setState({
        demandeData: serilizeGetDemande(res.data),
        total: res.total,
        pageNumber: this.getPageNumber(res.total)
      });
    }
  };

  getMobileData = async (skip, limit, data = [], searchWord) => {
    const res = await apiGetClientDemands(skip, limit, searchWord); // modif
    if (res.status == 200) {
      // modif
      this.setState({
        demandeDataMobile: [...data, ...serilizeGetDemande(res.data)],
        total: res.total,
        pageNumber: this.getPageNumber(res.total),
        skip: this.state.skip + 1
      });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState(() => {
        return {
          windowWidth: window.innerWidth
        };
      });
    });
    this.getClientDemand(
      0,
      DEMANDE_LIMIT,
      this.state.searchWord,
      this.state.orderBy,
      this.state.direction
    );
    this.getMobileData(0, DEMANDE_LIMIT, [], '');
  }

  onSubmit = () => {
    this.sendContact();
  };

  onChangeContact = (value, key) => {
    this.setState({
      contact: { ...this.state.contact, [key]: value }
    });
  };

  onHide = () => {
    this.setState({
      show: false
    });
  };

  changeStatus = (value) => {
    this.setState({
      status: value
    });
    if (value == 'Mes demandes') {
      this.props.history.push({
        pathname: '/contact',
        search: `?category=mesdemandes`
      });
    } else {
      this.props.history.push({
        pathname: '/contact',
        search: ``
      });
    }
  };

  onClickFAQ = () => {
    const { history } = this.props;
    history.push('/foire-aux-questions');
  };

  accessToChatbot = () => {
    window.botSDK.open();
  };

  sendContact = async () => {
    const body = new FormData();
    body.append('Type', 0);
    body.append('Object', this.state.contact.object);
    body.append('Message', this.state.contact.message);
    body.append('Attachment', this.state.contact.file[0]);

    const res = await apiSendContact(body);
    if (res.status == 200) {
      this.setState({
        show: true
      });
    }
  };

  viewDemandeClicked = (element, index) => {
    this.setState({
      selectedDemande: element,
      demandeStep: 2
    });
  };

  retourClicked = () => {
    this.setState({
      selectedDemande: null,
      demandeStep: 1
    });
  };

  responseClicked = () => {
    return true;
  };

  getSkipLimit = (page) => {
    return {
      skip: (page - 1) * DEMANDE_LIMIT,
      limit: DEMANDE_LIMIT
    };
  };

  onChangePagination = (next) => {
    if (next != 0 && next <= this.state.pageNumber) {
      const { skip, limit } = this.getSkipLimit(next);
      this.getClientDemand(
        skip,
        limit,
        this.state.searchWord,
        this.state.orderBy,
        this.state.direction
      );
      this.setState({
        page: next
      });
    }
  };

  scroll = (e) => {
    const bottom =
      ~~e.target.scrollHeight -
        ~~e.target.scrollTop -
        ~~e.target.clientHeight <=
      1;
    if (
      bottom &&
      this.state.demandeDataMobile.length < this.state.total &&
      this.state.status === 'Mes demandes' &&
      this.state.windowWidth < 768
    ) {
      this.getMobileData(
        this.state.skip * DEMANDE_LIMIT,
        DEMANDE_LIMIT,
        this.state.demandeDataMobile
      );
    }
  };

  onKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      if (page) {
        this.getClientDemand(
          0,
          DEMANDE_LIMIT,
          this.state.searchWord,
          this.state.orderBy,
          this.state.direction
        );
        this.setState({
          page: 1
        });
      } else {
        this.getMobileData(0, DEMANDE_LIMIT, [], this.state.searchWord);
      }
    }
  };

  OnLoopIconClick = (page) => {
    if (page) {
      this.getClientDemand(
        0,
        DEMANDE_LIMIT,
        this.state.searchWord,
        this.state.orderBy,
        this.state.direction
      );
    } else {
      this.getMobileData(0, DEMANDE_LIMIT, [], this.state.searchWord);
    }
  };

  searchWordChange = (e) => {
    this.setState({
      searchWord: e.target.value
    });
  };

  sortClicked = (sort, direction) => {
    this.getClientDemand(
      0,
      DEMANDE_LIMIT,
      this.state.searchWord,
      sort,
      direction
    );
    this.setState({
      orderBy: sort,
      direction: direction,
      page: 1
    });
  };

  responseChanged = (e) => {
    this.setState({
      response: e.target.value
    });
  };

  render() {
    return (
      <ContactTemplate
        state={this.state}
        onSubmit={this.onSubmit}
        onClickFAQ={this.onClickFAQ}
        onChangeContact={this.onChangeContact}
        onHide={() => this.onHide()}
        changeStatus={this.changeStatus}
        demandeData={this.state.demandeData}
        demandeStep={this.state.demandeStep}
        viewDemandeClicked={this.viewDemandeClicked}
        selectedDemande={this.state.selectedDemande}
        responseClicked={this.responseClicked}
        retourClicked={this.retourClicked}
        page={this.state.page}
        pageNumber={this.state.pageNumber}
        onChangePagination={this.onChangePagination}
        onScroll={this.scroll}
        demandeDataMobile={this.state.demandeDataMobile}
        value={this.state.searchWord}
        onKeyPress={this.onKeyPress}
        OnLoopIconClick={this.OnLoopIconClick}
        onChange={this.searchWordChange}
        sortClicked={this.sortClicked}
        response={this.state.response}
        responseChanged={this.responseChanged}
        sendResponse={this.sendResponse}
        accessToChatbot={this.accessToChatbot}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
