import React from 'react';
import { DocumentGarantiesTemplate } from '../../Templates';
import { connect } from 'react-redux';
import {
  apiGetAllCategories,
  apiGetDocumentsById,
  apiGetDocumentEntreprise,
  apiDownloadCompanyDocuments
} from '../../Api/Documents/Documents';
class DocumentEntreprise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      types: [],
      empty: false,
      data: [],
      docList: [],
      file: {
        attachement: '',
        blob: '',
        url: ''
      }
    };
  }

  onReturn = () => {
    if (this.state.step === 1) {
      this.props.history.push('/Documents');
    } else {
      this.props.changeContainer({
        type: 'SET_CURRENT_PADDING',
        value: { padding: true }
      });
      this.setState({ step: 1, types: [] });
    }
  };

  onSelectType = (value) => {
    const list = [...this.state.types];
    const index = list.indexOf(value);
    if (index == -1) {
      if (this.state.types.length == 0) {
        list.push(value);
      }
    } else {
      list.splice(index, 1);
    }
    this.setState({ types: list });
  };

  onSubmit = () => {
    this.setState({
      step: 2
    });
    this.props.changeContainer({
      type: 'SET_CURRENT_PADDING',
      value: { padding: false }
    });
  };

  componentWillUnmount() {
    this.props.changeContainer({
      type: 'SET_CURRENT_PADDING',
      value: { padding: true }
    });
  }

  getAllCategories = async () => {
    const res = await apiGetAllCategories();
    res.data.forEach((element) => {
      if (element.categoryName === 'Garantie') {
        this.getDocumentList(element.id);
      }
    });
  };
  getAllDocuments = async () => {
    const res = await apiGetDocumentEntreprise();
    this.setState(
      res.data?.length
        ? {
          docList: res.data.map((elem) => {
            return {
              name: `${elem.documentName}`,
              attachmentInfoId: elem.numero
            };
          })
        }
        : {
            empty: true
          }
    );
  };

  componentDidMount() {
    // this.getAllCategories();
    this.getAllDocuments();
  }

  getDocumentList = async (id) => {
    const res = await apiGetDocumentsById(id);
    if (res.status === 200) {
      this.setState({
        docList: res.data
      });
    } else {
      this.setState({
        docList: []
      });
    }
  };

  onDowLoad = async (el) => {
    if (el.content) {
      const { blob, url } = await this.createBlob(el.content);
      this.setState({
        file: {
          attachement: el,
          blob: blob,
          url: `${url}`
        }
      });
      if (url) {
        const download = document.createElement('a');
        download.setAttribute('href', url);
        download.setAttribute('target', '_blank');
        download.click();
      }
    } else {
      const res = await apiDownloadCompanyDocuments(el.attachmentInfoId);
      if (res.status === 200) {
        const { blob, url } = await this.createBlob(res.data);
        this.setState({
          file: {
            attachement: res,
            blob: blob,
            url: `${url}`
          }
        });
        if (url) {
          const download = document.createElement('a');
          download.setAttribute('href', url);
          download.setAttribute('target', '_blank');
          download.click();
        }
      }
    }
  };

  onOpen = async (el) => {
    if (el.content) {
      const { blob, url } = await this.createBlob(el.content);
      this.setState({
        documentSelected: el,
        step: 2,
        file: {
          attachement: el,
          blob: blob,
          url: `${url}`
        }
      });
    } else {
      const res = await apiDownloadCompanyDocuments(el.attachmentInfoId);
      if (res.status === 200) {
        const { blob, url } = await this.createBlob(res.data);
        this.setState({
          documentSelected: el,
          step: 2,
          file: {
            attachement: res,
            blob: blob,
            url: `${url}`
          }
        });
      }
    }
  };

  createBlob = async (data) => {
    try {
      const blob = await (
        await fetch(`data:application/pdf;base64,${data}`)
      ).blob();
      const url = URL.createObjectURL(blob);
      return { blob, url };
    } catch (err) {}
  };

  render() {
    return (
      <DocumentGarantiesTemplate
        name={'Documents entreprise'}
        state={this.state}
        history={this.props.history}
        onSelectType={this.onSelectType}
        onReturn={this.onReturn}
        onSubmit={this.onSubmit}
        onOpen={this.onOpen}
        onDowLoad={this.onDowLoad}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeContainer: (action) => {
      dispatch(action);
    }
  };
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  container: state.container
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentEntreprise);
