import { useState } from 'react';
import { Button } from '../../Atoms';
import { DemandeCardDetailOrganism, DemandeCardOrganism } from '..';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

function OrganismDemandeMobile (props) {
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState('');
  const responseClicked = () => {
    const res = props.responseClicked ? props.responseClicked() : null;
    if (res) {
      setResponse('');
    }
  };

  return (
    <>
      <DemandeCardOrganism
        date={props.element.date}
        demande={props.element.demande}
        status={props.element.status}
        documentNbr={props.element.documentNbr}
        onClick={() => setShow(!show)}
      />
      {show && (
        <div className='demande-detail'>
          <DemandeCardDetailOrganism
            data={props.element.detail}
            status={props.element.status}
            docData={props.element.numFic}
          />
          {props.element.status == 'WaitingLine' && (
            <>
              <Form.Control
                className='default-input demande-en-cour-input'
                placeholder='Repondre...'
                as={'textarea'}
                value={props.response}
                onChange={props.responseChanged}
              />
              <Button
                text='Répondre'
                className='demande-card-button'
                onClick={props.sendResponse}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
OrganismDemandeMobile.PropTypes = {
  element: PropTypes.any,
  responseClicked: PropTypes.any
};
OrganismDemandeMobile.defaultProps = {
  element: {
    date: '',
    demande: '',
    status: '',
    documentNbr: 0,
    detail: [],
    responseClicked: null
  }
};
export default OrganismDemandeMobile;
