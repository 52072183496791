import React, { useState } from 'react'
import { Icon } from "../../Atoms"
function MoleculeCollapse(props) {
    const [open, SetOpen] = useState(false)
    const className = ["container-collapse"]
    if (open === true) {
        className.push('container-collapse-open')
    }
    if (props.className) {
        className.push(props.className)
    }
    return <div className={className.join(' ')} onClick={() => SetOpen(!open)}>
        <Icon icon="Collapse" size="13.5px" className="icon-collapse" />
        {props.title}
        {open && <div className="full-container">
            {props.children}
        </div>}
    </div>
}
MoleculeCollapse.defaultProps = {
    title: "title",
    className: null
}
export default MoleculeCollapse
