import React from 'react';
import { Icon, Text } from '../../Atoms';
import PropTypes from 'prop-types';

MoleculeAffiliationText.PropTypes = {
    title: PropTypes.string,
    price: PropTypes.string,
    text: PropTypes.string,

};
MoleculeAffiliationText.defaultProps = {
    title: '',
    price: '',
    text: '',

};
function MoleculeAffiliationText(props) {
    return (
        <div className="molecule-affiliation-text-container">
            <div className="molecule-affiliation-text-icon-container" >
                <Icon icon="Fleche" size="13px" className="molecule-affiliation-text-icon" />
            </div>
            <div className="molecule-affiliation-text-content-container">
                <div className="molecule-affiliation-text-content">
                    <span>{props.title}</span> (déduite sur votre fiche de paie) - <span>{props.price}€/mois</span> à votre charge pour <span>{props.text}</span>.
                </div>
                <Text text="Paris Saint-Germain F.C finance 68.89€" className="molecule-affiliation-text-content-text" />
            </div>
        </div>
    );
}

export default MoleculeAffiliationText;
