import React from 'react';
import {  Text, Button, Icon } from '../../Atoms';
import {  Modal } from '../../Molecules';
import notifTel from '../../assets/img/notifTel.png';
import { COLOR_BUTTON } from '../../Config/Config';

function ModalServerError(props) {
  return (
    <Modal
      show={props.show}
      showIconClose={false}
      centered={true}
      onHide={() => props.onHide()}
      contentClassName={
        'content-modal-confirm-password' + props.contentClassName
      }
    >
      <div className="full-container">
        <Icon
          icon="BigX"
          className="icon-close-modal-confim-password"
          size="20px"
          onClick={() => props.onHide()}
        />
        <Text text={props.isMaintenance ? "Application en maintenance" : "OOPS, Il y a un soucis."} className="title-modal-confirm-password" />
        <Text
          text={props.isMaintenance ? "Nous tenons à vous informer que notre application est temporairement hors service en raison de travaux de maintenance. Si vous avez des questions ou préoccupation, n'hésitez pas à nous contacter. Nous vous remercions pour votre compréhension." : "Il semblerait que nous rencontrions un problème. Nous sommes en train de le résoudre et nous serons opérationnels très prochainement ! "}
          className="text-description-confirm-password"
          
        />
        {!props.isMaintenance &&
        <Text
          text={"En attendant, si vous avez besoin de l'aide de nos équipes concernant votre contrat, vous pouvez nous contacter à info@cgrm.com\n\nMerci pour votre patience"}
          className="text-description-confirm-password"
        />}

        <div className="container-center">
          <Button
            type="primary"
            text="Fermer"
            className={props.classNameBtncancel}
            onClick={props.onHide}
            isAlreadyOutline={true}
            color={COLOR_BUTTON.BLACK}
          />
        </div>
      </div>
    </Modal>
  );
}
ModalServerError.defaultProps = {
  img: notifTel,
  contentClassName: '',
  modalStep: 1,
  title: '',
  firstText: '',
  secondText: '',
  onConfirmCode: null,
  onCancel: null,
  classNameBtncancel: 'btn-cancel-confirm-password',
  classNameBtnSubmit: 'btn-submit-confirm-password',
  classNameInput: 'input-confirm-change',
  onChange: null,
  code: ''
};
export default ModalServerError;
