import React, { useState, useEffect } from 'react';
import { DatePicker, Icon, Text } from '../../Atoms';
import { Input } from '../../Molecules';
import PropTypes from 'prop-types';
import moment from 'moment';

const OrganismInputProfile = (props) => {
  const [edit, setEdit] = useState(false);
  const [firstValue, setFirstValue] = useState('');
  const classNameInput = [];
  if (props.autoComplete) {
    classNameInput.push('profile-input-auto-complete');
  } else {
    classNameInput.push('profile-input');
  }
  if (props.classNameInput) {
    classNameInput.push(`${props.classNameInput}`);
  }
  useEffect(() => {
    setFirstValue(props.value);
    props.edit && setEdit(props.edit);
  }, []);

  const cancelEdit = () => {
    onChangeEditMode();
    // props.onChange(firstValue)
  };

  useEffect(() => {
    if (props.edit != undefined) {
      // onChangeEditMode(props.edit);
      setEdit(props.edit);
    }
  }, [props.edit]);

  const onChangeEditMode = (value = !edit) => {
    if (props.handelEditMode) {
      props.handelEditMode(value);
      setEdit(props.edit);
    } else {
      setEdit(value);
    }
  };

  const birthDateFormatted = React.useMemo(() => {
    if (
      props.as === 'calendar' &&
      props.value &&
      props.value?.split('/')?.length
    ) {
      const [day, month, year] = props.value.split('/').map(Number);
      return new Date(year, month - 1, day);
    } else {
      return props.value;
    }
  }, [props]);
  return (
    <div
      className={
        props.classNameContainer
          ? `input-profile-container ${props.classNameContainer}`
          : 'input-profile-container'
      }
    >
      {props.label && (
        <Text text={props.label} className="profile-input-label" />
      )}
      <div className="input-profile-value-container">
        {!edit ? (
          <Text text={props.value} className="profile-input-value" />
        ) : (
          <div className="input-profile-container-margin">
            {props.as === 'calendar' ? (
              <DatePicker
                renderIcon={false}
                placeholder="JJ/MM/AAAA"
                noBorder={true}
                className={`date-picker-birth-date-beneficiaire-profile `}
                selected={birthDateFormatted}
                onChange={(e) =>
                  props.onChange(moment(new Date(e)).format('DD/MM/YYYY'))
                }
              />
            ) : props.as === 'adresse' ? (
              <Text text={props.value} className="profile-input-value" />
            ) : (
              <Input
                placeholder={props.label}
                className={classNameInput.join(' ')}
                onChange={(e) => props.onChange(e.target.value)}
                as={props.as}
                autoCompleteOption={props.autoCompleteOption}
                autoCompleteOptionClicked={props.autoCompleteOptionClicked}
                showDropDown={props.showDropDown}
                autoCompleteClickAway={props.autoCompleteClickAway}
              />
            )}
          </div>
        )}
        {props.editable && (
          <div className="profile-input-icon">
            <Icon
              icon={edit ? 'CloseIcon' : 'Edit'}
              size={15}
              onClick={() => {
                edit ? cancelEdit() : onChangeEditMode(!edit);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
OrganismInputProfile.defaultProps = {
  as: 'input',
  autoCompleteOption: [],
  autoCompleteOptionClicked: null,
  autoCompleteClickAway: null,
  showDropDown: false
};
OrganismInputProfile.PropTypes = {
  as: PropTypes.string,
  autoCompleteOption: PropTypes.array,
  autoCompleteOptionClicked: PropTypes.any,
  autoCompleteClickAway: PropTypes.any,
  showDropDown: false
};
export default OrganismInputProfile;
