import React from 'react';
import { Text } from '../../Atoms';
function TemplateAddBeneficiaireStep4 (props) {
  return (
    <div className='template-scroll cartetp-container'>
      <div className='cartetp-header-container'>
        <Text text='Ajouter un bénéficiaire' type='h4' className='title-page'/>
      </div>
      {/* <div className='profile-title'>
        <Text text='Ajouter des bénéficiaires' className='profile-sub-title' />
      </div> */}
      <div style={{ textAlign: 'center' }}>
        <Text
          text='Vos modifications ont été enregistrés !'
          className='recap-add-beneficiaire-profile-titel'
        />
        <Text
          text={props.state.message}
          className='recap-add-beneficiaire-profile-titel'
        />
       
      </div>
    </div>
  );
}

export default TemplateAddBeneficiaireStep4;
