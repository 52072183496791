import PropTypes from "prop-types";
import React, { useState } from 'react';
import { Text, DatePicker, Icon, Button, Card } from '../../Atoms'
import { Input, Modal } from '../../Molecules'
import { Row, Col } from 'react-bootstrap'
import ClickAwayListener from 'react-click-away-listener';
function OrganismSelect(props) {
    const [show, setShow] = useState(false)
    const submitChoice = (value) => {
        props.onChange(value)
        setShow(false)
    }
    function viewLabel() {
        return (props.inputLabel !== null) 
      ? <Text className={props.labelClassName} text={props.inputLabel} />
      : null
    }

    return (
        <>
            <div style={{position: 'relative'}} >
                {viewLabel()}
                <div className={props.classNameInput} onClick={() => setShow(true)} >
                    {props.value
                        ? <Text text={props.value} className='placeholder-calender' />
                        : <Text text={props.placeholder} className='placeholder-calender' />
                    }
                    <Icon icon={props.iconName} size={props.iconSize} className={props.classNameIcon} />
                </div>
                {(window.innerWidth > 768 || !props.showModalView) && show &&
                    <ClickAwayListener onClickAway={() => setShow(false)}>
                        <div className={props.classNameSelectMenu}>
                        { props.renderItem ? props.renderItem(props.option, props.value, submitChoice)
                            : props.option.map((el) => {
                                
                                    const className = [props.classNameOptionContainer]
                                if (el.text == props.value) {
                                    className.push(props.classNameSelectedOption)
                                }
                                return (
                                    <div className={className.join(' ')} onClick={() => submitChoice(el)}>
                                        {el.text}
                                    </div>
                                )
                            })}
                        </div>
                    </ClickAwayListener>
                }
            </div>
            {window.innerWidth <= 768 && show && props.showModalView &&
                <div className='modal-calendar ' >
                    <div className='modal-calendar-container slide-top'>
                        <div className='close-icon-calendar-modal-container' onClick={() => setShow(false)}>
                            <Icon icon='BigX' size={16} />
                        </div>
                        <Text text='Nature de l’hospitalisation' type='h4' className='calender-title-modal' />
                        <div className='profile-card-container' >
                            <Row>
                                {props.option.map((el) => {
                                    const className = ['card-hospitalisation']
                                    if (el.text == props.value) {
                                        className.push('selected-option')
                                    }
                                    return (
                                        <Col className="container-card-profile scale-up-center" md={6} xs={6}>
                                            <Card text={el.text} size='medium' onClick={() => submitChoice(el)} className={className.join(' ')} />
                                        </Col>
                                    )
                                }

                                )}
                            </Row>
                        </div>
                    </div>
                </div>
            }
        </>

    );

}
OrganismSelect.defaultProps = {
    option: [],
    showModalView: true,
    labelClassName: 'default-label',
    inputLabel: null,
    classNameIcon: 'icon-calendar-input',
    classNameInput: 'input-select-hospitalisation',
    iconSize: 10,
    classNameSelectMenu: 'input-select-modal',
    classNameOptionContainer: 'input-select-option',
    classNameSelectedOption: 'selected-option',
    iconName: 'Sort',
    renderItem: null
}
OrganismSelect.propTypes = {
    option: PropTypes.array,
    showModalView: PropTypes.bool,
    renderItem: PropTypes.func,
}
export default OrganismSelect;
