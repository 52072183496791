import React from 'react';
import PropTypes from 'prop-types';

const elementCardBorderColor = '#000000';

OrganismElementCard.PropTypes = {
  classNameElementContainer: PropTypes.string,
  cardContent: PropTypes.any,
  borderColor: PropTypes.string
};
OrganismElementCard.defaultProps = {
  classNameElementContainer: '',
  cardContent: null,
  borderColor: elementCardBorderColor
};

function OrganismElementCard (props) {
  const classNameElementContainer = ['default-elementCard-container'];
  if (props.classNameElementContainer) {
    classNameElementContainer.push(props.classNameElementContainer);
  }
  return (
    <div
      className={classNameElementContainer.join(' ')}
      style={{ borderColor: `${props.borderColor}` }}
    >
      {props.cardContent}
    </div>
  );
}

export default OrganismElementCard;
