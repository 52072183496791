import React from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from "../../Atoms"
MoleculeIconText.defaultProps = {
  icon: null,
  text: null,
  classNameContainer: '',
  iconStyle: null,
  iconClassName: null,
  onClick: null,
};
MoleculeIconText.prototypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  classNameContainer: PropTypes.string,
  iconStyle: PropTypes.string,
  iconClassName: PropTypes.string,
  onClick: PropTypes.any,
};

export default function MoleculeIconText(props) {
  return (
    <div className={props.classNameContainer}>
      <div className='MoleculeIconText-Icon'>
        <Icon icon={props.icon} size={props.size} style={props.iconStyle} className={props.iconClassName} onClick={props.onClick} />
      </div>
      {props.text}
    </div>
  );
}
