import React from 'react';
import { Text, Icon } from '../../Atoms';
import { useHistory } from 'react-router-dom';

const PageTitle = ({ children, onTitleClick, titleText,canGoBack=true, ...rest }) => {
    const history = useHistory();
    return (
        <div
            className={
                'page-title-container'
            }
        >
            {canGoBack &&(
                <Icon
                icon='SortTop'
                size='20px'
                className={
                    'icon-return'
                }
                onClick={() => { onTitleClick ? onTitleClick() : history.push('/') }}
            />
            )}
            <Text text={titleText} className='title-page' />
        </div>
    );
};

export default PageTitle;
