import React from 'react';
import PropTypes from 'prop-types';
AtomCard.PropTypes = {
    text: PropTypes.text,
    className: PropTypes.string,
    onClick: PropTypes.any,
    size: PropTypes.oneOf(['x-small', 'small', "medium", "larg"]),
};
AtomCard.defaultProps = {
    text: null,
    className: null,
    onClick: null,
    size: "larg",
};
function AtomCard(props) {
    let className = 'default-card ';
    if (props.size === 'larg') {
        className += 'larg-card-document';
    }
    if (props.size === 'medium') {
        className += 'medium-card-document';
    }
    if (props.size === 'small') {
        className += 'small-card-document';
    }
    if (props.size === 'x-small') {
        className += 'x-small-card-document';
    }
    if (props.className !== null) {
        className = className + ' ' + props.className;
    }
    return (
        <div style={props.disabled ? {background: "gray", opacity: 0.5}: {}} className={className} onClick={props.disabled ? () => null : props.onClick}>
            <div style={{width: '90%',padding: 10}}>
            {props.text}
            </div>
        </div>
    );
}
export default AtomCard;
