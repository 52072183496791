import React, { useState } from 'react';
import { RemboursementOrganism } from '../../Organisms/';
import { Text, Button, DatePicker } from '../../Atoms'
import { Input } from '../../Molecules';
function MoleculeSliderPoint(props) {
    const renderPoint = () => {
        const points = []
        for (let i = 0; i < props.length; i++) {
            points.push(<div className={i == props.index ? "slider-point-active" : "slider-point"} onClick={() => props.pointClicked(i)} key={i} ></div>)
        }
        return points
    }

    return (
        <div className="slider-point-container">
            {
                renderPoint()
            }
        </div>
    );
}

export default MoleculeSliderPoint;
