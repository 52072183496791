import React from "react"
import { Icon, Pdf } from "../../Atoms"
import { Modal } from "../../Molecules"
function ModalPdf(props) {
    return <Modal show={props.show} onHide={props.onHide} contentClassName="content-modal-pdf" showIconClose={false} >
        <Icon icon="Plus" className="close-modal-pdf" size="12px" onClick={props.onHide} />
        <Pdf url={props.url} modal={true} />
    </Modal>
}
ModalPdf.defaultProps = {
    show: false,
    onHide: null,
    url: null
}
export default ModalPdf
