import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '../';
import PropTypes from 'prop-types';
AtomButton.PropTypes = {
  text: PropTypes.string,
  bsPrefix: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.any,
  type: PropTypes.oneOf(['primary']),
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconStyle: PropTypes.object,
  boxShadow: PropTypes.bool,
  style: PropTypes.any,
};
AtomButton.defaultProps = {
  text: 'submit',
  bsPrefix: 'default-btn',
  className: null,
  disabled: false,
  onClick: null,
  type: null,
  icon: null,
  iconClassName: null,
  iconStyle: null,
  boxShadow: false,
  style: {},
  color:"#E44856",
  isAlreadyOutline: false,
  resetHover: false
};
function AtomButton(props) {
  const [buttonStyle, setButtonStyle] = useState({
    color: props.isAlreadyOutline ? props.color : "#FFFFFF",
    backgroundColor: props.isAlreadyOutline ? "#FFFFFF" : props.color,
    border:`1px solid ${props.color}`
  });

  let className = '';
  if (props.type === 'primary') {
    className += ' default-btn-cancel';
  }

  if (props.boxShadow === true) {
    className += ' btn-box-shadow';
  }

  // code color backoffice
  if (props.className !== null) {
    className = className + ' ' + props.className;
  }

  function viewIcon() {
    if (props.icon !== null) {
      const iconClassName = 'default-icon-btn';
      return (
        <Icon
          icon={props.icon}
          size={'16px'}
          style={props.iconStyle}
          className={iconClassName + ' ' + props.iconClassName}
        />
      );
    }
    return null;
  }

  const onMouseEnter = ()=>{
    if (!props.resetHover) {
      setButtonStyle({
        color: props.isAlreadyOutline ? "#FFFFFF" : props.color,
        backgroundColor: props.isAlreadyOutline ? props.color : "#FFFFFF",
        border: `1px solid ${props.color}`
      })
    }
  }

  const onMouseLeave = ()=>{
    if (!props.resetHover) {
      setButtonStyle({
        color: props.isAlreadyOutline ? props.color : "#FFFFFF",
        backgroundColor: props.isAlreadyOutline ? "#FFFFFF" : props.color,
        border:`1px solid ${props.color}`
      })

    }
  }

  return (
    <Button
      bsPrefix={props.bsPrefix}
      className={className}
      disabled={props.disabled}
      onClick={props.onClick}
      style={props.resetHover ? {...props.style} : {...props.style,...buttonStyle}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {viewIcon()}
      {props.text}
    </Button>
  );
}
export default AtomButton;
