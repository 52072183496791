import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader'
import Loader from 'react-loader-spinner'
function AtomLoader(props) {
    if (props.type === "text") {
        return (<ContentLoader
            speed={props.speed}
            width={props.width}
            height={props.height}
            viewBox="0 0 900 12"
            backgroundColor={props.backgroundColor}
            foregroundColor={props.foregroundColor}
        >
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
        </ContentLoader>);
    } else {
        if (props.type === "img") {
            return (<ContentLoader
                speed={props.speed}
                width={props.width}
                height={props.height}
                viewBox="0 0 900 12"
                backgroundColor={props.backgroundColor}
                foregroundColor={props.foregroundColor}
            >
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
            </ContentLoader>);
        } else {
            return (<Loader
                type="Oval"
                color={props.backgroundColor}
                width={props.width}
                height={props.height}
                timeout={300000}
            />);
        }
    }
}
AtomLoader.PropTypes = {
    type: PropTypes.oneOf(["text", "img", "page"]),
    width: PropTypes.string,
    height: PropTypes.string,
    backgroundColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    speed: PropTypes.number,
};
AtomLoader.defaultProps = {
    type: "text",
    width: "max-content",
    height: "max-content",
    backgroundColor: "#ebebea",
    foregroundColor: "#ffffff",
    number: 1,
};
export default AtomLoader;
