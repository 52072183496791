import React from 'react';
import { Button, Card, Icon, Pdf, Text } from '../../Atoms';
import { Collapse, Input, DetailDocument } from '../../Molecules';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { COLOR_BUTTON } from '../../Config/Config';
import PageTitle from '../../Components/Dashboard/PageTitle';
export default function TemplateDocumentGaranties(props) {
  const container = useSelector((state) => state.container);
  function cardClassName(value, list) {
    const classNames = ['type-garanties-doc'];
    if (list.indexOf(value) != -1) {
      classNames.push('type-garanties-doc-selected');
    }
    return classNames.join(' ');
  }
  function templateClassName() {
    const className = [
      ' template-container',
      'template-send-document',
      'template-scroll'
    ];
    if (container.padding === false) {
      className.push('mobile-content-nopadding');
    }
    return className.join(' ');
  }
  return (
    <div className={templateClassName()}>
      <PageTitle titleText={props.name} />
      {props.state.step === 1 && (
        <div className="content-template-type-document-container">
          {props?.state?.empty ? (
            <Text
              text="Vous n’avez pas de document disponible"
              className="title-send-document"
            />
          ) : (
            <>
              <Row>
                {props.state.docList.map((el, index) => (
                  <Col xs={12} md={6} className="" key={String(index)}>
                    <DetailDocument
                      text={el.name}
                      onViewFile={() => props.onOpen(el)}
                      onDownload={() => props.onDowLoad(el)}
                    />
                  </Col>
                ))}
              </Row>
              {props?.withNotice && !!props.state.docList?.length && (
                <p style={{ fontFamily: 'Mont' }}>
                  Ce document n'est pas contractuel. Veuillez consulter la
                  notice de l'assureur disponible dans votre bibliothèque
                  'documents entreprises' ou contacter votre interlocuteur des
                  ressources humaines.
                </p>
              )}
            </>
          )}
        </div>
      )}
      {props.state.step === 2 && (
        <div className="content-template-document-sended">
          <div className="container-doc-sended-step2"></div>
          <Pdf url={props.state.file.url} />
          <div
            className="container-btn-downlond-doc"
            style={{ paddingBottom: '50px' }}
          >
            <Button
              text="Télécharger"
              onClick={() => props.onDowLoad(props.state.documentSelected)}
              className="btn-downlond-doc"
              color={COLOR_BUTTON.SALMON}
            />
          </div>
        </div>
      )}
      {props.state.step === 1 && props?.withResiliateButton && (
        <div
          style={{
            position: 'absolute',
            bottom: 100,
            left: 0,
            right: 0,
            textAlign: 'center'
          }}
        >
          <a
            target="_blank"
            style={{ color: 'black', textDecoration: 'underline' }}
            href="https://www.cgrm.com/resiliation"
            className="text-default-font .text-default-size"
          >
            Résilier votre contrat
          </a>
        </div>
      )}
    </div>
  );
}
