import React, { useState } from 'react';
import { RemboursementOrganism } from '../../Organisms/';
import { Text, Button, DatePicker } from '../../Atoms'
import PropTypes from 'prop-types';
function MoleculeChartLegend(props) {
    return (
        <div className={props.classNameContainer}>
            <div style={{ backgroundColor: `${props.color}` }} className="legend-color"></div>
            <Text text={props.legend} className={props.classNameText} />
        </div>
    );
}
MoleculeChartLegend.PropTypes = {
    color: PropTypes.string,
    classNameContainer: PropTypes.string,
    legend: PropTypes.string,
    classNameText: PropTypes.string,
};
MoleculeChartLegend.defaultProps = {
    color: '',
    classNameContainer: 'legend-container',
    legend: '',
    classNameText: 'legend-text',
};
export default MoleculeChartLegend;
