const initialState = {
    step: null
};

function TutoReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_STEP':
            return {
                ...state,
                step: action.value.step,
            };
        default:
            return state;
    }
}

export default TutoReducer;
