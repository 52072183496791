import React, { useEffect, useState } from 'react';
import { Text, Button, Icon, Input } from '../../Atoms';
import { InputProfileOrganism } from '../../Organisms';
import { Upload } from '../../Molecules';
import { ConfirmPasswordModal, ModalConfirmAdress } from '../../Modals';
import { COLOR_BUTTON } from '../../Config/Config';
import ModalBankingTye from '../../Modals/ModalBankingType/ModalBankingType';
import {
  apiConfirmUpdateAddress,
  apiGetStreetTypeList,
  apiModifyAddress
} from '../../Api/Profile/Profile';
import { useSelector } from 'react-redux';
import villes from '../../assets/villes.json';

function TemplateProfileScreen2(props) {
  const token = useSelector((state) => state.auth.accessToken);
  const codeTiers = useSelector((state) => state.auth.user.codeTiers);
  const [showBankingTypeModal, setShowBankingTypeModal] = React.useState(false);
  const [displayMore, setDisplayMore] = useState(false);
  const [streetList, setStreetList] = useState(null);
  const [displayListStreet, setDisplayListStreet] = useState(false);
  const [displayPostalCode, setDisplayPostalCode] = useState(false);
  const [validationCodeSuccess, setValidationCodeSuccess] = useState(false);
  const [errorValidationCode, setErrorValidationCode] = useState(null);
  const [displayAdressConfirmationModal, setDisplayAdressConfirmationModal] =
    useState(false);
  const codePostalList = villes;
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState({
    innerWidth: window.innerWidth
  });

  const [adressPayload, setAdressPayload] = useState({
    addressPostalCode: null,
    addressNumber: '',
    addressStreetType: '',
    addressStreetName: '',
    addressCity: ''
  });

  const [additionalInfo, setAdditionalInfo] = useState({
    addressFloor: '',
    addressCorridor: '',
    addressStair: '',
    addressHall: '',
    addressProperty: '',
    addressBuilding: '',
    addressResidence: '',
    addressCountry: ''
  });

  const onChange = (e) => {
    setAdressPayload({
      ...adressPayload,
      [e.target.id]: e.target.value
    });
  };

  const onChangeAdditionalInfo = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      [e.target.id]: e.target.value
    });
  };

  const onChangeTypeVoie = async (e) => {
    setAdressPayload({
      ...adressPayload,
      [e.target.id]: e.target.value
    });

    setDisplayListStreet(true);
  };

  const onChangeCodePostal = async (e) => {
    setAdressPayload({
      ...adressPayload,
      [e.target.id]: e.target.value
    });
    setDisplayPostalCode(true);
  };

  const onSelectItem = (item) => {
    setAdressPayload({
      ...adressPayload,
      addressStreetType: item
    });
    setDisplayListStreet(false);
  };

  const onSelectCodePostal = (item) => {
    setAdressPayload((prevState) => ({
      ...prevState,
      addressPostalCode: item.pc,
      addressCity: item.city
    }));

    setDisplayPostalCode(false);
  };

  const resetForm = () => {
    setAdressPayload({
      addressPostalCode: '',
      addressNumber: '',
      addressStreetType: '',
      addressStreetName: '',
      addressCity: ''
    });

    setAdditionalInfo({
      addressFloor: '',
      addressCorridor: '',
      addressStair: '',
      addressHall: '',
      addressProperty: '',
      addressBuilding: '',
      addressResidence: '',
      addressCountry: ''
    });

    setDisplayMore(false);
  };

  const getStreetTypeList = async (token) => {
    const list = await apiGetStreetTypeList();
    setStreetList(list?.data);
  };

  const submitAdressForm = async () => {
    if (
      adressPayload.addressCity.trim() == '' ||
      adressPayload.addressStreetName.trim() == '' ||
      adressPayload.addressStreetType.trim() == '' ||
      adressPayload.addressNumber.trim() == '' ||
      adressPayload.addressPostalCode == null
    ) {
      setError(
        'Les champs Code postal, Numéro, Type de voie, Libellé et Ville sont obligatoires..'
      );
    } else {
      const rue1 = [
        ...(additionalInfo.addressFloor
          ? [`ETG ${additionalInfo.addressFloor}`]
          : []),
        ...(additionalInfo.addressCorridor
          ? [`CLR ${additionalInfo.addressCorridor}`]
          : []),
        ...(additionalInfo.addressStair
          ? [`ESC ${additionalInfo.addressStair}`]
          : [])
      ].join(' ');

      const rue2 = [
        ...(additionalInfo.addressHall
          ? [`ENT ${additionalInfo.addressHall}`]
          : []),
        ...(additionalInfo.addressProperty
          ? [`BAT ${additionalInfo.addressProperty}`]
          : []),
        ...(additionalInfo.addressBuilding
          ? [`IMM ${additionalInfo.addressBuilding}`]
          : []),
        ...(additionalInfo.addressResidence
          ? [`RES ${additionalInfo.addressResidence}`]
          : [])
      ].join(' ');

      const rue3 = [
        ...(adressPayload.addressNumber
          ? [`${adressPayload.addressNumber}`]
          : []),
        ...(adressPayload.addressStreetType
          ? [`${adressPayload.addressStreetType}`]
          : []),
        ...(adressPayload.addressStreetName
          ? [`${adressPayload.addressStreetName}`]
          : [])
      ].join(' ');

      const rue4 = [].join(' ');

      const response = await apiModifyAddress(
        codeTiers,
        props.data.mail,
        rue1,
        rue2,
        rue3,
        rue4,
        additionalInfo.addressCountry,
        adressPayload.addressCity,
        props.data.cp,
        adressPayload.addressPostalCode.toString()
      );
      if (response.status == 200) {
        setDisplayAdressConfirmationModal(true);
      }
    }
  };

  const onSubmitOTPCode = async (otpCode) => {
    const rue1 = [
      ...(additionalInfo.addressFloor
        ? [`ETG ${additionalInfo.addressFloor}`]
        : []),
      ...(additionalInfo.addressCorridor
        ? [`CLR ${additionalInfo.addressCorridor}`]
        : []),
      ...(additionalInfo.addressStair
        ? [`ESC ${additionalInfo.addressStair}`]
        : [])
    ].join(' ');

    const rue2 = [
      ...(additionalInfo.addressHall
        ? [`ENT ${additionalInfo.addressHall}`]
        : []),
      ...(additionalInfo.addressProperty
        ? [`BAT ${additionalInfo.addressProperty}`]
        : []),
      ...(additionalInfo.addressBuilding
        ? [`IMM ${additionalInfo.addressBuilding}`]
        : []),
      ...(additionalInfo.addressResidence
        ? [`RES ${additionalInfo.addressResidence}`]
        : [])
    ].join(' ');

    const rue3 = [
      ...(adressPayload.addressNumber
        ? [`${adressPayload.addressNumber}`]
        : []),
      ...(adressPayload.addressStreetType
        ? [`${adressPayload.addressStreetType}`]
        : []),
      ...(adressPayload.addressStreetName
        ? [`${adressPayload.addressStreetName}`]
        : [])
    ].join(' ');

    const rue4 = [].join(' ');

    const response = await apiConfirmUpdateAddress(
      codeTiers,
      props.data.mail,
      rue1,
      rue2,
      rue3,
      rue4,
      additionalInfo.addressCountry,
      adressPayload.addressCity,
      props.data.cp,
      adressPayload.addressPostalCode.toString(),
      otpCode
    );
    if (response.status == 200) {
      setValidationCodeSuccess(true);
      resetForm();
    } else {
      console.log('erreur', response);
      setErrorValidationCode([response.errors?.map?.((i) => i.errorMessage)]);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth({ innerWidth: window.innerWidth });
    });
    getStreetTypeList(token);
  }, []);

  return (
    <div className="template-scroll profile-container">
      <div style={{ overflowY: 'scroll', height: '90%' }}>
        <div className="container-title-send-doc">
          <Icon
            icon="SortTop"
            size="20px"
            className="icon-return-2"
            onClick={props.onIconClick}
          />
          <Text text={`Profil`} className="title-page" />
        </div>
        <div className="first-line-container-profile mt-16">
          <div className="item-col-profile item-col-profile-margin-left">
            <div className="col-profile input-profile-container__edit-width">
              <Text
                text="Informations personnelles"
                className="profile-sub-title"
              />
            </div>
          </div>
          {windowWidth.innerWidth > 786 && (
            <div className="item-col-profile item-col-profile-margin-right input-profile-container__edit-width-display ">
              <div className="ml-a">
                <Button
                  text="Ajouter un bénéficiaire"
                  className="btn-beneficier-profile"
                  boxShadow
                  onClick={() =>
                    props.navigationSteps('ADD_BENEFICIAIRE_STEP2')
                  }
                  color={COLOR_BUTTON.GREEN}
                />
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="mb-10">
            <InputProfileOrganism
              value={props.data.title}
              onChange={(value) => props.onChange(value, 'name')}
              classNameContainer="profile-title-container"
              classNameInput="profile-title-text"
            />
          </div>
          <div className="first-line-container-profile">
            <div className="item-col-profile item-col-profile-margin-left">
              <div className="col-profile">
                <InputProfileOrganism
                  editable
                  label={'Nom'}
                  value={props.data.lastName}
                  onChange={(value) => props.onChange(value, 'lastName')}
                  handelEditMode={(value) => props.handelEditLastname(value)}
                  edit={props.editLastname}
                />
              </div>
            </div>
            <div className="item-col-profile item-col-profile-margin-right">
              <InputProfileOrganism
                editable
                label={'Prénom'}
                value={props.data.name}
                onChange={(value) => props.onChange(value, 'name')}
                handelEditMode={(value) => props.handelEditName(value)}
                edit={props.editName}
              />
            </div>
          </div>
          <div className="second-line-container-profile">
            <div className="col-profile second-items-container-profile">
              <div className="col-input-container-profile item-col-profile item-col-profile-margin-left">
                <InputProfileOrganism
                  label={'Date de naissance'}
                  as="calendar"
                  value={props.data.birthDate ? props.data.birthDate : ''}
                  editable
                  onChange={(value) => props.onChange(value, 'birthDate')}
                  handelEditMode={(value) => props.handelEditBirthdate(value)}
                  edit={props.editBirthdate}
                />
                {props.currentField === 'birthdate' && (
                  <Text
                    text=" Attention, si vous modifiez la date de naissance, n’oubliez
                  pas de vérifier également le numéro de Sécurité Sociale."
                    className="notice-uptate-field"
                  ></Text>
                )}
              </div>
              <div className="col-input-container-profile item-col-profile item-col-profile-margin-right">
                <InputProfileOrganism
                  label={'Téléphone'}
                  value={props.data.tel}
                  onChange={(value) => props.onChange(value, 'tel')}
                  {...(props.editMail === false &&
                    props.editAdress === false && { edit: props.editTel })}
                  handelEditMode={(value) => props.handelEditTel(value)}
                />
              </div>
              {windowWidth.innerWidth > 768 && (
                <div className="pt-15">
                  <InputProfileOrganism
                    editable
                    label={'N°Sécurité Sociale'}
                    edit={props.editNumeroSecuriteSociale}
                    handelEditMode={(value) =>
                      props.handelEditNumeroSecuriteSociale(value)
                    }
                    value={props.data.numeroSecuriteSociale}
                    onChange={(value) =>
                      props.onChange(value, 'numeroSecuriteSociale')
                    }
                  />
                  {props.currentField === 'numsec' && (
                    <Text
                      text="Attention, si vous modifiez le numéro de Sécurité Sociale,
                  n’oubliez pas de vérifier également la date de naissance."
                      className="notice-uptate-field"
                    ></Text>
                  )}
                </div>
              )}

              {(props.editMail ||
                props.editTel ||
                props.editBirthdate ||
                props.editName ||
                props.editLastname ||
                props.editNumeroSecuriteSociale) &&
                windowWidth.innerWidth > 768 && (
                  <div className="col-input-container-profile item-col-profile item-col-profile-margin-left">
                    <div className="btn-submit-container-edit-tel">
                      <Button
                        text="Valider les modifications"
                        className="btn-submit-edit-profile"
                        onClick={() => props.showModal()}
                        color={COLOR_BUTTON.GREEN}
                      />
                    </div>
                  </div>
                )}
            </div>

            <div>
              <div className="col-input-container-profile">
                <InputProfileOrganism
                  label={'Email'}
                  value={props.data.mail}
                  onChange={(value) => props.onChange(value, 'mail')}
                  {...(props.editAdress === false &&
                    props.editTel === false && { edit: props.editMail })}
                  handelEditMode={(value) => props.handelEditMail(value)}
                />
              </div>
              <div className="col-input-container-profile">
                <InputProfileOrganism
                  {...(!props.manuelAddressEdit && { editable: true })}
                  label={'Adresse'}
                  {...(props.editMail === false &&
                    props.editTel === false && { edit: props.editAdress })}
                  value={props.data.adresse}
                  onChange={(value) => props.onChange(value, 'adresse')}
                  {...(!props.manuelAddressEdit && { autoComplete: true })}
                  handelEditMode={(value) => props.handelEditAdresse(value)}
                  autoComplete
                  as={'adresse'}
                  autoCompleteOption={props.optionAdressData}
                  showDropDown={props.showDropDown}
                  autoCompleteOptionClicked={props.autoCompleteOptionClicked}
                  autoCompleteClickAway={props.autoCompleteClickAway}
                  edit={props.editAdress}
                />
              </div>
              <div className="item-col-profile item-col-profile-margin-right col-input-container-profile">
                {props.manuelAddressEdit && (
                  <div className="ville-cp-input-container">
                    <div className="item-col-profile item-col-profile-margin-left">
                      <InputProfileOrganism
                        edit={true}
                        label={'Code postal'}
                        value={props.data.cp}
                        onChange={(value) => props.onChange(value, 'cp')}
                        {...(windowWidth.innerWidth > 768 && {
                          classNameContainer: 'input-ville-profile-container'
                        })}
                        {...(windowWidth.innerWidth > 768 && {
                          classNameInput: 'input-ville-profile'
                        })}
                      />
                    </div>
                    <div className="item-col-profile item-col-profile-margin-right">
                      <InputProfileOrganism
                        edit={true}
                        label={'Ville'}
                        value={props.data.ville}
                        onChange={(value) => props.onChange(value, 'ville')}
                        {...(windowWidth.innerWidth > 768 && {
                          classNameContainer: 'input-ville-profile-container'
                        })}
                        {...(windowWidth.innerWidth > 768 && {
                          classNameInput: 'input-ville-profile'
                        })}
                      />
                    </div>
                  </div>
                )}
                {props.editAdress && !props.editMail && !props.editTel && (
                  <div className="profile-adresse-form">
                    <Input
                      className="adress-input"
                      placeholder="Numéro *"
                      value={adressPayload.addressNumber}
                      id="addressNumber"
                      onChange={onChange}
                    />
                    <div className="adress-input-type-voie">
                      <Input
                        className="adress-input"
                        placeholder="Type de voie *"
                        value={adressPayload.addressStreetType}
                        id="addressStreetType"
                        onChange={onChangeTypeVoie}
                      />
                    </div>
                    {streetList &&
                      displayListStreet &&
                      adressPayload.addressStreetType.length >= 3 && (
                        <div className="street-list-container">
                          {streetList
                            .filter((item) =>
                              item.libelle.includes(
                                adressPayload.addressStreetType.toLocaleUpperCase()
                              )
                            )
                            .map((voie) => {
                              return (
                                <div
                                  className="voice-item"
                                  key={voie.abreviation}
                                  onClick={() => onSelectItem(voie.libelle)}
                                >
                                  {voie.libelle}
                                </div>
                              );
                            })}
                        </div>
                      )}
                    <Input
                      className="adress-input"
                      placeholder="Libellé *"
                      value={adressPayload.addressStreetName}
                      id="addressStreetName"
                      onChange={onChange}
                    />

                    <div className="adress-input-type-voie">
                      <Input
                        className="adress-input"
                        placeholder="Code postal *"
                        value={adressPayload.addressPostalCode}
                        id="addressPostalCode"
                        type="number"
                        onChange={onChangeCodePostal}
                      />

                      {codePostalList.length > 0 &&
                        displayPostalCode &&
                        adressPayload.addressPostalCode.length >= 3 && (
                          <div className="street-list-container">
                            {codePostalList
                              ?.filter((item) =>
                                item.pc
                                  .toString()
                                  .includes(adressPayload.addressPostalCode)
                              )
                              .map((code, index) => {
                                return (
                                  <div
                                    className="voice-item"
                                    key={index}
                                    onClick={() => onSelectCodePostal(code)}
                                  >
                                    {code.pc} {code.city}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                    </div>
                    <Input
                      className="adress-input"
                      placeholder="Ville *"
                      value={adressPayload.addressCity}
                      id="addressCity"
                      readOnly={true}
                      onChange={onChange}
                    />
                    {displayMore && (
                      <div className="display-more-container">
                        <Input
                          className="adress-input"
                          placeholder="Etage"
                          id="addressFloor"
                          value={additionalInfo.addressFloor}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Couloir"
                          id="addressCorridor"
                          value={additionalInfo.addressCorridor}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Escalier"
                          id="addressStair"
                          value={additionalInfo.addressStair}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Entrée"
                          id="addressHall"
                          value={additionalInfo.addressHall}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Bâtiment"
                          id="addressProperty"
                          value={additionalInfo.addressProperty}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Immeuble"
                          id="addressBuilding"
                          value={additionalInfo.addressBuilding}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Résidence"
                          id="addressResidence"
                          value={additionalInfo.addressResidence}
                          onChange={onChangeAdditionalInfo}
                        />
                        <Input
                          className="adress-input"
                          placeholder="Pays"
                          id="addressCountry"
                          value={additionalInfo.addressCountry}
                          onChange={onChangeAdditionalInfo}
                        />
                      </div>
                    )}

                    {error ? <p className="errorMessage">{error}</p> : null}

                    <div className="item-col-profile item-col-profile-margin-right input-profile-container__edit-width-display ">
                      <div className="ml-a">
                        <a
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontWeight: 'bold'
                          }}
                          onClick={() => {
                            setDisplayMore(!displayMore);
                          }}
                        >
                          {displayMore ? 'Afficher moins' : 'Afficher plus'}
                        </a>
                      </div>
                    </div>

                    <div>
                      <div className="btn-commit-edit-adresse-profile-container">
                        <Button
                          text="Annuler"
                          className="btn-submit-edit-adresse-profile"
                          onClick={resetForm}
                          color={COLOR_BUTTON.GREEN}
                          isAlreadyOutline={true}
                        />
                        <Button
                          text="Valider"
                          className="btn-cancel-edit-adresse-profile"
                          onClick={submitAdressForm}
                          color={COLOR_BUTTON.GREEN}
                        />
                      </div>
                    </div>

                    {displayAdressConfirmationModal && (
                      <ModalConfirmAdress
                        show={displayAdressConfirmationModal}
                        onHide={() => setDisplayAdressConfirmationModal(false)}
                        errorMessages={errorValidationCode}
                        modalStep={props.verificationSteps}
                        success={validationCodeSuccess}
                        title="Validation des modifications"
                        firstText={`Nous venons d’envoyer un code par  email à l’adresse  ${props.data.mail}`}
                        secondText="Veuillez le renseigner ci-dessous pour confirmer les modifications :"
                        onConfirmCode={() => onSubmitOTPCode(props.code)}
                        onCancel={() =>
                          setDisplayAdressConfirmationModal(false)
                        }
                        successText="Vos modifications ont été enregistrées."
                        classNameBtncancel="btn-cancel-modal-edit-profile"
                        classNameBtnSubmit="btn-submit-modal-edit-profile"
                        classNameInput="input-modal-edit-profile"
                        onChange={(e) => props.onChangeCode(e.target.value)}
                        code={props.code}
                      />
                    )}
                  </div>
                )}
                {windowWidth.innerWidth <= 768 && (
                  <div className={props.editAdress ? 'mt-15' : ''}>
                    <InputProfileOrganism
                      label={'N°Sécurité Sociale'}
                      value={props.data.numeroSecuriteSociale}
                      edit={props.editNumeroSecuriteSociale}
                      handelEditMode={(value) =>
                        props.handelEditNumeroSecuriteSociale(value)
                      }
                      onChange={(value) =>
                        props.onChange(value, 'numeroSecuriteSociale')
                      }
                    />
                    {props.currentField === 'numsec' && (
                      <Text
                        text="Attention, si vous modifiez le numéro de Sécurité Sociale,
                  n’oubliez pas de vérifier également la date de naissance."
                        className="notice-uptate-field"
                      ></Text>
                    )}
                  </div>
                )}
                <div
                  className={
                    props.editAdress
                      ? 'profile-rattachement-container mt-15'
                      : 'profile-rattachement-container'
                  }
                >
                  <InputProfileOrganism
                    label={'Organisme de rattachement'}
                    value={props.data.rattachement}
                    onChange={(value) => props.onChange(value, 'rattachement')}
                  />
                </div>
                {(props.editAdress ||
                  (props.editTel && windowWidth.innerWidth <= 768) ||
                  (props.editMail && windowWidth.innerWidth <= 768)) && (
                  <div className="btn-commit-edit-adresse-profile-big-container">
                    <div className="btn-commit-edit-adresse-profile-container">
                      <Button
                        text="Valider"
                        className="btn-submit-edit-adresse-profile"
                        onClick={() => props.showModal()}
                      />
                      <Button
                        text="Annuler"
                        className="btn-cancel-edit-adresse-profile"
                        onClick={() => props.handelEditAdresse(false)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="first-line-container-profile mt-30">
          <div className="item-col-profile item-col-profile-margin-left">
            <div className="col-profile input-profile-container__edit-width">
              <Text
                text="Informations bancaires"
                className="profile-sub-title"
              />
            </div>
          </div>
          <div className="item-col-profile item-col-profile-margin-right input-profile-container__edit-width-display ">
            <div className="ml-a">
              <a
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontWeight: 'bold'
                }}
                onClick={() => setShowBankingTypeModal(true)}
              >
                Modifier
              </a>
            </div>
          </div>
        </div>

        <div className="row-profile">
          <div className="line-edit-bank-profile">
            <div className="col-profile">
              <div className="col-input-container-profile">
                <InputProfileOrganism
                  label={'IBAN'}
                  value={props.data.iban}
                  edit={props.editBank}
                  onChange={(value) => props.onChange(value, 'iban')}
                />
              </div>
            </div>
            <div>
              <div className="col-input-container-profile">
                <InputProfileOrganism
                  label={'BIC'}
                  value={props.data.bic}
                  edit={props.editBank}
                  onChange={(value) => props.onChange(value, 'bic')}
                />
              </div>
            </div>
          </div>
          <div className="line-edit-bank-profile">
            <div className="col-profile">
              {props.editBank && (
                <Upload
                  handleFileChange={props.selectFile}
                  contentClassName="upload-file-add-beneficiaire-profile-input"
                  classNameText="upload-text-add-beneficiaire-profile"
                  {...(windowWidth.innerWidth > 768 && {
                    child: (
                      <div className="btn-upload-container-profile-edit-bank">
                        <div className="upload-doc-adresse-profile">
                          <Text
                            text="Veuillez joindre un RIB (obligatoire)"
                            className="placeholder-upload-doc-profile"
                          />
                          <Icon icon="Plus" size={14} />
                        </div>
                      </div>
                    )
                  })}
                />
              )}
            </div>
          </div>
        </div>

        {showBankingTypeModal && (
          <ModalBankingTye
            show={showBankingTypeModal}
            onHide={() => setShowBankingTypeModal(false)}
          />
        )}
        {props.showConfirmationModal && (
          <ConfirmPasswordModal
            show={props.showConfirmationModal}
            onHide={props.onHide}
            errorMessages={props.validationCodeErrors}
            modalStep={props.verificationSteps}
            success={props.validationCodeSuccess}
            title="Validation des modifications"
            firstText={`Nous venons d’envoyer un code par  email à l’adresse  ${props.data.mail}`}
            secondText="Veuillez le renseigner ci-dessous pour confirmer les modifications :"
            onConfirmCode={() => props.onConfirmCode()}
            onCancel={() => props.onHide()}
            successText="Vos modifications ont été enregistrées."
            classNameBtncancel="btn-cancel-modal-edit-profile"
            classNameBtnSubmit="btn-submit-modal-edit-profile"
            classNameInput="input-modal-edit-profile"
            onChange={(e) => props.onChangeCode(e.target.value)}
            code={props.code}
          />
        )}
      </div>
    </div>
  );
}

export default TemplateProfileScreen2;
