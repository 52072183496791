import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Text } from '../../Atoms';
import PageTitle from '../../Components/Dashboard/PageTitle';
function TemplateListDocument (props) {
  return (
    <div className='template-document template-send-document template-scroll'>
      <PageTitle canGoBack={false} titleText="Mes documents"/>
      {/* <Text text='Mes documents' className='title-document title-page' /> */}
      <Text
        text='Sélectionnez le type de documents que vous souhaitez visualiser ou télécharger :'
        type='h6'
        className='list-document text-default-size text-default-font'
      />
      <Row>
      <Col className='container-card-document scale-up-center' md={4} xs={6}>
          <Card
            text='Envoyer un document'
            onClick={() => props.onChangeRoute('documents/send')}
          />
        </Col>
        <Col className='container-card-document scale-up-center' md={4} xs={6}>
          <Card
            text='Contrats'
            onClick={() => props.onChangeRoute('contracts')}
          />
        </Col>
        <Col className='container-card-document scale-up-center' md={4} xs={6}>
          <Card
            text='Documents entreprise'
            onClick={() => props.onChangeRoute('DocumentEntreprise')}
          />
        </Col>
        <Col className='container-card-document scale-up-center' md={4} xs={6}>
          <Card
            text='Garanties'
            onClick={() => props.onChangeRoute('garanties')}
          />
        </Col>
        <Col className='container-card-document scale-up-center' md={4} xs={6}>
          <Card
            text='Ma bibliothèque'
            onClick={() => props.onChangeRoute('bibliotheque')}
          />
        </Col>
        <Col className='container-card-document scale-up-center' md={4} xs={6}>
          <Card
            text='Documents envoyés'
            onClick={() => props.onChangeRoute('contact', {status: "Mes demandes"}, true)}
          />
        </Col>
      </Row>
    </div>
  );
}

TemplateListDocument.defaultProps = {
  onChangeRoute: null
};
export default TemplateListDocument;
