import React, { Component } from 'react';
import {
  apiConsumptions,
  apiRepayments,
  apiRepaymentsDocument
} from '../../Api/Repayment/Repayment';
import { RemboursementTemplate } from './../../Templates/index';
import { connect } from 'react-redux';
import { COLOR_TABLE, REMBOURSEMENT_LIMIT } from '../../Config/Config';
import * as moment from 'moment';
import getMemberTypeCode from '../../Utils/getMemberTypeCode';
import { CONSUMPTIONTYPE } from '../../Config/enum';
const EmptyRemboursementText =
  "Vous n'avez pas de remboursement sur la période sélectionnée.";
class Remboursement extends Component {
  constructor(props) {
    super(props);
    const twoYearsAgo = moment().subtract(2, 'year');
    const twoYearsAgoDate = new Date(twoYearsAgo);
    this.state = {
      beginDate:
        this.props.location.state == null
          ? twoYearsAgoDate
          : this.props.location.state.beginDate, // modif
      endDate:
        this.props.location.state == null
          ? new Date()
          : this.props.location.state.endDate, // modif
      status:
        this.props.location.state == null
          ? 'remboursement'
          : this.props.location.state.status,
      carteTpCheck: true,
      selectData: {
        annual: [
          { text: 'Cette année', value: 'Cette année' },
          { text: 'L’année dernière', value: 'L’année dernière' }
        ],
        vision: [
          { text: 'Vision globale', value: CONSUMPTIONTYPE.GLOBAL, index: 0 },
          { text: 'Par bénéficiaire', value: CONSUMPTIONTYPE.BENEF, index: 2 },
          { text: 'Par poste', value: CONSUMPTIONTYPE.POSTE, index: 1 }
        ]
      },
      annual: 'Cette année',
      vision: CONSUMPTIONTYPE.GLOBAL,
      seriesVisionGlobal: [],
      seriesBeneficiary: [],
      seriesPoste: [],
      remboursement: [],
      MessageRemboursement: '',
      skip: 0,
      limit: REMBOURSEMENT_LIMIT,
      consumtion: null,
      total: 0
    };
  }

  getRemboursementData = async (skip, limit, remboursement = []) => {
    if (this.state.beginDate != null && this.state.endDate != null) {
      const { data, status, total } = await apiRepayments(
        this.props.user?.codeTiers, // modif
        getMemberTypeCode(this.props.user?.memberType),
        new Date(this.state.beginDate).toISOString(),
        new Date(this.state.endDate).toISOString(),
        // moment(this.state.beginDate).format('MM/DD/YYYY'),
        // moment(this.state.endDate).format('MM/DD/YYYY'),
        skip * limit,
        skip * limit + limit,
        this.state.carteTpCheck,
        this.props.user?.goodCodeTiers?.toString()
      );
      if (status == 200) {
        this.setState(
          {
            remboursement: data,
            skip: skip + 1,
            total: total
          },
          () => {
            if (this.state.remboursement.length == 0) {
              this.setState({
                MessageRemboursement: EmptyRemboursementText
              });
            } else {
              this.setState({
                MessageRemboursement: ''
              });
            }
          }
        );
      } else {
        this.setState({
          MessageRemboursement: EmptyRemboursementText
        });
      }
    }
  };

  downloadRepaymentFile = async (
    skip,
    limit,
    fileNumber,
    remboursement = []
  ) => {
    if (this.state.beginDate != null && this.state.endDate != null) {
      const { file, status } = await apiRepaymentsDocument(
        this.props.user.goodCodeTiers?.toString(),
        this.state.carteTpCheck,
        moment(this.state.beginDate),
        moment(this.state.endDate)
      );
      if (status == 200) {
        const { url } = await this.createBlob(file);
        if (url) {
          const download = document.createElement('a');
          download.setAttribute('href', url);
          download.setAttribute('target', '_blank');
          download.click();
        }
      }
    }
  };

  createBlob = async (data) => {
    try {
      const blob = await (
        await fetch(`data:application/pdf;base64,${data}`)
      ).blob();
      const url = URL.createObjectURL(blob);
      return { blob, url };
    } catch (err) {}
  };

  getAllRemboursement = async (skip, limit) => {
    const { total, data, status } = await apiRepayments(
      this.props.user?.memberNumber, // modif
      getMemberTypeCode(this.props.user?.memberType),
      moment(this.state.beginDate).format('MM/DD/YYYY'),
      moment(this.state.endDate).format('MM/DD/YYYY'),
      skip * limit,
      skip * limit + limit,
      this.state.carteTpCheck
    );

    let restElement = 0;
    let nextData = [];
    let nextStatus = 400;
    if (total >= this.state.limit) {
      restElement = total - this.state.limit;
    }
    if (restElement != 0) {
      const { data, status } = await apiRepayments(
        this.props.user?.memberNumber, // modif
        getMemberTypeCode(this.props.user?.memberType),
        moment(this.state.beginDate).format('MM/DD/YYYY'),
        moment(this.state.endDate).format('MM/DD/YYYY'),
        limit,
        total,
        this.state.carteTpCheck
      );
      nextData = data;
      nextStatus = status;
    } else {
      nextStatus = 200;
    }
    if (status == 200 && nextStatus == 200) {
      this.setState(
        {
          remboursement: [...data, ...nextData],
          beginDate: null,
          endDate: null,
          skip: 0
        },
        () => {
          if (this.state.remboursement.length == 0) {
            this.setState({
              MessageRemboursement: EmptyRemboursementText
            });
          } else {
            this.setState({
              MessageRemboursement: ''
            });
          }
        }
      );
    }
  };

  getConsumption = async (
    beginDate,
    endDate,
    consumptionType = CONSUMPTIONTYPE.GLOBAL
  ) => {
    const restGlobal = await apiConsumptions(
      this.props.user.goodCodeTiers?.toString(),
      consumptionType,
      moment(beginDate, 'MM/DD/YYYY'),
      moment(endDate, 'MM/DD/YYYY')
    );

    if (restGlobal.status == 200) {
      this.setState({
        seriesVisionGlobal: [
          {
            legend: 'Remboursement CGRM',
            data: restGlobal.rbcgrm,
            color: COLOR_TABLE[0]
          },
          {
            legend: 'Sécurité sociale',
            data: restGlobal.rbss,
            color: COLOR_TABLE[1]
          },
          {
            legend: 'Reste à charge',
            data: restGlobal.rac,
            color: COLOR_TABLE[2]
          }
        ],
        seriesBeneficiary: restGlobal?.beneficiaires?.map((element, index) => {
          return {
            legend: `${element.nom} ${element.prenom}`,
            data: element.rbcgrm,
            color: COLOR_TABLE[index],
            ...element
          };
        }),
        seriesPoste: restGlobal?.postes?.map((element, index) => {
          const { libPoste, ...rest } = element;
          return {
            serie: libPoste,
            data: [
              {
                legend: 'Remboursement CGRM',
                data: rest.rbcgrm,
                color: COLOR_TABLE[0]
              },
              {
                legend: 'Sécurité sociale',
                data: rest.rbss,
                color: COLOR_TABLE[1]
              },
              {
                legend: 'Reste à charge',
                data: rest.rac,
                color: COLOR_TABLE[2]
              }
            ]
          };
        })
      });
    }
  };

  isValidFetch = () => {
    return (
      // this.state.beginDate &&
      this.props.user?.codeTiers
    );
  };

  getDate = (fullYear) => {
    // modif
    // return {
    //   beginDate: `01/01/2012`,
    //   endDate: `12/31/2012`
    // };
    return {
      beginDate: `01/01/${fullYear}`,
      endDate: `12/31/${fullYear}`
    };
  };

  componentDidMount() {
    const { beginDate, endDate } = this.getDate(new Date().getFullYear());
    this.getConsumption(beginDate, endDate);
    if (this.isValidFetch()) {
      this.getRemboursementData(0, this.state.limit, this.state.remboursement);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.beginDate !== this.state.beginDate ||
        prevState.endDate !== this.state.endDate ||
        prevState.carteTpCheck !== this.state.carteTpCheck) &&
      this.isValidFetch()
    ) {
      this.getRemboursementData(0, this.state.limit, []);
    }
  }

  viewClicked = () => {
    this.setState({
      beginDate: new Date('01/01/2012'),
      endDate: new Date()
    });
    // this.getAllRemboursement(0, this.state.limit);
  };

  datePickerChanged = (key, value) => {
    this.setState({
      [key]: new Date(value),
      remboursement: []
    });
  };

  downloadDocument = (fileNumber) => {
    this.downloadRepaymentFile(0, this.state.limit, fileNumber);
  };

  statusChanged = (value) => {
    this.setState({
      status: value
    });

    if (value == 'consomation') {
      this.props.history.push('/consommation');
    } else if (value == 'remboursement') {
      this.props.history.push('/remboursement');
    }
  };

  selectFilterChange = (value, key) => {
    if (key == 'annual') {
      let beginDateFetch = '';
      let endDateFetch = '';
      if (value == 'Cette année') {
        const { beginDate, endDate } = this.getDate(new Date().getFullYear());
        beginDateFetch = beginDate;
        endDateFetch = endDate;
      } else {
        const { beginDate, endDate } = this.getDate(
          new Date().getFullYear() - 1
        );
        beginDateFetch = beginDate;
        endDateFetch = endDate;
      }

      this.getConsumption(beginDateFetch, endDateFetch, this.state.vision);
    } else {
      let beginDateFetch = '';
      let endDateFetch = '';
      if (this.state.annual == 'Cette année') {
        const { beginDate, endDate } = this.getDate(new Date().getFullYear());
        beginDateFetch = beginDate;
        endDateFetch = endDate;
      } else {
        const { beginDate, endDate } = this.getDate(
          new Date().getFullYear() - 1
        );
        beginDateFetch = beginDate;
        endDateFetch = endDate;
      }
      this.getConsumption(beginDateFetch, endDateFetch, value);
    }

    this.setState({
      [key]: value
    });
  };

  carteTpChecked = (value) => {
    this.setState({
      carteTpCheck: value
    });
  };

  scroll = (e) => {
    const bottom =
      ~~e.target.scrollHeight -
        ~~e.target.scrollTop -
        ~~e.target.clientHeight <=
      1;
    if (
      bottom &&
      this.isValidFetch() &&
      this.state.total > this.state.remboursement.length
    ) {
      this.getRemboursementData(
        this.state.skip,
        this.state.limit,
        this.state.remboursement
      );
    }
  };

  render() {
    return (
      <RemboursementTemplate
        beginDate={this.state.beginDate}
        endDate={this.state.endDate}
        datePickerChanged={this.datePickerChanged}
        downloadDocument={this.downloadDocument}
        status={this.state.status}
        statusChanged={this.statusChanged}
        selectData={this.state.selectData}
        selectFilterChange={this.selectFilterChange}
        annual={this.state.annual}
        vision={this.state.vision}
        seriesVisionGlobal={this.state.seriesVisionGlobal}
        seriesBeneficiary={this.state.seriesBeneficiary}
        seriesPoste={this.state.seriesPoste}
        carteTpCheck={this.state.carteTpCheck}
        carteTpChecked={this.carteTpChecked}
        remboursement={this.state.remboursement}
        MessageRemboursement={this.state.MessageRemboursement}
        viewClicked={this.viewClicked}
        scroll={this.scroll}
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
const mapStateToProps = (state) => ({
  user: state.auth.user
});
export default connect(mapStateToProps, mapDispatchToProps)(Remboursement);
