import React from "react"
import { Modal } from "../../Molecules"
import { Icon, Text } from "../../Atoms"
export default function ModalInfoDocument(props) {
    return <Modal show={props.show} onHide={props.onHide} showIconClose={false} contentClassName="centent-modal-info-document">
        <Icon icon="Plus" className="close-modal-info" size="12px" onClick={props.onHide} />
        <Text text="Retrouvez ces informations sur votre facture : " className="info-modal-doc text-bold" />
        <Text text="- N° ADELI : au niveau des coordonnées de votre professionnel de santé" className="info-modal-doc" />
        <Text text="- N°SIRET : tout en bas de la facture" className="info-modal-doc" />
    </Modal>
}
