import React from "react"
import { Icon, Text } from "../../Atoms"
import { blue } from "../../Config/Config"
function OrganismDocumentSended(props) {
    function contaienrClassName() {
        const className = ['container-document-sended']
        if (props.contaienrClassName) {
            className.push(props.contaienrClassName)
        }
        return className.join(' ')
    }
    function titleClassName() {
        const className = ['title-document-sended']
        if (props.titleClassName) {
            className.push(props.titleClassName)
        }
        return className.join(' ')
    }
    function dateClassName() {
        const className = ['date-document-sended']
        if (props.dateClassName) {
            className.push(props.dateClassName)
        }
        return className.join(' ')
    }
    function subTitleClassName() {
        const className = ['subtitle-document-sended']
        if (props.subTitleClassName) {
            className.push(props.subTitleClassName)
        }
        return className.join(' ')
    }
    return <div className={contaienrClassName()}>
        <div className="container-description-document-sended">
            <div className="container-title-description-document-sended">
                <Text text={props.date} className={dateClassName()} loader={false} />
                <Text text={props.title} className={titleClassName()} loader={false} />
            </div>
            <Text text={props.subTitle} className={subTitleClassName()} loader={false} />
        </div>
        <div className="container-icon-document-seneded">
            <Icon icon="Eyes" size="17px" className="cursor" onClick={props.onOpen} />
            <Icon icon="Telechargement" size="17px" color={blue} className="cursor" onClick={props.onDownload} />
        </div>
    </div>
}
OrganismDocumentSended.defaultProps = {
    title: "",
    subTitle: "",
    date: "",
    onDownload: null,
    onOpen: null,
    contaienrClassName: null,
    dateClassName: null,
    titleClassName: null,
    subTitleClassName: null
}
export default OrganismDocumentSended;
