import React, { Component } from 'react';
import { Icon } from '..';
import PropTypes from 'prop-types';
AtomStatus.PropTypes = {
  icon: PropTypes.bool,
  type: PropTypes.oneOf(['warning', 'erronous', 'success', 'error', 'info']),
  text: PropTypes.string,
  className: PropTypes.string
};
AtomStatus.defaultProps = {
  icon: false,
  type: 'warning',
  text: 'Statut',
  className: null
};
function AtomStatus(props) {
  let className = '';
  let icon = null;
  if (props.type === 'warning') {
    className += 'warning-status';
    icon = 'EnCours';
  }
  if (props.type === 'erronous') {
    className += 'warning-erronous';
    icon = 'CloseIcon';
  }
  if (props.type === 'success') {
    className += 'warning-success';
    icon = 'Valid';
  }
  if (props.type === 'error') {
    className += 'warning-error';
    icon = 'CloseIcon';
  }
  if (props.type === 'info') {
    className += 'warning-info';
    icon = 'Valid';
  }
  if (props.className) {
    className += " " + props.className
  }
  function viewIcon() {
    if (props.icon) {
      return (
        <Icon icon={icon} size={'12px'} style={{ marginRight: '5.5px' }} />
      );
    }
    return null;
  }
  return (
    <div className={className}>
      {viewIcon()}
      <span>{props.text}</span>
    </div>
  );
}

export default AtomStatus;
