import React from 'react'
import { Button, Icon, Text } from '../../Atoms'
import { Modal } from "../../Molecules"
function ModalSuccessContact(props) {
    return <Modal
        show={props.show}
        onHide={props.onHide}
        showIconClose={false}
        contentClassName={"modal-success-contact"}
    >
        <Icon icon="BigX" className="close-modal-contact" size="20px" onClick={props.onHide} />
        <Text text="Message reçu 5 sur 5" className="text-modal-contact" />
        <Text text="Comptez sur nous pour vous répondre le plus vite possible !" className="text-modal-contact sub-text-modal-contact" />
        <Button onClick={props.onHide} text="Termnier" className="btn-modal-contact" />
    </Modal>
}
ModalSuccessContact.defaultProps = {
    show: false,
    onHide: null
}
export default ModalSuccessContact
