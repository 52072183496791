import React, { useEffect, useState } from 'react';
import { Button, Img, Text } from '../../Atoms';
import PageTitle from './../../Components/Dashboard/PageTitle';
import HospitalizationImage from '../../assets/img/Hospitalization_image.png';
import { apiPost } from '../../Api/standardApi';
import { useSelector } from 'react-redux';
import { COLOR_BUTTON } from '../../Config/Config';

function TemplateHospitalisationStep0(props) {
  const user = useSelector((state) => state.auth.user);  
  const [responseApi, setResponseApi] = useState(null); 
  const apiSendContact = async (body) => {
    const reponse = await apiPost(
      '/Contact/SendMedicalCareDemand',
      JSON.stringify(body),
      '',
      'application/json'
    );
    
    if(reponse.status == 410){
      setResponseApi(reponse.errors[0].errorMessage);
    }
}
useEffect(()=>{
      const body ={
          "numeroFax": "01 23 45 67 89",
          "telephone": "01 23 45 67 89",
          "admissionDate": "2024-02-12T11:48:17.928Z",
          "admissionDuration": 0,
          "admissionCause": "cause admission",
          "hospitalizedPerson": {
            "name": user.lastName,
            "firstName": user.firstName,
            "type": "HOPITAL",
            "socialSecurityNumber": "1 99 12 34 567 890 12",
            "codeTiers": user.codeTiers
          },
          "hospitalName": "Hôpital Pitié-Salpêtrière"
      }

     apiSendContact(body)
  },[])

  const goTo = ()=>{
    window.open("https://esante.gouv.fr/roc", "_blank")
  }

  return (
    <div className='template-scroll profile-container'>
      <PageTitle titleText={'Hospitalisation'} canGoBack={false} />
      {responseApi &&(
        <div className='cartetp-organism-container'>
          <div className='hospitalization-organism-box'>
            <p className='text-default-size text-default-font'>{responseApi}</p>
            <Button
              onClick={goTo}
              text='En savoir plus'
              className='hospitalization-organism-button cartetp-organism-button__partage-margin'
              color={COLOR_BUTTON.ORANGE}
            />
          </div>
          <div className='cartetp-organism-img-container'>
            <Img src={HospitalizationImage} className='cartetp-organism-img' />
          </div>
        </div>
      )}
    </div>
  );
}

export default TemplateHospitalisationStep0;
