export const MENU = [
  {
    name: 'Documents',
    path: '/documents',
    icon: 'Document'
  },
  {
    name: 'Garanties',
    path: '/Garanties',
    icon: 'Garantie'
  },
  {
    name: 'Remboursements',
    path: '/remboursement',
    icon: 'Remboursement'
  },
  {
    name: 'Hospitalisation',
    path: '/hospitalisation',
    icon: 'Hospitalisation'
  },
  {
    name: 'Service de santé',
    path: '/healthservice',
    icon: 'ServiceSante'
  },
  {
    name: 'Géolocalisation',
    path: '/geolocation/select',
    icon: 'Geolocation'
  },
  // {
  //   name: 'Partenaires',
  //   path: '/partenaire',
  //   icon: 'Partenaire'
  // },
  {
    name: 'Réglages',
    path: '/setting',
    icon: 'Reglage'
  }
  // {
  //   type: 'SubMenu',
  //   name: 'Documents',
  //   path: '/',
  //   icon: 'TableauDeBordIcon',
  //   childrens: [
  //     {
  //       type: 'SubMenu',
  //       name: 'Lot',
  //       path: '/frererf',
  //       icon: 'TableauDeBordIcon',
  //       childrens: [
  //         {
  //           name: 'test2',
  //           path: '/fref',
  //           icon: 'TableauDeBordIcon'
  //         }
  //       ]
  //     }
  //   ]
  // },
];

export const MENUPREV = [
  {
    name: 'Documents',
    path: '/documents',
    icon: 'Document'
  },
  {
    name: 'Réglages',
    path: '/setting',
    icon: 'Reglage'
  }
];
