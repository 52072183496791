import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../Atoms';

MoleculeAffiliationRecapSummary.PropTypes = {
    title: PropTypes.string,
    data: PropTypes.string,
};
MoleculeAffiliationRecapSummary.defaultProps = {
    title: '',
    data: '',
};
function MoleculeAffiliationRecapSummary(props) {
    return (
        <div className="molecule-affiliation-recap-summary-container">
            <Text text={props.title} className="molecule-affiliation-recap-summary-title" />
            <Text text={props.data} className="molecule-affiliation-recap-summary-price" />
        </div>
    );
}

export default MoleculeAffiliationRecapSummary;
