import React, { useState } from 'react';
import { Input, Text, Icon } from '../../Atoms';
import PropTypes from 'prop-types';
import ClickAwayListener from 'react-click-away-listener';
const MoleculeInput = (props) => {
  const [visible, setVisibility] = useState(false);
  const [value, setValue] = useState();
  let className =
    props.as === 'input' || props.as === 'textarea'
      ? 'container-generic-input '
      : 'container-generic-select ';
  let inputClassName = props.className;

  if (props.classNameInputContainer) {
    className += props.classNameInputContainer;
  }
  if (props.icon) {
    inputClassName += ' padding-input-icon';
  }
  if (props.leftIcon) {
    inputClassName += ' padding-input-left-icon';
  }
  function viewLabel() {
    return props.inputLabel !== null ? (
      <Text className={props.labelClassName} text={props.inputLabel} />
    ) : null;
  }
  function viewErrorMsg() {
    if (props.errorMsg !== null) {
      return <Text className={props.errorMsgClassName} text={props.errorMsg} />;
    }
    return null;
  }
  function viewIcon(icon, className, size, style = {}) {
    if (icon !== null) {
      return (
        <Icon
          icon={icon}
          className={className}
          size={size}
          style={style}
          onClick={props.onClickIcon && props.onClickIcon}
        />
      );
    }
    return null;
  }
  function viewLeftIcon() {
    if (props.leftIcon !== null) {
      return (
        <div
          className={props.leftIconContainerClassName}
          onClick={props.leftIconContainerOnClick}
        >
          <Icon
            icon={props.leftIcon}
            className={props.leftIconClassName}
            size={props.size}
            style={props.style}
            onClick={props.onClickIcon && props.onClickIcon}
          />
        </div>
      );
    }
    return null;
  }
  function viewInput() {
    return (
      <div style={{ width: '100%' }}>
        {viewLeftIcon()}
        <Input
          as={props.as}
          disabled={props.disabled}
          isInvalid={props.isInvalid}
          isValid={props.isValid}
          className={inputClassName}
          onChange={props.onChange}
          readOnly={props.readOnly}
          value={props.value}
          bsPrefix={props.bsPrefix}
          id={props.id}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          feedback={props.feedback}
          feedbackTooltip={props.feedbackTooltip}
          type={props.type}
          heightSize={props.heightSize}
          password={props.password}
          onKeyPress={props.onKeyPress}
        />
        {viewIcon(props.icon, props.iconClassName, props.iconSize)}
      </div>
    );
  }
  function renderOption(text, key) {
    function onSelect(key) {
      props.onChange && props.onChange(props.options[key].value);
      setValue(props.options[key].value);
      setVisibility(false);
    }
    const optionsClassName = ['default-option-input-select'];
    if (props.optionsClassName) {
      optionsClassName.push(props.optionsClassName);
    }
    if (props.options[key].value == props.value) {
      optionsClassName.push('option-input-selected');
    }
    return (
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => onSelect(key)}
        className={
          props.options[key].value === props.value &&
          props.optionContainerClassName
        }
      >
        <Text
          className={optionsClassName.join(' ')}
          type="h3"
          text={text}
          key={key}
        />
      </div>
    );
  }
  function viewOptions() {
    const classNameContainer = ['container-option-input-select'];
    if (props.selectOptionContainerClassName) {
      classNameContainer.push(props.selectOptionContainerClassName);
    }
    return (
      <div className={classNameContainer.join(' ')}>
        {props.options &&
          props.options.map((el, i) => renderOption(el.text, i))}
      </div>
    );
  }
  function returnValue() {
    if (props.value) {
      if (props.as === 'input') {
        return props.value;
      } else {
        let val = null;
        for (let index = 0; index < props.options.length; index++) {
          if (props.value === props.options[index].value) {
            val = props.options[index].text;
            break;
          }
        }
        return val;
      }
    }
    return null;
  }
  function getIconClassName(defaultClassName) {
    const className = [defaultClassName, 'default-icon-select'];
    if (props.heightSize === 'x-small') {
      className.push('icon-select-xs');
    }
    if (props.heightSize === 'small') {
      className.push('icon-select-s');
    }
    if (props.heightSize === 'meduim') {
      className.push('icon-select-m');
    }
    if (props.heightSize === 'large') {
      className.push('icon-select-l');
    }
    if (props.heightSize === 'x-large') {
      className.push('icon-select-xl');
    }
    if (props.heightSize === 'xx-large') {
      className.push('icon-select-xxl');
    }
    if (props.iconClassName) {
      className.push(props.iconClassName);
    }
    return className.join(' ');
  }
  function renderSelect() {
    inputClassName += ' input-select ';
    let iconClassName = 'icon-input-select ';
    if (visible === true) {
      iconClassName += ' icon-input-select-open';
    }
    return (
      <ClickAwayListener onClickAway={() => setVisibility(false)}>
        <div>
          <div onClick={() => setVisibility(true)}>
            <Input
              disabled={props.disabled}
              isInvalid={props.isInvalid}
              isValid={props.isValid}
              className={inputClassName}
              onChange={(e) =>
                props.search ? setFilter(e.target.value) : null
              }
              readOnly={props.readOnly}
              value={returnValue()}
              bsPrefix={props.bsPrefix}
              id={props.id}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              feedback={props.feedback}
              feedbackTooltip={props.feedbackTooltip}
              heightSize={props.heightSize}
              onKeyPress={props.onKeyPress}
            />
          </div>
          {props.as === 'select' &&
            viewIcon(
              'SortTop',
              getIconClassName(iconClassName),
              props.iconSelectSize
            )}
          {visible ? viewOptions() : null}
        </div>
      </ClickAwayListener>
    );
  }
  function renderAutoCompelete() {
    return (
      <div>
        <div style={{ position: 'relative' }}>
          <Input
            as={props.as}
            disabled={props.disabled}
            isInvalid={props.isInvalid}
            isValid={props.isValid}
            className={inputClassName}
            onChange={props.onChange}
            readOnly={props.readOnly}
            value={props.value}
            bsPrefix={props.bsPrefix}
            id={props.id}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            feedback={props.feedback}
            feedbackTooltip={props.feedbackTooltip}
            type={props.type}
            heightSize={props.heightSize}
            password={props.password}
            onKeyPress={props.onKeyPress}
          />
          {props.autoCompleteOption.length !== 0 && props.showDropDown && (
            <ClickAwayListener onClickAway={props.autoCompleteClickAway}>
              <div className="auto-complete-option-container">
                {props.autoCompleteOption.map((element, index) => {
                  return (
                    <div
                      className="auto-complete-option"
                      onClick={() => props.autoCompleteOptionClicked(element)}
                    >
                      {element.address}
                    </div>
                  );
                })}
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
    );
  }
  function renderInput() {
    if (props.as === 'select') {
      return renderSelect();
    }
    if (props.as == 'autoComplete') {
      return renderAutoCompelete();
    }
    return viewInput();
  }
  return (
    <div className={'container-width ' + props.classNameContainer}>
      {viewLabel()}
      <div className={className}>{renderInput()}</div>
      {viewErrorMsg()}
      {props.isInvalid && (
        <Text
          className="mt-10 upload-error-message"
          text={props.errorMessage}
        />
      )}
    </div>
  );
};
MoleculeInput.defaultProps = {
  as: 'input',
  disabled: false,
  isInvalid: false,
  isValid: false,
  className: '',
  onChange: null,
  readOnly: false,
  value: null,
  bsPrefix: null,
  id: null,
  placeholder: 'Texte',
  defaultValue: null,
  feedback: false,
  feedbackTooltip: false,
  password: false,
  heightSize: 'large',
  borderRadius: false,
  labelClassName: 'default-label',
  inputLabel: null,
  icon: null,
  errorMsg: null,
  errorMsgClassName: 'default-error-msg',
  options: null,
  iconClassName: 'icon-search',
  iconSize: '12px',
  classNameInputContainer: '',
  optionsClassName: '',
  classNameContainer: '',
  optionContainerClassName: '',
  iconSelectSize: '8px',
  selectOptionContainerClassName: '',
  errorMessage: '',
  autoCompleteOption: [],
  autoCompleteOptionClicked: null,
  showDropDown: false,
  autoCompleteClickAway: null,
  leftIconClassName: '',
  leftIcon: '',
  onKeyPress: null,
  size: '12px'
};
MoleculeInput.PropTypes = {
  as: PropTypes.oneOf(['input', 'select', 'autoComplete']),
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.any,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  bsPrefix: PropTypes.string,
  id: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  feedback: PropTypes.any,
  feedbackTooltip: PropTypes.any,
  password: PropTypes.bool,
  heightSize: PropTypes.oneOf([
    'x-small',
    'small',
    'meduim',
    'large',
    'x-large',
    'xx-large'
  ]),
  borderRadius: PropTypes.bool,
  labelClassName: PropTypes.string,
  inputLabel: PropTypes.string,
  icon: PropTypes.string,
  errorMsg: PropTypes.string,
  errorMsgClassName: PropTypes.string,
  options: PropTypes.array,
  iconClassName: PropTypes.string,
  iconSize: PropTypes.string,
  classNameInputContainer: PropTypes.string,
  optionsClassName: PropTypes.string,
  optionContainerClassName: PropTypes.string,
  iconSelectSize: PropTypes.string,
  selectOptionContainerClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  autoCompleteOption: PropTypes.array,
  autoCompleteOptionClicked: PropTypes.any,
  showDropDown: PropTypes.bool,
  autoCompleteClickAway: PropTypes.any,
  leftIconClassName: PropTypes.string,
  leftIcon: PropTypes.string,
  onKeyPress: PropTypes.any,
  size: PropTypes.string
};
export default MoleculeInput;
