import React from 'react';
import './style/sideBar/styles.scss';
import './style/main.scss';

import MainRoute from './Routes/MainRoute';
import { useSelector } from 'react-redux';
import MainLoader from './Components/MainLoader';
import RenderAuth from './Routes/RenderAuth';
import RenderPrivate from './Routes/RenderPrivate';
import Auth from './Routes/Auth';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import ModalServerError from './Modals/ModalServerError/ModalServerError';
import Store from './Redux/Store';
import {
  serverError,
  serverMaintenance
} from './Redux/Actions/authActions/authActions';
import ModalMaintenance from './Modals/ModalMaintenance/ModalMaintenance';
import { apiGetMaintenance } from './Api/Maintenance/Maintenance';
import { useState } from 'react';
import { useEffect } from 'react';

const instance = createInstance({
  urlBase: 'https://spvie.matomo.cloud/',
  siteId: process.env.REACT_APP_MATOMO_SITEID,
  linkTracking: false // optional, default value: true
});
function App() {
  const pipeline = useSelector((state) => state.loader.pipeline);
  const serverErrorVisible = useSelector((state) => state.loader.serverError);
  const isMaintenance = useSelector((state) => state.loader.isMaintenance);

  const [loading, setLoading] = useState(true);
  const [lastCall, setLastCall] = useState(() => {
    const savedLastCall = localStorage.getItem('lastMaintenanceCheck');
    return savedLastCall ? new Date(parseInt(savedLastCall)) : null;
  });
  const [maintenanceMessage, setMaintenanceMessage] = useState('');

  const checkLastMaintenanceCall = () => {
    const lastCall = localStorage.getItem('lastMaintenanceCheck');

    const now = new Date().getTime();

    if (lastCall) {
      const lastCallTime = new Date(parseInt(lastCall));
      const hoursSincelastCall = (now - lastCallTime.getTime()) / (1000 * 3600);

      if (hoursSincelastCall >= 1) {
        fetchMaintenance();
      } else {
        setLoading(false);
      }
    } else {
      fetchMaintenance();
    }
  };
  const fetchMaintenance = async () => {
    try {
      const res = await apiGetMaintenance();
      if (res.status == 200) {
        localStorage.setItem(
          'lastMaintenanceCheck',
          new Date().getTime().toString()
        );
        if (res.isActive == true) {
          setMaintenanceMessage(res.message);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkLastMaintenanceCall();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [lastCall]);

  useEffect(() => {
    checkLastMaintenanceCall();
  }, []);
  return (
    <MatomoProvider value={instance}>
      {loading === false && (
        <>
          <RenderAuth>
            <Auth />
          </RenderAuth>
          <RenderPrivate>
            <MainRoute />
          </RenderPrivate>
        </>
      )}

      {!!maintenanceMessage && (
        <ModalMaintenance
          show={!!maintenanceMessage}
          message={maintenanceMessage}
          onHide={() => {
            setMaintenanceMessage('');
            setLoading(false);
          }}
        />
      )}
      {(serverErrorVisible === true || isMaintenance === true) && (
        <ModalServerError
          show={serverErrorVisible === true || isMaintenance === true}
          onHide={
            isMaintenance
              ? () => Store.dispatch(serverMaintenance())
              : () => Store.dispatch(serverError())
          }
          isMaintenance={isMaintenance}
        />
      )}
      {pipeline.length > 0 && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 9999,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: '#ffffff80'
          }}
        >
          <MainLoader />
        </div>
      )}
    </MatomoProvider>
  );
}

export default App;
