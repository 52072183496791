import React from 'react';
import { Button } from '../../Atoms';

function MoleculeAffiliationButton(props) {
    return (
        <div className="organism-affiliation-stage1-button-container">
            <Button
                text={props.dispense ? "Annuler" : "Précedent"}
                className="organism-affiliation-stage1-button-confirm"
                labelClassName="organism-affiliation-stage1-input-label"
                type="primary"
                onClick={props.goBackClicked}
            />
            <Button
                text={props.stage == 5 ? "Je m’affilie" : "Continuer"}
                className="organism-affiliation-stage1-button-back"
                labelClassName="organism-affiliation-stage1-input-label"
                onClick={props.confirmerClicked}
            />
        </div>
    );
}
MoleculeAffiliationButton.defaultProps = {
    dispense: false,
    goBackClicked: null,
    confirmerClicked: null,
    stage: 1,
};
export default MoleculeAffiliationButton;
