import React from "react"
import { NotificationTemplate } from "../../Templates";
class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    
    static defaultProps = {
        onHide: null
    }

    render() {
        return (
            <NotificationTemplate
                onHide={this.props.onHide}
            />
        );
    }
}

export default Notification;
