import React from 'react';
import { Text, Button, Card } from '../../Atoms';
import { Col, Row } from 'react-bootstrap';
import { COLOR_BUTTON } from '../../Config/Config';
function TemplateProfileScreen1(props) {
  return (
    <div className='template-scroll profile-container'>
      <div className='cartetp-header-container'>
        <Text text='Profil' type='h4' className='title-page' />
      </div>
      <div className='profile-title'>
        <Text
          text={
            window.innerWidth <= 768
              ? 'Choisissez un bénéficiaire'
              : 'Choisissez un bénéficiaire pour consulter ses informations.'
          }
          type='h7'
          className="text-default-size text-default-font"
        />
      </div>
      <div className='profile-card-container'>
        <Row>
          {props.benificiaireList.map((el, index) => (
            <Col
              className='new-container-card-profile scale-up-center'
              md={4}
              xs={
                (index + 1) % 3 === 2 &&
                  props.benificiaireList.length === index + 1
                  ? 12
                  : 6
              }
            >
              <Card
                text={el.name}
                size='medium'
                onClick={() => props.handelSelectedProfile(el.value, index)}
              />
            </Col>
          ))}
        </Row>
      </div>

      <div className='profile-btn-add-beneficiaire-container'>
        {props.benificiaireList?.length >= 1 &&
        <Button
          text={
            'Ajouter un bénéficiaire'
          }
          className='btn-add-beneficiaire-profile'
          onClick={() => props.navigationSteps('ADD_BENEFICIAIRE_STEP1')}
          color={COLOR_BUTTON.GREEN}
        />}
      </div>
      <div className='profile-btn-delete-beneficiaire-container'>
        <Button
          text={
            window.innerWidth <= 768
              ? 'Supprimer des béneficiaires'
              : 'Supprimer un bénéficiaire'
          }
          className='btn-add-beneficiaire-profile'
          onClick={() => props.navigationSteps('DELETE_BENEFICIAIRE')}
          color={COLOR_BUTTON.GREEN}
        />
      </div>
    </div>
  );
}

export default TemplateProfileScreen1;
