import React from 'react';
import { Img } from '../../Atoms';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import capitalize from '../../Utils/capitalize';

const CardCategorie = (props) => {
    const history = useHistory();
    const replaceSVGColors = (svgString, ancienneCouleur, nouvelleCouleur) => {
        const newSvgString = svgString
        .replace(/#fff/g, '#F28F2F')
        .replace(/#27b4ae/g, '#F0F0F0');
  
      return newSvgString;
      };
    
    const goToQuestions = (item)=>{
        let finalTitle = item.title.toLowerCase();
        if (finalTitle.split(' ').length !== 0) {
            finalTitle = finalTitle.split(' ').join('-');
        }
        history.push({
            pathname: `/foire-aux-questions/${finalTitle}`,
            state: { item : item }
          })
    }
    return (
        <div className='card-categorie-container' onClick={() => goToQuestions(props.item)}>
            <Img
                src={`data:image/svg+xml,${encodeURIComponent(replaceSVGColors(props.item.icon))}`}
                alt="icone"
                className="card-icon"
                width="34"
                height="34"
            />
            <p className='card-title'>{capitalize(props.item.title)}</p>
        </div>
    );
};

export default CardCategorie;