export function validateSpace(value) {
    if (!value.replace(/\s/g, '').length) {
        return false;
    }
    return true;
}
export function validateNull(value) {
    if (value !== "") {
        return true
    }
    return false
}
export function validateEmail(value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}
export function isNumber(value) {
    const re = /^\d+$/;
    return re.test(value)
}
