import { CONSUMPTIONTYPE } from '../../Config/enum';
import { apiGet, apiPost } from '../standardApi';

export const apiRepayments = async (
  cgrmContractingCode,
  clientType,
  startDate,
  endDate,
  skip,
  limit,
  carteTpCheck,
  codeTiers
) => {
  let params = `?ClientType=${clientType}&StartDate=${startDate}&EndDate=${endDate}&WithTp=${carteTpCheck}`;
  if (skip) {
    params += `&Skip=${skip}`;
  }
  if (limit) {
    params += `&Top=${limit}`;
  }
  const payload = {
    codeTiers: codeTiers,
    startDate: new Date(startDate).toISOString(),
    endDate: new Date(endDate).toISOString(),
    top: limit,
    skip: 0,
    withTP:   carteTpCheck,
    pdf: false
  };
  const response = await apiPost(
    '/Repayment/GetRepayments',
    JSON.stringify(payload)
  );
  return response;
};
export const apiRepaymentsDocument = async (
  codeTiers,
  withTP,
  startDate,
  endDate,
) => {
  const payload = {
    codeTiers,
    withTP,
    startDate,
    endDate,
    pdf: true
  }
  const response = await apiPost(
    '/Repayment/GetRepaymentDocument',
    JSON.stringify(payload), 'application/json',
  )
  return response;
};



export const apiConsumptions = async (
  codeTiers,
  consumptionType,
  startDate,
  endDate,
) => {
  return await apiPost(
    '/Consumption/Consumptions',
    JSON.stringify({
      codeTiers,
      consumptionType,
      startDate,
      endDate,
    }),
    'application/json',
  );
};