import React from 'react';
import { Modal } from '../../Molecules';
import { Button, Text } from '../../Atoms';
import PropTypes from 'prop-types';
import { COLOR_BUTTON } from '../../Config/Config';
import { useState } from 'react';
import { FEEDBACK_STEP } from '../../Config/enum';
import { Form } from 'react-bootstrap';
import { apiSendFeedback } from '../../Api/Contact/Contact';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apiGetFAQ } from '../../Api/FAQ/FAQ';
ModalConfirmRating.defaultProps = {
  text: 'Merci pour votre avis, nous prenons en compte tous vos précieux retours.',
  description: 'Donnez votre avis sur :'
};
ModalConfirmRating.PropTypes = {
  text: PropTypes.string
};
function ModalConfirmRating(props) {
  const history = useHistory();
  const fillColorClicked = '#FBD13E';
  const strokeColorClicked = 'none';

  const defaultFill = 'none';
  const defaultStroke = '#000000';
  const rateData = [
    { note: 1, fill: defaultFill, stroke: defaultStroke },
    { note: 2, fill: defaultFill, stroke: defaultStroke },
    { note: 3, fill: defaultFill, stroke: defaultStroke },
    { note: 4, fill: defaultFill, stroke: defaultStroke },
    { note: 5, fill: defaultFill, stroke: defaultStroke }
  ];

  const [touched, setTouched] = useState(false);
  const [questionType, setQuestionType] = useState('');
  const [rates, setRates] = useState(rateData);

  const [note, setNote] = useState(null);
  const [comment, setComment] = useState('');
  const [step, setStep] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.show) {
      resetModal();
    }
  }, [props.show]);

  const getFAQ = async () => {
    return await apiGetFAQ();
  };

  const goToQuestion = (touchedButton) => {
    touchedButton == FEEDBACK_STEP.FEEDBACK_GESTION
      ? setQuestionType('de votre gestion ?')
      : setQuestionType('de vos garanties ?');
    setTouched(true);
    setStep(touchedButton);
  };

  const onchangeComment = (e) => {
    setComment(e.target.value);
  };
  const giveNote = (note) => {
    setNote(note);
    setRates((prevRates) => {
      return prevRates.map((rate) => {
        if (rate.note <= note) {
          return {
            ...rate,
            fill: fillColorClicked,
            stroke: strokeColorClicked
          };
        } else {
          return { ...rate, fill: defaultFill, stroke: defaultStroke };
        }
      });
    });
  };

  const onSubmit = async () => {
    if (note == null) {
      setError(['Veuillez sélectionner une note en cliquant sur une étoile.']);
      return;
    }
    const response = await apiSendFeedback(
      step,
      note,
      comment,
      props.codeTiers
    );
    // const response = await apiSendFeedback(step,note,comment,2798432);

    if (response.status === 200) {
      if (response.googleFeedbackUrl === null) {
        const faqResponse = await getFAQ();
        if (faqResponse.status === 200) {
          const reclamationCategory = faqResponse.data.filter(
            (faq) => faq.title == 'RECLAMATION'
          )[0];
          history.push({
            pathname: `/foire-aux-questions/reclamation`,
            state: {
              item: reclamationCategory,
              category:
                step == FEEDBACK_STEP.FEEDBACK_GESTION ? 'gestion' : 'garanties'
            }
          });
        }
      } else {
        window.open(response.googleFeedbackUrl, '_blank');
      }
      setSubmitted(true);
    } else {
      setError(
        response.errors?.map?.((i) => i.errorMessage) || [
          "Une erreur s'est produite"
        ]
      );
    }
  };

  const resetModal = () => {
    setTouched(false);
    setSubmitted(false);
    setStep(null);
    setComment('');
    setNote(null);
    setError(null);
    setRates(rateData);
  };

  return (
    <Modal {...props}>
      <div className="rating-confirm-container">
        {!touched && !submitted && (
          <>
            <Text text={props.text} className="rating-confim-text" />
            <Text
              text={props.description}
              className="rating-confim-description"
            />

            <div className="rating-confirm-button">
              <Button
                text="Notre gestion"
                color={COLOR_BUTTON.SECOND_RED}
                className="rate-button"
                onClick={() => goToQuestion(FEEDBACK_STEP.FEEDBACK_GESTION)}
              />
              <br />
              <Button
                text="Vos garanties"
                color={COLOR_BUTTON.SECOND_RED}
                className="rate-button"
                onClick={() => goToQuestion(FEEDBACK_STEP.FEEDBACK_GARANTIES)}
              />
            </div>
          </>
        )}

        {touched && !submitted && (
          <>
            <Text text={props.text} className="rating-confim-text" />
            <Text
              text={`Êtes-vous satisfait ${questionType}`}
              className="rating-confim-description"
            />
            <div className="rating-confim-star-container">
              {rates.map((note) => {
                return (
                  <svg
                    key={note.note}
                    onClick={() => giveNote(note.note)}
                    width="22"
                    height="22"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="rating-confim-star-image"
                  >
                    <path
                      d="M8.14711 11.5312L8 11.4241L7.85289 11.5312L3.52992 14.6773L5.18438 9.59742L5.24125 9.42279L5.09161 9.31631L0.782522 6.25H6.06667H6.24877L6.30462 6.07667L8 0.815146L9.69538 6.07667L9.75123 6.25H9.93333H15.2175L10.9084 9.31631L10.7587 9.42279L10.8156 9.59742L12.4701 14.6773L8.14711 11.5312Z"
                      stroke={note.stroke}
                      fill={note.fill}
                      stroke-width="0.5"
                    />
                  </svg>
                );
              })}
            </div>

            <Form.Control
              className="default-input rating-confirm-input"
              placeholder="Ajouter un commentaire"
              as={'textarea'}
              value={comment}
              rows={4}
              onChange={onchangeComment}
            />

            <div className="rating-confirm-button">
              <br />
              {error != null &&
                error?.map((err, i) => {
                  return (
                    <p className="errorMessage" key={i}>
                      {err}
                    </p>
                  );
                })}
              <Button
                text="Envoyer"
                color={COLOR_BUTTON.SECOND_RED}
                className="rate-button"
                onClick={onSubmit}
              />
            </div>
          </>
        )}

        {submitted && touched && (
          <>
            <Text text={props.text} className="rating-confim-text" />
            <Text
              text="Merci pour votre avis, nous prenons en compte tous vos précieux retours."
              className="rating-confim-description"
            />

            <div className="rating-confirm-button">
              <Button
                text="Retour"
                color={COLOR_BUTTON.SECOND_RED}
                className="rate-button"
                onClick={props.onHide}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
export default ModalConfirmRating;
