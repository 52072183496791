import React from 'react';
import { Button, Icon, Text } from '../../Atoms';
import { Modal } from '../../Molecules';
import { COLOR_BUTTON } from '../../Config/Config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function ModalBankingTye(props) {
  const history = useHistory();

  const selectType = (name, page) => {

    history.push({
      pathname: `/documents/send`,
      state: {
        name,
        page
      }
    });
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered={true}
      showIconClose={false}
      contentClassName={'content-modal-confirm-password'}
    >
      <div className="full-container">
        <Icon
          icon="Plus"
          className="close-modal-pdf"
          size="20px"
          onClick={props.onHide}
        />
        <Text
          text="Modification  des informations bancaires"
          className="title-modal-confirm-password"
        />
        <Text
          style={{ textAlign: 'center' }}
          text="Pour quel type de compte souhaitez-vous effectuer votre modification ?"
          className="text-description-confirm-password"
        />

        <div className="mt-30">
          <div className="profile-btn-add-beneficiaire-container">
            <Button
              text={'Compte de prélevements'}
              className="btn-banking-type-profile"
              onClick={() => selectType('BANQUE','SEPA - mes cotisations')}
              color={COLOR_BUTTON.GREEN}
            />
          </div>
          <div className="profile-btn-delete-beneficiaire-container">
            <Button
              text={'Compte de remboursements'}
              className="btn-banking-type-profile"
              onClick={() => selectType('BANQUE','SEPA - mes cotisations et remboursements')}
              color={COLOR_BUTTON.GREEN}
            />
          </div>{' '}
          <div className="profile-btn-delete-beneficiaire-container">
            <Button
              text={'Prélevements et remboursements'}
              className="btn-banking-type-profile"
              onClick={() => selectType('BANQUE','SEPA - mes cotisations et remboursements')}
              color={COLOR_BUTTON.GREEN}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
ModalBankingTye.defaultProps = {
  show: false,
  onHide: null
};
export default ModalBankingTye;
