import React, { Component } from 'react';
import { CHECK_MEMBER_NOT_VALID_MAIL } from '../../Api/Login/errorMessage';
import { apiCheckEmail } from '../../Api/Login/Login';
import {
  TemplateResetPasswordMailStep2,
  TemplateResetPasswordMailStep1
} from '../../Templates';
class ResetPasswordMail extends Component {
  constructor (props) {
    super(props);
    this.state = {
      step: 1,
      mail: '',
      isValid: true
    };
  }

  nextStep = async () => {
    const res = await apiCheckEmail(this.state.mail);
    if (res.status === 200) {
      this.setState({ step: this.state.step + 1 });
    } else {
      this.setState({
        isValid: false
      });
    }
  };

  onChange = value => {
    this.setState({
      mail: value
    });
  };

  render () {
    switch (this.state.step) {
      case 1:
        return <TemplateResetPasswordMailStep1 {...this.state} {...this} />;

      case 2:
        return <TemplateResetPasswordMailStep2 {...this.state} {...this} />;
      default:
        return null;
    }
  }
}
export default ResetPasswordMail;
