import React from 'react';
import { Text, Img, Button, Checkbox } from '../../Atoms';
import HappyBunchChat from '../../assets/img/Happy-Bunch-Chat.png';
import CarteTPImage from '../../assets/img/Carte_TP_image.png';
import { TextMolecule } from '../../Modals';
import { Row, Col } from 'react-bootstrap';
import gPlay from '../../assets/img/gplay.png';
import apple from '../../assets/img/apple.png';
import { Link } from 'react-router-dom';
import Store from '../../Redux/Store';
import { COLOR_BUTTON } from '../../Config/Config';
function OrganismCarteTp(props) {
  return (
    <div className='cartetp-organism-container'>
      <div className='cartetp-organism-box'>
        <Text
          text={`${props.civility} ${props.firstName.toLowerCase()} ${props.lastName}`}
          type='h6'
          className="text-default-size text-default-font text-bold"
          style={{marginBottom:"20px"}}
        />
        <Text
          text={`Numéro de Sécurité Sociale : ${props.socialSecurityNumber}`}
          type='h6'
          className="text-default-size text-default-font"
          // style={{ fontWeight: 400, fontSize: 12, fontFamily: "Mont" }}
        />
        <Button
          onClick={props.download}
          text='Télécharger ma carte'
          className='cartetp-organism-button cartetp-organism-button__download-width cartetp-organism-button__partage-margin'
          color={COLOR_BUTTON.SECOND_RED}
        />
        {/* <Checkbox
          type='checkbox'
          classNameContainer='organism-cartetp-checkbox-container'
          label='Je souhaite recevoir ma carte de tp par courrier papier (à l’adresse indiquée sur mon profil)'
          className='organism-cartetp-checkbox'
          size={16}
          onClick={props.checkBoxClicked}
          checked={props.check}
        /> */}
        <div className='cartetp-header-container carte-telecharger-text'>
          Pour consulter vos garanties,{' '}
          <Link to='/Garanties'>
            <span className='cartetp-telecharger-bold'>
              téléchargez votre tableau
            </span>
          </Link>
        </div>
      </div>
      <div className='cartetp-organism-img-container'>
        <Img src={CarteTPImage} className='cartetp-organism-img' />
      </div>

    </div>
  );
}

export default OrganismCarteTp;
