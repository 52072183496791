import React, { useState, useEffect } from 'react';
import { Icon } from '../../Atoms';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_LINKS } from './pathLinks';
OrganismHeader.defaultProps = {
  title: 'title'
};
export default function OrganismHeader(props) {
  const location = useLocation();
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [selectedPath, setselectedPath] = useState('');
  const sideBar = useSelector((state) => state.sideBar);
  const tuto = useSelector((state) => state.tuto);
  const container = useSelector((state) => state.container);

  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener('resize', () => {
      setwindowWidth(window.innerWidth);
    });
    const path = location.pathname;
    const aux = path.split('/');
    if (aux.length === 0) {
      setselectedPath('/');
    } else {
      setselectedPath(`/${aux[1].toLowerCase()}`);
    }
  }, [location.pathname]);

  function mobileHeaderClassName() {
    const classNames = ['mobile-container-header-icon'];
    if (container.padding == false) {
      classNames.push('mobile-container-padding');
    }
    return classNames.join(' ');
  }

  function openSlideNotification(page) {
    dispatch({
      type: 'SET_CURRENT_SLIDE_NOTIFICATION',
      value: { open: true, page: page }
    });
  }
  const linkClicked = (index) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
        return null;
      case 3:
        return openSlideNotification;
      default:
        return null;
    }
  };
  function headerClassName() {
    const className = ['container-header'];
    // if (tuto.step == 7 && window.innerWidth < 762) {
    //   className.push('mobile-header-tuto-full-opacity');
    // }
    return className.join(' ');
  }
  function webHeaderClassName() {
    const className = ['container-header-icon'];
    if (tuto.step == 8 && window.innerWidth > 762) {
      className.push('web-header-tuto-full-opacity');
    }
    return className.join(' ');
  }
  return (
    <div className={headerClassName()}>
      <div className={webHeaderClassName()}>
        {PATH_LINKS.map((path, index) => {
          const onClick = linkClicked(index);
          return (
            <Link
              key={index}
              className={
                path.links.includes(selectedPath)
                  ? 'selected-icon-header'
                  : 'container-icon hover-header'
              }
              to={path.defaultLink}
            >
              <Icon
                size={path.iconSize}
                icon={path.icon}
                className={path.iconClassName}
                onClick={onClick ? () => onClick(path.params) : null}
              />
            </Link>
          );
        })}
      </div>
      <div className={mobileHeaderClassName()}>
        <Icon
          size={18}
          icon={'Hamburger'}
          color="#C4C4C4"
          onClick={() =>
            dispatch({
              type: 'SET_CURRENT_SIDE_BAR',
              value: { open: !sideBar.open }
            })
          }
        />

        {/* <Link className='container-icon' to='/notification'>
          <Icon size={18} icon={'Notification'} />
        </Link> */}
      </div>
    </div>
  );
}
