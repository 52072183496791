import React, { useState } from 'react';
import { Button, Text } from '../../Atoms';
import { Input } from '../../Molecules';
import { PhotoLoginOrganisme } from '../../Organisms';
function TemplateFirstLoginStep2 (props) {
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  return (
    <div className='login-template'>
      <PhotoLoginOrganisme />
      <div className='login-template-organism'>
        <div className='login-container'>
          <div>
            <Text text='PREMIERE CONNEXION' type='h4' />
            <Text
              text='Définissez un nouveau mot de passe.'
              className='text-description-first-login'
            />
            <div className='first-login-small-container'>
              {!props.isValid && (
                <Text
                  type='h8'
                  className='reset-password-error-message text-center mb-22'
                  text='Ce mot de passe n’est pas valide. Essayer à nouveau en respectant les conditions décrites en bas de page.'
                />
              )}
              <Input
                placeholder='Nouveau mot de passe'
                className='input-first-login'
                icon='Eyes'
                password={showPassword}
                onClickIcon={() => setShowPassword(!showPassword)}
                iconSize={18}
                iconClassName={
                  showPassword
                    ? 'input-first-login-password-icon'
                    : 'input-first-login-password-icon-show'
                }
                onChange={e => props.onChange('password', e.target.value)}
                value={props.password}
              />
              <Input
                placeholder='Confirmez le nouveau mot de passe'
                className='code-input-first-login'
                icon='Eyes'
                onClickIcon={() => setShowConfirmPassword(!showConfirmPassword)}
                iconSize={18}
                password={showConfirmPassword}
                iconClassName={
                  showConfirmPassword
                    ? 'input-first-login-password-icon'
                    : 'input-first-login-password-icon-show'
                }
                onChange={e =>
                  props.onChange('confirmPassword', e.target.value)
                }
                value={props.confirmPassword}
              />
              <div className='password-conditions-container-first-login'>
                <Text
                  text='Votre mot de passe doit contenir au minimum :'
                  className='text-description-password-first-login'
                />
                <Text
                  text='- 8 caractères'
                  className='text-description-password-first-login'
                />
                <Text
                  text='- 1 majuscule'
                  className='text-description-password-first-login'
                />
                <Text
                  text='- 1 minuscule'
                  className='text-description-password-first-login'
                />
                <Text
                  text='- 1 chiffre '
                  className='text-description-password-first-login'
                />
                <Text
                  text='- 1 caractère spécial '
                  className='text-description-password-first-login'
                />
              </div>
              <div className='btn-confirme-first-login-container'>
                <Button
                  text='Confirmer'
                  className='btn-next-first-login'
                  boxShadow
                  onClick={() => props.onSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateFirstLoginStep2;
