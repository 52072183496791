import React from 'react';
import { Text, Img, Icon } from '../../Atoms';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

OrganismSlider.PropTypes = {
    elementNumber: PropTypes.number,
    contentNumber: PropTypes.PropTypes.oneOf([1, 2]),
    dotClicked: PropTypes.any,
    element: PropTypes.any,
    classNameContentContainer: PropTypes.string,
    classNameSliderContainer: PropTypes.string,
};
OrganismSlider.defaultProps = {
    elementNumber: 0,
    contentNumber: 1,
    dotClicked: null,
    classNameContentContainer: '',
    element: [],
    classNameSliderContainer: '',
};
function OrganismSlider(props) {
    const classNameContentContainer = ['slider-content']
    const classNameSliderContainer = ["default-slider-container"]
    if (classNameSliderContainer) {
        classNameSliderContainer.push(props.classNameSliderContainer)
    }
    if (props.classNameContentContainer) {
        classNameContentContainer.push(props.classNameContentContainer)
    }
    const [selected, setSelected] = React.useState(0)

    const onClick = (i) => {
        setSelected(i)
        props.onClick && props.onClick()
    }

    const sliderDots = () => {
        const dots = []
        const dotLenght = props.contentNumber == 1 ? props.elementNumber : props.elementNumber / 2
        for (let i = 0; i < dotLenght; i++) {
            dots.push(<div key={i} className={i === selected ? "slider-dot active" : "slider-dot"} onClick={() => onClick(i)}></div>)
        }
        return dots

    }

    const renderElement = () => {
        return props.element.slice(selected * props.contentNumber, (selected * props.contentNumber) + props.contentNumber)
    }
    const getDotslength = () => {
        const reste = props.elementNumber % props.contentNumber == 0 ? 0 : 1
        return Math.floor(props.elementNumber / props.contentNumber) + reste
    }
    const rightIconClicked = () => {
        if (selected < getDotslength() - 1) {
            setSelected(selected + 1)
        }
    }
    const leftIconClicked = () => {
        if (selected != 0) {
            setSelected(selected - 1)
        }
    }
    const swipeHandler = useSwipeable({
        onSwipedLeft: rightIconClicked,
        onSwipedRight: leftIconClicked
    })
    return (
        <div className={classNameSliderContainer.join(" ")}>
            <div className='slider-container'>
                <div className={selected == 0 ? "slider-icon-desactive" : "slider-icon-left-container"}>
                    <Icon icon="SortTop" className="slider-icon-left" onClick={leftIconClicked} />
                </div>
                <div className={classNameContentContainer.join(" ")} {...swipeHandler}>
                    {
                        renderElement()
                    }
                </div>
                <div className={selected == getDotslength() - 1 ? "slider-icon-desactive" : "slider-icon-right-container"} >
                    <Icon icon="SortTop" className="slider-icon-right" onClick={rightIconClicked} />
                </div>
            </div>
            <div className="slider-dot-container">
                {sliderDots().map(element => element)}
            </div>
        </div>
    );
}

export default OrganismSlider;
