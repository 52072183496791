import React from 'react';
import { Modal, Input } from '../../Molecules';
import {Text, Icon, Button} from '../../Atoms';
import ReactStars from "react-rating-stars-component";
import PropTypes from 'prop-types';
ModalRating.defaultProps = {
    garanteeValue: 0,
    ratingChange: null,
    manegementValue: 0,
    manegementComentary: '',
    manegementSendClicked: null,
    garanteeSendClicked: null,
    garanteeComentary: '',
    hideGaranteeComentary: false,
    hideManegementComentary: false,
    ratingGaranteeChange: null,
    ratingManegementChange: null,
};
ModalRating.PropTypes = {
    garanteeValue: PropTypes.number,
    ratingChange: PropTypes.func,
    manegementValue: PropTypes.number,
    manegementComentary: PropTypes.string,
    manegementSendClicked: PropTypes.func,
    garanteeSendClicked: PropTypes.func,
    garanteeComentary: PropTypes.string,
    hideGaranteeComentary: PropTypes.bool,
    hideManegementComentary: PropTypes.bool,
    ratingGaranteeChange: PropTypes.func,
    ratingManegementChange: PropTypes.func,
};
function ModalRating(props) {
    const defaultRatingOption = {
        count: 5,
        size: "30px",
        emptyIcon: <Icon icon="mobileStar" size="30px" />,
        filledIcon: <Icon icon="filledStar" size="30px" />,
    }
    const garanteeRatingOption = {
        ...defaultRatingOption,
        value: props.garanteeValue,
        onChange: (value) => props.ratingGaranteeChange(value)
    }
    const manegementRatingOption = {
        ...defaultRatingOption,
        value: props.manegementValue,
        onChange: (value) => props.ratingManegementChange(value)
    }
    return (
        <Modal
            {...props}
        >
            <div className={"rating-container"}>
                <Text text="Votre avis nous intéresse" className="rating-title" />
                <Text text="Êtes-vous satisfait de notre gestion ?" className="rating-manegement-title" />
                <div className="rating-manegement">
                    <ReactStars
                        {...manegementRatingOption}
                    />
                </div>
                {props.hideManegementComentary && <Input
                    placeholder="Votre texte ici (150 caractères maximum)"
                    className="actuality-input-text-container"
                    classNameInputContainer="full-container"
                    as="textarea"
                    value={props.manegementComentary}
                    onChange={(e) => props.ratingChange(e.target.value, 'manegementComentary')}
                />}
                {props.hideManegementComentary && <Button text="Envoyer" className="send-button" onClick={props.manegementSendClicked} />}
                <Text text="Êtes-vous satisfait de vos garanties ?" className="rating-manegement-title" />
                <div className="rating-garantee">
                    <ReactStars
                        {...garanteeRatingOption}
                    />
                </div>
                { props.hideGaranteeComentary && <Input
                    placeholder="Votre texte ici (150 caractères maximum)"
                    className="actuality-input-text-container"
                    classNameInputContainer="full-container"
                    as="textarea"
                    value={props.garanteeComentary}
                    onChange={(e) => props.ratingChange(e.target.value, 'garanteeComentary')}
                />}
                {props.hideGaranteeComentary && <Button text="Envoyer" className="send-button" onClick={props.garanteeSendClicked} />}
            </div>
        </Modal>
    );
}
export default ModalRating;
