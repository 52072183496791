import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { getFrenchDepartments as apiGetFrenchDepartments } from '../../../../Api/Geolocation/Geolocation.api';
import styles from '../../Geolocation.module.scss';
import theme from './AutosuggestTheme.module.scss';

const getSuggestionValue = (suggestion) =>
  `${suggestion.departmentNumber} - ${suggestion.department}`;
const renderSuggestion = (suggestion) =>
  suggestion.loading
    ? 'Chargement en cours'
    : `${suggestion.departmentNumber} - ${suggestion.department}`;

const FrenchDepartmentsAutosuggest = ({
  value,
  onChangeValue,
  onSelectSuggestion,
  ...rest
}) => {
  const [frenchDepartments, setFrenchDepartments] = useState([]);
  const [frenchDepartmentsLoading, setFrenchDepartmentsLoader] = useState(true);

  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) => {
    const departmentsFilter = (dep, index) => {
      const baseSearch = getSuggestionValue(dep);
      const keywords = value.split(' ').filter((i) => !!i);

      return keywords.every((keyword) =>
        baseSearch.toLowerCase().includes(keyword.toLowerCase())
      );
    };
    setSuggestions(frenchDepartments.filter(departmentsFilter));
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);
  const shouldRenderSuggestions = (value, reason) => {
    if (frenchDepartmentsLoading) {
      return true;
    }
    return true;
  };

  const inputProps = {
    value: value,
    onChange: (e) => onChangeValue(e.target.value),
    placeholder: 'Exemple: 75',
    id: 'department-input',
    autoComplete: 'off',
    'data-lpignore': true,
    'data-form-type': 'other'
  };

  const getFrenchDepartments = async () => {
    setFrenchDepartmentsLoader(true);

    const res = await apiGetFrenchDepartments();
    if (res.status === 200) {
      setFrenchDepartmentsLoader(false);
      setFrenchDepartments(res.data);
    }
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    onSelectSuggestion({
      ...suggestion,
      formated: getSuggestionValue(suggestion)
    });
  };

  useEffect(() => {
    getFrenchDepartments();
  }, []);

  return (
    <div {...rest}>
      <label
        htmlFor="department-input"
        className={theme['autosuggest-input-label']}
      >
        Indiquez un département
      </label>
      <Autosuggest
        suggestions={
          !frenchDepartmentsLoading ? suggestions : [{ loading: true }]
        }
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        theme={theme}
      />
    </div>
  );
};

export default FrenchDepartmentsAutosuggest;
