import React from 'react';
import { Icon, Text } from '../../Atoms';
import PropTypes from 'prop-types';

MoleculePagination.defaultProps = {
  page: 1,
  onChangePagination: null,
  pageNumber: 20,
  numberOfElement: 1,
  classNameText: '',
  classNamePaginationContainer: '',
  classNameIcon: ''
};
MoleculePagination.PropTypes = {
  page: PropTypes.number,
  onChangePagination: PropTypes.any,
  pageNumber: PropTypes.number,
  numberOfElement: PropTypes.number,
  classNameText: PropTypes.string,
  classNamePaginationContainer: PropTypes.string,
  classNameIcon: PropTypes.string
};
export default function MoleculePagination (props) {
  const classNameText = props.classNameText + ' text-pagination ';
  const classNameIconDec = ' pagination-icon-dec ' + props.classNameIcon;
  const classNameIconInc = ' pagination-icon-inc ' + props.classNameIcon;
  const classNameContainerPagination =
    ' container-pagination ' + props.classNamePaginationContainer;
  const pageRender = (page, pageNumber) => {
    const pageRender = [];
    const reste = page % props.numberOfElement;
    const quotien = Math.trunc(page / props.numberOfElement);
    let pageBegin;
    let pageEnd;
    if (reste == 0) {
      pageBegin = page + 1 - props.numberOfElement;
      pageEnd = page;
    } else {
      pageBegin = props.numberOfElement * quotien + 1;
      pageEnd =
        props.numberOfElement * quotien + props.numberOfElement < pageNumber
          ? props.numberOfElement * quotien + props.numberOfElement
          : pageNumber;
    }

    for (let i = pageBegin; i <= pageEnd; i++) {
      pageRender.push(
        <Text
          // onClick={() => props.onChangePagination && props.onChangePagination(i)}
          text={i}
          className={classNameText}
          type={'h1'}
        />
      );
    }
    return pageRender;
  };

  return (
    <div className={classNameContainerPagination}>
      <div
        onClick={() => props.onChangePagination && props.onChangePagination(1)}
        className='container-pagination-icon'
      >
        <Icon
          icon='Back'
          size='10px'
          className={'pagination-icon-dec-back'}
          onClick={null}
        />
      </div>
      <div
        onClick={() =>
          props.onChangePagination &&
          props.onChangePagination(parseInt(props.page) - 1)
        }
        className='container-pagination-icon'
      >
        <Icon
          icon='SortTop'
          size='10px'
          className={classNameIconDec}
          onClick={null}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {pageRender(parseInt(props.page), parseInt(props.pageNumber))}
      </div>
      <div
        onClick={() =>
          props.onChangePagination &&
          props.onChangePagination(parseInt(props.page) + 1)
        }
        className='container-pagination-icon'
      >
        <Icon
          icon='SortTop'
          size='10px'
          className={classNameIconInc}
          onClick={null}
        />
      </div>
      <div
        onClick={() =>
          props.onChangePagination && props.onChangePagination(props.pageNumber)
        }
        className='container-pagination-icon'
      >
        <Icon
          icon='Back'
          size='10px'
          className={'pagination-icon-inc-back'}
          onClick={null}
        />
      </div>
    </div>
  );
}
