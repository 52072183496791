import React, { useEffect, useState } from 'react';
import { Text, Button, Icon } from '../../Atoms';
import { InputProfileOrganism } from '../../Organisms';
import { Upload } from '../../Molecules';
import { ConfirmPasswordModal } from '../../Modals';
import { COLOR_BUTTON } from '../../Config/Config';
function TemplateProfileScreen2Beneficiaire(props) {
  const [windowWidth, setWindowWidth] = useState({
    innerWidth: window.innerWidth
  });
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth({ innerWidth: window.innerWidth });
    });
  }, []);
  return (
    <div className="template-scroll profile-container">
      <div className="container-title-send-doc">
        <Icon
          icon="SortTop"
          size="20px"
          className="icon-return"
          onClick={props.onIconClick}
        />
        <Text text={`Profil`} className="title-send-document title-page" />
      </div>
      <div className="first-line-container-profile mt-10 mb-30">
        <div className="item-col-profile item-col-profile-margin-left">
          <div className="col-profile input-profile-container__edit-width">
            <Text
              text="Informations personnelles"
              className="profile-sub-title"
            />
          </div>
        </div>
        <div className="item-col-profile item-col-profile-margin-right input-profile-container__edit-width-display ">
          <div className="ml-a">
            <Button
              text="Ajouter un bénéficiaire"
              className="btn-beneficier-profile"
              boxShadow
              onClick={() => props.navigationSteps('ADD_BENEFICIAIRE_STEP2')}
              color={COLOR_BUTTON.GREEN}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="first-line-container-profile">
          <div className="item-col-profile item-col-profile-margin-left">
            <div className="col-profile">
              <InputProfileOrganism
                editable
                label={'Nom'}
                value={props.data.lastName}
                onChange={(value) => props.onChange(value, 'lastName')}
                handelEditMode={(value) => props.handelEditLastname(value)}
                edit={props.editLastname}
              />
            </div>
          </div>
          <div className="item-col-profile item-col-profile-margin-right">
            <InputProfileOrganism
              label={'Type de bénéficiaire'}
              value={props.data.typeBeneficiaire}
              onChange={(value) => props.onChange(value, 'typeBeneficiaire')}
            />
          </div>
        </div>
        <div className="second-line-container-profile">
          <div className="col-profile second-items-container-profile">
            <div className="item-col-profile item-col-profile-margin-right mt-10">
              <InputProfileOrganism
                label={'Prénom'}
                value={props.data.name}
                onChange={(value) => props.onChange(value, 'name')}
              />
            </div>
            <div className="col-input-container-profile item-col-profile item-col-profile-margin-left">
              <InputProfileOrganism
                editable
                label={'N°Sécurité Sociale'}
                value={props.data.numeroSecuriteSociale}
                onChange={(value) =>
                  props.onChange(value, 'numeroSecuriteSociale')
                }
                edit={props.editNumeroSecuriteSociale}
                handelEditMode={(value) =>
                  props.handelEditNumeroSecuriteSociale(value)
                }
              />
              {props.currentField === 'numsec' && (
                <Text
                  text="Attention, si vous modifiez le numéro de Sécurité Sociale,
                  n’oubliez pas de vérifier également la date de naissance."
                  className="notice-uptate-field"
                ></Text>
              )}
            </div>
          </div>
          <div>
            <div className="col-input-container-profile item-col-profile item-col-profile-margin-left">
              <InputProfileOrganism
                editable
                label={'Date de naissance'}
                value={props.data.birthDate ? props.data.birthDate : ''}
                as="calendar"
                onChange={(value) => props.onChange(value, 'birthDate')}
                handelEditMode={(value) => props.handelEditBirthdate(value)}
                edit={props.editBirthdate}
              />
              {props.currentField === 'birthdate' && (
                <Text
                  text=" Attention, si vous modifiez la date de naissance, n’oubliez
                  pas de vérifier également le numéro de Sécurité Sociale."
                  className="notice-uptate-field"
                ></Text>
              )}
            </div>
            <div className="item-col-profile item-col-profile-margin-right col-input-container-profile">
              <InputProfileOrganism
                label={'Organisme de rattachement'}
                value={props.data.rattachement}
                onChange={(value) => props.onChange(value, 'rattachement')}
              />
              {props.editAdress && !props.manuelAddressEdit && (
                <div className="btn-edit-manuelle-adresse-profile-container">
                  <Button
                    text="Saisie manuelle"
                    className="btn-edit-manuelle-adresse-profile"
                    onClick={() => props.handelManuelAddressEdit(true)}
                  />
                </div>
              )}
              {props.manuelAddressEdit && (
                <div className="ville-cp-input-container">
                  <div className="item-col-profile item-col-profile-margin-left">
                    <InputProfileOrganism
                      edit={true}
                      label={'Code postal'}
                      value={props.data.cp}
                      onChange={(value) => props.onChange(value, 'cp')}
                      {...(window.innerWidth > 768 && {
                        classNameContainer: 'input-ville-profile-container'
                      })}
                      {...(window.innerWidth > 768 && {
                        classNameInput: 'input-ville-profile'
                      })}
                    />
                  </div>
                  <div className="item-col-profile item-col-profile-margin-right">
                    <InputProfileOrganism
                      edit={true}
                      label={'Ville'}
                      value={props.data.ville}
                      onChange={(value) => props.onChange(value, 'ville')}
                      {...(window.innerWidth > 768 && {
                        classNameContainer: 'input-ville-profile-container'
                      })}
                      {...(window.innerWidth > 768 && {
                        classNameInput: 'input-ville-profile'
                      })}
                    />
                  </div>
                </div>
              )}
              {props.editAdress && (
                <Upload
                  handleFileChange={props.selectFile}
                  contentClassName="upload-file-add-beneficiaire-profile-input"
                  classNameText="upload-text-add-beneficiaire-profile"
                  {...(window.innerWidth > 768 && {
                    child: (
                      <div className="btn-upload-container-profile">
                        <div className="upload-doc-adresse-profile">
                          <Text
                            text="Justificatif-domicile.pdf"
                            className="placeholder-upload-doc-profile"
                          />
                          <Icon icon="Plus" size={14} />
                        </div>
                        <Text
                          text="PDF, JPEG -  5mo maximum autorisé"
                          className="condition-upload-doc-profile"
                        />
                      </div>
                    )
                  })}
                />
              )}

              {(props.editAdress ||
                (props.oldtel != props.data.tel && window.innerWidth <= 768) ||
                (props.oldMail != props.data.mail &&
                  window.innerWidth <= 768)) && (
                <div className="btn-commit-edit-adresse-profile-big-container">
                  <div className="btn-commit-edit-adresse-profile-container">
                    <Button
                      text="Valider"
                      className="btn-submit-edit-adresse-profile"
                      onClick={() =>
                        !props.editAdress
                          ? props.showModal()
                          : props.handelEditAdresse(false)
                      }
                    />
                    <Button
                      text="Annuler"
                      className="btn-cancel-edit-adresse-profile"
                      onClick={() => props.handelEditAdresse(false)}
                    />
                  </div>
                </div>
              )}
            </div>
            {(props.editBirthdate ||
              props.editLastname ||
              props.editNumeroSecuriteSociale) && (
              <div className="col-input-container-profile item-col-profile item-col-profile-margin-left">
                <div className="btn-submit-container-edit-tel">
                  <Button
                    text="Valider les modifications"
                    className="btn-submit-edit-profile"
                    onClick={() => props.showModal()}
                    color={COLOR_BUTTON.GREEN}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div></div>
      </div>
      <div className="profile-title-bank">
        <Text text="Informations bancaires" className="profile-sub-title" />
      </div>
      <div className="profile-btn-bank-type">
        <Button
          text="Remboursements"
          className={
            props.data.remboursement
              ? 'btn-radio-bank-profile-selected'
              : 'btn-radio-bank-profile-unselected'
          }
          boxShadow
          onClick={() => props.onChangeBankType('remboursement')}
          color={COLOR_BUTTON.GREEN}
        />
        <Button
          text="Prélèvements"
          className={
            props.data.prelevement
              ? 'btn-radio-bank-profile-selected'
              : 'btn-radio-bank-profile-unselected'
          }
          boxShadow
          onClick={() => props.onChangeBankType('prelevement')}
          color={COLOR_BUTTON.GREEN}
        />
      </div>
      <div className="row-profile">
        <div>
          <div className="line-edit-bank-profile">
            <div className="col-profile">
              <InputProfileOrganism
                label={'Propriétaire du compte'}
                value={props.data.accountOwner}
                edit={props.editBank}
                onChange={(value) => props.onChange(value, 'accountOwner')}
              />
            </div>
            <div className="name-bank-container">
              <InputProfileOrganism
                label={'Nom de la banque'}
                value={props.data.bankName}
                edit={props.editBank}
                onChange={(value) => props.onChange(value, 'bankName')}
              />
            </div>
          </div>
          <div className="line-edit-bank-profile">
            <div className="col-profile">
              <div className="col-input-container-profile">
                <InputProfileOrganism
                  label={'IBAN'}
                  value={props.data.iban}
                  edit={props.editBank}
                  onChange={(value) => props.onChange(value, 'iban')}
                />
              </div>
            </div>
            <div>
              <div className="col-input-container-profile">
                <InputProfileOrganism
                  label={'BIC'}
                  value={props.data.bic}
                  edit={props.editBank}
                  onChange={(value) => props.onChange(value, 'bic')}
                />
              </div>
            </div>
          </div>
          <div className="line-edit-bank-profile">
            <div className="col-profile">
              {props.editBank && (
                <Upload
                  handleFileChange={props.selectFile}
                  contentClassName="upload-file-add-beneficiaire-profile-input"
                  classNameText="upload-text-add-beneficiaire-profile"
                  {...(window.innerWidth > 768 && {
                    child: (
                      <div className="btn-upload-container-profile-edit-bank">
                        <div className="upload-doc-adresse-profile">
                          <Text
                            text="Veuillez joindre un RIB (obligatoire)"
                            className="placeholder-upload-doc-profile"
                          />
                          <Icon icon="Plus" size={14} />
                        </div>
                      </div>
                    )
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {props.showConfirmationModal && (
        <ConfirmPasswordModal
          show={props.showConfirmationModal}
          onHide={props.onHide}
          modalStep={props.verificationSteps}
          title="Validation des modifications"
          firstText={`Nous venons d’envoyer un code par ${
            props.editTel
              ? `email à l’adresse  ${props.data.mail}`
              : `SMS au ${props.data.tel}`
          }`}
          secondText="Veuillez le renseigner ci-dessous pour confirmer les modifications :"
          onConfirmCode={() => props.onConfirmCode()}
          onCancel={() => props.onHide()}
          successText="Vos modifications ont été enregistrées."
          classNameBtncancel="btn-cancel-modal-edit-profile"
          classNameBtnSubmit="btn-submit-modal-edit-profile"
          classNameInput="input-modal-edit-profile"
          onChange={(e) => props.onChangeCode(e.target.value)}
          code={props.code}
        />
      )}
    </div>
  );
}

export default TemplateProfileScreen2Beneficiaire;
