import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Button, Icon } from '../../Atoms';
import { Modal, ChartLegendMolecule } from '../../Molecules';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, Redirect } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { ModalConfirmRating, ModalRating } from '../../Modals';
import * as moment from 'moment';
import { FALSE } from 'sass';
import ShortcutButton from './components/ShortcutButton';
import Carousel from './components/carrousel';
import styles from './TemplateHome.module.scss';
import NewHomeCarteTpImage from '../../assets/img/NewHomeCarteTpImage.png';
import NewHomeAskRemboursementImage from '../../assets/img/NewHomeRemboursementImage.png';
import NewHomeGarantiesImage from '../../assets/img/NewHomeShowGaranties.png';
import NewHomeAccessPractitienImage from '../../assets/img/NewHomeAccessPractitienImage.png';
import NewHomeMedadomImage from '../../assets/img/newhome-medadom.png';
import NewHomeSendDocumentImage from '../../assets/img/NewHomeSendDocumentImage.png';

function TemplateHome(props) {
  const user = useSelector((state) => state.auth.user);
  const twoYearsAgo = moment().subtract(2, 'year');
  const twoYearsAgoDate = new Date(twoYearsAgo);
  // const [show, setShow] = useState(user.hasIntro);
  const pipeline = useSelector((state) => state.loader.pipeline);
  const [show, setShow] = useState(user.hasIntro);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const tuto = useSelector((state) => state.tuto);
  const sideBar = useSelector((state) => state.sideBar);
  const rating = useSelector((state) => state.rating);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const mediaQuery = window.matchMedia('(max-width: 768px)');
  const shortcutCarouselRef = useRef(null);
  const [carouselPage, setCarouselPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  function htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  const SHORTCUTS_ELEMENTS = [
    {
      text: 'Ma carte de tiers payant',
      img: NewHomeCarteTpImage,
      onClick: () => {
        history.push('/cartetp');
      },
      optClass: 'short-text-container'
    },
    {
      text: 'Demander un remboursement',
      img: NewHomeAskRemboursementImage,
      onClick: () => {
        history.push({
          pathname: '/documents/send',
          search: '?type=facture'
        });
      }
    },
    {
      text: 'Envoi de documents',
      img: NewHomeSendDocumentImage,
      onClick: () => {
        history.push('/documents/send');
      },
      optClass: 'short-text-container'
    },
    {
      text: 'Accéder à un praticien',
      img: NewHomeAccessPractitienImage,
      onClick: () => {
        props.onOpenMedadomModal();
      }
    },
    {
      text: 'Visualiser mes garanties',
      img: NewHomeGarantiesImage,
      onClick: () => {
        history.push('/garanties');
      }
    }
  ];
  const renderMedadomModalContent = () => {
    return (
      <div style={{ height: '100%' }}>
        <div className={styles['medadom-modal-close-container']}>
          <button
            className={styles['medadom-modal-close-btn']}
            onClick={props.onCloseMedadomModal}
          >
            <Icon
              icon="CloseIcon"
              size={20}
              color="#000"
              className={styles['medadom-modal-close-icon']}
            />
          </button>
        </div>
        <div className={styles['medadom-modal-content-container']}>
          {props.medadomModalStep === 1 ? (
            <>
              <div className={styles['medadom-modal-title']}>
                Vous recherchez un professionnel de santé :
              </div>
              <button
                className={styles['medadom-modal-btn']}
                onClick={props.onNextStepMedadomModal}
              >
                Proche de vous
              </button>
              <button
                className={styles['medadom-modal-btn']}
                onClick={props.onPressMedadomTeleconsultation}
              >
                En téléconsultation
              </button>
            </>
          ) : (
            <>
              <div className={styles['medadom-modal-title']}>
                Vous souhaitez :
              </div>
              <button
                className={styles['medadom-modal-btn']}
                onClick={props.onPressMedadomUseHealthService}
              >
                Utiliser votre réseau de soins
              </button>
              <button
                className={styles['medadom-modal-btn']}
                onClick={props.onPressGeolocateAroundMe}
              >
                Géolocaliser un praticien
              </button>
            </>
          )}
        </div>
      </div>
    );
  };
  const handleScroll = useCallback(
    (e) => {
      const el = shortcutCarouselRef.current;
      const currentScroll = el.scrollLeft;
      const totalWidth = el.scrollWidth - el.offsetWidth;
      const itemSize = 190 + 40;
      const page = Math.round(currentScroll / itemSize);
      if (parseInt(carouselPage) !== parseInt(page)) {
        setCarouselPage(page);
      }

      if (currentScroll === totalWidth) {
        setCarouselPage(SHORTCUTS_ELEMENTS.length - 1);
      }
    },
    [carouselPage]
  );

  const getOptionChart = (color) => {
    return {
      tooltip: {
        enabled: false
      },
      stroke: {
        colors: ['undefined']
      },
      chart: {
        id: 'home',
        width: 100,
        type: 'donut',
        height: '100%',
        redrawOnWindowResize: true,
        dropShadow: {
          enabled: false
        },
        events: {
          click: undefined
        }
      },
      legend: {
        show: false
      },
      colors: color,
      dataLabels: {
        formatter: function (val, num, opts) {
          return num.w.globals.series[num.seriesIndex] + ' €';
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Mont',
          fontWeight: 'bold',
          colors: ['#fff']
        },
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          customScale: 0.6,
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          dataLabels: {
            offset: 40,
            minAngleToShowLabel: 0
          },
          donut: {
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: false
              },
              value: {
                fontSize: '14px',
                fontFamily: 'Mont',
                fontWeight: 800,
                color: '#fff'
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '17px',
                fontFamily: 'Mont',
                fontWeight: 800,
                color: '#fff',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                  return total.toFixed(1) + ' €';
                }
              }
            }
          }
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        }
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              type: 'donut',
              width: '100%',
              height: '100%'
            },
            dataLabels: {
              style: {
                fontSize: '12px',
                fontFamily: 'Mont',
                fontWeight: 'bold',
                colors: ['#fff']
              }
            },
            plotOptions: {
              pie: {
                expandOnClick: false,
                dataLabels: {
                  offset: 30,
                  minAngleToShowLabel: 0
                },
                donut: {
                  background: 'transparent',
                  labels: {
                    show: true,
                    name: {
                      show: false
                    },
                    value: {
                      fontSize: '14px',
                      fontFamily: 'Mont',
                      fontWeight: 800,
                      color: '#fff'
                    },
                    total: {
                      show: true,
                      fontSize: '13px',
                      fontFamily: 'Raleway',
                      fontWeight: 800,
                      color: '#fff',
                      formatter: function (w) {
                        const total = w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0);
                        return total.toFixed(1) + ' €';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      ]
    };
  };
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowHeight(window.innerHeight);
    });
  }, []);
  useEffect(() => {
    if (window.innerWidth < 762) {
      if (step == 9) {
        dispatch({ type: 'SET_CURRENT_SIDE_BAR', value: { open: true } });
      } else {
        if (sideBar.open == true) {
          dispatch({ type: 'SET_CURRENT_SIDE_BAR', value: { open: false } });
        }
      }
    }
    if (step == 7 || step == 8 || (step == 9 && window.innerWidth > 762)) {
      dispatch({ type: 'SET_STEP', value: { step: step } });
    } else {
      if (tuto.step != null) {
        dispatch({ type: 'SET_STEP', value: { step: null } });
      }
    }
  }, [step]);
  function renderClassNameTuto(mobileStep, webStep = 0) {
    let className = '';
    if (window.innerWidth < 762) {
      if (mobileStep == step) {
        if (step == 9) {
          className = ' text-tuto-full-opacity';
        } else {
          className = ' tuto-full-opacity';
        }
      }
    } else {
      if (webStep == step) {
        className = ' tuto-full-opacity';
      }
    }
    return className;
  }

  function renderIconClose() {
    const className = ['close-tuto'];
    className.push('close-tuto-step-' + step);
    return (
      <Icon
        icon="Plus"
        className={className.join(' ')}
        onClick={() => onClose()}
      />
    );
  }
  function renderIconCloseCarousel() {
    const className = ['close-tuto'];
    className.push('close-carousel-desc');
    return (
      <Icon
        icon="Plus"
        className={className.join(' ')}
        onClick={() => onClose()}
      />
    );
  }
  function renderCarouselContent() {
    const contentClassName = [];
    const foundCarousel = props.slideshow.findIndex(
      (r) => r.id == carouselIndex
    );
    return (
      <div className={contentClassName.join(' ')}>
        {renderIconCloseCarousel()}
        <div
          style={{ padding: 25 }}
          dangerouslySetInnerHTML={{
            __html: props.slideshow[foundCarousel]?.description
          }}
        />
      </div>
    );
  }

  function renderContentTuto() {
    const contentClassName = ['content-tuto'];
    if (window.innerWidth < 762) {
      if (
        step == 4 ||
        step == 5 ||
        step == 6 ||
        step == 7 ||
        step == 8 ||
        step == 9
      ) {
        contentClassName.push('medium-content-tuto');
      }
      // if (step == 9) {
      //   contentClassName.push('small-content-tuto');
      // }
    } else {
      if (step == 2 || step == 6 || step == 7 || step == 8 || step == 9) {
        contentClassName.push('medium-content-tuto');
      }
      if (step == 3 || step == 4 || step == 5) {
        contentClassName.push('small-content-tuto');
      }
    }
    contentClassName.push('step-' + step);
    return (
      <div className={contentClassName.join(' ')}>
        {renderIconClose()}
        {step === 1 && <Text text="Bienvenue !" className="welcome-tuto" />}
        {step === 1 && (
          <div>
            Retrouvez sur votre nouvel espace assuré toutes vos informations
            essentielles et et
            <span className="close-weight-web"> bien plus encore !</span>
            <p>Suivez-nous pour un tutoriel...</p>
          </div>
        )}
        {step === 2 && <div>Sur votre page d’accueil, vous trouverez :</div>}
        {step == 10 && (
          <p className="close-description">
            Nous vous souhaitons une bonne utilisation de votre{' '}
            <span className="close-weight">nouvel espace !</span>
          </p>
        )}
        {step != 1 && renderDescription()}
        <Button
          text={step === 1 ? 'Commencer' : step === 10 ? 'Terminer' : 'Suivant'}
          onClick={() => (step === 10 ? onClose() : setStep(step + 1))}
          className="btn-tuto"
        />
      </div>
    );
  }
  function onClose() {
    setShow(false);
    setStep(1);
  }
  function renderDescription() {
    let description = '';
    const mobileDescription = [
      '1. La possibilité de visualiser vos garanties',
      '2. Votre carte de tiers-payant digitale à télécharger ou partager par email',
      '3. Les deux derniers remboursements effectués',
      '4. Votre consommation en un coup d’oeil ',
      '4. La possibilité de faire une demande de remboursement',
      '5. La possibilité de nous transmettre des documents !',
      '6. Une barre de tâche qui vous permets d’accéder facilement à la page d’accueil, à votre profil, aux notifications et à l’espace contact',
      '7.  Votre identité, votre numéro d’adhérent ainsi que le menu avec toutes les catégories nécessaires ! '
    ];
    const webDescription = [
      '1. Votre carte de tiers-payant digitale à télécharger ou partager par email',
      '2. La possibilité de nous transmettre des documents !',
      '3. La possibilité de visualiser vos garanties',
      '4. Les deux derniers remboursements effectués',
      '5. La possibilité de faire une demande de remboursement',
      '6. Votre consommation en un coup d’oeil ',
      '7. Une barre de tâche qui vous permets d’accéder facilement à la page d’accueil, à votre profil, aux notifications et à l’espace contact',
      '8.  Votre identité, votre numéro d’adhérent ainsi que le menu avec toutes les catégories nécessaires ! '
      // '7.  Votre identité, votre numéro d’adhérent ainsi que le menu avec toutes les catégories nécessaires !'
    ];
    if (window.innerWidth < 762) {
      description = mobileDescription[step - 2];
    } else {
      description = webDescription[step - 2];
    }
    if (description != '') {
      return (
        <Text text={description} className="description-tuto" loader={false} />
      );
    }
    return null;
  }
  const openModalCarousel = (id) => {
    setCarouselIndex(id);
    setShow(true);
  };

  const renderRemboursement = (element, index) => {
    return (
      <div key={String(index)} className="home-remboursement-container">
        <Text
          text={`${element?.ligne[0]?.ayantDroit}`}
          className="home-remboursement-date"
        />
        <div className="home-remboursement-content-container">
          <div style={{ width: '35%', wordBreak: 'break-word' }}>
            <Text
              text={`Remboursement effectué le ${element?.reglement?.dateReglement}`}
            />
          </div>
          <div style={{ width: '30%', textAlign:'center' }}>
            <Text
              text={`à ${element?.reglement?.beneficiaire}`}
              className="text-default-size"
            />
          </div>
          <div style={{ width: '30%' }}>
            <Text
              text={`Remboursement CGRM :${element?.reglement?.totalRembCGRM}€`}
              className="text-default-size"
            />
          </div>
        </div>
      </div>
    );
  };

  const contractTemplate = () => {
    return (
      <>
        <Modal
          show={props.medadomModalOpen}
          onHide={props.onCloseMedadomModal}
          dialogClassName={styles['medadom-modal-container']}
          contentClassName={styles['medadom-modal']}
          showIconClose={false}
        >
          {renderMedadomModalContent()}
        </Modal>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          contentClassName=""
          showIconClose={false}
          size={window.innerWidth < 768 ? 'meduim' : 'larg'}
        >
          {renderCarouselContent()}
        </Modal>
        <div
          className={`home-container template-scroll mb-36 pt-34 ${styles['home-container']}`}
        >
          <div style={{ gridArea: 'shortcuts' }}>
            <Row className={styles['container-row']}>
              <Col xs={12} style={{ padding: 0 }}>
                <div
                  ref={shortcutCarouselRef}
                  className={styles['carousel-container']}
                  onScroll={handleScroll}
                >
                  {SHORTCUTS_ELEMENTS.map((item, idx) => (
                    <ShortcutButton
                      key={idx}
                      text={item.text}
                      image={item.img}
                      onClick={item.onClick}
                      optClass={item.optClass}
                      disableArrow={idx === SHORTCUTS_ELEMENTS.length - 1}
                    />
                  ))}
                </div>
                {/* <div className={styles['carousel-dots-container']}>
                  {SHORTCUTS_ELEMENTS.map((item, idx) => (
                    <div
                      key={idx}
                      className={styles['carousel-dots']}
                      style={{
                        backgroundColor:
                          carouselPage === idx ? '#fff' : '#d9d9d9'
                      }}
                    />
                  ))}
                </div> */}
              </Col>
            </Row>
          </div>
          <div style={{ gridArea: 'repayments' }}>
            <Row>
              <Col xs={12}>
                <h1 className={`${styles['category-title']}`}>
                  Derniers remboursements
                </h1>
              </Col>
            </Row>
            <Row className={styles['container-row']}>
              <Col xs={12}>
                <div
                  className={styles['remboursement-container']}
                  onClick={props.remboursementClicked}
                >
                  {!pipeline?.length && props.remboursement?.length === 0 ? (
                    <Text
                      text="Vous n'avez pas de remboursements."
                      type="h8"
                      style={{
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: 'bold'
                      }}
                    />
                  ) : null}
                  {props.remboursement.map((el, i) =>
                    renderRemboursement(el, i)
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ gridArea: 'additional-services' }}>
            <Row>
              <Col xs={12}>
                <h1 className={`${styles['category-title']}`}>Informations</h1>
              </Col>
            </Row>
            <Row className={styles['container-row']}>
              <Col xs={12} lg={12} style={{ cursor: 'pointer' }}>
                <div className="pr-xl-3">
                  <Carousel>
                    {props.slideshow?.map((element, index) => {
                      return element.type == 'actu' ? (
                        <div
                          style={{
                            whiteSpace: 'pre-wrap',
                            backgroundColor: '#0D5381',
                            padding: 5
                          }}
                          onClick={() => {
                            openModalCarousel(element.id);
                          }}
                          dangerouslySetInnerHTML={{
                            __html: element.previewActuality
                          }}
                        ></div>
                      ) : (
                        <img
                          src={element.imageLink}
                          key={index}
                          style={{ maxWidth: '100%', height: 'auto' }}
                          alt="placeholder"
                          onClick={() => {
                            openModalCarousel(element.id);
                          }}
                        />
                      );
                    })}
                  </Carousel>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ gridArea: 'consomation' }} className="pr-xl-3">
            <Row>
              <Col xs={12}>
                <h1 className={`${styles['category-title']}`}>
                  Consommation {new Date().getFullYear()}
                </h1>
              </Col>
            </Row>
            <Row className={styles['container-row']}>
              <Col xs={12}>
                <Link
                  to={{
                    pathname: '/consommation',
                    state: {
                      status: 'consomation',
                      beginDate: twoYearsAgoDate,
                      endDate: new Date()
                    }
                  }}
                >
                  <div
                    className={`home-consomation-card-container ${styles['home-consomation']}`}
                  >
                    <div className="home-chart-container">
                      <div
                        className={`chart-legends-container ${styles['home-consomation-legend-container']}`}
                      >
                        {props.seriesVisionGlobal.map((element, index) => {
                          return (
                            <ChartLegendMolecule
                              key={String(index)}
                              legend={element.legend}
                              color={element.color}
                              classNameContainer="home-legend-container"
                              classNameText={`home-legend-text ${styles['home-consomation-legend-text']}`}
                            />
                          );
                        })}
                      </div>
                      <div className="home-chart-content">
                        <Chart
                          options={getOptionChart(
                            props.seriesVisionGlobal.map(
                              (element) => element.color
                            )
                          )}
                          series={props.seriesVisionGlobal.map(
                            (element) => element.data
                          )}
                          type="donut"
                          height={windowHeight > 900 ? '200px' : '150px'}
                          width={windowWidth < 1470 ? '150px' : '200px'}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  if (FALSE) {
    return contractTemplate();
  } else {
    return (
      <div className="home-container template-scroll mb-61 pt-34">
        <ModalRating
          show={rating.show}
          onHide={props.ratingModalHide}
          size={window.innerWidth < 768 ? 'small' : 'meduim'}
          contentClassName="rating-content"
          containerIconClassName="rating-icon-container"
          garanteeValue={props.garanteeValue}
          ratingChange={props.ratingChange}
          manegementValue={props.manegementValue}
          manegementComentary={props.manegementComentary}
          manegementSendClicked={props.manegementSendClicked}
          garanteeSendClicked={props.garanteeSendClicked}
          garanteeComentary={props.garanteeComentary}
          hide={props.ratingModalHide}
          hideGaranteeComentary={props.hideGaranteeComentary}
          hideManegementComentary={props.hideManegementComentary}
          ratingGaranteeChange={props.ratingGaranteeChange}
          ratingManegementChange={props.ratingManegementChange}
          onHideWithReset={props.ratingModalHide}
        />
        <ModalConfirmRating
          contentClassName="rating-content"
          containerIconClassName="rating-icon-container"
          show={props.modalSentShow}
          onHide={props.ratingModalHide}
          onHideWithReset={props.ratingModalHide}
          size={window.innerWidth < 768 ? 'small' : 'meduim'}
        />
        <Modal
          show={show}
          onHide={() => setShow(false)}
          contentClassName="modal-tuto"
          showIconClose={false}
        >
          {renderContentTuto()}
        </Modal>

        <Row style={{ height: '100%' }}>
          <Col
            lg={{ span: 4, order: 1 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 6, order: 2 }}
            className="home-remboursement-col"
          >
            <Link to="/cartetp">
              <div
                className={
                  'home-carteTp-card-container' + renderClassNameTuto(3, 2)
                }
              >
                <Text text="Ma carte de tiers-payant" />
              </div>
            </Link>
          </Col>
          <Col
            lg={{ span: 4, order: 2 }}
            md={{ span: 6, order: 6 }}
            xs={{ span: 6, order: 6 }}
            className="home-remboursement-col"
          >
            <Link to="/documents/send">
              <div
                className={
                  'home-document-card-container' + renderClassNameTuto(7, 3)
                }
              >
                <Text text="Envoyer un document" />
              </div>
            </Link>
          </Col>
          <Col
            lg={{ span: 4, order: 3 }}
            md={{ span: 6, order: 1 }}
            xs={{ span: 6, order: 1 }}
            className="home-remboursement-col home-remboursement-cantact-card-container"
          >
            <Link to="/Garanties">
              <div
                className={
                  'home-contact-card-container' + renderClassNameTuto(2, 4)
                }
              >
                <Text text="Visualiser mes" />
                <Text text="garanties" />
              </div>
            </Link>
          </Col>
          <Col
            lg={{ span: 12, order: 4 }}
            md={{ span: 12, order: 3 }}
            xs={{ span: 12, order: 3 }}
            className="home-remboursement-col"
          >
            <div
              className={
                'home-remboursement-card-container' + renderClassNameTuto(4, 5)
              }
              onClick={() => props.remboursementMobileClicked()}
            >
              <>
                <Text
                  text="Derniers remboursements"
                  className="home-remboursement-title"
                />
                {props.remboursement.map((el, i) => renderRemboursement(el, i))}
                <div className="home-remboursement-button-container">
                  <Button
                    text="Voir tous mes remboursements"
                    className="home-remboursement-button"
                    onClick={props.remboursementClicked}
                  />
                </div>
              </>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 5 }}
            md={{ span: 6, order: 5 }}
            xs={{ span: 6, order: 5 }}
            className="home-remboursement-col home-remboursement-cantact-card-container"
          >
            <div
              className={
                'home-actuality-card-container' + renderClassNameTuto(6, 6)
              }
              onClick={() => props.SendBillMobileClicked()}
            >
              <Text
                text="Demander un remboursement"
                className="home-actuality-content-title"
              />
              <div className="home-actuality-description-container">
                <Text
                  text={
                    <>
                      Vous souhaitez vous faire rembourser des frais de santé ?{' '}
                      <span className="home-actuality-description-bold close-weight">
                        Envoyez nous votre facture !
                      </span>
                    </>
                  }
                  className="home-actuality-description"
                />
              </div>
              <div className="home-remboursement-button-container">
                <Button
                  text="Envoyer votre facture"
                  className="home-remboursement-button"
                  onClick={() => {
                    props.sendBill();
                  }}
                />
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 6 }}
            md={{ span: 12, order: 4 }}
            xs={{ span: 12, order: 4 }}
            className="home-remboursement-col"
          >
            <Link
              to={{
                pathname: '/consommation',
                state: {
                  status: 'consomation',
                  beginDate: twoYearsAgoDate,
                  endDate: new Date()
                }
              }}
            >
              <div
                className={
                  'home-consomation-card-container' + renderClassNameTuto(5, 7)
                }
              >
                <Text
                  text={`Consommation ${new Date().getFullYear()}`}
                  className="home-consomation-title"
                />
                <div className="home-chart-container">
                  <div className="chart-legends-container">
                    {props.seriesVisionGlobal.map((element, index) => {
                      return (
                        <ChartLegendMolecule
                          key={String(index)}
                          legend={element.legend}
                          color={element.color}
                          classNameContainer="home-legend-container"
                          classNameText="home-legend-text"
                        />
                      );
                    })}
                  </div>
                  <div className="home-chart-content">
                    <Chart
                      options={getOptionChart(
                        props.seriesVisionGlobal.map((element) => element.color)
                      )}
                      series={props.seriesVisionGlobal.map(
                        (element) => element.data
                      )}
                      type="donut"
                      height={windowHeight > 900 ? '230px' : '150px'}
                      width="200px"
                    />
                  </div>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
        {/* <div
          className={'feed-back-container ' + renderClassNameTuto(0, 8)}
          onClick={props.ratingModalShowClicked}
        >
          Votre avis nous intéresse
        </div> */}
      </div>
    );
  }
}

export default TemplateHome;
