import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

export const TrackPageView = () => {
  const { trackPageView } = useMatomo();
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location) => {
      trackPageView({
        href: location.pathname
      });
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};
