import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

MoleculeButtonDropDown.defaultProps = {
  containerOptionClassName: '',
  ListDropDown: [{ item: 'ajouter' }],
  dots: false,
  ButtonContainerClassName: 'dropdown-select',
  ButtonContentClassName: 'select',
  icon: null,
  positionLeft: true,
  ButtonValue: 'Nouveau',
  ButtonValueClassName: 'btn-dropDown',
  ContainerDropdownClassName: 'dropdown',
  parentClick: false,
  visibility: false
};
MoleculeButtonDropDown.PropTypes = {
  containerOptionClassName: PropTypes.string,
  ListDropDown: PropTypes.array,
  dots: PropTypes.bool,
  ButtonContainerClassName: PropTypes.string,
  ButtonContentClassName: PropTypes.string,
  icon: PropTypes.elementType,
  positionLeft: PropTypes.bool,
  ButtonValue: PropTypes.string,
  ButtonValueClassName: PropTypes.string,
  ContainerDropdownClassName: PropTypes.string,
  parentClick: PropTypes.bool,
  visibility: PropTypes.bool
};
export default function MoleculeButtonDropDown(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [visibility, setVisibility] = useState(false);
  const localVisibility =
    props.parentClick === true ? props.visibility : visibility;
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (props.parentClick === true) {
            props.setVisibility(false);
          } else {
            setVisibility(false);
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  let className = 'container-element-option ';
  className += props.containerOptionClassName;
  if (localVisibility === false) {
    className += ' invisible-container-element-option';
  } else {
    className += ' visible-container-element-option';
  }
  if (props.dots == false) {
    className += ' button-visible ';
  }
  function renderOption() {
    const list = [];
    props.ListDropDown.map(element => {
      list.push(
        <div onClick={element.onClick} className={element.className}>
          {element.item}
        </div>
      );
    });
    return list;
  }
  function renderDots() {
    const list = [];
    if (props.dots === true) {
      list.push(<div className='dot-option'></div>);
      list.push(<div className='dot-option'></div>);
      list.push(<div className='dot-option'></div>);
    }
    return list;
  }
  function renderButton() {
    if (props.dots !== true) {
      return (
        <div className={props.ContainerDropdownClassName}>
          <span className={props.ButtonContentClassName}>
            {props.positionLeft == true ? props.icon : null}
            <div className={props.ButtonValueClassName}>
              {props.ButtonValue}
            </div>
            {props.positionLeft == false ? props.icon : null}
          </span>
        </div>
      );
    }
    return null;
  }
  return (
    <div
      id='ddButton'
      className={
        props.dots === true
          ? 'container-dots'
          : props.ContainerDropdownClassName
      }
      onClick={() =>
        props.parentClick === true
          ? props.setVisibility(!props.visibility)
          : setVisibility(!visibility)
      }
      ref={wrapperRef}
    >
      {renderDots()}
      <div style={{ position: 'relative' }}>
        {renderButton()}
        <div className={className}>{renderOption()}</div>
      </div>
    </div>
  );
}
