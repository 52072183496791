import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { getFrenchCitiesByDepartment as apiGetFrenchCitiesByDepartment } from '../../../../Api/Geolocation/Geolocation.api';
import styles from '../../Geolocation.module.scss';
import theme from './AutosuggestTheme.module.scss';

const getSuggestionValue = (suggestion) =>
  `${suggestion.postCode} - ${suggestion.cityName}`;
const renderSuggestion = (suggestion) =>
  suggestion.loading
    ? 'Chargement en cours'
    : `${suggestion.postCode} - ${suggestion.cityName}`;

const CitiesAutosuggest = ({
  value,
  onChangeValue,
  onSelectSuggestion,
  departmentNumber,
  ...rest
}) => {
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoader] = useState(true);

  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) => {
    const citiesFilter = (dep, index) => {
      const baseSearch = getSuggestionValue(dep);
      const keywords = value.split(' ').filter((i) => !!i);

      return keywords.every((keyword) =>
        baseSearch.toLowerCase().includes(keyword.toLowerCase())
      );
    };
    setSuggestions(cities.filter(citiesFilter));
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);
  const shouldRenderSuggestions = (value, reason) => {
    if (citiesLoading) {
      return true;
    }
    return true;
  };

  const inputProps = {
    value: value,
    onChange: (e) => onChangeValue(e.target.value),
    placeholder: 'Exemple: Paris',
    id: 'cities-input',
    autoComplete: 'off',
    'data-lpignore': true,
    'data-form-type': 'other'
  };

  const getCities = async () => {
    setCitiesLoader(true);

    const res = await apiGetFrenchCitiesByDepartment(departmentNumber);
    if (res.status === 200) {
      setCitiesLoader(false);
      setCities(res.data);
    }
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    onSelectSuggestion({
      ...suggestion,
      formated: getSuggestionValue(suggestion)
    });
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <div {...rest}>
      <label
        htmlFor="cities-input"
        className={theme['autosuggest-input-label']}
      >
        Indiquez une ville
      </label>
      <Autosuggest
        suggestions={!citiesLoading ? suggestions : [{ loading: true }]}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        theme={theme}
      />
    </div>
  );
};

export default CitiesAutosuggest;
