import React from 'react';
import { Text, Icon, Switch } from '../../Atoms';
function OraganismSetting (props) {
  return (
    <div className={props.containerClassName}>
      <div>
        <Text text={props.title} className={props.titleClassName} />
        <Text text={props.description} className={props.descriptionClassName} />
      </div>
      {props.password ? (
        <Icon
          size={props.size}
          icon={props.icon}
          className={props.iconClassName}
          style={{cursor: 'pointer'}}
          onClick={props.onClick}
        />
      ) : (
        <div
          className={
            props.check ? 'container-switch-checked' : 'container-switch'
          }
        >
          <Switch
            {...props}
            checked={props.check}
            onChange={props.setCheck}
            {...(props.check && { className: 'setting-switch' })}
          />
        </div>
      )}
    </div>
  );
}
OraganismSetting.defaultProps = {
  icon: 'SortTop',
  size: '12px',
  iconClassName: 'icon-next-step',
  title: '',
  description: '',
  password: false,
  onClick: null,
  check: false,
  setCheck: null,
  titleClassName: 'title-organism-setting',
  descriptionClassName: 'description-organism-setting',
  containerClassName: 'container-organism-setting'
};
export default OraganismSetting;
