import React from 'react';
import styles from './LoginStyles.module.scss';
import backLogoTopLeft from '../../assets/img/login-logo-top-left.png';
import backRectangleTopLeft from '../../assets/img/login-top-centerleft.png';
import backShapeTopRight from '../../assets/img/login-top-right.png';
import backTriangleBottomRight from '../../assets/img/login-bottom-right.png';
import backShapeBottomLeft from '../../assets/img/login-bottomcenter-left.png';
import backCircleCenter from '../../assets/img/circle-dotted.png';

const LoginBackground = ({ children }) => {
  return (
    <>
      <div className={styles.background}>
        <div className={styles.backgroundLayer1}>
          <img src={backLogoTopLeft} className={styles.backgroundImage1} />
        </div>
        <div className={styles.backgroundLayer2}>
          <img src={backRectangleTopLeft} className={styles.backgroundImage2} />
        </div>
        <div className={styles.backgroundLayer3}>
          <img src={backShapeTopRight} className={styles.backgroundImage3} />
        </div>
        <div className={styles.backgroundLayer4}>
          <img
            src={backTriangleBottomRight}
            className={styles.backgroundImage4}
          />
        </div>
        <div className={styles.backgroundLayer5}>
          <img src={backShapeBottomLeft} className={styles.backgroundImage5} />
        </div>
        <div className={styles.backgroundLayer6}>
          <img src={backCircleCenter} className={styles.backgroundImage6} />
        </div>
      </div>
      {children}
    </>
  );
};

export default LoginBackground;
