import React from 'react';
import { Img, Text, Button, Icon } from '../../Atoms';
import { Input, Modal } from '../../Molecules';
import notifTel from '../../assets/img/notifTel.png';

function ModalAddBeneficiary(props) {
  return (
    <Modal
      show={props.show}
      showIconClose={false}
      centered={true}
      contentClassName={'content-modal-confirm-password'}
    >
      <div className="full-container">
        <Icon
          icon="BigX"
          className="icon-close-modal-confim-password"
          size="20px"
          onClick={() => props.onClose()}
        />
        <Text
          text={`Demande d'ajout prise en compte !`}
          className="title-modal-confirm-password"
        />
        <p
          style={{ fontFamily: 'Mont', marginBottom: 10, textAlign: 'center' }}
        >
          En cas de besoin, notre équipe du service de gestion vous recontactera
          pour vous fournir les détails nécessaires à l'ajout de cette demande.
        </p>

        <p
          style={{ fontFamily: 'Mont', marginBottom: 10, textAlign: 'center' }}
        >
          Une fois celle-ci finalisée,
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            vous recevrez prochainement votre carte de tiers payant ainsi qu'un
            nouvel échéancier
          </span>{' '}
          en cas de modification tarifaire.
        </p>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="primary"
            text="Terminer"
            className="btn-submit-modal-edit-profile"
            onClick={() => props.onClose()}
          />
        </div>
      </div>
    </Modal>
  );
}

ModalAddBeneficiary.defaultProps = {
  img: notifTel,
  contentClassName: '',
  modalStep: 1,
  title: '',
  firstText: '',
  secondText: '',
  onConfirmCode: null,
  onCancel: null,
  classNameBtncancel: 'btn-cancel-confirm-password',
  classNameBtnSubmit: 'btn-submit-confirm-password',
  classNameInput: 'input-confirm-change',
  onChange: null,
  code: ''
};
export default ModalAddBeneficiary;
