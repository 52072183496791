import React, { useState } from "react"
import Switch from "react-switch";
import PropTypes from 'prop-types';
import { pink, white } from "../../Config/Config";

function AtomSwitch(props) {
    return <Switch
        checked={props.check}
        onChange={props.setCheck}
        uncheckedIcon={props.uncheckedIcon}
        checkedIcon={props.checkedIcon}
        onColor={props.onColor}
        offHandleColor={props.offHandleColor}
        offColor={props.offColor}
        width={props.width}
        height={props.height}
        borderRadius={props.borderRadius}
        className={props.className}
    />
}
AtomSwitch.PropTypes = {
    checked: PropTypes.bool,
    uncheckedIcon: PropTypes.bool,
    checkedIcon: PropTypes.bool,
    onChange: PropTypes.any,
    className: PropTypes.string,
    onColor: PropTypes.string,
    offHandleColor: PropTypes.string,
    offColor: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
};
AtomSwitch.defaultProps = {
    checked: false,
    checkedIcon: false,
    uncheckedIcon: false,
    onChange: null,
    className: "switch",
    onColor: pink,
    offColor: white,
    offHandleColor: pink,
    width: 52,
    height: 15,
    borderRadius: 10,
};
export default AtomSwitch
