import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OrganismSelectContact } from '..';
import { Button, Text, Img, Icon, DatePicker } from '../../Atoms';
import { Upload, Input } from '../../Molecules';
import fileImage from '../../assets/img/file.png';
import nature from '../../Json/Nature.json';
import { ModalInfoDocument } from '../../Modals';
import { COLOR_BUTTON } from '../../Config/Config';
import { apiGetMembers } from '../../Api/Profile/Profile';

function OrganismFormDocument(props) {
  const [show, setShow] = React.useState(false);
  const [benefItems, setBenefItems] = React.useState([]);
  useEffect(() => {
    const fetchBenef = async () => {
      if (props.stepMedicine === 1) {
        const res = await apiGetMembers();
        if (!!res?.benificiaries?.length) {
          setBenefItems(
            res?.benificiaries?.map((benef) => {
              const fullname = `${benef.firstName} ${benef.lastName}`;
              return {
                value: fullname,
                text: fullname
              };
            })
          );
        }
      }
    };

    fetchBenef();
  }, []);
  function renderStep2() {
    return (
      <div>
        <Text
          text={props.type.name + ' - ' + props.category}
          type="h8"
          className="header-description-document-step2"
        />

        <Input
          className="input-decription-document input-description-medicine"
          onChange={(e) => props.onChange(e)}
          value={props.description}
          as="textarea"
          placeholder="Laissez-nous un message qui décrit votre besoin (facultatif)"
        />

        <div className="container-description-spvie-medicine">
          <Text
            text="Vous pouvez également nous transmettre ces documents par voie postale à l'adresse ci-dessous :"
            className={'description-condition-send-document  doc-mar-bot'}
          />
          <div className="description-spvie-medicine">
            <Text
              text="SPVIE Remboursements"
              className={'description-condition-send-document  doc-mar-bot'}
            />
            <Text
              text="CGRM TSA 91005"
              className={'description-condition-send-document doc-mar-bot'}
            />
            <Text
              text="59952 Dunkerque"
              className={'description-condition-send-document doc-mar-bot'}
            />
          </div>
        </div>
        <div className="container-btn-send-doc">
          <Button
            text="Envoyer"
            className="btn-send-doc btn-send-doc-medicine"
            onClick={() => props.onSubmit()}
            resetHover={true}
          />
        </div>
      </div>
    );
  }

  if (
    props.type.name.toLowerCase() === 'facture' &&
    props.category.toLowerCase() === 'médecine douce'
  ) {
    return (
      <div>
        {props.stepMedicine === 1 && (
          <div>
            <Row className="row-document">
              <Col xs={6} className="col-document">
                <Text
                  text="Bénéficiaire du soin"
                  className="label-input-document"
                />

                <OrganismSelectContact
                  containerClassName="select-document-container"
                  placeholder=""
                  iconClassName="icon-select-document"
                  onChange={(e) => props.onChangeMedicine(e, 'benefitaire')}
                  value={props.medicine.benefitaire}
                  containerMobileOption="container-option-mobile-document"
                  subTitleMobileClassName="sub-title-select-document"
                  headerClassName="header-select-document"
                  options={benefItems}
                  iconSize={'7px'}
                />
                {/* <Input
                  placeholder=""
                  as="select"
                  classNameInputContainer="full-container"
                  className="input-document"
                  iconClassName="icon-select-document"
                  options={benefItems}
                  onChange={(e) => props.onChangeMedicine(e, 'benefitaire')}
                  value={props.medicine.benefitaire}
                  optionsClassName="option-select-document"
                  iconSelectSize={'7px'}
                /> */}
              </Col>
              <Col xs={6} className="col-document">
                <Text text="Date du soin" className="label-input-document" />
                <DatePicker
                  placeholder="JJ/MM/AAAA"
                  renderIcon={false}
                  containerClassName="full-container"
                  className="full-container"
                  onChange={(e) => props.onChangeMedicine(e, 'date')}
                  selected={props.medicine.date}
                />
              </Col>
              <Col xs={6} lg={12} className="col-document">
                <Text
                  text="Nature de la prestation"
                  className="label-input-document"
                />
                <OrganismSelectContact
                  containerClassName="select-document-container"
                  placeholder=""
                  iconClassName="icon-select-document"
                  onChange={(e) => props.onChangeMedicine(e, 'nature')}
                  value={props.medicine.nature}
                  containerMobileOption="container-option-mobile-document"
                  titleMobile="Choissisez la nature de votre prestation"
                  subTitleMobileClassName="sub-title-select-document"
                  subTitleMobile="Attention, si une des prestations ci-dessous est grisée cela veut dire que l’acte est non garanti dans votre contrat."
                  header="Facture - Medecine douce"
                  headerClassName="header-select-document"
                  options={nature.data}
                  iconSize={'7px'}
                />
              </Col>
              <Col xs={6} className="col-document">
                <div className="container-spaced">
                  <Text
                    text="Code Adeli ou N° Siret"
                    className="label-input-document"
                  />
                  <Icon
                    icon="info"
                    size="14px"
                    className="icon-info-document"
                    onClick={() => setShow(true)}
                  />
                </div>
                <Input
                  type="number"
                  placeholder=""
                  classNameInputContainer="full-container"
                  onChange={(e) =>
                    props.onChangeMedicine(e.target.value, 'code')
                  }
                  value={props.medicine.code}
                />
              </Col>
              <Col xs={12} lg={6}>
                <div className="container-amount-document">
                  <Text
                    text="Montant dépensé :"
                    className="label-input-document"
                  />
                  <Input
                    value={props.medicine.amount}
                    onChange={(e) =>
                      props.onChangeMedicine(e.target.value, 'amount')
                    }
                    className="input-amount-doc"
                    classNameContainer="container-input-amount-doc"
                    placeholder=""
                  />
                  <Text text="€" className="label-input-document" />
                </div>
              </Col>
            </Row>
            <Upload
              handleFileChange={props.selectFile}
              id="document"
              contentClassName={'input-file-document'}
              style={{ height: '1px' }}
              uploadContent={
                window.innerWidth > 768 ? (
                  <div className="content-input-file-document">
                    <Img src={fileImage} className="img-file-upload" />
                    <div>
                      Glissez et déposez vos fichiers ici, ou{' '}
                      <span>téléchargez-les</span> depuis votre ordinateur
                    </div>
                  </div>
                ) : null
              }
            />
            {!props.showNewMutual && (
              <div className="container-btn-send-doc container-btn-send-doc-medicine ">
                <Button
                  text="Ajouter une autre mutuelle"
                  className="btn-send-doc btn-add-mutual"
                  onClick={() => props.openNewMutual()}
                  color={COLOR_BUTTON.SALMON}
                />
              </div>
            )}
            {props.showNewMutual && (
              <div>
                <div className="container-mutual-amount-document">
                  <Text
                    text="Montant remboursé par l’autre mutuelle :"
                    className="label-input-document"
                  />
                  <Input
                    value={props.mutual.amount}
                    onChange={(e) =>
                      props.onChangeMutual(e.target.value, 'amount')
                    }
                    className="input-amount-doc"
                    classNameContainer="container-input-amount-doc"
                    placeholder=""
                  />
                  <Text text="€" className="label-input-document" />
                </div>
                <Text
                  text="Merci de bien vouloir nous transmettre le justificatif de votre autre mutuelle"
                  className="label-input-document"
                />
                <Upload
                  handleFileChange={(e) => props.onChangeMutual(e, 'file')}
                  id="mutual"
                  contentClassName={'input-file-document'}
                  style={{ height: '1px' }}
                  uploadContent={
                    window.innerWidth > 768 ? (
                      <div className="content-input-file-document">
                        <Img src={fileImage} className="img-file-upload" />
                        <div>
                          Glissez et déposez vos fichiers ici, ou{' '}
                          <span>téléchargez-les</span> depuis votre ordinateur
                        </div>
                      </div>
                    ) : null
                  }
                />
              </div>
            )}
            <div className="container-btn-send-doc container-btn-send-doc-medicine">
              <Button
                text="Suivant"
                className="btn-send-doc"
                onClick={() => props.medicineStep(2)}
                color={COLOR_BUTTON.SALMON}
              />
            </div>
          </div>
        )}
        {props.stepMedicine == 2 && renderStep2()}
        <ModalInfoDocument show={show} onHide={() => setShow(false)} />
      </div>
    );
  }
  return (
    <div style={{ paddingBottom: '30px' }}>
      <Upload
        handleFileChange={props.selectFile}
        errorMsg="Attention : merci de bien vouloir nous joindre votre document."
        isUploadValid={props.isUploadValid}
      />
      <Input
        placeholder="Laissez-nous un message qui décrit votre besoin (obligatoire)"
        className="input-decription-document"
        onChange={(e) => props.onChange(e)}
        as="textarea"
        value={props.description}
        errorMessage="Attention : merci de bien vouloir joindre un message à votre envoi."
        isInvalid={!props.isDescriptionValid}
      />
      <div className="container-btn-send-doc">
        <Button
          disabled={
            !props.isUploadValid ||
            !props.isDescriptionValid ||
            (props.type?.name == 'FACTURE' && props.seuil === 0)
          }
          text="Envoyer"
          className="btn-send-doc"
          onClick={() => props.onSubmit()}
          resetHover={true}
        />
      </div>
    </div>
  );
}
OrganismFormDocument.defaultProps = {
  type: '',
  category: '',
  onSubmit: null,
  onChange: null,
  description: null,
  selectFile: null,
  selectBenef: null,
  medicineStep: null,
  openNewMutual: null
};
export default OrganismFormDocument;
