import React, { Component } from 'react';
import { SendDocumentTemplate } from '../../Templates';
import {
  apiGetAllCategories,
  apiPostSendDocument,
  apiGetSeuilFacture,
  apiPostNewSendDocument
} from '../../Api/Documents/Documents';
import { isNumber } from '../../Validation';
import Store from '../../Redux/Store';
import moment from 'moment';
class SendDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      category: null,
      type: null,
      description: '',
      files: [],
      medicine: {
        benefitaire: '',
        date: null,
        nature: '',
        code: '',
        amount: ''
      },
      stepMedicine: 1,
      showNewMutual: false,
      mutual: { amount: '', file: null },
      remboursement: '48',
      isUploadValid: true,
      isDescriptionValid: true,
      data: [],
      contractTypes: Store.getState().auth.user.contractTypes,
      categoryList: [],
      selectedSeuil: 0
    };
  }

  componentDidMount() {
    this.getAllCategories();
  }
  getActionsFromFAQ(data) {
    const category = this.props.location?.state?.name;
    const page = this.props.location?.state?.page;

    const categoryElement = this.state.data.find(
      (elem) => elem.name.trim() == category.trim()
    );
    const categoryId = categoryElement?.categoryId;
    const subCategory = data?.find((el) => el.categoryName === page);
    this.selectCategory(
      subCategory,
      categoryElement,
      data?.filter((el) => el.parentCategoryId === categoryId),
      category
    );
  }
  getAllCategories = async () => {
    const res = await apiGetAllCategories();
    const resSeuil = await apiGetSeuilFacture();
    // var documentsList = this.state.contractTypes?.includes('SANTE')
    //   ? res.data.filter(el => el.canSend === true)
    //   : res.data.filter(el => el.canSend === true && !["Décompte", "Devis", "Facture"].includes(el.name))
    var documentsList = res.data.map((elem) => {
      return { ...elem, name: elem.categoryName, id: elem.categoryId };
    });
    if (res.status === 200) {
      this.setState({
        data: documentsList,
        seuilFacture: resSeuil
      });
      if (
        this.props.location?.state?.name &&
        this.props.location?.state?.page
      ) {
        this.getActionsFromFAQ(this.state.data);
      }
      if (this.props.location?.state?.name) {
        const subCategoryName = this.props.location?.state?.name;
        const bankElement = documentsList.find((elem) => elem.name == 'BANQUE');
        const devisElement = documentsList.find((elem) => elem.name == 'DEVIS');
        if (bankElement) {
          const categoryId = bankElement?.categoryId;
          const subCategory = documentsList?.find(
            (el) =>
              el.parentCategoryId === categoryId &&
              el.categoryName === subCategoryName
          );
          if (subCategory) {
            this.selectCategory(
              subCategory,
              bankElement,
              documentsList?.filter((el) => el.parentCategoryId === categoryId)
            );
          }
        }

        if (devisElement) {
          this.selectElement(devisElement);
        }
      }

      if (
        this.props.location?.state?.name == 'ATTESTATION DE SECURITE SOCIALE'
      ) {
        const subCategoryName = this.props.location?.state?.name;
        const attestation = documentsList.find(
          (elem) => elem.name == subCategoryName
        );
        this.selectElement(attestation);
      }
      const queryParameters = new URLSearchParams(window.location.search);
      if (queryParameters.get('type') == 'facture') {
        let factureElem = this.state.data.find(
          (elem) => elem.name == 'FACTURE'
        );
        this.selectElement(factureElem);
      }
    }
  };
  onSelectSeuil = (selectedSeuil) => {
    this.setState({ selectedSeuil: selectedSeuil });
  };

  onReturn = () => {
    if (this.state.stepMedicine === 2) {
      this.setState({
        stepMedicine: 1
      });
    } else {
      if (this.state.step === 1) {
        this.props.history.push('/documents');
      } else {
        if (this.state.step == 3) {
          const contactParams = new URLSearchParams(document.location.search);
          this.props.history.push({
            pathname: '/documents/send',
            search: `?category=${contactParams.get('category')}`
          });
        } else {
          this.props.history.push('/documents/send');
        }

        this.setState({
          step:
            this.state.step === 4 ||
            (this.state.step == 3 && this.state.categoryList.length == 0)
              ? 1
              : this.state.step - 1,
          files: [],
          errorsUpload: [],
          sendDocumentIsLoading: false,
          description: '',
          isUploadValid: true,
          isDescriptionValid: true,
          stepMedicine: 1,
          showNewMutual: false,
          mutual: { amount: '', file: [] },
          remboursement: '48',
          medicine: {
            benefitaire: '',
            date: null,
            nature: '',
            code: '',
            amount: '',
            files: []
          },
          errorsMedecine: []
        });
      }
    }
  };

  selectElement = (value) => {
    var category = [];
    this.state.data.forEach((element) => {
      if (value.id === element.parentCategoryId) {
        category.push(element);
      }
    });

    this.setState({
      step: category?.length === 1 ? 3 : 2,
      type: value,
      stepMedicine: 1,
      showNewMutual: false,
      mutual: { amount: '', file: null },
      remboursement: '48',
      categoryList: category
    });

    this.props.history.push({
      pathname: '/documents/send',
      search: `?category=${value.name}`
    });

    if (category.length == 0 && !this.props.location?.state?.data) {
      this.selectCategory(value);
    }
    if (category.length === 1) {
      this.selectCategory(category[0]);
    }
  };

  selectCategory = (value, type, categoryList, categoryFromFAQ = null) => {
    if (
      value.categoryName === 'Ajouter un conjoint(e)' ||
      value.categoryName === 'Ajouter un enfant'
    ) {
      this.props.history.push({
        pathname: '/profile',
        state: { benefType: value.categoryName }
      });
      return;
    }
    const contactParams = new URLSearchParams(document.location.search);
    const category =
      categoryFromFAQ != null ? categoryFromFAQ : contactParams.get('category');
    this.props.history.push({
      pathname: '/documents/send',
      search: `?category=${category}&subCategory=${value.name}`
    });
    this.setState({
      step: 3,
      ...(!!type && { type }),
      ...(!!categoryList && { categoryList }),
      category: value.name,
      stepMedicine: 1,
      showNewMutual: false,
      mutual: { amount: '', file: null },
      remboursement: '48'
    });
  };

  selectFile = (e) => {
    this.setState({ files: e }, () => {
      if (this.state.files.length !== 0) {
        this.validateFileUpload();
      }
    });
  };

  onChange = (e) => {
    this.setState({ description: e.target.value }, this.validateDescription);
  };

  validateFileUpload = () => {
    if (!this.state.files.length) {
      this.setState({
        isUploadValid: false
      });
    } else {
      this.setState({
        isUploadValid: true
      });
    }
  };
  validateDescription = () => {
    if (this.state.description.length < 0) {
      this.setState({
        isDescriptionValid: false
      });
    } else {
      this.setState({
        isDescriptionValid: true
      });
    }
  };

  validate = () => {
    this.validateFileUpload();
    this.validateDescription();
    return this.state.files.length;
  };

  onSubmit = async () => {
    if (this.validate()) {
      const res = await this.sendDocument();
      if (res.status === 200) {
        this.setState({
          step: 4
        });
      }
    }
  };

  sendDocument = async () => {
    const data = new FormData();
    data.append('Category.Name', this.state.type.name);
    data.append('CategoryId', this.state.type.id);
    data.append('Category.Id', this.state.type.id);
    data.append('Category.Type', this.state.category);
    const commentaire = `
    BENEFICIAIRE DU SOIN:${this.state.medicine.benefitaire}
    DATE DU SOIN: ${moment(new Date(this.state.medicine.date)).format(
      'DD/MM/YYYY'
    )}
    NATURE DU SOIN: ${this.state.medicine.nature}
    CODE ADELI ou N° Siret: ${this.state.medicine.code}
    MONTANT DEPENSE : ${this.state.medicine.amount} €
    COMMENTAIRE: ${this.state.description}
    ${
      this.state.mutual.amount
        ? `MONTANT REMBOURSE PAR AUTRE MUTUELLE :${this.state.mutual.amount} €`
        : ''
    }
  `;
    data.append(
      'Comment',
      this.state.category.toLowerCase() === 'médecine douce'
        ? commentaire
        : this.state.description
    );
    for (let i = 0; i < this.state.files.length; i++) {
      data.append('Files', this.state.files[i]);
    }
    if (
      this.state.category.toLowerCase() === 'médecine douce' &&
      this.state.mutual.file
    ) {
      data.append('Files', this.state.mutual.file);
    }
    const type = this.state.categoryList?.find(
      (cat) => cat.categoryName === this.state.category
    );

    if (this.state.category.toLowerCase() === 'médecine douce') {
      const processedData = {
        categoryId: type.parentCategoryId || this.state.type.id,
        subCategoryId: type.categoryId || this.state.type.id,
        isSeuilSuperior: this.state.selectedSeuil === 2
      };

      const res = await apiPostNewSendDocument(data, processedData);
      return res;
    } else {
      const processedData = {
        categoryId: type.parentCategoryId || this.state.type.id,
        subCategoryId: type.categoryId || this.state.type.id,
        comment:
          this.state.category.toLowerCase() === 'médecine douce'
            ? commentaire
            : this.state.description,
        isSeuilSuperior: this.state.selectedSeuil === 2
      };

      const res = await apiPostSendDocument(data, processedData);
      return res;
    }
  };

  onChangeMedicine = (value, key) => {
    const aux = { ...this.state.medicine };
    if (key == 'date') {
      aux[key] = new Date(value);
    } else if (key == 'code' || key == 'amount') {
      if (isNumber(value) || value == '') {
        aux[key] = value;
      }
    } else {
      aux[key] = value;
    }
    this.setState({ medicine: aux });
  };

  medicineStep = (step) => {
    this.setState({
      stepMedicine: step
    });
  };

  openNewMutual = () => {
    this.setState({
      showNewMutual: true
    });
  };

  onChangeMutual = (value, key) => {
    const aux = { ...this.state.mutual };
    aux[key] = value;
    this.setState({ mutual: aux });
  };

  render() {
    return (
      <SendDocumentTemplate
        state={this.state}
        onReturn={this.onReturn}
        selectElement={this.selectElement}
        selectCategory={this.selectCategory}
        selectFile={this.selectFile}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        onChangeMedicine={this.onChangeMedicine}
        medicineStep={this.medicineStep}
        openNewMutual={this.openNewMutual}
        onChangeMutual={this.onChangeMutual}
        onSelectSeuil={this.onSelectSeuil}
      />
    );
  }
}

export default SendDocument;
