import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Icon } from '..';

class AtomPdf extends Component {
  constructor (props) {
    super(props);
    this.state = {
      numPages: 1,
      pageNumber: 1,
      show: false,
      success: false,
      width: null
    };
    this.ref = React.createRef();
  }

  static defaultProps = {
    scale: 1,
    center: true,
    modal: false
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevState.success != this.state.success) {
      const width =
        window.innerWidth > 768
          ? this.ref.current.children[0].children[0].width + 200
          : '100%';
      this.setState({
        width: width
      });
    }
  }

  onLoadSuccess = e => {
    setTimeout(() => {
      this.setState({
        numPages: e._pdfInfo.numPages,
        success: true
      });
    }, 1000);
  };

  onLoadError = e => {};

  onItemClick = e => {};

  renderWidth = () => {
    if (window.innerWidth < 768) {
      if (this.props.modal) {
        return window.innerWidth - 110;
      }
      return window.innerWidth - 80;
    }
    if (window.innerWidth < 1200) {
      return window.innerWidth - 400;
    }
    return null;
  };

  getClassName = () => {
    const className = ['container-pdf'];
    if (this.props.center === true) {
      className.push('pdf-center');
    }
    return className.join(' ');
  };

  containerBtnClassName = () => {
    const className = ['flex', 'full-container', 'flex-start'];
    if (this.props.center) {
      className.push('flex-center');
    }
    return className.join(' ');
  };

  render () {
    return (
      <div style={{ position: 'relative' }}>
        <Document
          inputRef={this.ref}
          file={this.props.url}
          noData={<div>no file</div>}
          onLoadSuccess={e => this.onLoadSuccess(e)}
          loading={() => <div className='error-pdf-page'>Chargement ...</div>}
          onLoadError={e => this.onLoadError(e)}
          error={<div>échec du chargement du document</div>}
          onItemClick={e => this.onItemClick(e)}
          className={this.getClassName()}
          renderMode='canvas'
          options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
        >
          <Page
            pageNumber={this.state.pageNumber}
            pageIndex={this.state.pageNumber}
            renderMode={'canvas'}
            width={this.renderWidth()}
            error={() => (
              <div className='error-pdf-page'>
                échec du chargement du document
              </div>
            )}
            scale={this.props.scale}
          />
        </Document>
        {this.state.numPages > 1 && this.state.width && (
          <div className={this.containerBtnClassName()}>
            {' '}
            <div
              className='content-btn-pdf'
              style={{
                maxWidth: this.state.width,
                position: 'absolute',
                top: '50%'
              }}
            >
              <span
                className='btn-pdf'
                onClick={() =>
                  this.state.pageNumber != 1 &&
                  this.setState({ pageNumber: this.state.pageNumber - 1 })
                }
              >
                <div className='btn-prev-pdf'>
                  <Icon icon={'SortTop'} size={'12px'} />
                </div>
              </span>
              <span
                className='btn-pdf '
                onClick={() =>
                  this.state.numPages != this.state.pageNumber &&
                  this.setState({ pageNumber: this.state.pageNumber + 1 })
                }
              >
                <div className=' btn-next-pdf'>
                  <Icon icon={'SortTop'} size={'12px'} />
                </div>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default AtomPdf;
