const initialState = {
    padding: true
};

function ContainerReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CURRENT_PADDING':
            return {
                ...state,
                padding: action.value.padding
            };
        default:
            return state;
    }
}

export default ContainerReducer;
