import React from 'react';
import { Text, Checkbox } from '../../Atoms';

function MoleculeDispenseCheckBox(props) {
    return (
        <div className="organism-affiliation-dispense-check-box-container">
            <Checkbox
                type="checkbox"
                className="organism-affiliation-stage1-checkbox"
                classNameContainer="organism-affiliation-checkbox-container"
                size={14}
            />
            <div className="organism-affiliation-dispense-check-box-content-container">
                {props.text}
                <Text text={props.justification} className="affiliation-dispense-bold-font-size" />
            </div>
        </div>
    );
}
MoleculeDispenseCheckBox.defaultProps = {
    text: '',
    justification: '',
};
export default MoleculeDispenseCheckBox;
