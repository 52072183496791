import React, { Component } from 'react';
import { HomeTemplate } from './../../Templates';
import { connect } from 'react-redux';
import { apiConsumptions, apiRepayments } from '../../Api/Repayment/Repayment';
import { apiGetSlideshow, apiGetSlideshowNews } from '../../Api/Home/Home';
import * as moment from 'moment';
import { COLOR_TABLE } from '../../Config/Config';
import Store from '../../Redux/Store';
import getMemberTypeCode from '../../Utils/getMemberTypeCode';
import { apiMedadomURL } from '../../Api/Affiliate/Medadom';
import { apiGetHealthService } from '../../Api/HealthService/HealthService';
import { CONSUMPTIONTYPE, HEALTH_SERVICE } from '../../Config/enum';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesVisionGlobal: [],
      contractFamily: Store.getState().auth.user.contractTypes,
      garanteeValue: 0,
      manegementValue: 0,
      manegementComentary: '',
      garanteeComentary: '',
      modalSentShow: false,
      hideManegementComentary: false,
      hideGaranteeComentary: false,
      remboursement: [],
      slideshow: [],
      medadomModalOpen: false,
      medadomModalStep: 1
    };
  }

  ratingChange = (value, key) => {
    this.setState({
      [key]: value
    });
  };

  onOpenMedadomModal = () => {
    this.setState({ medadomModalOpen: true, medadomModalStep: 1 });
  };

  onNextStepMedadomModal = () => {
    this.setState({ medadomModalStep: 2 });
  };

  onCloseMedadomModal = () => {
    this.setState({ medadomModalOpen: false });
  };

  onPressGeolocateAroundMe = () => {
    this.onCloseMedadomModal();
    setTimeout(() => {
      this.props.history.push('/geolocation/select');
    }, 300);
  };

  onPressMedadomTeleconsultation = async () => {
    const res = await apiMedadomURL();

    if (res.status === 200) {
      this.onCloseMedadomModal();
      this.openLinkInNewTab(res.data);
    }
  };
  openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  getHealthService = async () => {
    const reseauPartenaire = this.props.user.autorization.reseauPARTENAIRE;
    switch (reseauPartenaire) {
      case HEALTH_SERVICE.ITELIS:
        this.openLinkInNewTab(
          'http://partenaires.itelis.fr/partners?access_token=w089ho5t'
        );
        this.onCloseMedadomModal();
        break;
      case HEALTH_SERVICE.KALIVIA:
        this.openLinkInNewTab(
          'https://www.viamedis.net/ViamedisNet/cartographie/numeroClient/00000000990140/'
        );
        this.onCloseMedadomModal();
        break;
      case HEALTH_SERVICE.CARTEBLANCHE:
        this.openLinkInNewTab(
          'https://www.carteblanchepartenaires.fr/CBPSSOUnique/connexionService?ext=5454frr54t&ste_cod=CM&signature=A%2BSET5cbirlcy%2Bpwwy/8pKfjp2TvAkdiIcsU9oICgNw%3D&redirect=assure/annuaire/professionnels-sante'
        );
        this.onCloseMedadomModal();
        break;
      case HEALTH_SERVICE.SANTECLAIR:
        const res = await apiGetHealthService();
        let jeton = '';
        if (res.status === 200) {
          jeton = res.data;
        }
        this.openLinkInNewTab(
          `https://services.integration.santeclair.fr/owop/authentification.do?idPart=CGRM&idJeton=${jeton}`
        );
        this.onCloseMedadomModal();
        break;
      default:
        alert(
          "Impossible d'utiliser le réseau de soins pour localiser un praticien."
        );
        break;
    }
  };

  ratingGaranteeChange = (value) => {
    this.setState({
      garanteeValue: value,
      hideGaranteeComentary: value != 0 ? true : false
    });
  };

  ratingManegementChange = (value) => {
    this.setState({
      manegementValue: value,
      hideManegementComentary: value != 0 ? true : false
    });
  };

  getSlideshow = async () => {
    const cachedData = localStorage.getItem('getSlideshow');
    const cacheTimestamp = localStorage.getItem(`getSlideshow_timestamp`);
    const cacheExpiration = 30 * 60 * 1000; // 30 minutes in milliseconds
    const now = new Date().getTime();
    if (
      cachedData &&
      cacheTimestamp &&
      now - cacheTimestamp < cacheExpiration
    ) {
      this.setState({
        slideshow: JSON.parse(cachedData)
      });
    } else {
      const res = await Promise.all([apiGetSlideshowNews(), apiGetSlideshow()]);

      let data = [];
      res.forEach((it, i) =>
        data.push(
          ...it.data.map((el) => ({
            ...el,
            type: i === 0 ? 'actu' : 'slideshow'
          }))
        )
      );

      this.setState({
        slideshow: data
      });

      localStorage.setItem('getSlideshow', JSON.stringify(data));
      localStorage.setItem('getSlideshow_timestamp', now.toString());
    }
  };

  manegementSendClicked = () => {
    this.setState({
      hideManegementComentary: false
    });
  };

  garanteeSendClicked = () => {
    const action = {
      type: 'RATING_MODAL_SHOW',
      value: false
    };
    this.props.dispatch(action);
    this.setState({
      garanteeValue: 0,
      manegementValue: 0,
      manegementComentary: '',
      garanteeComentary: '',
      modalSentShow: true,
      hideManegementComentary: false,
      hideGaranteeComentary: false
    });
  };

  ratingModalShowClicked = () => {
    const action = {
      type: 'RATING_MODAL_SHOW',
      value: true
    };
    this.props.dispatch(action);
  };

  ratingModalHide = () => {
    const action = {
      type: 'RATING_MODAL_SHOW',
      value: false
    };
    this.props.dispatch(action);
    this.setState({
      garanteeValue: 0,
      manegementValue: 0,
      manegementComentary: '',
      garanteeComentary: '',
      modalSentShow: false,
      hideManegementComentary: false,
      hideGaranteeComentary: false
    });
  };
  convertRemboursementData = (remboursementData) => {
    // let returnData = [];
    // remboursementData.forEach((remboursementDataElem) => {
    //   remboursementDataElem.ligne.forEach((remboursementDataLineElem) => {
    //     returnData.push({
    //       ...remboursementDataLineElem,
    //       dateActe: this.convertDate(remboursementDataLineElem.dateActe),
    //       reglement: remboursementDataElem.reglement,
    //       numero: remboursementDataElem.numero,
    //       ligne: remboursementDataElem.ligne,
    //     });
    //   });
    // });
    // return returnData.slice(0, 5);
    return remboursementData.slice(0, 3);
  };

  convertDate = (inputDate) => {
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);
    const outputData = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    return outputData;
  };

  getRemboursementData = async (skip, limit) => {
    const cachedData = localStorage.getItem('getRemboursementData');
    const cacheTimestamp = localStorage.getItem(
      `getRemboursementData_timestamp`
    );
    const cacheExpiration = 30 * 60 * 1000; // 30 minutes in milliseconds
    const now = new Date().getTime();
    if (
      cachedData &&
      cacheTimestamp &&
      now - cacheTimestamp < cacheExpiration
    ) {
      this.setState({
        remboursement: JSON.parse(cachedData)
      });
    } else {
      const { data, status } = await apiRepayments(
        this.props.user?.memberNumber,
        getMemberTypeCode(this.props.user?.memberType),
        moment().subtract(2, 'year'),
        moment(),
        skip,
        limit,
        true,
        this.props.user.goodCodeTiers?.toString()
      );
      if (status == 200) {
        const dataToSave = data?.slice(0, 3);
        this.setState({
          remboursement: dataToSave
        });
        localStorage.setItem(
          'getRemboursementData',
          JSON.stringify(dataToSave)
        );
        localStorage.setItem('getRemboursementData_timestamp', now.toString());
      }
    }
  };

  getConsumption = async (beginDate, endDate) => {
    const cachedData = localStorage.getItem('getConsumption');
    const cacheTimestamp = localStorage.getItem(`getConsumption_timestamp`);
    const cacheExpiration = 30 * 60 * 1000; // 30 minutes in milliseconds
    const now = new Date().getTime();
    if (
      cachedData &&
      cacheTimestamp &&
      now - cacheTimestamp < cacheExpiration
    ) {
      this.setState({ seriesVisionGlobal: JSON.parse(cachedData) });
    } else {
      const resGlobal = await apiConsumptions(
        this.props.user.goodCodeTiers?.toString(),
        CONSUMPTIONTYPE.GLOBAL,
        moment(beginDate, 'MM/DD/YYYY'),
        moment(endDate, 'MM/DD/YYYY')
      );
      if (resGlobal.status == 200 && resGlobal.codeRetour) {
        const { codeRetour, postes, beneficiaires, status, ...restGlobal } =
          resGlobal;
        const dataToSave = [
          {
            legend: 'Remboursement CGRM',
            data: restGlobal.rbcgrm,
            color: COLOR_TABLE[0]
          },
          {
            legend: 'Sécurité sociale',
            data: restGlobal.rbss,
            color: COLOR_TABLE[1]
          },
          {
            legend: 'Reste à charge',
            data: restGlobal.rac,
            color: COLOR_TABLE[2]
          }
        ];
        this.setState({
          seriesVisionGlobal: dataToSave
        });
        localStorage.setItem('getConsumption', JSON.stringify(dataToSave));
        localStorage.setItem('getConsumption_timestamp', now.toString());
      }
    }
  };

  getDate = (fullYear) => {
    // modif
    // return {
    //   beginDate: `01/01/2022`,
    //   endDate: `12/31/2022`
    // };
    return {
      beginDate: `01/01/${fullYear}`,
      endDate: `12/31/${fullYear}`
    };
  };

  componentDidMount() {
    const { beginDate, endDate } = this.getDate(new Date().getFullYear());
    this.getConsumption(beginDate, endDate);
    this.getRemboursementData(1, 3);
    this.getSlideshow();
  }

  remboursementClicked = () => {
    this.props.history.push({
      pathname: '/remboursement',
      state: {
        beginDate: new Date('01/01/2012'),
        endDate: new Date(),
        status: 'remboursement'
      }
    });
  };

  remboursementMobileClicked = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.remboursementClicked();
    }
  };

  ProfilMobileClicked = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.CheckProfil();
    }
  };

  ContactMobileClicked = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.Contact();
    }
  };

  SendBillMobileClicked = () => {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 768) {
      this.SendBill();
    }
  };

  SendBill = () => {
    this.props.history.push({
      pathname: '/contact',
      state: { object: 'Mes remboursements', status: 'Nous contacter' }
    });
  };

  CheckProfil = () => {
    this.props.history.push({
      pathname: '/profile'
    });
  };

  Contact = () => {
    this.props.history.push({
      pathname: '/contact',
      state: { status: 'Nous contacter' }
    });
  };

  render() {
    return (
      <HomeTemplate
        seriesVisionGlobal={this.state.seriesVisionGlobal}
        garanteeValue={this.state.garanteeValue}
        ratingChange={this.ratingChange}
        manegementValue={this.state.manegementValue}
        manegementComentary={this.state.manegementComentary}
        manegementSendClicked={this.manegementSendClicked}
        garanteeSendClicked={this.garanteeSendClicked}
        garanteeComentary={this.state.garanteeComentary}
        ratingModalShowClicked={this.ratingModalShowClicked}
        ratingModalHide={this.ratingModalHide}
        hideGaranteeComentary={this.state.hideGaranteeComentary}
        hideManegementComentary={this.state.hideManegementComentary}
        ratingGaranteeChange={this.ratingGaranteeChange}
        ratingManegementChange={this.ratingManegementChange}
        modalSentShow={this.state.modalSentShow}
        remboursement={this.state.remboursement}
        remboursementClicked={this.remboursementClicked}
        sendBill={this.SendBill}
        contractFamily={this.state.contractFamily}
        CheckProfil={this.CheckProfil}
        Contact={this.Contact}
        remboursementMobileClicked={this.remboursementMobileClicked}
        SendBillMobileClicked={this.SendBillMobileClicked}
        ProfilMobileClicked={this.ProfilMobileClicked}
        ContactMobileClicked={this.ContactMobileClicked}
        slideshow={this.state.slideshow}
        medadomModalOpen={this.state.medadomModalOpen}
        medadomModalStep={this.state.medadomModalStep}
        onOpenMedadomModal={this.onOpenMedadomModal}
        onCloseMedadomModal={this.onCloseMedadomModal}
        onNextStepMedadomModal={this.onNextStepMedadomModal}
        onPressMedadomTeleconsultation={this.onPressMedadomTeleconsultation}
        onPressMedadomUseHealthService={this.getHealthService}
        onPressGeolocateAroundMe={this.onPressGeolocateAroundMe}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  rating: state.rating,
  user: state.auth.user
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
