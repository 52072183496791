import React from 'react';
import { Button, Text } from '../../Atoms';
import { PhotoLoginOrganisme } from '../../Organisms';
import { useHistory } from 'react-router-dom'
function TemplateResetPasswordMailStep2(props) {
    const history = useHistory()
    const faireRedirection = (url) => {
        history.push(url)
    }
    return (
        <div className='login-template'>
            <PhotoLoginOrganisme />
            <div className='login-template-organism' >
                <div className='login-container' >
                    <div>
                        <Text text='Réinitalisation de votre mot de passe' type='h4' />
                        <Text
                            {...(props.firstConnexion ? { text: `Un lien vient de vous être envoyé à l’adresse mail suivante ${props.state.mail} . Il vous permettra de créer un mot de passe.` }
                                : { text: 'Un lien de réinitialisation vient d‘être envoyé à l’adresse mail indiquée. Il vous permettra de choisir un nouveau mot de passe.' })}
                            className='text-description-first-login'
                        />
                        <div className='first-login-small-container'>
                            <div className='btn-connexion-first-login-container' >
                                <Button
                                    text='Revenir à l’espace de connexion'
                                    className='btn-next-first-login-big'
                                    boxShadow
                                    onClick={() => faireRedirection('/login')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplateResetPasswordMailStep2;
