import React, { Component } from 'react';
import {
  apiGetMembers,
  apiGetPostalAddressOnly,
  apiSendCodeByEmail,
  apiUpdateAddress,
  apiUpdateEmail,
  apiUpdatePhone,
  apiCreateBeneficiary,
  apiUpdateMainInsuredInfo,
  apiConfirmUpdateUserInfo,
  apiSendBeneficiaryResiliationRequest,
  apiConfirmBeneficiaryResiliation
} from '../../Api/Profile/Profile';
import { Text, Button, Icon } from '../../Atoms';
import { Input } from '../../Molecules';
import { InputProfileOrganism } from '../../Organisms';
import { connect } from 'react-redux';
import {
  ProfileScreen1Template,
  ProfileScreen2Template,
  AddBeneficiaireStep1Template,
  AddBeneficiaireStep2Template,
  AddBeneficiaireStep3Template,
  AddBeneficiaireStep4Template,
  ProfileTemplateScreen2Beneficiaire,
  ProfileTemplateDelete
} from '../../Templates';
import {
  formatProfileResponse,
  serilizeGetMembers
} from '../../Api/Profile/serilizeProfile';
import {
  FILE_SIZE_NOT_VALID,
  FILE_TYPE_NOT_VALID,
  validateSize,
  validateType
} from '../../Api/Profile/errorMessage';
import { isNumber } from '../../Validation';
import moment from 'moment';
import {
  apiGetAllCategories,
  apiPostNewSendDocument,
  apiPostSendDocument
} from '../../Api/Documents/Documents';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadAdressErrorMessage: '',
      isUploadValid: true,
      categories: null,
      adequatCategory: null,
      validationCodeSuccess: false,
      isUploadAddressValid: true,
      showDropDown: false,
      validationCodeErrors: [],
      addressDetails: null,
      optionAdressData: [],
      showDeleteModal: false,
      beneficiaireToDelete: '',
      showDeleteName: '',
      currentField: '',
      data: [
        {
          otherMutuelle: ''
        }
      ],
      editName: false,
      editNumeroSecuriteSociale: false,
      editLastname: false,
      oldName: '',
      oldLastname: '',
      oldNumeroSecuriteSociale: '',
      oldMail: '',
      editBank: false,
      oldBankdata: {
        accountOwner: 'Eva  ROBIN',
        iban: 'FRPP XXXX XXXX XXXX XXXX XXXX XXX',
        bankName: 'Caisse d’Epargne',
        bic: 'CEPAXXXXXXXX',
        remboursement: true,
        prelevement: false
      },
      editAdress: false,
      editBirthdate: false,
      oldBirthdate: '',
      manuelAddressEdit: false,
      oldAdress: {
        adresse: '',
        cp: '',
        ville: ''
      },
      editTel: false,
      oldTel: '',
      benificiaireList: [],
      step: 'PICK_BENEFICIAIRE',
      beneficiaireType: '',
      beneficiaireDataFomulaire: [
        {
          civilite: '',
          type: '',
          nom: '',
          prenom: '',
          birthDate: '',
          numSecurity: '',
          files: [],
          dateEffet: '',
          realTimeValidation: [
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            false
          ],
          validation: [true, true, true, true, true, true, true, true],
          errorMessage: ''
        }
      ],
      message: '',
      ServerErrorMessage: ''
    };
  }

  getProfile = async () => {
    try {
      if (this.props.location.state && this.props.location.state?.benefType) {
        const aux = [...this.state.beneficiaireDataFomulaire];
        aux[0].type = this.props.location.state?.benefType?.includes('enfant')
          ? 'Enfant'
          : 'Conjoint';
        aux[0].realTimeValidation[1] = true;

        this.setState({ beneficiaireDataFomulaire: aux });

        return this.navigationSteps('ADD_BENEFICIAIRE_STEP2');
      }
      const res = await apiGetMembers();
      if (res.benificiaries?.length === 1) {
        this.setState({
          data: formatProfileResponse(res, this.props.user.memberType)?.value,
          step: 'EDIT_PROFILE',
          addressDetails: null
        });
      }
      this.setState({
        benificiaireList: serilizeGetMembers(res, this.props.user.memberType),
        contracts: res.contracts
      });

      if (this.props.location?.state?.name) {
        this.getActionsFromFAQ(this.state.benificiaireList);
      }
      if (this.props.location?.state?.step) {
        this.navigationSteps(this.props.location?.state?.step);
      }
    } catch (err) {
      this.setState({
        benificiaireList: []
      });
    }
  };

  componentDidMount() {
    this.getProfile();
    this.getCategories();
  }

  getActionsFromFAQ(data) {
    this.handelSelectedProfile(data[0]?.value, 0);
  }

  isAddressValid = () => {
    const size = this.state.files[0].size;
    const type = this.state.files[0].type;
    const isValidType = validateType(type);
    const isValidSize = validateSize(size);
    return { isValidSize, isValidType };
  };

  showModal = async () => {
    this.setState({
      ...this.state.data,
      validationCodeErrors: [],
      editLastname: false,
      editBirthdate: false,
      editNumeroSecuriteSociale: false
    });
    if (
      this.state.oldLastname ||
      this.state.oldBirthdate ||
      this.state.oldNumeroSecuriteSociale
    ) {
      if (
        this.state.data.lastName !== this.state.oldLastname ||
        this.state.data.birthDate !== this.state.oldBirthdate ||
        this.state.data.numeroSecuriteSociale !==
          this.state.oldNumeroSecuriteSociale
      ) {
        const resp = await apiUpdateMainInsuredInfo({
          name: this.state.data.lastName,
          birthdate: this.state.data.birthDate,
          numSecu: this.state.data.numeroSecuriteSociale
        });
        if (resp?.status === 200) {
          this.setState({
            showConfirmationModal: true,
            isUploadAddressValid: true,
            uploadAdressErrorMessage: ''
          });
        }
      }
    }
  };

  onHide = () => {
    this.setState({
      showConfirmationModal: false,
      verificationSteps: 1
    });
  };

  onChangeCode = (value) => {
    this.setState({
      code: value
    });
  };
  onConfirmCodeDelete = async () => {
    const res = await apiConfirmBeneficiaryResiliation(this.state.code);
    if (res?.status === 200) {
      this.setState({
        showConfirmationModal: false,
        showDeleteModal: true,
        validationCodeSuccess: true
      });
    } else {
      this.setState({
        validationCodeErrors: [res.errors?.map?.((i) => i.errorMessage)]
      });
    }
  };

  onConfirmCode = async () => {
    const res = await apiConfirmUpdateUserInfo(this.state.code);
    if (res?.status === 200) {
      this.setState({
        validationCodeSuccess: true
      });
    } else {
      this.setState({
        validationCodeErrors: [res.errors?.map?.((i) => i.errorMessage)]
      });
    }
  };

  handelEditMail = (value) => {
    if (!this.state.editTel && !this.state.editAdress) {
      this.setState({
        editMail: value,
        oldMail: this.state.data.mail
      });
      if (!value) {
        this.setState({
          data: { ...this.state.data, mail: this.state.oldMail }
        });
      }
    }
  };

  onIconClick = () => {
    this.setState({
      step: 'PICK_BENEFICIAIRE'
    });

    this.props.history.push({
      pathname: '/profile',
      search: ``
    });
  };

  validateFileUpload = () => {
    const { beneficiaireDataFomulaire } = this.state;
    let isUploadValid = true;
    beneficiaireDataFomulaire.forEach((beneficiaire) => {
      if (beneficiaire.files.length === 0) {
        isUploadValid = false;
        return;
      }
    });

    this.setState({
      isUploadValid: isUploadValid
    });
  };

  selectFile = (e, index) => {
    const updatedBeneficiaireData = [...this.state.beneficiaireDataFomulaire];
    updatedBeneficiaireData[index].files = e;
    this.setState(
      { beneficiaireDataFomulaire: updatedBeneficiaireData },
      () => {
        if (updatedBeneficiaireData[index].files.length !== 0) {
          this.validateFileUpload();
        }
      }
    );
  };
  onChangeVerificationCode = (value) => {
    this.setState({
      verificationCode: value
    });
  };

  onSelectOutilDeValidation = (value) => {
    this.setState({
      outilDeValidation: value
    });
  };

  handelSelectedProfileDelete = (value, index) => {
    this.setState({
      showDeleteModal: true,
      showDeleteName: `${value.name} ${value.lastName}`,
      beneficiaireToDelete: value.codeAdherent
    });
  };
  onConfirmDeletion = async () => {
    const resp = await apiSendBeneficiaryResiliationRequest(
      this.state.beneficiaireToDelete
    );

    if (resp.status === 200) {
      this.onClose();

      setTimeout(() => {
        this.setState({
          showConfirmationModal: true
        });
      }, 700);
    }
  };
  onClose = () => {
    this.setState({
      showDeleteModal: false,
      beneficiaireToDelete: ''
    });
  };

  handelSelectedProfile = (value, index) => {
    if (index === 0) {
      this.setState({
        data: value,
        step: 'EDIT_PROFILE',
        addressDetails: null
      });
      this.props.history.push({
        pathname: '/profile',
        search: `?category=principal`
      });
    } else {
      this.setState({
        data: value,
        step: 'EDIT_PROFILE_BENEFICIAIRE',
        addressDetails: null
      });
      this.props.history.push({
        pathname: '/profile',
        search: `?category=beneficiaire${index}`
      });
    }
  };

  onChangeBirthDate = (date, key, aux) => {
    if (date.length === 2 || date.length === 5) {
      if (date.length > birthDate.length) {
        aux[key] = `${date}/`;
        this.setState({ data: aux });
      } else {
        aux[key] = date.slice(0, -1);
        this.setState({ data: aux });
      }
    } else {
      aux[key] = date;
      this.setState({ data: aux });
    }
  };

  onChange = async (value, key) => {
    const aux = { ...this.state.data };
    if (key === 'adresse') {
      aux[key] = value;
      this.setState({
        data: { ...aux }
      });
      return;
    }
    if (key === 'birthDate') {
      this.onChangeBirthDate(value, key, aux);
    }
    if (key !== 'tel') {
      aux[key] = value;
      this.setState({ data: aux });
    } else if (value.length <= 15) {
      const onlyNumber = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g);
      aux[key] = onlyNumber;
      this.setState({ data: aux });
    }
  };

  onChangeBankType = (key) => {
    if (this.state.editBank) {
      const aux = { ...this.state.data };
      if (key === 'remboursement') {
        aux.prelevement = false;
      } else {
        aux.remboursement = false;
      }
      aux[key] = true;
      this.setState({ data: aux });
    }
  };

  cancelEditBank = () => {
    this.setState({
      data: { ...this.state.data, ...this.state.oldBankdata },
      editBank: false
    });
  };

  handelEditAdresse = (value) => {
    if (!this.state.editTel && !this.state.editMail) {
      this.setState({
        editAdress: value,
        adresse: this.state.adresse,
        oldAdress: {
          adresse: this.state.data.adresse,
          cp: this.state.data.cp,
          ville: this.state.data.ville
        }
      });
    }
    if (!value) {
      if (window.innerWidth <= 768) {
        this.setState({
          data: {
            ...this.state.data,
            ...this.state.oldAdress,
            tel: this.state.oldTel,
            mail: this.state.oldMail
          },
          manuelAddressEdit: false,
          editTel: false,
          editMail: false
        });
      } else {
        this.setState({
          data: { ...this.state.data, ...this.state.oldAdress },
          manuelAddressEdit: false
        });
      }
    }
  };

  handelEditTel = (value) => {
    if (!this.state.editMail && !this.state.editAdress) {
      this.setState({
        editTel: value,
        oldTel: this.state.data.tel
      });
      if (!value) {
        this.setState({
          data: { ...this.state.data, tel: this.state.oldTel }
        });
      }
    }
  };

  handelEditBirthdate = (value) => {
    this.setState({
      currentField: 'birthdate',
      editBirthdate: value,
      editNumeroSecuriteSociale: value,
      oldBirthdate: this.state.data.birthDate,
      oldNumeroSecuriteSociale: this.state.data.numeroSecuriteSociale
    });
    if (!value) {
      this.setState({
        data: {
          ...this.state.data,
          birthDate: this.state.oldBirthdate,
          numeroSecuriteSociale: this.state.oldNumeroSecuriteSociale
        },
        currentField: ''
      });
    }
  };

  handelEditName = (value) => {
    this.setState({
      editName: value,
      oldName: this.state.data.name
    });
    if (!value) {
      this.setState({
        data: { ...this.state.data, name: this.state.oldName }
      });
    }
  };

  handelEditLastname = (value) => {
    this.setState({
      editLastname: value,
      oldLastname: this.state.data.lastName
    });
    if (!value) {
      this.setState({
        data: { ...this.state.data, lastName: this.state.oldLastname }
      });
    }
  };

  handelEditNumeroSecuriteSociale = (value) => {
    this.setState({
      currentField: 'numsec',
      editNumeroSecuriteSociale: value,
      editBirthdate: value,
      oldBirthdate: this.state.data.birthDate,
      oldNumeroSecuriteSociale: this.state.data.numeroSecuriteSociale
    });
    if (!value) {
      this.setState({
        data: {
          ...this.state.data,
          numeroSecuriteSociale: this.state.oldNumeroSecuriteSociale,
          birthDate: this.state.oldBirthdate
        },
        currentField: ''
      });
    }
  };

  handelManuelAddressEdit = (value) => {
    this.setState({ manuelAddressEdit: !this.state.manuelAddressEdit });
  };

  handelEditBank = (value) => {
    this.setState({ editBank: value });
  };

  navigationSteps = (value) => {
    let search = '';
    if (value.includes('ADD_BENEFICIAIRE_STEP')) {
      search = '?category=ajout-beneficiaire&subcategory=informations';
    } else if (value == 'DELETE_BENEFICIAIRE') {
      search = '?category=supprimer-beneficiaire';
    }
    this.setState({ step: value });
    this.props.history.push({
      pathname: '/profile',
      search: search
    });
  };

  onSelectTypeBeneficiaire = (value) => {
    this.setState({
      step: 'ADD_BENEFICIAIRE_STEP2',
      beneficiaireType: value
    });
  };

  onChangeBeneficiaireData = (value, key, index, indexValidtion) => {
    const aux = [...this.state.beneficiaireDataFomulaire];
    if (['nom', 'prenom', 'numSecurity', 'organism'].includes(key)) {
      if (value.trim().length > 0) {
        aux[index].realTimeValidation[indexValidtion] = true;
      } else {
        aux[index].realTimeValidation[indexValidtion] = false;
      }
    } else {
      aux[index].realTimeValidation[indexValidtion] = true;
    }
    switch (key) {
      case 'numSecurity':
        if ((isNumber(value) && value.length <= 15) || !value) {
          aux[index][key] = value;
        }
        break;
      case 'organism':
        if ((isNumber(value) && value.length <= 9) || !value) {
          aux[index][key] = value;
        }
        break;
      default:
        aux[index][key] = value;
    }

    this.setState({ beneficiaireDataFomulaire: aux });
  };

  CloseModal = () => {
    this.setState({
      showConfirmationModal: false,
      showSuccessModal: false
    });
  };

  addOtherBenef = () => {
    this.setState((prevState) => ({
      beneficiaireDataFomulaire: [
        ...prevState.beneficiaireDataFomulaire,
        {
          civilite: '',
          type: '',
          nom: '',
          prenom: '',
          birthDate: '',
          numSecurity: '',
          organism: '',
          dateEffet: '',
          realTimeValidation: [
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            false
          ],
          validation: [true, true, true, true, true, true, true, true],
          errorMessage: ''
        }
      ],
      data: [...prevState.beneficiaireDataFomulaire, { otherMutuelle: '' }]
    }));
  };

  getCategories = async () => {
    try {
      const response = await apiGetAllCategories();
      const categoryName = 'AJOUT DE BENEFICIAIRE';
      const categories = response?.data;
      const [adequatCategory] = categories?.filter(
        (category) => category.categoryName == categoryName
      );

      this.setState({
        categories: categories,
        adequatCategory: adequatCategory
      });
    } catch (err) {
      console.log(err);
    }
  };

  onValidStep = () => {
    const aux = [...this.state.beneficiaireDataFomulaire];
    let valid = true;
    for (let i = 0; i < aux.length; i++) {
      aux[i].errorMessage = '';
      this.setState({
        beneficiaireDataFomulaire: aux
      });

      if (!aux[i].files.length) {
        valid = false;
        this.setState({ isUploadValid: false });
      }
      if (
        aux[i].realTimeValidation.includes(false) ||
        (aux[i].type == 'Enfant' && !aux[i].otherMutuelle) ||
        (aux[i].type == 'Enfant' && !aux[i].joinNoemie) ||
        (aux[i].type == 'Enfant' && !aux[i].attachedTo) ||
        (aux[i].type == 'Conjoint' && !aux[i].otherMutuelle) ||
        (aux[i].type == 'Conjoint' && !aux[i].joinNoemie)
      ) {
        valid = false;
        aux[i].errorMessage =
          'Merci de bien vouloir compléter tous les champs.';
      } else {
        aux[i].errorMessage = '';
      }
      aux[i].validation = aux[i].realTimeValidation;
    }

    if (valid) {
      this.setState({
        beneficiaireDataFomulaire: aux,
        showConfirmationModal: true
      });
    } else {
      return;
    }
  };

  onCancelClick = () => {
    this.setState({
      step: 'PICK_BENEFICIAIRE'
    });

    this.props.history.push({
      pathname: '/profile',
      search: ``
    });
  };
  getSubCategory = (subCategory) => {
    return this.state.categories.filter(
      (category) => category.categoryName == subCategory
    );
  };

  SendBenefData = async () => {
    this.setState({
      showConfirmationModal: false
    });
    const benef = this.state.beneficiaireDataFomulaire;
    const benefType = benef[0].type;
    const messages = benef.map((beneficiary, index) => {
      let message = `${index + 1}e bénéficiaire à ajouter: \n\n`;

      const defaultElements = [
        `CIVILITE: ${beneficiary.civilite}`,
        `PRENOM: ${beneficiary.prenom}`,
        `NOM: ${beneficiary.nom}`,
        `TYPE DE BENEFICIARE: ${beneficiary.type}`,
        `DATE DE NAISSANCE: ${moment(beneficiary.birthDate).format(
          'DD/MM/YYYY'
        )}`,
        `NUMERO DE SECURITE SOCIAL: ${beneficiary.numSecurity}`,
        `DATE EFFET: ${moment(beneficiary.dateEffet).format('DD/MM/YYYY')}`
      ];

      message += defaultElements.join('\n');

      if (beneficiary.otherMutuelle) {
        let sentence = '';
        if (beneficiary.type == 'Conjoint') {
          sentence =
            "Votre conjoint(e) bénéficie-t-il/elle d'une autre mutuelle";
        } else if (beneficiary.type == 'Enfant') {
          sentence = "L'enfant bénéficie d'une autre mutuelle ?";
        }
        message += `\n${sentence} : ${beneficiary.otherMutuelle}`;
      }
      if (beneficiary.joinNoemie) {
        message += `\n'Souhaitez-vous la mise en place de la liaison Noémie?' : ${beneficiary.joinNoemie}`;
      }
      if ((beneficiary.type = 'Enfant' && beneficiary.attachedTo)) {
        message += `\n'Sur quel numéro de Sécurité Social l’enfant doit être rattaché' : ${beneficiary.attachedTo}`;
      }

      return message;
    });

    const comment = messages.join('\n------\n');

    let data = new FormData();
    benef.forEach((beneficiary, indexBenef) => {
      data.append('Civility', beneficiary.civilite);
      data.append('FirstName', beneficiary.prenom);
      data.append('LastName', beneficiary.nom);
      data.append(
        'BirthDate',
        moment(beneficiary.birthDate.toDateString()).format('DD/MM/YYYY')
      );
      data.append('SocialSecurityNumber', beneficiary.numSecurity);
      data.append(
        'AffiliationDate',
        moment(beneficiary.dateEffet.toDateString()).format('DD/MM/YYYY')
      );
      const attachments = beneficiary.files;
      attachments.forEach((attach, indexFile) => {
        data.append('Files', attach);
      });
    });

    let subCategoryName = 'Ajouter un enfant';
    if (benefType == 'Enfant') {
      subCategoryName = 'Ajouter un enfant';
    } else if (benefType == 'Conjoint') {
      subCategoryName = 'Ajouter un conjoint(e)';
    }
    const [subCategory] = this.getSubCategory(subCategoryName);

    const processedData = {
      categoryId: this.state.adequatCategory.categoryId,
      subCategoryId: subCategory.categoryId,
      isSeuilSuperior: false
    };

    data.append('Comment', comment);

    const res = await apiPostNewSendDocument(data, processedData);

    if (res.status === 200) {
      this.setState({
        message: res.message,
        showSuccessModal: true,
        ServerErrorMessage: '',
        beneficiaireDataFomulaire: [
          {
            civilite: '',
            type: '',
            nom: '',
            prenom: '',
            birthDate: '',
            numSecurity: '',
            dateEffet: '',
            files: [],
            realTimeValidation: [
              false,
              false,
              false,
              false,
              false,
              false,
              true,
              false
            ],
            validation: [true, true, true, true, true, true, true, true],
            errorMessage: ''
          }
        ]
      });
    } else {
      var ErrorMessage = '';
      res.errors.map((el) => {
        ErrorMessage += el + '\n';
      });
      this.setState({
        ServerErrorMessage: ErrorMessage
      });
    }
  };

  optionClicked = (value, index, key) => {
    this.setState((prevState) => {
      const newData = [...prevState.beneficiaireDataFomulaire];
      newData[index][key] = value;
      if (
        key === 'otherMutuelle' &&
        value === 'OUI' &&
        this.state.beneficiaireDataFomulaire[index].type == 'Enfant'
      ) {
        newData[index]['joinNoemie'] = 'NON';
      }
      return { data: newData };
    });
  };

  autoCompleteOptionClicked = (element) => {
    const aux = { ...this.state.data };
    this.setState({
      data: {
        ...aux,
        adresse: element.address,
        ville: element.city,
        cp: element.postCode
      },
      addressDetails: element
    });
  };

  autoCompleteClickAway = () => {
    this.setState({
      showDropDown: false
    });
  };

  render() {
    switch (this.state.step) {
      case 'PICK_BENEFICIAIRE':
        return <ProfileScreen1Template {...this} {...this.state} />;
      case 'EDIT_PROFILE':
        return <ProfileScreen2Template {...this} {...this.state} />;
      case 'DELETE_BENEFICIAIRE':
        return <ProfileTemplateDelete {...this} {...this.state} />;
      case 'EDIT_PROFILE_BENEFICIAIRE':
        return <ProfileTemplateScreen2Beneficiaire {...this} {...this.state} />;
      case 'ADD_BENEFICIAIRE_STEP1':
        return <AddBeneficiaireStep2Template {...this} {...this.state} />;
      case 'ADD_BENEFICIAIRE_STEP2':
        return <AddBeneficiaireStep2Template {...this} {...this.state} />;
      case 'ADD_BENEFICIAIRE_STEP3':
        return <AddBeneficiaireStep3Template {...this} {...this.state} />;
      case 'ADD_BENEFICIAIRE_STEP4':
        return <AddBeneficiaireStep4Template {...this} {...this.state} />;
      default:
        return null;
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    }
  };
};
const mapStateToProps = (state) => ({
  user: state.auth.user
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
