import React, { useEffect, useState } from 'react';
import LoginBackground from '../../Components/Login/LoginBackground';
import styles from './../LoginScreens/login.module.css';
import LoginModal from '../../Components/Login/LoginModal';
import LoginModalTitle from '../../Components/Login/LoginModalTitle';
import { Text } from '../../Atoms';
import LoginInput from '../../Components/Login/LoginInput';
import LoginInputLabel from '../../Components/Login/LoginInputLabel';
import LoginButton from '../../Components/Login/LoginButton';
import { apiGet, apiPost } from '../../Api/standardApi';
import { apiCollectPhone } from '../../Api/Login/Login';
const RecoverPhoneNumber = () => {
    const isDigit = (val) => {
        return /^\d+$/.test(val);
    };
    const [captchaDataGenerated, setCaptchaDataGenerated] = useState({
        id: '',
        image: ''
      });
    const [captchaText, setCaptchaText] = useState('');  
    const [errorCaptcha, setErrorCaptcha] = useState("")  
    const [captchaToken, setCaptchaToken] = useState("")  
    const [isValid, setIsValid] = useState({
        internationalCode: true,
        phoneNumber: true,
        name: true,
        birthDate: true,
        socialSecurityNumber: true,
        codeTiers: true,
        all:true
    });  
    const [error, setError] = useState("Tous ces champs sont obligatoires") 
    const [birthdayDate, setBirthdayDate] = useState('');
    const [payload,setPayload] = useState({
        internationalCode: "",
        phoneNumber: "",
        name: "",
        socialSecurityNumber: "",
        codeTiers: "",
        email:""
    });

    const handleCapchaTextChange = (event)=>{
        setCaptchaText(event.target.value);
    }

    const onChangePayload = (event) => {
        setPayload(prevPayload => {
            const updatedPayload = {
                ...prevPayload,
                [event.target.name]: event.target.value
            };
            return updatedPayload;
        });
    };


    const handleChange = (event) => {
        const isNumeric = /^\d+$/.test(event.target.value);

        // Si la valeur est numérique, ajouter le signe "+" au début
        if (isNumeric) {
            setPayload(prevPayload => {
                const updatedPayload = {
                    ...prevPayload,
                    internationalCode: '+'+event.target.value
                };
                return updatedPayload;
            });
        } else {
            setPayload(prevPayload => {
                const updatedPayload = {
                    ...prevPayload,
                    internationalCode: ""
                };
                return updatedPayload;
            });
        }
    };

    const handleBirthdateChange = (event) => {
        let inputDate = event.target.value;
    
        if (inputDate.length > birthdayDate.length) {
          const edition = inputDate.replace(birthdayDate, '');
          if (!isDigit(edition)) return;
        }
    
        if (inputDate.length === 2 || inputDate.length === 5) {
          if (inputDate.length > birthdayDate.length) {
            setBirthdayDate(`${inputDate}/`);
          } else {
            setBirthdayDate(inputDate.slice(0, -1));
          }
        } else {
          setBirthdayDate(inputDate);
        }
      };
    const apiGenerateCaptcha = async () => {
        try {
          const res = await apiGet('/Captcha/GenerateCaptcha');
          if (res?.status === 200) {
            setCaptchaDataGenerated({
              id: res?.id,
              image: `data:image/png;base64,${res.data}`
            })
          }
  
        } catch (error) {
          console.log('erreur', error)
        }
    }

    const apiValidateCaptcha = async (body) => {
        return await apiPost('/Captcha/ValidateCaptcha',JSON.stringify(body));
    }

    const collectPhone = async (captchaToken,body) => {
        return await apiCollectPhone(captchaToken,body);
    }

    const checkIsValidDate = (myDate)=>{
        const parts = myDate.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);

        var date = new Date(year, month, day);
    
        return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year && !isNaN(date.getTime());
    }


    const onSubmit = async()=>{
        // vérification des champs
        if (payload.name.trim()=="" && payload.codeTiers.trim()=="" && payload.internationalCode.trim()=="" && payload.phoneNumber.trim()=="" && payload.socialSecurityNumber.trim()=="") {
            setIsValid({
                internationalCode:false,
                phoneNumber: false,
                name: false,
                birthDate: false,
                socialSecurityNumber: false,
                codeTiers: false,
                all:false 
            })
        }else{
            setIsValid({
                internationalCode: true,
                phoneNumber: true,
                name: true,
                birthDate: true,
                socialSecurityNumber: true,
                codeTiers: true,
                all:true
            })
            
            checkField("name",payload.name);
            checkField("codeTiers",payload.codeTiers);
            checkField("internationalCode",payload.internationalCode);
            checkField("phoneNumber",payload.phoneNumber);
            checkField("socialSecurityNumber",payload.socialSecurityNumber);
            checkField("birthDate",birthdayDate);

             
            if (isValid.all) {

                const validDate = checkIsValidDate(birthdayDate);
                if (!validDate) {
                    setIsValid(prevState=>{
                        return {
                            ...prevState,
                            birthDate:false,
                            all:false
                        }
                    })
                    setError('Date invalide')
                }else{
                    
                    if (captchaText.trim()=="") {
                        setErrorCaptcha("Merci de renseigner les bons caractères");
                    }else{
                        setErrorCaptcha("")
                        const body = {
                            id: captchaDataGenerated.id,
                            value: captchaText
                        }
                        const response = await apiValidateCaptcha(body);
                        if (response?.status === 200) {
                            setCaptchaToken(response.data);
                            const body = {
                                ...payload,
                                birthDate:new Date(
                                    birthdayDate.split('/').reverse().join('-'),
                                  ).toISOString()
                            }
                            collectPhone(captchaToken,body);
                        }else{
                            setErrorCaptcha(response.errors[0].errorMessage)
                        }


                    }
                }
                
            }
        }

    }

    const checkField = (key, value) => {
        setIsValid(prevState => {
            if (value.trim() === "") {
                return {
                    ...prevState,
                    [key]: false,
                    all: false
                };
            } else {
                return {
                    ...prevState,
                    [key]: true,
                    all: true
                };
            }
        });
    };
    
    useEffect(()=>{
        apiGenerateCaptcha();
    },[])

    return (
        <LoginBackground>
            <div className={styles.container}>
                <LoginModal>
                    <LoginModalTitle title={'Connexion à votre espace Assuré :'} />
                    <div className={styles.contentContainer}>
                        <Text text="Bonjour," type=""/>
                        <Text text="Votre espace assuré n'est pas totalment sécurisé, certaines informations sont manquantes dans votre dossier" type=""/>
                        {/* <br/>   */}
                        <Text text="Merci de confirmer ces informations ci-dessous" type=""/>  
                        <br/>
                        {isValid.all == false ? (
                            <div className={styles.errorMessageContainer}>
                                <strong className={styles.errorMessage}>
                                {error}
                                </strong>
                            </div>
                             ) : null}

                        <LoginInput
                            placeholder="Quel est votre Nom ?"
                            name="name"
                            value={payload.name}
                            onChange={onChangePayload}
                            className={isValid.name == false? styles.error :styles.no_error}
                        />

                        <LoginInput 
                            placeholder="N° Adhérent:"
                            name="codeTiers"
                            value={payload.codeTiers}
                            onChange={onChangePayload}
                            type="number"
                            className={isValid.codeTiers == false? styles.error :styles.no_error}
                        />

                        <LoginInput
                            placeholder="Adresse mail:"
                            name="email"
                            value={payload.email}
                            onChange={onChangePayload}
                            type="email"
                        />

                        <div className={styles.phoneNumberContainer}>
                            <div className={styles.indicatifContainer}>
                                <LoginInput
                                    placeholder="Ex: +33"
                                    name="internationalCode"
                                    value={payload.internationalCode}
                                    onChange={handleChange}
                                    className={isValid.internationalCode == false? styles.error :styles.no_error}
                                    maxLength="4"
                                />
                            </div>
                            <div className={styles.numberContainer}>
                                <LoginInput
                                    placeholder="Numéro de téléphone mobile:"
                                    name="phoneNumber"
                                    value={payload.phoneNumber}
                                    onChange={onChangePayload}
                                    className={isValid.phoneNumber == false? styles.error :styles.no_error}
                                />
                            </div>
                        </div>

                        <LoginInput
                            placeholder="Numéro de sécutité sociale:"
                            name="socialSecurityNumber"
                            onChange={onChangePayload}
                            className={isValid.socialSecurityNumber == false? styles.error :styles.no_error}
                            maxLength="15"
                        />

                        <LoginInput
                            placeholder="Date de Naissance (JJ/MM/AAAA):"
                            value={birthdayDate}
                            onChange={handleBirthdateChange}
                            className={isValid.birthDate == false? styles.error :styles.no_error}
                        />
                        <div style={{textAlign:'center', marginBottom:"10px"}} >
                            <img src={captchaDataGenerated.image} />
                            <div>
                                <span className='no-code-received' onClick={apiGenerateCaptcha}>Changer l'image</span>
                            </div>
                        </div>

                        <LoginInput
                            placeholder="Recopiez les caractères de l'image"
                            name="captcha"
                            value={captchaText}
                            onChange={handleCapchaTextChange}
                        />
                        {errorCaptcha !="" ? (
                            <div className={styles.errorMessageContainer}>
                                <strong className={styles.errorMessage}>
                                {errorCaptcha}
                                </strong>
                            </div>
                        ) : null}
                        <Text type="" text="Une fois ces informations validées par nos équipes, vous allez recevoir un email pour vous connecter à votre espace en toute sécurité."/>

                        <div className={styles.submitButtonContainer}>
                            <LoginButton onClick={onSubmit} form="reset-password-form">
                                Valider
                            </LoginButton>
                            <hr className={styles.loginSeparator}></hr>
                            <Text type="" text="Pour rappel, nous utilisons vos informations de sécurité uniquement pour vérifier votre idendité si vous accédez à des données personelles ou si nous détéctons un problème avec votre compte."/>
                            {/* <Text type="h8" text="Bien à vous. L'équipe CGRM"/> */}

                        </div>
                    </div>
                </LoginModal>
            </div>
        </LoginBackground>
    );
};

export default RecoverPhoneNumber;