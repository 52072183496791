import React, { Component } from 'react';
import { DispenseTemplate } from './../../Templates/index';

class Dispense extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <DispenseTemplate />;
    }
}
export default Dispense;
