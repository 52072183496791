import React from 'react';
import { Icon, Text } from '../../Atoms';
import PropTypes from 'prop-types';

MoleculeAffiliationOption.PropTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string,

};
MoleculeAffiliationOption.defaultProps = {
    title: '',
    description: '',
};
function MoleculeAffiliationOption(props) {
    return (
        <div className="molecule-affiliation-text-container molecule-affiliation-option-container">
            <div className="molecule-affiliation-text-icon-container" >
                <Icon icon="Fleche" size="13px" className="molecule-affiliation-text-icon" />
            </div>
            <div className="molecule-affiliation-text-content-container">
                <div className="molecule-affiliation-text-content">
                    <span>{props.title}</span> {props.description}
                </div>
            </div>
        </div>
    );
}

export default MoleculeAffiliationOption;
