import React, { useState, useEffect, useRef } from 'react';

import { Text, Img } from '../../Atoms';
import Flamme from '../../assets/flamme.jpg';
import { ButtonDropDown } from '../../Molecules';
import dropDownIcon from '../../assets/icons/dropDownIcon.svg';
import PropTypes from 'prop-types';

MoleculeAvatar.PropTypes = {
  onClick: PropTypes.any,
  onclickMonCompte: PropTypes.any,
  onclickDeconnexion: PropTypes.any,
  img: PropTypes.any,
  avatarDescription: PropTypes.string,
  listDropDown: PropTypes.array,
  classNameContainer: PropTypes.string,
  classNameContainerImage: PropTypes.string,
  classNameImage: PropTypes.string,
  classNameContainerDescription: PropTypes.string,
  classNameDescription: PropTypes.string,
  ContainerDropdownClassName: PropTypes.string,
  classNameDropDown: PropTypes.string,
  ButtonValue: PropTypes.string,
  ButtonValueClassName: PropTypes.string,
  sizeIcon: PropTypes.number,
  classNameIcon: PropTypes.string,
  classNameDropDownInVisible: PropTypes.string
};
MoleculeAvatar.defaultProps = {
  onClick: null,
  onclickMonCompte: null,
  onclickDeconnexion: null,
  img: Flamme,
  avatarDescription: 'Admin',
  listDropDown: [],
  classNameContainer: 'container-header-avatar',
  classNameContainerImage: 'OrganismHeader-Avatar-Image-container',
  classNameImage: 'OrganismHeader-Avatar-Image',
  classNameContainerDescription: 'container-header-avatar-Description',
  classNameDescription: 'OrganismHeader-Avatar-Description',
  ContainerDropdownClassName: 'container-molecule-avatar',
  ButtonValue: '',
  ButtonValueClassName: 'btn-dropDown',
  sizeIcon: 14,
  classNameIcon: 'OrganismHeader-Avatar-Icon'
};
export default function MoleculeAvatar(props) {
  const [visibility, setVisibility] = useState(false);
  function open() {
    setVisibility(!visibility);
  }
  return (
    <div className={props.classNameContainer} onClick={() => open()}>
      <div className={props.classNameContainerImage}>
        <Img src={props.img} className={props.classNameImage} />
      </div>
      <div className={props.classNameContainerDescription}>
        <Text className={props.classNameDescription} text={props.avatarDescription} />
        <ButtonDropDown
          parentClick={true}
          visibility={visibility}
          setVisibility={setVisibility}
          ButtonValue={props.ButtonValue}
          ButtonContainerClassName={props.ButtonContainerClassName}
          ListDropDown={props.ListDropDown}
          ContainerDropdownClassName={props.ContainerDropdownClassName}
          icon={<img className={props.classNameIcon} src={dropDownIcon}></img>}
          positionLeft={true}
        />
      </div>
    </div>
  );
}
