import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store, { persistor } from './Redux/Store';

import { PersistGate } from 'redux-persist/lib/integration/react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import KeycloakService from './Services/KeycloakService';

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <PersistGate persistor={persistor}>
        <Provider store={Store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </PersistGate>
    </React.StrictMode>,
    document.getElementById('root')
  );
KeycloakService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
