import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import RegisterUserScreen from '../Pages/LoginScreens/registerUser/registerUser';
import RegisterSuccess from '../Pages/LoginScreens/registerUser/registerSuccess';

export const AUTH_ROUTES = [
  {
    name: 'register',
    route: '/register',
    component: RegisterUserScreen
  },
  {
    name: 'registerSuccess',
    route: '/register-success',
    component: RegisterSuccess
  }
];
const Auth = () => (
  <Router>
    <Switch>
      <Route
        exact
        path="/redirect/to"
        component={(props) => {
          const redirectTo = (url) => (window.location.href = url);
          const params = new URLSearchParams(props.location.search);
          const webUrl = params.get('url');
          const mobileUrl = params.get('url_mobile');

          redirectTo(mobileUrl);

          const now = Date.now();
          setTimeout(() => {
            if (Date.now() - now < 400) {
              redirectTo(webUrl);
            }
          }, 200);
          return <div>redirect</div>;
        }}
      />

      {AUTH_ROUTES?.map(({ component, route, name }) => (
        <Route key={name} exact path={route} component={component} />
      ))}
    </Switch>
  </Router>
);

export default Auth;
