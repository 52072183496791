import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Icon, Img, Text } from '../../Atoms';
import {
  OrganismFormDocument,
  DescriptionRenderedSendDocument
} from '../../Organisms';
import success from '../../assets/img/doc-success.png';
import PageTitle from '../../Components/Dashboard/PageTitle';
function TemplateSendDocument(props) {
  const category = props?.state?.type?.name;

  return (
    <div className="template-container template-send-document template-scroll">
      {/* <div className="container-title-send-doc">
        <Icon
          icon="SortTop"
          size="20px"
          className="icon-return"
          onClick={() => props.onReturn()}
        />
       <Text
          text={`Envoyer un document`}
          className="title-send-document title-page"
        />
     
      </div> */}

      <PageTitle
        titleText={'Envoyer un document'}
        onTitleClick={props.onReturn}
      />

      {category && props.state.step == 2 && (
        <div style={{ paddingTop: 25, paddingLeft: 50 }}>
          <Text text={category} className="text-type-doc" type="h8"></Text>
        </div>
      )}
      {props.state.step === 2 || props.state.step === 1 ? (
        <div className="content-template-document">
          <div className="container-text-type-doc">
            <Text
              text={
                props.state.step === 1
                  ? 'Quel type de document souhaitez-vous nous envoyer ?'
                  : 'Merci de bien vouloir préciser votre type de document'
              }
              className="text-type-doc"
              type="h8"
            />
          </div>
          <Row
            className={
              props.state.step === 2 && props.state.categoryList?.length <= 5
                ? 'd-flex justify-content-center'
                : ''
            }
          >
            {props.state.step === 1 &&
              props.state.data
                .filter((el) => el.parentCategoryId == null)
                .map((el, index) => (
                  <Col
                    key={String(index)}
                    md={6}
                    xs={12}
                    className={
                      index % 2 === 0
                        ? 'container-left-card-doc'
                        : 'container-right-card-doc'
                    }
                  >
                    <Card
                      size="small"
                      text={el.name}
                      className="card-type-doc"
                      onClick={() => props.selectElement(el)}
                    />
                  </Col>
                ))}
            {props.state.step === 2 && props.state.categoryList?.length <= 5 ? (
              <div>
                {props.state.categoryList.map((el, index) => (
                  <Col key={String(index)} md={6} xs={12}>
                    <Card
                      size="small"
                      text={el.name}
                      className="card-type-doc"
                      onClick={() => props.selectCategory(el)}
                    />
                  </Col>
                ))}
              </div>
            ) : (
              props.state.step === 2 &&
              props.state.categoryList.map((el, index) => (
                <Col
                  key={String(index)}
                  md={
                    props.state.categoryList.length % 2 === 1
                      ? index + 1 === props.state.categoryList.length
                        ? 12
                        : 6
                      : 6
                  }
                  xs={12}
                  className={
                    props.state.categoryList.length % 2 === 1 &&
                    index + 1 === props.state.categoryList.length
                      ? 'container-center'
                      : index % 2 === 0
                      ? 'container-left-card-doc'
                      : 'container-right-card-doc'
                  }
                >
                  <Card
                    size="small"
                    text={el.name}
                    className="card-type-doc"
                    onClick={() => props.selectCategory(el)}
                  />
                </Col>
              ))
            )}
          </Row>
        </div>
      ) : props.state.step === 3 ? (
        <div>
          <Row>
            <Col xs={12} md={7}>
              <div className="container-description-condition-send-document">
                {(props.state.type.name.toLowerCase() === 'facture' &&
                  props.state.category.toLowerCase() === 'médecine douce' &&
                  props.state.stepMedicine == 1 &&
                  window.innerWidth < 768) ||
                props.state.category.toLowerCase() !== 'médecine douce' ||
                (props.state.category.toLowerCase() === 'médecine douce' &&
                  window.innerWidth >= 768) ? (
                  <DescriptionRenderedSendDocument
                    selectedSeuil={props.state.selectedSeuil}
                    onSelectSeuil={props.onSelectSeuil}
                    type={props.state.type}
                    categoryList={props.state.data}
                    category={props.state.category}
                  />
                ) : // <DescriptionSendDocument
                //   type={props.state.type}
                //   category={props.state.category}
                // />
                null}
              </div>
            </Col>
            <Col xs={12} md={5} className="container-col-send-doc">
              <OrganismFormDocument
                selectFile={props.selectFile}
                description={props.state.description}
                onSubmit={props.onSubmit}
                onChange={props.onChange}
                type={props.state.type}
                category={props.state.category}
                onChangeMedicine={props.onChangeMedicine}
                medicine={props.state.medicine}
                medicineStep={props.medicineStep}
                openNewMutual={props.openNewMutual}
                stepMedicine={props.state.stepMedicine}
                showNewMutual={props.state.showNewMutual}
                mutual={props.state.mutual}
                onChangeMutual={props.onChangeMutual}
                remboursement={props.state.remboursement}
                isUploadValid={props.state.isUploadValid}
                isDescriptionValid={props.state.isDescriptionValid}
                seuil={props.state.selectedSeuil}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="container-success">
          <Text
            text="Votre document a bien été envoyé."
            type="h4"
            className="doc-success-msg text-default-size"
          />
          <div className="container-center">
            <Img src={success} className="img-success-document object-fit" />
          </div>
        </div>
      )}
    </div>
  );
}
export default TemplateSendDocument;
