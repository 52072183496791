const initialState = {
  pipeline: [],
  serverError: false,
  isMaintenance: false
};

const LoaderReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_PIPELINE_LOADER':
      const aux = [...state.pipeline];
      aux.push(action.value);
      return {
        ...state,
        pipeline: aux
      };
    case 'RM_PIPELINE_LOADER':
      const aux2 = [...state.pipeline];
      aux2.splice(aux2.indexOf(action.value), 1);
      return {
        ...state,
        pipeline: aux2
      };
    case 'CLEAN_PIPE_LINE':
      return {
        ...state,
        pipeline: []
      };

    case 'SERVER_ERROR':
      return {
        ...state,
        serverError: !state.serverError
      };

    case 'SERVER_MAINTENANCE':
      return {
        ...state,
        isMaintenance: !state.isMaintenance
      };

    default:
      return state;
  }
};

export default LoaderReducer;
