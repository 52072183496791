import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Text, Button } from '../../Atoms';
import { Input } from '../../Molecules';
import {
  DemandeDesckTop,
  OrganismDemandeDeskTop,
  OrganismDemandeMobile
} from '../../Organisms';
export default function TemplateDemande (props) {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setwindowWidth(window.innerWidth);
    });
  }, []);
  const renderMobile = () => {
    return (
      <>
        <Text
          text='Visionnez l’évolution de vos demandes et échangez avec nos équipes !'
          className='title-demande'
        />
        <div className='demande-search-container'>
          <Input
            placeholder='Rechercher'
            leftIcon='Loop'
            leftIconClassName='demande-search-icon'
            leftIconContainerClassName='demande-icon-container'
            leftIconContainerOnClick={()=>props.OnLoopIconClick(false)}
            value={props.searchWord}
            onKeyPress={e => props.onKeyPress(e, false)}
            onChange={props.onChange}
            size={'16px'}
          />
        </div>
        <div className='demande-step1-container'>
          {props.demandeDataMobile.map((element, index) => {
            return (
              <OrganismDemandeMobile
                element={element}
                responseClicked={props.responseClicked}
                response={props.response}
                responseChanged={props.responseChanged}
                sendResponse={props.sendResponse}
              />
            );
          })}
        </div>
      </>
    );
  };
  return (
    <div>
      {windowWidth <= 768 ? (
        renderMobile()
      ) : (
        <OrganismDemandeDeskTop
          demandeData={props.demandeData}
          responseClicked={props.responseClicked}
          viewDemandeClicked={props.viewDemandeClicked}
          selectedDemande={props.selectedDemande}
          demandeStep={props.demandeStep}
          retourClicked={props.retourClicked}
          page={props.page}
          pageNumber={props.pageNumber}
          onChangePagination={props.onChangePagination}
          value={props.searchWord}
          onKeyPress={props.onKeyPress}
          OnLoopIconClick={props.OnLoopIconClick}
          onChange={props.onChange}
          sortClicked={props.sortClicked}
          response={props.response}
          responseChanged={props.responseChanged}
          sendResponse={props.sendResponse}
        />
      )}
    </div>
  );
}
