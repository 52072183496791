import React from 'react';
import { Img, Text, Button, Icon } from '../../Atoms';
import { Input, Modal } from '../../Molecules';
import notifTel from '../../assets/img/notifTel.png';
import { COLOR_BUTTON } from '../../Config/Config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function ModalConfirmAdress(props) {
  const history = useHistory();
  const goToAttestationPage = ()=>{
    history.push({
      pathname: `/documents/send`,
      state: {
        name:"ATTESTATION DE SECURITE SOCIALE"
      }
    });
  }

  return (
    <Modal
      show={props.show}
      showIconClose={false}
      centered={true}
      onHide={() => props.onHide()}
      contentClassName={
        'content-modal-confirm-password' + props.contentClassName
      }
    >
      {props.modalStep === 1 && !props.success ? (
        <div className="full-container">
          <Icon
            icon="BigX"
            className="icon-close-modal-confim-password"
            size="20px"
            onClick={() => props.onHide()}
          />
          <Text text={props.title} className="title-modal-confirm-password" />
          <Text
            text={props.firstText}
            className="text-description-confirm-password"
          />
          <Text
            text={props.secondText}
            className="text-description-confirm-password"
          />
          <div className="container-center">
            <Input
              classNameContainer="container-input-confirm-change"
              className={props.classNameInput}
              placeholder="tapez le code ici"
              onChange={props.onChange}
              value={props.code}
            />
          </div>
          {props?.errorMessages?.map((e) => {
            return (
              <p
                style={{
                  color: 'red',
                  textAlign: 'center',
                  fontSize: 12,
                  marginTop: 10
                }}
              >
                {e}
              </p>
            );
          })}
          <div className="container-center">
            <Button
              text="Valider"
              className={`${props.classNameBtnSubmit} web-display-none`}
              onClick={props.onConfirmCode}
              color={COLOR_BUTTON.GREEN}
            />
            <Button
              text="Confirmer le code"
              className={`${props.classNameBtnSubmit} mob-display-none`}
              onClick={props.onConfirmCode}
              color={COLOR_BUTTON.GREEN}
            />
          </div>
          <div className="container-center">
            <Button
              type="primary"
              text="Annuler"
              className={props.classNameBtncancel}
              onClick={props.onCancel}
              isAlreadyOutline={true}
              color={COLOR_BUTTON.BLACK}
            />
          </div>
        </div>
      ) : (
        props.modalStep === 1 &&
        props.success && (
          <div className="full-container">
            <Icon
              icon="BigX"
              className="icon-close-modal-confim-password"
              size="20px"
              onClick={() => props.onHide()}
            />
            <Text text={"Votre modification a bien été effectuée !"} className="title-modal-confirm-password" />
            <Text text={"Attention"} className="title-modal-confirm-password" />
            <Text
              text={`En cas de changement de centre de Sécurité
              Sociale et pour la bonne tenue de votre dossier,
              nous vous invitons à vous rapprocher de votre
              CPAM et de nous transmettre ultérieurement votre
              nouvelle attestation de carte vitale.`} 
              className="text-description-confirm-password"
            />
          
         
          
            <div className="container-center">
              <Button
                type="primary"
                text="Je dispose déjà de mon attestation"
                className={props.classNameBtncancel}
                onClick={goToAttestationPage}
                color={COLOR_BUTTON.GREEN}
              />
              <Button
                type="primary"
                text="Je n'ai pas encore mon attestation"
                className={props.classNameBtncancel}
                onClick={props.onCancel}
                color={COLOR_BUTTON.GREEN}
                isAlreadyOutline={true}
              />
            </div>
          </div>
        )
      )}
      {props.modalStep === 2 && (
        <div className="full-container">
          <Icon
            icon="BigX"
            className="icon-close-modal-confim-password"
            size="20px"
            onClick={() => props.onHide()}
          />
          <Text
            text={props.successText}
            className="title-modal-confirm-password"
          />
          <div className="container-center">
            <Img
              src={props.img}
              className="img-success object-fit object-fit"
            />
          </div>
          <div
            className="container-center title-web-hidden"
            style={{ marginTop: '22px' }}
          >
            <Button
              type="primary"
              text="Fermer"
              className={props.classNameBtncancel}
              onClick={props.onCancel}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}
ModalConfirmAdress.defaultProps = {
  img: notifTel,
  contentClassName: '',
  modalStep: 1,
  title: '',
  firstText: '',
  secondText: '',
  onConfirmCode: null,
  onCancel: null,
  classNameBtncancel: 'btn-cancel-confirm-password',
  classNameBtnSubmit: 'btn-submit-confirm-password',
  classNameInput: 'input-confirm-change',
  onChange: null,
  code: ''
};
export default ModalConfirmAdress;
