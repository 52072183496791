import React from 'react';
import loginImg from '../../assets/img/login.png';
import smallLoginImg from '../../assets/img/smallLoginImg.png';
import { Img } from '../../Atoms';
function OrganismePhotoLogin (props) {
  return (
    <div className='login-img-container'>
      {window.innerWidth > 768 ? (
        <Img src={loginImg} width={'100%'} height={'100%'} />
      ) : (
        <Img
          src={loginImg}
          style={{ fitObject: 'cover' }}
          width={'100%'}
          height={'100%'}
        />
      )}
    </div>
  );
}

export default OrganismePhotoLogin;
