import React from 'react';
import {  Text, Button, Icon } from '../../Atoms';
import {  Modal } from '../../Molecules';
import notifTel from '../../assets/img/notifTel.png';
import { COLOR_BUTTON } from '../../Config/Config';

function ModalMaintenance(props) {
  return (
    <Modal
      show={props.show}
      showIconClose={false}
      centered={true}
      onHide={() => props.onHide()}
      contentClassName={
        'content-modal-confirm-password' + props.contentClassName
      }
    >
      <div className="full-container">
        <Icon
          icon="BigX"
          className="icon-close-modal-confim-password"
          size="20px"
          onClick={() => props.onHide()}
        />
        <Text text={"Maintenance"} className="title-modal-confirm-password" />
        <Text
          text={props.message}
          className="text-description-confirm-password"
          
        />
      
        <div className="container-center">
          <Button
            type="primary"
            text="Merci pour l'info"
            className={props.classNameBtncancel}
            onClick={props.onHide}
            isAlreadyOutline={true}
            color={COLOR_BUTTON.BLACK}
          />
        </div>
      </div>
    </Modal>
  );
}
ModalMaintenance.defaultProps = {
  img: notifTel,
  contentClassName: '',
  modalStep: 1,
  title: '',
  firstText: '',
  secondText: '',
  onConfirmCode: null,
  onCancel: null,
  classNameBtncancel: 'btn-cancel-confirm-password',
  classNameBtnSubmit: 'btn-submit-confirm-password',
  classNameInput: 'input-confirm-change',
  onChange: null,
  code: ''
};
export default ModalMaintenance;
