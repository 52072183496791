import React, { useState } from 'react';
import { Text } from '../../Atoms';
import LoginInput from '../../Components/Login/LoginInput';
import styles from '../../Components/Login/LoginStyles.module.scss';

function OraganismChangeSettingDetails(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <>
      <Text
        text={props.firstInputLabel}
        className={props.firstInputClassName}
      />
      {props.firstInputdiscription && (
        <Text
          text={props.firstInputdiscription}
          className={props.firstInputdiscriptionClassName}
        />
      )}
      <LoginInput
        className={
          props.errorMsg === '' || props.errorMsg === undefined
            ? styles.loginInputUpdatePassword
            : styles.loginInputUpdatePasswordError
        }
        placeholder=""
        type="password"
        previewPasswordEnabled={true}
        onPreviewPassword={() => setShowPassword((curr) => !curr)}
        passwordVisible={showPassword}
        value={props.firstInputValue}
        onChange={props.firstInputOnChange}
      />

      <Text
        text={props.SecondInputLabel}
        className={props.firstInputdiscriptionClassName}
      />
      <LoginInput
        className={
          props.errorMsg === '' || props.errorMsg === undefined
            ? styles.loginInputUpdatePassword
            : styles.loginInputUpdatePasswordError
        }
        placeholder=""
        type="password"
        previewPasswordEnabled={true}
        onPreviewPassword={() => setShowConfirmPassword((curr) => !curr)}
        passwordVisible={showConfirmPassword}
        value={props.SecondInputValue}
        onChange={props.SecondInputOnChange}
      />

      {props.errorMsg && (
        <p style={{ color: 'red', marginTop : -5, marginBottom: 10 }} >{props.errorMsg}</p>
      )}
    </>
  );
}
OraganismChangeSettingDetails.defaultProps = {
  firstInputText: '',
  firstInputClassName: '',
  firstInputdiscription: '',
  firstInputdiscriptionClassName: '',
  firstInputClassNameContainer: '',
  firstInputlabelClassName: '',
  SecondInputLabel: ''
};
export default OraganismChangeSettingDetails;
