import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Icon, Text, Img } from '../../Atoms';
import fileImage from '../../assets/img/file.png';
MoleculeUpload.defaultProps = {
  text: 'Importer un fichier',
  textClassName: 'text-input-file',
  id: 'default-input-file',
  accept: '*/*',
  className: 'default-input-file',
  handleFileChange: null,
  isInvalid: false,
  errorMsg: '',
  errorMsgClassName: '',
  classNameFileInputContainer: '',
  contentClassName: '',
  uploadContent: null,
  style: null,
  size: 'medium',
  placeholder: 'Joindre un fichier (facultatif)',
  containerClassName: '',
  classNameText: 'upload-text',
  isUploadValid: true,
  type: 'multiple'
};
MoleculeUpload.prototypes = {
  text: PropTypes.string,
  textClassName: PropTypes.string,
  id: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  handleFileChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  errorMsg: PropTypes.string,
  errorMsgClassName: PropTypes.string,
  classNameFileInputContainer: PropTypes.string,
  contentClassName: PropTypes.string,
  uploadContent: PropTypes.any,
  style: PropTypes.object,
  size: PropTypes.PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.PropTypes.oneOf(['multiple', 'single']),
  placeholder: PropTypes.string,
  containerClassName: PropTypes.string,
  isUploadValid: PropTypes.bool
};

export default function MoleculeUpload(props) {
  const classNameFileInputContainer = ['container-files-input-file'];
  if (props.classNameFileInputContainer) {
    classNameFileInputContainer.push(props.classNameFileInputContainer);
  }
  const [file, setFile] = useState([]);
  const contentClassName = ['default-container-input-file'];
  if (!file.length && !props.isUploadValid) {
    contentClassName.push('invalid-container-input-file');
  }
  if (props.contentClassName) {
    contentClassName.push(props.contentClassName);
  }
  function getFile() {
    document.getElementById(props.id).click();
  }
  function handleFileChange(e) {
    if (props.size === 'small' || props.type == 'single') {
      setFile(e);
    } else {
      setFile([...file, ...e]);
    }
  }
  React.useEffect(() => {
    props.handleFileChange && props.handleFileChange(file);
  }, [file]);
  function renderInputFile() {
    return (
      <div style={props.style}>
        <input
          id={props.id}
          type="file"
          className={props.className}
          accept={props.accept}
          onChange={(e) => handleFileChange(e.target.files)}
        />
      </div>
    );
  }
  function dropHandler(ev) {
    ev.preventDefault();
    const files = [];
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          var f = ev.dataTransfer.items[i].getAsFile();
          files.push(f);
        }
      }
    } else {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        files.push(f);
      }
    }
    setFile([...file, ...files]);
  }
  function dragOverHandler(ev) {
    ev.preventDefault();
  }
  function renderInputWeb(uploadContent) {
    const renderInput = (
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '23px'
          }}
        >
          <Img src={fileImage} className="img-file-upload" />
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <p className="first-text-input-file">
            Glissez et déposez votre fichier ici
          </p>
        </div>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <p className="seconde-text-input-file">
            ou <span className={props.classNameText}>téléchargez-le</span>{' '}
            depuis votre ordinateur
          </p>
        </div>
      </div>
    );
    if (props.child !== undefined) {
      return (
        <span
          onDrop={(e) => dropHandler(e)}
          onDragOver={(e) => dragOverHandler(e)}
          onClick={() => getFile()}
        >
          {props.child}
        </span>
      );
    } else {
      return (
        <div
          className={contentClassName.join(' ')}
          onDrop={(e) => dropHandler(e)}
          onDragOver={(e) => dragOverHandler(e)}
          onClick={() => getFile()}
        >
          {uploadContent ? uploadContent : renderInput}
        </div>
      );
    }
  }
  function renderInputMobile(uploadContent) {
    const renderInput = (
      <div style={{ width: '100%' }}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Img src={fileImage} className="img-file-upload" />
          <p
            className="seconde-text-input-file"
            style={{ marginLeft: '32px', marginTop: '9px' }}
          >
            <span className={props.classNameText}>Prenez en photo</span> ou
            téléchargez votre document depuis votre smartphone
          </p>
        </div>
      </div>
    );
    contentClassName.push('input-file-mobile');
    if (props.child !== undefined) {
      return <span onClick={() => getFile()}>{props.child}</span>;
    } else {
      return (
        <div className={contentClassName.join(' ')} onClick={() => getFile()}>
          {uploadContent ? uploadContent : renderInput}
        </div>
      );
    }
  }
  function renderInput(uploadContent) {
    if (window.innerWidth < 762) {
      return renderInputMobile(uploadContent);
    } else {
      if (props.size == 'medium') {
        return renderInputWeb(uploadContent);
      } else {
        return renderSmallInput();
      }
    }
  }
  function renderFiles() {
    return (
      <div className={classNameFileInputContainer.join(' ')}>
        <div className="content-files-input-file">
          {file.map((el, index) => (
            <div className="file-description">
              <span className="ellipse">{el.name}</span>
              <Icon
                icon="Poubelle"
                color="red"
                onClick={() => deleteFile(index)}
                className="icon-delete-upload-file"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
  function deleteFile(i) {
    const list = [...file];
    list.splice(i, 1);
    setFile(list);
  }
  function renderSmallInput() {
    return (
      <div className="container-small-input-file" onClick={() => getFile()}>
        {file.length == 0 ? props.placeholder : file[0].name}
        <Icon icon="Plus" size={18} style={{ width: '18px', height: '18px' }} />
      </div>
    );
  }
  function renderError() {
    const className = ['upload-error-message'];
    if (props.errorMsgClassName) {
      className.push(props.errorMsgClassName);
    }
    return <div className={className.join(' ')}>{props.errorMsg}</div>;
  }
  return (
    <div className={props.containerClassName}>
      {renderInputFile()}
      {renderInput(props.uploadContent)}
      {props.size === 'medium' && props.type !== 'single' && renderFiles()}
      {!props.isUploadValid && renderError()}
    </div>
  );
}
