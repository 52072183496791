import React from 'react';
import { Text, Icon } from '../../Atoms';
import { CarteTpOrganism } from './../../Organisms/index';
import PageTitle from './../../Components/Dashboard/PageTitle';

function TemplateCarteTp(props) {
  return (
    <div className="template-scroll cartetp-container cartetp">
      <PageTitle titleText={'Ma carte de tiers-payant'} />
      <CarteTpOrganism
        check={props.check}
        checkBoxClicked={props.checkBoxClicked}
        validate={props.validate}
        validateClicked={props.validateClicked}
        download={props.download}
        handleSharing={props.handleSharing}
        civility={props.civility}
        firstName={props.firstName}
        lastName={props.lastName}
        socialSecurityNumber={props.socialSecurityNumber}
        adherentNumber={props.adherentNumber}
      />
    </div>
  );
}

export default TemplateCarteTp;
