import { Col, Form, Row } from 'react-bootstrap';
import { DemandeCardDetailOrganism, DemandeCardOrganism } from '..';
import { Button, Icon, Text } from '../../Atoms';
import {
  COLOR_BUTTON,
  DEMANDE_COLOR,
  DEMANDE_HEADER
} from '../../Config/Config';
import { Input, Pagination, Table } from '../../Molecules';
function OrganismDemandeDeskTop(props) {
  const renderStep1 = () => {
    return (
      <>
        <div className="demande-search-container">
          <div>
            <Text
              text="Visionnez l’évolution de vos demandes et échangez avec nos équipes !"
              className="title-demande"
            />
          </div>
          <div style={{ width: '308px' }}>
            <Input
              placeholder="Rechercher"
              leftIcon="Loop"
              leftIconClassName="demande-search-icon"
              leftIconContainerClassName="demande-icon-container"
              leftIconContainerOnClick={() => props.OnLoopIconClick(true)}
              value={props.searchWord}
              onKeyPress={(e) => props.onKeyPress(e, true)}
              onChange={props.onChange}
            />
          </div>
        </div>
        <table className="demande-table">
          <thead className="demande-table-header">
            <tr>
              {DEMANDE_HEADER.map((element, index) => {
                return (
                  <th>
                    <div className="demande-table-header-container">
                      <div className="demande-table-header-text text-default-size">
                        {element.text}
                      </div>
                      <div className="demande-table-header-icon-container">
                        <Icon
                          icon="SortTop"
                          size={6}
                          onClick={() => props.sortClicked(element.sort, 'ASC')}
                        />
                        <Icon
                          icon="Sort"
                          size={6}
                          onClick={() =>
                            props.sortClicked(element.sort, 'DESC')
                          }
                        />
                      </div>
                    </div>
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody className="demande-table-body">
            {props.demandeData.map((element, index) => {
              let findStatus = DEMANDE_COLOR.find(
                (el) => el.status == element.status
              );
              findStatus =
                findStatus === undefined
                  ? {
                      status: '',
                      color: 'white'
                    }
                  : findStatus;
              return (
                <tr>
                  <td className='text-default-size'>{element.date}</td>
                  <td className='text-default-size' style={{ color: findStatus.color, fontWeight: 'bold' }}>
                    {findStatus.text}
                  </td>
                  <td className='text-default-size'>{element.demande}</td>
                  <td className='text-default-size'>
                    {element.documentNbr}{' '}
                    {element.documentNbr > 1 ? 'documents' : 'document'}
                  </td>
                  <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      text="Voir la demande"
                      className="demande-card-button"
                      onClick={() => props.viewDemandeClicked(element, index)}
                      color={COLOR_BUTTON.ORANGE}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            page={props.page}
            pageNumber={props.pageNumber}
            numberOfElement={1}
            onChangePagination={props.onChangePagination}
          />
        </div>
      </>
    );
  };
  const renderStep2 = () => {
    return (
      <div className="demande-step2-container">
        <Row>
          <Col md={4}>
            <DemandeCardOrganism
              date={props.selectedDemande.date}
              demande={props.selectedDemande.demande}
              status={props.selectedDemande.status}
              documentNbr={props.selectedDemande.documentNbr}
              treatmentTime={props.selectedDemande.treatmentTime}
              showButton={false}
            />
          </Col>
          <Col md={8}>
            <DemandeCardDetailOrganism
              data={props.selectedDemande.detail}
              docData={props.selectedDemande.numFic}
              date={props.selectedDemande.date}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="demande-retour-container">
            <Button
              className="demande-retour-button"
              icon="FlecheSuivi"
              text="Retour"
              iconClassName="demande-retour-icon"
              onClick={props.retourClicked}
              color={COLOR_BUTTON.ORANGE}
              isAlreadyOutline={true}
            />
          </Col>

          {props.selectedDemande.status == 'WaitingLine' && (
            <Col md={8}>
              <Form.Control
                className="default-input demande-en-cour-input"
                placeholder="Repondre..."
                as={'textarea'}
                value={props.response}
                onChange={props.responseChanged}
              />
              <div className="demande-button-container">
                <Button
                  text="Répondre"
                  className="demande-card-button"
                  onClick={props.sendResponse}
                />
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const render = () => {
    switch (props.demandeStep) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      default:
        return null;
    }
  };

  return <div>{render()}</div>;
}

export default OrganismDemandeDeskTop;
