import React, { useState } from 'react';
import { Chart } from '../../Organisms/';
import { Text, Button, DatePicker } from '../../Atoms';
import { Input } from '../../Molecules';
import { Col, Row } from 'react-bootstrap';
import { COLOR_BUTTON, DEMANDE_COLOR, red } from '../../Config/Config';
import PropTypes from 'prop-types';

function OrganismDemandeCard (props) {
  let findStatus = DEMANDE_COLOR.find(
    element => element.status == props.status
  );
  findStatus =
    findStatus === undefined
      ? {
          status: '',
          color: 'white'
        }
      : findStatus;
  return (
    <div className='demande-card-container'>
      <Row className='demande-row-font'>
        <Col xs={5} sm={5} md={{ span: 12, order: 1 }}>
          <span className='close-weight'>Date : </span>
          <span>{props.date}</span>
        </Col>
        <Col xs={7} sm={7} md={{ span: 12, order: 4 }}>
          <span className='close-weight'>Demande </span>
          <span>{props.demande}</span>
        </Col>
        <Col xs={5} sm={5} md={{ span: 12, order: 2 }}>
          <span className='close-weight'>Statut : </span>
          <span style={{ color: findStatus.color }}>{findStatus.text}</span>
        </Col>
        <Col xs={7} sm={7} md={{ span: 12, order: 5 }}>
          <span className='close-weight'>Documents : </span>
          <span>{props.documentNbr} {props.documentNbr > 1 ? 'documents' : 'document'}</span>
        </Col>
        {!props.showButton && (
          <Col xs={12} sm={12} md={{ span: 12, order: 3 }}>
            <span className='close-weight'>Durée traitement : </span>
            <span>{props.treatmentTime}</span>
          </Col>
        )}
      </Row>
      {props.showButton && (
        <Button
          text='Voir la demande'
          className='demande-card-button'
          onClick={props.onClick}
          color={COLOR_BUTTON.ORANGE}
        />
      )}
    </div>
  );
}
OrganismDemandeCard.PropTypes = {
  date: PropTypes.string,
  demande: PropTypes.string,
  status: PropTypes.string,
  documentNbr: PropTypes.number,
  showButton: PropTypes.bool,
  treatmentTime: PropTypes.string
};
OrganismDemandeCard.defaultProps = {
  date: '25/01/2021',
  demande: 'Garanties',
  status: 'Non traité',
  documentNbr: 1,
  showButton: true,
  treatmentTime: ''
};
export default OrganismDemandeCard;
