const initialState = {
  profile: []
};

function ProfileReducer (state = initialState, action) {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.value.profile
      };
    default:
      return state;
  }
}

export default ProfileReducer;
