export const serilizeGetMembers = (res, type) => {
  const list = [];
  const userData = formatProfileResponse(res, type)
  list.push(userData);
  const beneficiaries = res.benificiaries;
  try {
    if (beneficiaries && beneficiaries.length) {
      for (let i = 1; i < beneficiaries.length; i++) {
        list.push({
          value: {
            id: '',
            name: beneficiaries[i].firstName,
            birthDate: beneficiaries[i].birthDate,
            tel: beneficiaries[i].mailPhoneFax?.phone,
            lastName: beneficiaries[i].lastName,
            mail: beneficiaries[i].mailPhoneFax?.emailAddress,
            adresse: beneficiaries[i].address?.cityAddress,
            accountOwner: beneficiaries[i].bankingInformation?.accountOwnerName,
            iban: beneficiaries[i].bankingInformation?.iban,
            bankName: beneficiaries[i].bankingInformation?.bic,
            bic: beneficiaries[i].bankingInformation?.bic,
            remboursement: true,
            prelevement: false,
            cp: beneficiaries[i].address?.postalCode,
            ville: beneficiaries[i].address?.city,
            title: beneficiaries[i].gender,
            numeroSecuriteSociale: beneficiaries[i].securityNumber,
            rattachement: '',
            typeBeneficiaire: beneficiaries[i].typeAdherent,
            related: beneficiaries[i].related,
            codeAdherent: beneficiaries[i].codeAdherent
          },
          name: `${beneficiaries[i].firstName} ${beneficiaries[i].lastName}`
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
  
  return list;
};



export const formatProfileResponse = (res, type) => {
  const bank = type == 0 ? res.paymentAccount : res.bankingInformation;
  const data = {
    value : {
    id: res.id,
    name: res.firstName,
    birthDate: res.birthDate,
    tel: res.mailPhoneFax?.phone,
    lastName: res.lastName,
    mail: res.mailPhoneFax?.emailAddress,
    adresse: res.postalAddress?.cityAddress,
    accountOwner: bank?.accountOwnerName,
    iban: bank?.iban,
    bankName: bank?.bic,
    bic: bank?.bic,
    remboursement: true,
    prelevement: false,
    cp: res.postalAddress?.postalCode,
    ville: res.postalAddress?.city,
    title: res.gender,
    numeroSecuriteSociale: res.securityNumber,
    rattachement: res.number,
    typeBeneficiaire: '',
    related: "Ayant-Droit"
    },
    name: `${res.firstName} ${res.lastName}`
  }
  return data
}