import React from "react"
import { Icon, Text } from "../../Atoms"
function OrganismElementNotification(props) {
    const dateClassName = ['date-text-element-notification']
    const descriptionClassName = ["description-text-element-notification"]
    if (props.weight === true) {
        dateClassName.push('weight-notification')
        descriptionClassName.push('weight-notification')
    }
    return <div className="element-notification">
        {props.weight && <div className="dot-notification"></div>}
        <div className="container-icon-element-notification">
            <div className="content-icon-element-notification" style={{ backgroundColor: props.color }}>
                <Icon icon={props.icon} size="16px" color="#FFFFFF" />
            </div>
        </div>
        <div className="container-description-element-notification">
            <Text text={props.date} className={dateClassName.join(' ')} />
            <Text text={props.description} className={descriptionClassName.join(' ')} />
        </div>
    </div>
}
OrganismElementNotification.defaultProps = {
    description: "",
    date: "",
    color: "red",
    weight: false
}
export default OrganismElementNotification
