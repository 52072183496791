import React from 'react';
import { Img, Text, Button, Icon } from '../../Atoms';
import { Input, Modal } from '../../Molecules';
import notifTel from '../../assets/img/notifTel.png';

function ModalConfirmAddBeneficiary(props) {
  return (
    <Modal
      show={props.show}
      showIconClose={false}
      centered={true}
      contentClassName={'content-modal-confirm-password'}
    >
      <div className="full-container">
        <Icon
          icon="BigX"
          className="icon-close-modal-confim-password"
          size="20px"
          onClick={() => props.onClose()}
        />
        <Text
          text={`Ajout de bénéficiare`}
          className="title-modal-confirm-password"
        />
        <p
          style={{ fontFamily: 'Mont', marginBottom: 10, textAlign: 'center' }}
        >
          Cette modification peut entrainer une{' '}
          <span style={{ fontWeight: 'bold' }}>modification tarifaire.</span>
        </p>

        <p
          style={{ fontFamily: 'Mont', marginBottom: 10, textAlign: 'center' }}
        >
          Votre demande d'ajout de bénéficiaire sera envoyée et traitée par
          notre service adhésion.
        </p>

        <p
          style={{ fontFamily: 'Mont', marginBottom: 10, textAlign: 'center' }}
        >
          Souhaitez-vous valider la demande d'ajout de
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            {`${props.beneficiary?.join(', ')} ?`}
          </span>
        </p>

        <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
          <Button
            type="primary"
            text="Annuler"
            isAlreadyOutline
            className="btn-submit-modal-edit-profile"
            color="gray"
            onClick={() => props.onClose()}
          />
          <Button
            type="primary"
            color="#8BCAB5"
            text="Valider"
            className="btn-submit-modal-edit-profile"
            onClick={() => props.submit()}
          />
        </div>
      </div>
    </Modal>
  );
}

ModalConfirmAddBeneficiary.defaultProps = {
  img: notifTel,
  contentClassName: '',
  modalStep: 1,
  title: '',
  firstText: '',
  secondText: '',
  onConfirmCode: null,
  onCancel: null,
  classNameBtncancel: 'btn-cancel-confirm-password',
  classNameBtnSubmit: 'btn-submit-confirm-password',
  classNameInput: 'input-confirm-change',
  onChange: null,
  code: ''
};
export default ModalConfirmAddBeneficiary;
