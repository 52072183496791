import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '..';
AtomText.PropTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.any,
  style: PropTypes.object,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8']),
  filter: PropTypes.bool,
  filterText: PropTypes.string,
  loader: PropTypes.bool
};
AtomText.defaultProps = {
  text: null,
  className: null,
  onClick: null,
  style: null,
  type: 'h1',
  filter: false,
  filterText: '',
  loader: true
};
function AtomText (props) {
  let className = '';
  if (props.type.toUpperCase() === 'H1') {
    className += 'text-h1 ';
  }
  if (props.type.toUpperCase() === 'H2') {
    className += 'text-h2 ';
  }
  if (props.type.toUpperCase() === 'H3') {
    className += 'text-h3 ';
  }
  if (props.type.toUpperCase() === 'H4') {
    className += 'text-h4 ';
  }
  if (props.type.toUpperCase() === 'H5') {
    className += 'text-h5 ';
  }
  if (props.type.toUpperCase() === 'H6') {
    className += 'text-h6 ';
  }
  if (props.type.toUpperCase() === 'H7') {
    className += 'text-h7 ';
  }
  if (props.type.toUpperCase() === 'H8') {
    className += 'text-h8 ';
  }
  function renderText () {
    if (props.className) {
      className += ' ' + props.className;
    }
    if (props.filter != true) {
      return (
        <p className={className} style={props.style} onClick={props.onClick}>
          {/* {!props.text && props.loader ? <Loader /> : props.text} */}
          {props.text}
        </p>
      );
    } else {
      const { firstText, lastText } = renderFilter();
      return (
        <p className={className} style={props.style} onClick={props.onClick}>
          <span style={{ fontWeight: '600', marginRight: '-2px' }}>
            {firstText}
          </span>{' '}
          {lastText}
        </p>
      );
    }
  }
  function renderFilter () {
    const index = props.text
      .toUpperCase()
      .indexOf(props.filterText.toUpperCase());
    const firstText = props.text.slice(index, props.filterText.length);
    const lastText = props.text.slice(
      index + firstText.length,
      props.text.length
    );
    return { firstText: firstText, lastText: lastText };
  }

  return renderText();
}
export default AtomText;
