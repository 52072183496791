import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ListDocumentTemplate } from '../../Templates';
class ListDocument extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: []
    };
  }

  onChangeRoute = (link, data = '', redirect= false) => {
   if(redirect) {
    this.props.history.push(link, data);
   }else {
    this.props.history.push(link);
   }
    if (data && !redirect) {
      this.props.dispatch({
        type: 'SET_DOCUMENT_CATEGORY',
        value: { data: data }
      });
    }
  };

 

  render () {
    return (
      <ListDocumentTemplate
        onChangeRoute={this.onChangeRoute}
        data={this.state.data}
      />
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch: action => {
      dispatch(action);
    }
  };
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, mapDispatchToProps)(ListDocument);
