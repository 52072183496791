import React from 'react';
import { Icon, Text } from '../../Atoms';
import EyeSvg from '../../assets/img/eyes.svg';
import styles from  '../../Components/Login/LoginStyles.module.scss';
import { COLOR_BUTTON } from '../../Config/Config';

function MoleculeDetailDocument (props) {
  return (
    <div className='container-molecule-document'>
      <div className='container-description-doc'>
        <Text
          text={props.type}
          className='txt-description-doc'
          loader={false}
        />
        <Text
          text={props.amount}
          className='txt-description-doc'
          loader={false}
        />
      </div>
      <div className='molecule-document'>
        <Icon icon='Doc' size='22px' className='icon-document' />
        <div style={{fontWeight: "bold"}} className='container-text-detail-doc'>{props.text}</div>
        <div className='container-icons-detail-doc'>
          <img
          style={{cursor: 'pointer'}}
              src={EyeSvg}
              className={`${styles.loginInputEyeIcon}`}
              onClick={props.onViewFile}
            />
          <Icon
           style={{cursor: 'pointer'}}
            icon='Telechargement'
            size='22px'
            onClick={props.onDownload}
          />
        </div>
      </div>
    </div>
  );
}
MoleculeDetailDocument.defaultProps = {
  text: null,
  type: null,
  amount: null,
  onViewFile: null,
  onDownload: null
};
export default MoleculeDetailDocument;
