import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getPractitionerSpecialities } from '../../../Api/Geolocation/Geolocation.api';
import { Icon, Text } from '../../../Atoms';
import GeolocationHeader from '../components/GeolocationHeader';
import styles from '../Geolocation.module.scss';

const SelectPractitionerType = (props) => {
  const history = useHistory();
  const [list, setPractitionerList] = useState([]);
  const [error, setError] = useState(false);

  const onGetPractionner = async () => {
    const res = await getPractitionerSpecialities();

    if (res.status !== 200) {
      setPractitionerList([]);
      return setError(true);
    }

    setPractitionerList(res.data);
    setError(false);
  };

  const onPressPractitioner = (e) => {
    history.push({
      pathname: '/geolocation/set-location',
      search: `?type=${e.target.value}`
    });
  };

  useEffect(() => {
    onGetPractionner();
  }, []);

  return (
    <div className={styles['geolocation-template']}>
      <GeolocationHeader />
      <div className={styles['select-practitioner-content']}>
        <Text
          type="h5"
          className={styles['select-practitioner-title']}
          text="Que recherchez-vous ?"
        />
        {list.length === 0 && error ? (
          <div className={styles['select-practitioner-error-container']}>
            <div className={styles['select-practitioner-error-text']}>
              Impossible de charger les praticiens
            </div>
            <button
              className={styles['select-practitioner-error-btn']}
              onClick={onGetPractionner}
            >
              Réessayer
            </button>
          </div>
        ) : null}
        <div className={styles['select-practitioner-buttons-container']}>
          {list.map((item) => {
            return (
              <button
                key={item}
                className={styles['select-practitioner-buttons']}
                onClick={onPressPractitioner}
                value={item}
              >
                {item}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectPractitionerType;
