import React from 'react'
import { Img, Text } from '../../Atoms'
function organismLinkHealth(props) {
    const imgClassName = ["img-link-health"]
    if (props.imgDirection === 'circle') {
        imgClassName.push('img-circle-link-health')
    }
    if (props.imgDirection === 'horizontale') {
        imgClassName.push('img-horizontale-link-health')
    }
    if (props.imageClassName) {
        imgClassName.push(props.imageClassName)
    }
    return <div className="container-link-health">
        <div className="content-link-health">
            <Img src={props.src} className={imgClassName.join(' ')} onClick={props.onClick} />
        </div>
        <div style={{ height: "50%" }}>
            <Text text={props.text} className="text-link-health" onClick={props.onClick} />
        </div>
    </div>
}
organismLinkHealth.defaultProps = {
    img: null,
    text: null,
    onClick: null,
    imgDirection: "vertical",
    imageClassName: null
}
export default organismLinkHealth
