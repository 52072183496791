import React from 'react';
import { Text, Button, Icon } from '../../Atoms';
import { Input } from '../../Molecules';
function TemplateHospitalisationStep3 (props) {
  return (
    <div className='template-scroll cartetp-container'>
      <div className='cartetp-header-container hospitalisation-title-container'>
        <Text text='Hospitalisation' type='h4' />
      </div>
      <Icon
        icon='Sort'
        size={15}
        onClick={() => props.prevStep()}
        className='go-back-icon-hospitalisation'
      />
      <Text
        text='Informations sur l’établissement'
        type='h7'
        className='hospitalisation-step-sub-title'
      />

      <div className='container-hospitalisation'>
        <Text
          text='Informations sur l’établissement'
          type='h7'
          className='hospitalisation-step-title'
        />
        <div className='container-hospitalisation-fomulaire'>
          <div className='hospitalisation-fomulaire-left-container'>
            <div>
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='Nom de l’établissement'
                value={props.data.facilityName}
                onChange={e => props.onChange(e.target.value, 'facilityName')}
              />
            </div>
            <div className='input-hospitalisation-container'>
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='Adresse'
                value={props.data.adresse}
                onChange={e => props.onChange(e.target.value, 'adresse')}
              />
            </div>
            <div className='input-hospitalisation-container'>
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='CP'
                value={props.data.cp}
                onChange={e => props.onChange(e.target.value, 'cp')}
              />
            </div>
            <div className='input-hospitalisation-container'>
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='Ville'
                value={props.data.city}
                onChange={e => props.onChange(e.target.value, 'city')}
              />
            </div>
          </div>
          <div className='hospitalisation-fomulaire-right-container'>
            <div
              style={{ marginTop: window.innerWidth <= 768 ? '24px' : '0px' }}
            >
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='Email'
                value={props.data.mail}
                onChange={e => props.onChange(e.target.value, 'mail')}
              />
            </div>
            <div className='input-hospitalisation-container'>
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='Téléphone'
                value={props.data.tel}
                onChange={e => props.onChange(e.target.value, 'tel')}
              />
            </div>
            <div className='input-hospitalisation-container'>
              <Input
                className='input-hospitalisation-formulaire'
                placeholder='Fax'
                value={props.data.fax}
                onChange={e => props.onChange(e.target.value, 'fax')}
              />
            </div>
          
          </div>
        </div>
        
        {props.showNextStepBtn() && (
          <div className='btn-next-step-fomulaire-hospitalisation'>
            <p style={{color : 'red'}}>{props.errorMessage}</p>
            <Button
              text={'Confirmer'}
              className='submit-btn-modal-calendar'
              boxShadow
              onClick={() => props.nextStep()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TemplateHospitalisationStep3;
