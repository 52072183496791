import { apiGet, apiPost } from '../standardApi';

export async function apiPostClientCarteTpDownload (
  memberId,
  codeMember,
  memberType
) {
  const response = await apiGet(
    '/Clients/ClientCarteTpDownload',
    // JSON.stringify({
    //   memberId: memberId,
    //   codeMember: codeMember,
    //   memberType: memberType
    // }),
    'application/json'
  );
  return response;
}

export async function apiGetClientCarteTpDownload (codeAdherent,) {
  const response = await apiGet(
     `/CarteTp/GetCarteTp?codeAdherent=${codeAdherent}`,
    'application/json'
  );
  return response;
}
