import React from 'react';
import { Text, Button } from '../../Atoms';
import { COLOR_BUTTON } from '../../Config/Config';
function OrganismDescriptionRenderedSendDocument(props) {
  const descriptionIndex = props.categoryList.find((elem) => {
    return elem.categoryName == props.category;
  });
  function htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }
  function renderHeader() {
    if (props.type && props.category) {
      return (
        <Text
          text={props.type.name + ' - ' + props.category}
          type="h8"
          className="header-description-document"
        />
      );
    }
    return null;
  }
  if (descriptionIndex) {
    return (
      <div>
        {renderHeader()}
        {descriptionIndex?.info4User && (
          <div
            dangerouslySetInnerHTML={{
              __html: htmlDecode(
                descriptionIndex.info4User?.replace(
                  /(font-size:\s*13px;)/g,
                  'font-size: 16px;'
                )
              )
            }}
          ></div>
        )}
        {descriptionIndex?.addressValid && (
          <>
            <div>
              Vous pouvez également nous transmettre ces documents{' '}
              <span className="doc-bold-text">(sans agrafe)</span> par voie
              postale à l&apos;adresse ci-dessous :{' '}
            </div>
            <div
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: htmlDecode(descriptionIndex.addressValid)
              }}
            ></div>{' '}
          </>
        )}
        <br />
        {props.type?.name == 'FACTURE' ? (
          <>
            <div>
              {' '}
              Merci de sélectionner le montant total estimé de la facture
            </div>
            <div className="mt-20">
              <Button
                text="Inférieur a 150€"
                className={`btn-seuil-doc mr-20 ${
                  props.selectedSeuil == 1 ? 'btn-seuil-doc-select' : ''
                }`}
                onClick={() => props.onSelectSeuil(1)}
                resetHover={true}
                color={COLOR_BUTTON.SALMON}
              />
              <span className="doc-bold-text">ou</span>
              <Button
                text="Supérieur a 150€"
                className={`btn-seuil-doc ml-20 ${
                  props.selectedSeuil == 2 ? 'btn-seuil-doc-select' : ''
                }`}
                onClick={() => props.onSelectSeuil(2)}
                resetHover={true}
                color={COLOR_BUTTON.SALMON}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default OrganismDescriptionRenderedSendDocument;
