import React, { useState } from 'react';
import { RemboursementOrganism } from '../../Organisms/';
import { Text, Icon, DatePicker } from '../../Atoms';
import { ChartLegendMolecule, SliderPoint } from '../../Molecules';
import { Row, Col } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { CONSUMPTIONTYPE } from '../../Config/enum';
function OrganismChart(props) {
  const [index, setindex] = useState(0);
  const getOptionChart = color => {
    return {
      tooltip: {
        enabled: false
      },
      states: {
        hover: {
         filter: {
            type: 'none'
          }
        }
      },
      stroke: {
        colors: ['undefined']
      },
      chart: {
        id: 'consomation',
        height: '100%',
        type: 'donut',
        redrawOnWindowResize: true,
        dropShadow: {
          enabled: false
        },
        events: {
          click: undefined,
        }
      },
      legend: {
        show: false,
      },
      colors: color,
      dataLabels: {
        formatter: function (val, num, opts) {
          return num.w.globals.series[num.seriesIndex].toFixed(2) + ' €';
        },
        style: {
          fontSize: '16px',
          fontFamily: 'Mont',
          fontWeight: "bold",
          colors: ['#000']
        },
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          customScale: 0.7,
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          dataLabels: {
            offset: 55,
            minAngleToShowLabel: 0
          },
          donut: {
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: false
              },
              value: {
                fontSize: '25px',
                fontFamily: 'Mont',
                fontWeight: "bold",
                color: '#000'
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '25px',
                fontFamily: 'Mont',
                fontWeight: "bold",
                color: '#000',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                  return total.toFixed(2) + ' €';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              type: 'donut',
              width: '100%',
              height: 'auto'
            },
            dataLabels: {
              style: {
                fontSize: '14px',
                fontFamily: 'Mont',
                fontWeight: "bold",
                colors: ['#000']
              }
            },
            plotOptions: {
              pie: {
                customScale: 0.8,
                expandOnClick: false,
                dataLabels: {
                  offset: 40
                },
                donut: {
                  background: 'transparent',
                  labels: {
                    show: true,
                    name: {
                      show: false
                    },
                    value: {
                      fontSize: '25px',
                      fontFamily: 'Mont',
                      fontWeight: "bold",
                      color: '#000'
                    },
                    total: {
                      show: true,
                      fontSize: '25px',
                      fontFamily: 'Mont',
                      fontWeight: 500,
                      color: '#000',
                      formatter: function (w) {
                        const total = w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0);
                        return total.toFixed(2) + ' €';
                      }
                    }
                  }
                }
              }
            }
          }
        },
        {
          breakpoint: 425,
          options: {
            chart: {
              type: 'donut',
              width: '100%',
              height: 'auto'
            },
            dataLabels: {
              style: {
                fontSize: '12px',
                fontFamily: 'Mont',
                fontWeight: "bold",
                colors: ['#000']
              }
            },
            plotOptions: {
              pie: {
                customScale:0.8,
                expandOnClick: false,
                dataLabels: {
                  offset: 35
                },
                donut: {
                  background: 'transparent',
                  labels: {
                    show: true,
                    name: {
                      show: false
                    },
                    value: {
                      fontSize: '18px',
                      fontFamily: 'Mont',
                      fontWeight: 'bold',
                      color: '#000'
                    },
                    total: {
                      show: true,
                      fontSize: '25px',
                      fontFamily: 'Mont',
                      fontWeight: 500,
                      color: '#000',
                      formatter: function (w) {
                        const total = w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0);
                        return total.toFixed(2) + ' €';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      ]
    };
  };
  const renderLegend = () => {
    switch (props.vision) {
      case CONSUMPTIONTYPE.GLOBAL:
        return props.seriesVisionGlobal?.map(element => {
          return (
            <ChartLegendMolecule
              legend={element.legend}
              color={element.color}
            />
          );
        });
      case CONSUMPTIONTYPE.BENEF:
        return props.seriesBeneficiary.map(element => {
          return (
            <ChartLegendMolecule
              legend={element.legend}
              color={element.color}
            />
          );
        });
      case CONSUMPTIONTYPE.POSTE:
        return props.seriesPoste[index]?.data?.map(element => {
          return (
            <ChartLegendMolecule
              legend={element.legend}
              color={element.color}
            />
          );
        });
      default:
        return null;
    }
  };
  const iconLeftClicked = () => {
    if (index != 0) {
      setindex(index - 1);
    }
  };
  const iconRightClicked = () => {
    if (index != props.seriesPoste.length - 1) {
      setindex(index + 1);
    }
  };
  const renderIconLeft = () => {
    return props.vision == CONSUMPTIONTYPE.POSTE && props.seriesPoste.length != 0;
  };

  const renderIconRight = () => {
    return props.vision == CONSUMPTIONTYPE.POSTE && props.seriesPoste.length != 0;
  };
  const renderChart = () => {
    switch (props.vision) {
      case CONSUMPTIONTYPE.GLOBAL:
        return (
          <Chart
            options={getOptionChart(
              props.seriesVisionGlobal.map(element => element.color)
            )}
            series={
              props?.seriesVisionGlobal?.length
                ? props.seriesVisionGlobal.map(element => element.data)
                : []
          }
            type='donut'
            height='270px'
            width='350px'
          /> )
      case CONSUMPTIONTYPE.BENEF:
        return (
          <Chart
            options={getOptionChart(
              props.seriesBeneficiary.map(element => element.color)
            )}
            series={
              props?.seriesBeneficiary?.length
                ? props.seriesBeneficiary.map(element => element.data)
                : []
            }
            type='donut'
            height='270px'
            width='350px'
          />
        );
      case CONSUMPTIONTYPE.POSTE:
        return (
          <Chart
            options={getOptionChart(
              props.seriesPoste[index]?.data.map(element => element.color)
            )}
            series={
              props?.seriesPoste?.length
                ? props.seriesPoste[index]?.data.map(element => element.data)
                : []
            }
            type='donut'
            height='270px'
            width='350px'
          />
        );
      default:
        return null;
    }
  };
  const pointClicked = index => {
    setindex(index);
  };
  const renderTitle = () => {
    const year = new Date(Date.now()).getFullYear();
    const yearToRender = props.annual == 'Cette année' ? year : year - 1;
    switch (props.vision) {
      case CONSUMPTIONTYPE.GLOBAL:
        return `Consommation ${yearToRender}`;
      case CONSUMPTIONTYPE.BENEF:
        return `Reste à charge par bénéficiaire en ${yearToRender}`;
      case CONSUMPTIONTYPE.POSTE:
        return `Consommation par poste en ${yearToRender}`;
      default:
        return null;
    }
  };
  return (
    <div className='chart-container'>
      <Text text={renderTitle()} className='chart-title' />
      {props.vision == CONSUMPTIONTYPE.POSTE && (
        <Text
          text={props.seriesPoste[index]?.serie}
          className='legend-poste-title-mobile'
        />
      )}
      <div className='consomation-icon-container'>
        {renderIconLeft() && (
          <Icon
            icon='SortTop'
            className={
              index == 0 ? 'chart-icon-left-hidden' : 'chart-icon-left'
            }
            size='24px'
            onClick={iconLeftClicked}
          />
        )}
        {renderIconRight() && (
          <Icon
            icon='SortTop'
            className={
              index == props.seriesPoste.length - 1
                ? 'chart-icon-right-hidden'
                : 'chart-icon-right'
            }
            size='24px'
            onClick={iconRightClicked}
          />
        )}
      </div>
      <div className='chart-content'>

        <div className='chart-graph-container'>{renderChart()}</div>
        <div
          className={
            props.vision == CONSUMPTIONTYPE.BENEF
              ? 'chart-legends-container-grid'
              : 'chart-legends-container'
          }
        >
          {props.vision == CONSUMPTIONTYPE.POSTE && (
            <Text
              text={props.seriesPoste[index]?.serie}
              className='legend-poste-title-desktop'
            />
          )}
          {renderLegend()}
        </div>
      </div>
      {props.vision == CONSUMPTIONTYPE.POSTE && (
        <SliderPoint
          index={index}
          length={props.seriesPoste.length}
          pointClicked={pointClicked}
        />
      )}
    </div>
  );
}

export default OrganismChart;
